<template>
  <v-container fluid>
    <v-main>
      <h1>Firma Verbale di campionamento</h1>
    </v-main>
    <div v-if="loadingComponents">
      <v-main v-if="loadingComponents">
        <DatiFirmatario
          v-if="rendiconto.assegnazioneControlloUdm.incaricato"
          :responsabile-controllo="
            rendiconto.assegnazioneControlloUdm.incaricato
          "
        />
      </v-main>
      <v-main v-if="loadingComponents">
        <CaricamentoFileVerbaleEstensioneUdm
          :rendiconto="rendiconto"
          :verbale="verbaleEstensione"
          :tipo-caricamento="1"
          @callback="salvaVerbale"
          @inizializzazionePagina="inizializzazionePagina"
        />
      </v-main>
      <v-main v-if="rendiconto">
        <v-row>
          <v-col cols="5">
            <v-btn color="white" class="w-100 align-center justify-center">
              <router-link
                :to="{
                  name: 'verbaleEstensioneUdm',
                  params: { idRendiconto: idRendicontoSelezionato },
                }"
              >
                Indietro
              </router-link></v-btn
            ></v-col
          >
          <v-col cols="2"></v-col>
          <v-col v-if="verbaleEstensione.fileVerbaleEsteso" cols="5">
            <router-link
              class="v-btn primary float-end"
              :disabled="verbaleEstensione.fileVerbaleEsteso == null"
              :to="{
                name: 'verificaRendicontoUdmStepUno',
                params: { idRendiconto: this.idRendicontoSelezionato },
              }"
            >
              <span class="ma-1 pa-1"> Continua </span>
            </router-link>
          </v-col>
          <v-col v-else cols="5">
            <v-btn
              color="primary"
              v-html="'Continua'"
              class="v-btn primary float-end"
              :disabled="true"
            ></v-btn>
          </v-col>
        </v-row>
      </v-main>
      <v-snackbar
        v-model="snackbar"
        :timeout="5000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import DatiFirmatario from "@/components/Controlli/VerificheRendiconti/DatiFirmatarioComponent.vue";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import CaricamentoFileVerbaleEstensioneUdm from "@/components/Controlli/Udm/VerificheSostanziali/CaricamentoFileVerbaleEstensioneUdmComponent.vue";
export default {
  name: "firmaVerbaleEstensioneUdm",
  components: {
    CaricamentoFileVerbaleEstensioneUdm,
    DatiFirmatario,
  },
  computed: {
    ...mapState(["anno", "campioneAggiornato", "campioneEstratto", "user"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
  },
  watch: {
    anno() {
      this.loadinPage = false;
      this.initialize();
    },
  },
  data: () => ({
    loadingComponents: false,
    idRendicontoSelezionato: null,
    idVerbale: null,
    rendiconto: null,
    verbaleCampionamento: null,
    verificaRendiconto: null,
    verbaleEstensione: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    form: {
      uploadedFiles: null,
      currentStep: 6,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.isRoleConsulenza || this.isRoleIncaricatoControllo) &&
        this.$route.params.idRendiconto &&
        this.$route.params.idVerbale
      ) {
        this.idRendicontoSelezionato = this.$route.params.idRendiconto;
        this.idVerbale = this.$route.params.idVerbale;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
          parseInt(this.anno),
          this.idRendicontoSelezionato,
          -1
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.verificaRendiconto = this.rendiconto.verificaRendicontoUdm;
        this.verbaleEstensione =
          this.rendiconto.verificaRendicontoUdm.verbaleEstensione.find(
            (element) => element.id === this.idVerbale
          );
        this.loadingComponents = true;
      }
    },
    async salvaVerbale(form) {
      const formData = new FormData();
      formData.append("file", form.uploadedFiles);
      try {
        await ControlliUdmRepository.salvaVerbaleEstensioneUdm(
          this.anno,
          this.verbaleEstensione.id,
          true,
          formData
        );
        this.inizializzazionePagina();
        this.snackbarText =
          "Il file di firma del verbale è stato caricato correttamente.";
        this.snackbarColor = "success";
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "E' stato riscontrato un errore di server durante la richiesta.";
        this.snackbarColor = "red accent-2";
      }
      this.snackbar = true;
    },
    inizializzazionePagina() {
      this.loadingComponents = false;
      this.initialize();
    },
    goToNextStep() {},
  },
};
</script>
