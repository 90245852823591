const filtersNumber = [
  {
    name: "formatNumber",
    execute: function (value) {
      if (value) {
        return Number(value).toLocaleString();
      }
      return 0;
    },
    // execute: function (value, decimals = 2, thousandsSeparator = '.') {
    //     let result = parseFloat(value).toFixed(decimals).toString();
    //     if (thousandsSeparator) result = result.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
    //     return result
    // }
  },
];

export default filtersNumber;
