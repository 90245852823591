<template>
  <div class="container" v-if="loadingComponent">
    <v-card-text>
      <v-row class="">
        <v-col cols="4">ID Accordo </v-col>
        <v-col cols="4">Data inserimento accordo </v-col>
        <v-col cols="4">Anno scolastico </v-col>
      </v-row>
      <v-row class="font-weight-bold">
        <v-col cols="4">{{ accordo.id }} </v-col>
        <v-col cols="4"
          >{{ formatDate(accordo.fileAccordo.dataInserimento) }}
        </v-col>
        <v-col cols="4"
          >{{ accordo.annoScolastico }}/{{ accordo.annoScolastico + 1 }}
        </v-col>
      </v-row>
      <div>
        <v-row class="my-2">
          <v-col cols="6"> Istituto scolastico </v-col>
          <v-col cols="6"> PDF accordo </v-col>
        </v-row>
        <v-row class="font-weight-bold my-2">
          <v-col cols="6">{{ accordo.istituto.denominazioneScuola }} </v-col>
          <v-col cols="6">
            <a
              @click="
                downloadPDF(
                  accordo.fileAccordo.id,
                  accordo.fileAccordo.nomeOriginale
                )
              "
            >
              Scarica accordo
              <v-icon> mdi-tray-arrow-down </v-icon>
            </a>
          </v-col>
        </v-row>
      </div>
      <div class="mt-3 pt-3">
        <h3>Sotto accordi scuola</h3>
        <v-card-text v-if="sottoAccordi.length">
          <ul>
            <li
              v-for="(sottoAccordo, indexSottoAccordi) in sottoAccordi"
              :key="indexSottoAccordi"
            >
              <span>
                Id Sotto-accordo <strong> {{ sottoAccordo.id }}</strong></span
              >
              <br />
              <span style="display=block;">
                Data inserimento sotto accordo
                <strong>
                  {{
                    formatDate(sottoAccordo.fileSottoAccordo.dataInserimento)
                  }}</strong
                ></span
              >
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-else> Sotto accordi non presenti. </v-card-text>
      </div>
      <v-divider></v-divider>
    </v-card-text>
  </div>
</template>
<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import CorsiRepository from "@/api/CorsiRepository";

export default {
  name: "dettaglioAccordo",
  components: {},
  props: {
    accordo: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    loadingComponent: false,
    sottoAccordi: [],
  }),
  methods: {
    initialize() {
      if (this.accordo) {
        let idStrutture = this.accordo.programmaOrientamento.idStrutture;
        let idAccordo = this.accordo.id;
        this.getSottoAccordiScuolaByAccordo(idStrutture, idAccordo);
        this.loadingComponent = true;
      }
    },
    formatDate(data) {
      if (data) {
        return new Date(data).toLocaleDateString("it-IT");
      }
      return "";
    },
    async downloadPDF(idFile, nomeFile) {
      return await MinisteroRepository.downloadFile(
        this.accordo.annoScolastico,
        idFile,
        nomeFile
      );
    },
    async getSottoAccordiScuolaByAccordo(idStrutture, idAccordo) {
      this.sottoAccordi = await CorsiRepository.getSottoAccordiScuolaByAccordo(
        idStrutture,
        idAccordo
      );
    },
  },
};
</script>
