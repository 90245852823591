<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <div class="mt-4" v-if="idRendiconto">
      <v-btn text class="blue--text" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
      </v-btn>
    </div>
    <DettaglioCorsoComponent
      :id-corso="Number(idCorso)"
      :id-strutture="Number(idStrutture)"
    ></DettaglioCorsoComponent>
    <div class="mt-4" v-if="idRendiconto">
      <v-btn text class="blue--text" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import DettaglioCorsoComponent from "@/components/Controlli/DettaglioCorso/DettaglioCorsoComponent";
export default {
  name: "dettaglioCorso",
  components: { BreadcrumbComponent, DettaglioCorsoComponent },
  props: {
    idRendiconto: {
      type: [String, Number],
      required: false,
    },
    idCorso: {
      type: [String, Number],
      required: true,
    },
    idStrutture: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters(["isRoleUdm"]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/${this.isRoleUdm ? "udm/" : ""}controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Lista Rendiconti",
          disabled: false,
          to: `/scrivania/${
            this.isRoleUdm ? "udm/" : ""
          }controlli/lista-verbali`,
          exact: true,
          link: true,
        },
        {
          text: "Dettaglio rendiconto",
          disabled: false,
          to: `/scrivania/controlli/lista-rendiconti/${this.idStrutture}/${this.idRendiconto}/dettaglioRendiconto`,
          exact: true,
          link: true,
        },
        {
          text: "Dettaglio corso",
          disabled: true,
          to: `/scrivania/controlli/lista-verbali/${this.anno}/nuovo-controllo/dettaglio-rendiconto/dettaglio-corso`,
          exact: true,
          link: true,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
