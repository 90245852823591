<template>
  <v-app>
    <v-card
      class="overflow-hidden o-card-drawer"
      v-if="$store.getters.isRoleConsulenza || $store.getters.isRoleMinistero"
    >
      <v-navigation-drawer absolute temporary v-model="drawer">
        <NavigationDrawer />
      </v-navigation-drawer>

      <v-app-bar color="#06c" dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Orientamento 2026 - PNRR</v-toolbar-title>
        <v-row class="navbar_main">
          <v-toolbar-items>
            <v-col cols="8">
              <SelezionaStruttura />
            </v-col>
            <v-col cols="5">
              <SelezionaAnno />
            </v-col>
            <v-col cols="3">
              <SelezionaCapofila />
            </v-col>
          </v-toolbar-items>
        </v-row>
        <v-chip
          class="ma-2"
          color="pink"
          label
          text-color="white"
          v-if="$store.getters.isRoleConsulenza"
        >
          <v-icon left> mdi-label </v-icon>
          Consulenza
        </v-chip>
        <v-chip
          class="ma-2"
          color="orange"
          label
          text-color="white"
          v-if="$store.getters.isRoleMinistero"
        >
          <v-icon left> mdi-label </v-icon>
          Ministero
        </v-chip>
        <InfoPersona />
      </v-app-bar>

      <v-main>
        <v-container fluid style="height: 100%">
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-footer app> </v-footer>
    </v-card>

    <v-card v-else>
      <v-app-bar color="#06c" dark></v-app-bar>

      <v-main>
        <v-container fluid style="height: 100%">
          <div class="text-center" v-if="!$store.getters.isUserLoaded">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div class="text-center" v-else>Accesso non autorizzato</div>
        </v-container>
      </v-main>

      <v-footer app>
        <footer-component />
      </v-footer>
    </v-card>
  </v-app>
</template>

<script>
import axios from "axios";
import NavigationDrawer from "@/components/NavigationDrawer";
import InfoPersona from "@/components/InfoPersona";
import FooterComponent from "@/components/Common/FooterComponent";
import SelezionaStruttura from "@/components/SelezionaStruttura";
import SelezionaAnno from "@/components/SelezionaAnno";
import SelezionaCapofila from "@/components/SelezionaCapofila";

export default {
  name: "app",
  components: {
    SelezionaCapofila,
    SelezionaAnno,
    SelezionaStruttura,
    InfoPersona,
    NavigationDrawer,
    FooterComponent,
  },

  props: ["keycloak"],
  data() {
    return {
      msg: "Welcome to Your Secured Vue.js App with Keycloak",
      drawer: false,
    };
  },
  mounted() {
    this.getUserInfo();
    this.$store.dispatch("loadKeycloak", this.keycloak);
  },
  methods: {
    async getUserInfo() {
      const result = await axios.get(
        process.env.VUE_APP_API_URL + "/api/user/info"
      );
      await this.$store.dispatch("loadUser", result.data);
    },
  },
};
</script>

<style>
.o-card-drawer {
  height: 100%;
}
</style>
