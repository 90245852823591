import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keycloak: null,
    user: null,
    idStrutture: null,
    nomeStruttura: null,
    anno: null,
    capofila: "istituzione",
    // il campione che viene generato dall'algoritmo di campionamento. NON MODIFICABILE
    campioneEstratto: null,
    // il campione che viene generato dall'algoritmo di campionamento, ma MODIFICABILE ( da funzionalità estendi campione ed estendi percentuale )
    campioneAggiornato: null,
    // il campione che viene generato dall'algoritmo di campionamento. NON MODIFICABILE
    campioneEstrattoUdm: null,
    // il campione che viene generato dall'algoritmo di campionamento, ma MODIFICABILE ( da funzionalità estendi campione ed estendi percentuale )
    campioneAggiornatoUdm: null,
    //dati inseriti dal Responsabile del controllo in fase di generazioen del campione UDM
    infoCampionamentoUdm: null,
    //se il campione viene esteso almeno una volta, lo setto a true per avere riscontro in verbale
    isCampioneEsteso: null,
  },
  getters: {
    isRoleConsulenza(state) {
      return state.user?.roles?.includes("ROLE_CONSULENZA") ?? false;
    },
    isRoleMinistero(state) {
      return state.user?.roles?.includes("ROLE_MINISTERO") ?? false;
    },
    isRoleResponsabileControllo(state) {
      return (
        state.user?.roles?.includes("ROLE_RESPONSABILE_CONTROLLO") ?? false
      );
    },
    isRoleIncaricatoControllo(state) {
      return state.user?.roles?.includes("ROLE_INCARICATO_CONTROLLO") ?? false;
    },
    isRoleUdm(state) {
      return state.user?.roles?.includes("ROLE_UDM") ?? false;
    },
    isUserLoaded(state) {
      return state.user ?? false;
    },
    isRoleRup(state) {
      return state.user?.roles?.includes("ROLE_RUP") ?? false;
    },
    isRoleDgUdm(state) {
      return state.user?.roles?.includes("ROLE_DG_UDM") ?? false;
    },
  },
  mutations: {
    setKeycloak(state, keycloak) {
      state.keycloak = keycloak;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIdStrutture(state, idStrutture) {
      state.idStrutture = idStrutture;
    },
    setNomeStruttura(state, nomeStruttura) {
      state.nomeStruttura = nomeStruttura;
    },
    setAnno(state, anno) {
      state.anno = anno;
    },
    setCapofila(state, capofila) {
      state.capofila = capofila;
    },
    setCampioneEstratto(state, campioneEstratto) {
      state.campioneEstratto = campioneEstratto;
    },
    setCampioneAggiornato(state, campioneAggiornato) {
      state.campioneAggiornato = campioneAggiornato;
    },
    //campione UDM
    setCampioneEstrattoUdm(state, campioneEstrattoUdm) {
      state.campioneEstrattoUdm = campioneEstrattoUdm;
    },
    setCampioneAggiornatoUdm(state, campioneAggiornatoUdm) {
      state.campioneAggiornatoUdm = campioneAggiornatoUdm;
    },
    setInfoCampionamentoUdm(state, infoCampionamentoUdm) {
      state.infoCampionamentoUdm = infoCampionamentoUdm;
    },
    setIsCampioneEsteso(state, isCampioneEsteso) {
      state.isCampioneEsteso = isCampioneEsteso;
    },
  },
  actions: {
    async loadKeycloak({ commit }, keycloak) {
      commit("setKeycloak", keycloak);
    },
    async loadUser({ commit }, user) {
      commit("setUser", user);
    },
    async loadIdStrutture({ commit }, idStrutture) {
      commit("setIdStrutture", idStrutture);
    },
    async loadNomeStruttura({ commit }, nomeStruttura) {
      commit("setNomeStruttura", nomeStruttura);
    },
    async loadAnno({ commit }, anno) {
      commit("setAnno", anno);
    },
    async loadCapofila({ commit }, capofila) {
      commit("setCapofila", capofila);
    },
    async loadCampioneEstratto({ commit }, campioneEstratto) {
      commit("setCampioneEstratto", campioneEstratto);
    },
    async loadCampioneEstrattoUdm({ commit }, campioneEstrattoUdm) {
      commit("setCampioneEstrattoUdm", campioneEstrattoUdm);
    },
    async loadInfoCampionamentoUdm({ commit }, infoCampionamentoUdm) {
      commit("setInfoCampionamentoUdm", infoCampionamentoUdm);
    },
    async loadIsCampioneEsteso({ commit }, isCampioneEsteso) {
      commit("setIsCampioneEsteso", isCampioneEsteso);
    },
  },
  modules: {},
});
