import Repository from "@/api/Repository";

export default {
  async getDichiarazioni(idStrutture, anno) {
    const url = `api/ministero/${idStrutture}/flussi-finaziari/${anno}/dichiarazioni`;
    return (await Repository.get(url)).data;
  },
  async getSingolaDichiarazione(idStrutture, idDichiarazione) {
    const url = `api/ministero/${idStrutture}/flussi-finaziari/${idDichiarazione}/dichiarazione`;
    return (await Repository.get(url)).data;
  },
  async presaVisioneConti(idStrutture, idDichiarazione) {
    const url = `api/ministero/${idStrutture}/flussi-finaziari/${idDichiarazione}/presa-visione`;
    return await Repository.put(url, null);
  },
  async downloadFileRiepilogoDichiarazioni(
    idStrutture,
    tipoFile,
    tipoDati,
    formData
  ) {
    const url = `api/ministero/${idStrutture}/flussi-finaziari/${tipoDati}/riepiloghi/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formData, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      let oggetto = "";
      switch (tipoDati) {
        case "a":
          oggetto = "dichiarazioni";
          break;
        case "b":
          oggetto = "conto";
          break;
        case "c":
          oggetto = "delegati";
          break;
      }
      fileLink.download = `Riepilogo_${oggetto}${extension}`;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
};
