<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <div v-if="loadinPage">
      <v-main class="mt-5">
        <v-row class="mb-6">
          <v-col cols="9">
            <h1 style="color: #1a237e">Verifica Rendiconto</h1>
          </v-col>
          <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="6">
            <step-verifica-formale-udm
              :numero-step="1"
            ></step-verifica-formale-udm>
          </v-col>
        </v-row>
        <v-main class="ma-4 pa-2">
          <v-card-title>
            Anagrafica Amministrazione centrale titolare di interventi
          </v-card-title>
          <anagrafica-rendiconto-progetto :editable="true" />
        </v-main>
        <v-main class="ma-4 pa-4">
          <v-card-title class="px-0"> Anagrafica Intervento </v-card-title>
          <anagrafica-intervento
            :editable="true"
            :rendiconto="rendiconto"
            :costoTotale="
              costoProgettoAnnuale ? costoProgettoAnnuale.assegnazioneTotale : 0
            "
          />
        </v-main>
        <v-main class="ma-4 pa-4">
          <RiepilogoGeneraleRendiconto
            v-if="rendiconto"
            :rendiconto="rendiconto"
            class="mt-3"
          />
        </v-main>
        <v-main class="ma-4 pa-4">
          <v-data-table
            :headers="headersCorsi"
            :items="rendiconto.corsiRendiconto"
            class="elevation-1"
          >
            <!-- eslint-disable -->
            <template v-slot:item.importo="{ item }">
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(item.importoCorso)
              }}
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-main>
        <v-main class="ma-4 pa-4">
          <v-card-title> Allegati </v-card-title>
          <v-row>
            <v-col>
              <h4 class="mb-4" style="color: gray; padding: 5px">
                Dichiarazione del soggetto attuatore propedeutica alla
                presentazione del rendiconto di progetto
              </h4>
              <a v-if="rendiconto" @click="downloadPdf">
                Scarica
                <v-icon> mdi-tray-arrow-down </v-icon>
              </a>
            </v-col>
          </v-row>
        </v-main>
      </v-main>
      <dialog-conferma
        :text-conferma="textDialogConferma"
        @callback="salvaVerificaFormaleRendiconto()"
        :dialog.sync="dialogConferma"
        @close-dialog="dialogConferma = false"
      />
      <dialog-conferma
        v-model="dialogEsciDaStepVerifica"
        :dialog.sync="dialogEsciDaStepVerifica"
        @callback="esciDaStepVerifica"
        @close-dialog="dialogEsciDaStepVerifica = false"
        :text-conferma="'ATTENZIONE!<br /> Si sta uscendo dalla verifica del rendiconto. Continuare?<br />'"
      />
      <template>
        <v-main>
          <div class="d-flex justify-space-around mb-6 bg-surface-variant">
            <v-sheet class="ma-2 pa-2">
              <v-btn @click="dialogEsciDaStepVerifica = true">
                <v-icon color="blue">mdi-arrow-left</v-icon>
                Esci dalla verifica del rendiconto
              </v-btn>
            </v-sheet>
            <v-sheet class="ma-2 pa-2">
              <a class="mr-6" @click="dialogRichiestaModifiche = true">
                <v-icon color="blue">mdi-plus</v-icon>
                Richiedi chiarimenti
              </a></v-sheet
            >
            <v-sheet class="ma-2 pa-2">
              <v-btn @click="dialogConferma = true" color="primary"
                >Compila checklist
              </v-btn>
            </v-sheet>
          </div>
        </v-main>
        <v-dialog v-model="dialogRichiestaModifiche">
          <ContenutoDialogRichiestaModificheUdm
            :rendiconto="this.rendiconto"
            @close-dialog="dialogRichiestaModifiche = false"
            :dialog.sync="dialogRichiestaModifiche"
            @richiesta-aggiunta="nuovaRichiesta += 1"
          />
        </v-dialog>
        <v-dialog
          v-model="dialogRiepilogoCorso"
          class="pa-4"
          persistent
          style="background-color: azure"
        >
          <DettaglioCorsoComponent
            :id-strutture="datiDialogRiepilogoCorso.idStrutture"
            :id-corso="datiDialogRiepilogoCorso.idCorso"
            :is-dialog="true"
            @callback="chiudiDialogRiepilogoCorso"
          />
        </v-dialog>
      </template>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <v-card-actions class="justify-center">
        <alert-component
          testo="Attendere: caricamento in corso"
          tipo-alert="info"
        />
      </v-card-actions>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiepilogoGeneraleRendiconto from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";
import ContenutoDialogRichiestaModificheUdm from "@/components/Controlli/Udm/VerificheFormali/ContenutoDialogRichiestaModificheUdmComponent.vue";
// eslint-disable-next-line no-unused-vars
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import DettaglioCorsoComponent from "@/components/Controlli/DettaglioCorso/DettaglioCorsoComponent.vue";
import StepVerificaFormaleUdm from "@/components/Controlli/Udm/VerificheFormali/StepVerificaFormaleUdm";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import AnagraficaRendicontoProgetto from "@/components/Controlli/VerificheRendiconti/AnagraficaRendicontoProgettoComponent.vue";
import AnagraficaIntervento from "@/components/Controlli/VerificheRendiconti/AnagraficaInterventoComponent.vue";

export default {
  name: "VerificaFormaleUdmStepUnoView",
  components: {
    StepVerificaFormaleUdm,
    DettaglioCorsoComponent,
    DialogConferma,
    ContenutoDialogRichiestaModificheUdm,
    RiepilogoGeneraleRendiconto,
    AlertComponent,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    AnagraficaIntervento,
    AnagraficaRendicontoProgetto,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Controllo formale rendiconti",
            disabled: false,
            to: `/scrivania/udm/controlli/controllo-formale-rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Verifica Rendiconto",
            disabled: true,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  /* eslint-disable */
  watch: {
    anno() {
      this.initialize();
    },
    nuovaRichiesta() {
      setTimeout(() => {
        this.loadinPage = false;
        this.initialize();
      }, 6000);
    },
  },
  data: () => ({
    costoProgettoAnnuale: null,
    loadinPage: false,
    nuovaRichiesta: 0,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    corsiCampionati: [],
    certificatiCampionati: [],
    idRendiconto: null,
    step: 1,
    dialogRichiestaModifiche: false,
    dialogConferma: false,
    importoNonAmmesso: 0,
    importoTotale: 0,
    paginaSuccessiva: null,
    importiCalcolati: {
      importoAmmessoTotale: 0,
      importoCalcolatoTotale: 0,
      percErrore: 0,
      importoNonAmmesso: 0,
      totaleAttestatiDaVerificare: 0,
    },
    attestatiConVerifica: [],
    attestatiConImportiCalcolati: [],
    dialogRiepilogoCorso: false,
    dialogEsciDaStepVerifica: false,
    datiDialogRiepilogoCorso: {
      idCorso: null,
      idStrutture: null,
      idRendiconto: null,
    },
    textDialogConferma: "Proseguire con il secondo step della verifica?",
    headersCorsi: [
      {
        text: "ID Corso di orientamento",
        align: "start",
        sortable: true,
        value: "id_corso",
      },
      { text: "N° alunni del corso", value: "corso.numeroStudenti" },
      { text: "Importo rendicontato", value: "importo" },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getCostoTotaleProgetto() {
      const idStrutture = this.rendiconto.idStrutture;
      const anno = this.rendiconto.anno;
      const capofila = this.rendiconto.capofila;

      this.costoProgettoAnnuale =
          await MinisteroRepository.getCostoAnnualeProgettoIstituzione(
              idStrutture,
              anno,
              capofila
          );
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
          await ControlliUdmRepository.getSingoloVerbaleControlliFormaliUdm(
              parseInt(this.anno),
              this.idRendiconto,
              1
          );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        await this.getCostoTotaleProgetto();
        this.loadinPage = true;
      }
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
       /* this.verificaRendiconto = this.rendiconto.verificaRendiconto ?? null;
        this.corsiCampionati = this.verbaleCampionamentoRendiconto.corsiCampionati;*/
        this.loadinPage = true;
      }
    },
    async downloadPdf() {
      await MinisteroRepository.downloadFile(
          this.rendiconto.anno,
          this.rendiconto.fileDocumentazioneFirmata.id,
          this.rendiconto.fileDocumentazioneFirmata.nomeOriginale
      );
    },
    goToNextPage() {
      this.$router.push({
        name: "VerificaFormaleUdmChecklistsView",
        params: {idRendiconto: this.idRendiconto},
      });
    },
    setValueSnackbar(esito, text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    chiudiDialogRiepilogoCorso() {
      this.dialogRiepilogoCorso = false;
    },
    esciDaStepVerifica() {
      this.dialogEsciDaStepVerifica = false;
      this.$router.push({name: "ListaVerbaliFormaleUdm"});
    },
    async salvaVerificaFormaleRendiconto() {

      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.rendiconto.id;

      if (this.rendiconto.verificaFormaleRendiconto == null) {
        await RendicontiRepository.inserimentoVerificaFormaleRendiconto(idStrutture, idRendiconto).then((data) => {
          if (data) {
            this.goToNextPage();
          }
        }).catch((reason) => {
          this.setValueSnackbar(
              false,
              "Un errore server è stato riscontrato.",
              "red-darken-4"
          );
        });
      } else {
        this.goToNextPage();
      }
    },
  },
};
</script>
