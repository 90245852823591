<template>
  <v-card elevation="4">
    <v-card-title> Riepilogo Anticipi </v-card-title>
    <v-card-text>
      <v-data-table
        :items="richiesteAnticipoFiltered"
        :headers="headers"
        v-model="formRichiesteAnticipo.richisteAnticipoSelezionate"
        item-key="richiestaAnticipo.id"
        show-select
        @toggle-select-all="checkSelectableItems"
      >
        <!--eslint-disable -->
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
              :readonly="item.richiestaAnticipo.idTipoErogazione.id !== 1"
              :disabled="item.richiestaAnticipo.idTipoErogazione.id !== 1"
              :value="isSelected"
              @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.richiestaAnticipo.dataInvio="{item}">
          <span v-html="getDataInvio(item.richiestaAnticipo.dataInvio)" />
        </template>
        <template v-slot:item.richiestaAnticipo.importo="{ item }">
          <span v-html="getFormattedAmount(item.richiestaAnticipo.importo)" />
        </template>
        <template v-slot:item.importoErogato=" { item }">
          <span> {{ item.richiestaAnticipo.idTipoErogazione.id !== 1 ? getFormattedAmount(item.richiestaAnticipo.importo) : '0 €'}} </span>
        </template>
        <template v-slot:item.azioni="{item}">
           <!--<DialogDettaglioRichiestaAnticipo :id-richiesta-anticipo="item.richiestaAnticipo.id" /> -->
          <v-text-field
              v-model="item.richiestaAnticipo.note"
              :disabled="item.richiestaAnticipo.idTipoErogazione.id !== 1"
          ></v-text-field>
        </template> <!-- eslint-enable -->
      </v-data-table>
    </v-card-text>

    <v-card-actions class="pa-5 ma-5">
      <v-btn
        @click="dialogConfermaOperazione = true"
        v-html="'Inviato a SAP IGRUE'"
        color="primary"
      />
    </v-card-actions>
    <dialog-conferma
      :dialog.sync="dialogConfermaOperazione"
      @callback="settaggioStatoRichiesta"
      @close-dialog="dialogConfermaOperazione = false"
    />
    <v-snackbar :color="snackbar.color" v-model="snackbar.value">
      {{ snackbar.text }}
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
//import DialogDettaglioRichiestaAnticipo from "@/components/AssegnazioneTargetControlli/DialogDettaglioRichiestaComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import AssegnazioneRisorseRepository from "@/api/ministero/AssegnazioneRisorseRepository";

export default {
  name: "riepilogoQuattroAssegnazioneRisorse",
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.getFilteredData();
    },
    anno() {
      this.getFilteredData();
    },
  },
  components: {
    DialogConferma,
    //DialogDettaglioRichiestaAnticipo
  },
  created() {
    this.getFilteredData();
  },
  props: {
    richiesteAnticipo: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "CUP",
        value: "richiestaAnticipo.idProgramma.cup",
        align: "start",
      },
      {
        text: "NOME ISTITUZIONE",
        value: "richiestaAnticipo.idProgramma.nomeIstituzione",
      },
      { text: "ID RICHIESTA", value: "richiestaAnticipo.id" },
      { text: "DATA TRASMISSIONE", value: "richiestaAnticipo.dataInvio" },
      { text: "IMPORTO RICHIESTO", value: "richiestaAnticipo.importo" },
      { text: "IMPORTO EROGATO", value: "importoErogato" },
      {
        text: "STATO",
        value: "richiestaAnticipo.idTipoErogazione.descrizione",
      },
      { text: "NOTE", sortable: false, value: "azioni" },
    ],
    formRichiesteAnticipo: {
      richisteAnticipoSelezionate: [],
    },
    dialogDettaglioRichiesta: false,
    richiesteAnticipoFiltered: [],
    dialogConfermaOperazione: false,
    snackbar: {
      color: "",
      text: "",
      value: false,
    },
  }),
  methods: {
    getDataInvio(dataInvio) {
      return new Date(dataInvio).toLocaleDateString();
    },
    getFilteredData() {
      this.richiesteAnticipoFiltered = this.richiesteAnticipo.filter(
        (element) => {
          return (
            (this.idStrutture != null
              ? element.richiestaAnticipo.idProgramma.idStrutture ===
                this.idStrutture
              : element.richiestaAnticipo.idProgramma.idStrutture > 0) &&
            (this.anno != null
              ? element.richiestaAnticipo.idProgramma.anno === this.anno
              : element.richiestaAnticipo.idProgramma.anno > 0)
          );
        }
      );
    },
    getFormattedAmount(importo) {
      if (!importo) return 0 + " €";
      return (
        parseFloat(importo).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " €"
      );
    },
    async settaggioStatoRichiesta() {
      const formBody = new FormData();
      formBody.append(
        "richiesteAnticipo",
        JSON.stringify(this.formRichiesteAnticipo.richisteAnticipoSelezionate)
      );
      await AssegnazioneRisorseRepository.setStatoRichieste(formBody)
        .then((data) => {
          if (data) {
            this.setValueSnackbar(
              "Operazione conclusa con successo",
              "success"
            );
            this.dialogConfermaOperazione = false;
            window.location.reload();
          }
        })
        .catch((reason) => {
          if (reason.response.status === 500) {
            this.setValueSnackbar(
              "E' stato riscontrato un'errore di server",
              "red"
            );
          } else {
            this.dialogConfermaOperazione = false;
            this.setValueSnackbar(reason.response.data["payload"]);
          }
        });
    },
    setValueSnackbar(text, color) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
    checkSelectableItems() {
      this.formRichiesteAnticipo.richisteAnticipoSelezionate = [];
      this.richiesteAnticipoFiltered.forEach((item) => {
        if (!item.disableSelection) {
          this.formRichiesteAnticipo.richisteAnticipoSelezionate.push(item);
        }
      });
    },
  },
};
</script>
