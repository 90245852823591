var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.anno)?_c('v-container',{attrs:{"fluid":""}},[_c('v-main',[_c('div',{staticClass:"ma-6 pa-6"},[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_c('div',{staticClass:"ma-6 pa-6"},[_c('StepRendicontazioni',{attrs:{"numero-step":2}})],1),_c('h1',[_vm._v("Anagrafiche")])]),(_vm.rendiconto)?_c('v-card',{attrs:{"elevation":"5"}},[_c('v-form',[_c('v-main',{staticClass:"ma-4 pa-4"},[_c('v-card-title',[_vm._v(" Anagrafica Amministrazione centrale titolare di interventi ")]),_c('anagrafica-rendiconto-progetto',{attrs:{"editable":true}})],1),_c('v-main',{staticClass:"ma-4 pa-4"},[_c('v-card-title',[_vm._v(" Anagrafica Intervento ")]),(_vm.costoProgettoAnnuale)?_c('anagrafica-intervento',{attrs:{"editable":true,"rendiconto":_vm.rendiconto,"costoTotale":_vm.costoProgettoAnnuale.assegnazioneTotale}}):_vm._e()],1),_c('v-main',{staticClass:"ma-4 pa-4"},[_c('v-card-title',[_vm._v(" Rendiconti precedentemente presentati ")]),_c('AnagraficaRendicontiPrecedentiComponent',{attrs:{"rendiconto":_vm.rendiconto}})],1)],1)],1):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"blue"}}),(_vm.rendiconto)?_c('v-card',{attrs:{"elevation":"4"}},[_c('v-main',[_c('riepilogo-generale-rendiconto',{attrs:{"rendiconto":_vm.rendiconto}})],1),_c('v-main',[(_vm.verbaleCampionamentoRendiconto)?_c('table-attestati-campionati',{attrs:{"con-menu-azioni":false,"headers-table":_vm.headersTable,"items-attestati":_vm.getAttestatiCampionatiRendiconto()}}):_vm._e()],1),_c('v-main',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('router-link',{staticClass:"v-btn ma-3 pa-3",attrs:{"to":{
              name: 'verificaRendicontoStepUno',
              params: { idRendiconto: this.idRendiconto },
            }},domProps:{"innerHTML":_vm._s('Indietro')}},[_c('span',{},[_vm._v(" Indietro ")])])],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('router-link',{staticClass:"v-btn primary ma-3 pa-3 float-end",attrs:{"to":{
              name: 'verificaChecklistsRendiconto',
              params: { idRendiconto: this.idRendiconto },
            }}},[_c('span',[_vm._v(" Continua ")])])],1)],1)],1)],1):_vm._e()],1):_c('v-container',[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" E' necessaria la selezione dell'anno associato al rendiconto che si sta verificando ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }