<template>
  <v-card elevation="4" v-if="loadingComponent" flat outlined>
    <v-form ref="form" lazy-validation v-model="validForm">
      <div class="d-flex justify-center">
        <v-main class="pa-2 order-1">
          <v-row>
            <span>
              <v-icon size="150" color="blue-grey lighten-5">
                mdi-check-circle
              </v-icon>
              Doc_da_firmare
            </span>
          </v-row>
          <v-row>
            <v-card-text>
              <h3 v-html="'Documento'" />
            </v-card-text>
            <v-card-text> Scarica la documentazione </v-card-text>
            <v-card-actions>
              <v-btn
                class="primary"
                v-html="'Scarica il Documento'"
                @click="downloadTemplateFile()"
              />
            </v-card-actions>
          </v-row>
          <br />
          <alert-component
            tipo-alert="warning"
            :testo="'La procedura potrebbe richiedere diversi secondi'"
            :width="400"
          />
        </v-main>
        <v-main class="pa-2 order-2">
          <v-row>
            <span>
              <v-icon
                size="150"
                :color="
                  verbale.fileVerbaleEsteso
                    ? 'blue lighten-3'
                    : 'blue-grey lighten-5'
                "
              >
                mdi-check-circle
              </v-icon>
              {{
                verbale.fileVerbaleEsteso
                  ? "Documento caricato: " +
                    verbale.fileVerbaleEsteso.nomeOriginale
                  : "Documento da caricare"
              }}
            </span>
          </v-row>
          <v-row>
            <v-card-text>
              <h3>
                {{
                  verbale.fileVerbaleEsteso
                    ? "Documento Caricato"
                    : "Documento Firmato"
                }}
              </h3>
            </v-card-text>
            <v-card-text>
              Carica il documento firmato, in formato
              <b>.pdf</b>
              per ultimare la procedura.
            </v-card-text>
            <v-card-text v-if="!verbale.fileVerbaleEsteso">
              <v-file-input
                v-model="form.uploadedFiles"
                placeholder="Effettua l'upload del tuo documento firmato"
                label="Documentazione verbale firmato"
                :rules="rules.fieldRequired"
                accept=".pdf"
              />
              <v-btn
                v-html="'Carica documento'"
                color="primary"
                :disabled="!validForm"
                @click="dialogConferma = true"
              />
              <dialog-conferma
                :dialog.sync="dialogConferma"
                @close-dialog="dialogConferma = false"
                @callback="emitActionsToParent()"
              />
              <v-snackbar :color="snackbar.color" v-model="snackbar.value">
                {{ snackbar.text }}
              </v-snackbar>
            </v-card-text>
            <v-card-text v-else>
              <v-btn
                v-if="verbale.fileVerbaleEsteso"
                @click="dialogDeleteFile = true"
                class="border-danger"
              >
                <v-icon> mdi-delete</v-icon> Rimuovi il file</v-btn
              >
              <v-card-text
                v-if="verbale.fileVerbaleEsteso"
                v-html="
                  'File caricato: <br />' +
                  verbale.fileVerbaleEsteso.nomeOriginale
                "
              />
              <dialog-conferma
                :dialog.sync="dialogDeleteFile"
                @callback="eliminaFileCaricato"
                @close-dialog="dialogDeleteFile = false"
              />
            </v-card-text>
          </v-row>
        </v-main>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import { mapState } from "vuex";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "caricamentoFileVerbaleEstensioneUdm",
  components: { AlertComponent, DialogConferma },
  computed: {
    ...mapState(["anno"]),
  },
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
    verbale: {
      type: Object,
      required: true,
    },
    tipoCaricamento: {
      type: Number,
      required: 1,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    loadingComponent: false,
    dialogDeleteFile: false,
    dialogConferma: false,
    verificaRendiconto: null,
    downloadedFile: null,
    snackbar: {
      text: null,
      color: null,
      value: false,
    },
    form: {
      uploadedFiles: null,
    },
    rules: {
      fieldRequired: [
        (v) =>
          !!v ||
          "E' richiesto necessariamente l'upload del documento in formato .pdf e dunque firmato",
      ],
    },
    validForm: false,
  }),
  methods: {
    initialize() {
      if (this.rendiconto) {
        this.verificaRendiconto = this.rendiconto.verificaRendiconto;
        this.loadingComponent = true;
      }
    },
    async downloadTemplateFile() {
      const idVerbale = parseInt(this.verbale.id);
      const idRendiconto = parseInt(this.rendiconto.id);
      this.downloadedFile =
        await ControlliUdmRepository.getTemplatePdfVerbaleEstensioneUdm(
          this.anno,
          idRendiconto,
          idVerbale
        );
    },
    async eliminaFileCaricato() {
      await ControlliUdmRepository.eliminaFileVerbaleEstensioneUdm(
        parseInt(this.anno),
        parseInt(this.verbale.id)
      ).then(() => {
        this.$emit("inizializzazionePagina");
      });
    },

    emitActionsToParent() {
      if (this.$refs.form.validate() && this.form.uploadedFiles != null) {
        this.$emit("callback", this.form);
      } else {
        this.setValueSnackbar(
          "E' necessaria la selezione del file per poter proseguire.",
          "red"
        );
      }
    },
    setValueSnackbar(testo, color) {
      this.snackbar.text = testo;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
  },
};
</script>
