<template>
  <v-autocomplete
    v-if="anni"
    :items="anni"
    class="mx-4"
    flat
    hide-details
    item-text="name"
    item-value="anno"
    v-model="annoSelezionato"
    label="Anno..."
    @change="setIdAnno"
    solo-inverted
  >
  </v-autocomplete>
</template>
<script>
export default {
  name: "selezionaAnno",
  components: {},
  data: () => ({
    anni: [
      {
        name: "Tutti gli anni",
        anno: null,
      },
      {
        name: "2022/2023",
        anno: 2022,
      },
      {
        name: "2023/2024",
        anno: 2023,
      },
    ],
    annoSelezionato: null,
  }),
  created() {},
  mounted() {},
  methods: {
    setIdAnno() {
      this.$store.dispatch("loadAnno", this.annoSelezionato);
    },
  },
};
</script>
