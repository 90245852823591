<template>
  <v-main>
    <v-card class="ma-8 pa-8" elevation="4">
      <v-card-title
        v-html="
          'Verifica attestato dello studente ' +
          attestatoCampionato.certificato.studente.nome +
          ' ' +
          attestatoCampionato.certificato.studente.cognome
        "
      ></v-card-title>
      <v-alert type="info" max-width="1400" class="justify-center align-center">
        La verifica del singolo attestato viene salvata in memoria solo dopo
        aver confermato le modifiche dopo il click sul pulsante di estendi
        campione o compilazione checklist(se attiva).
      </v-alert>
      <v-form
        lazy-validation
        ref="formVerificaAttestato"
        v-model="valid"
        :rules="validateField.esito"
      >
        <v-row>
          <v-col cols="4">
            <v-radio-group
              v-model="
                formVerificaAttestato.tipologiaEsitoVerificaCertificato.id
              "
              label="Esito del controllo"
              :rules="validateField.esito"
              @input="setValueImportoAmmesso"
              @change="setValueImportoAmmesso"
            >
              <v-radio
                v-for="(
                  opzioniVerificaAttestato, indice
                ) in opzioniVerificaAttestato"
                :key="indice"
                :label="opzioniVerificaAttestato.testo"
                :value="opzioniVerificaAttestato.valore"
              ></v-radio> </v-radio-group
          ></v-col>
          <v-col cols="6">
            <v-textarea
              auto-grow
              filled
              background-color="grey lighten-2"
              color="black"
              label="Motivazione"
              v-model="formVerificaAttestato.motivazione"
              :rules="[validateFieldMotivazione()]"
          /></v-col>
          <v-col cols="2">
            <v-text-field
              label="Importo ammesso"
              type="number"
              v-model="formVerificaAttestato.importoAmmesso"
              :disabled="
                formVerificaAttestato.tipologiaEsitoVerificaCertificato.id !== 3
              "
              :rules="[validateFieldImporto()]"
              @input="validateFieldImporto"
            />
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn color="primary" @click="verificaAttestato" :disabled="!valid">
            Verifica Attestato</v-btn
          >
          <v-btn class="ml-4" @click="closeDialog"> Indietro</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-main>
</template>
<script>
export default {
  name: "contenutoDialogVerifcaAttestato",
  props: {
    attestatoCampionato: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setValueForm();
  },
  watch: {
    attestatoCampionato() {
      this.setValueForm();
    },
  },
  data: () => ({
    formVerificaAttestato: {
      motivazione: null,
      tipologiaEsitoVerificaCertificato: { id: 0, descrizione: null },
      dialogConferma: false,
      importoAmmesso: null,
    },
    opzioniVerificaAttestato: [
      {
        testo: "Ammesso",
        valore: 1,
      },
      {
        testo: "Non ammesso",
        valore: 2,
      },
      {
        testo: "Parz. ammesso",
        valore: 3,
      },
    ],
    valid: false,
    validateField: {
      motivazione: [
        (v) => !!v || "Campo obbligatorio",
        (v) => (v && v.length <= 800) || "Numero di caratteri elevato",
      ],
      esito: [(v) => (v > 0 && v < 4) || "Necessaria la selezione del campo."],
    },
  }),
  methods: {
    setValueForm() {
      if (this.attestatoCampionato.tipologiaEsitoVerificaCertificato != null) {
        this.formVerificaAttestato.motivazione =
          this.attestatoCampionato.motivazione;
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id =
          this.attestatoCampionato.tipologiaEsitoVerificaCertificato.id;
      } else {
        this.formVerificaAttestato.motivazione = null;
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id = 0;
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.descrizione =
          null;
        this.formVerificaAttestato.importoAmmesso = null;
      }
    },
    verificaAttestato() {
      if (this.$refs.formVerificaAttestato.validate()) {
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.descrizione =
          this.setDescrizioneEsitoVerifica(
            this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id
          );
        this.$emit(
          "callback",
          this.formVerificaAttestato,
          this.attestatoCampionato
        );
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    setDescrizioneEsitoVerifica(esito) {
      let descrizioneEsito = "";
      switch (esito) {
        case 1:
          descrizioneEsito = "Ammesso";
          break;

        case 2:
          descrizioneEsito = "Non ammesso";
          break;
        case 3:
          descrizioneEsito = "Parz. ammesso";
          break;
      }

      return descrizioneEsito;
    },
    setValueImportoAmmesso() {
      if (this.formVerificaAttestato.esito !== 3) {
        this.formVerificaAttestato.importoAmmesso = null;
      }
    },
    validateFieldImporto() {
      const tipoVerifica =
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id;
      if (tipoVerifica === 3) {
        const importo = parseFloat(this.formVerificaAttestato.importoAmmesso);
        this.formVerificaAttestato.importoAmmesso = importo;
        if (importo == null || isNaN(importo)) {
          return "L'importo ammesso non è stato inserito o non è nel formato corretto";
        } else if (
          importo >=
          parseFloat(this.attestatoCampionato.certificato.valoreAttribuito)
        ) {
          return "Il valore inserito non può essere maggior del valore attribuito associato all'attestato dello studente";
        } else {
          return true;
        }
      }
      return true;
    },
    validateFieldMotivazione() {
      const tipoVerifica =
        this.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id;
      if (tipoVerifica === 2 || tipoVerifica === 3) {
        const inputMotivazione = this.formVerificaAttestato.motivazione;

        if (
          !(
            inputMotivazione &&
            inputMotivazione.length &&
            inputMotivazione.trim().length > 0
          )
        ) {
          return "Il campo 'motivazione' è obbligatorio se l'attestato viene parzialmente ammesso oppure non ammesso.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
