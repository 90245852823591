<template>
  <v-btn text class="blue--text" @click="$router.go(-1)">
    <v-icon>mdi-arrow-left-thin</v-icon> {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "TornaIndietroComponent",
  props: {
    text: {
      type: String,
      required: false,
      default: "Torna indietro",
    },
  },
};
</script>

<style scoped></style>
