<template>
  <v-container fluid>
    <v-main> <BreadcrumbComponent :breadcrumbs="breadcrumbs" /> </v-main>
    <v-main class="mt-3">
      <h1>Finalizza Campione</h1>
      <p style="font-size: 20px">
        Lista dei rendiconti trasmessi e sottoposti a campione, con indicazione
        del soggetto attuatore e del rischio calcolato. Per ogni rendiconto, è
        possibile visualizzare gli id dei corsi estratti e gli attestati
        associati a questi corsi. Anche questi ultimisono stati estratti
        randomicamente tra tutti gli attestati creati per i corsi campionati.
      </p>
    </v-main>
    <v-card v-if="loadingRendicontiEstratti" elevation="4">
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="(rendicontiCampionati, index) in arrayCampioneEstratto"
          :key="index"
        >
          <v-expansion-panel-header color="blue" class="white--text mb-5">
            <v-card-text class="float-start">
              ID RENDICONTO -
              <b> {{ rendicontiCampionati.rendiconto.id }} </b> -
              {{ rendicontiCampionati.rendiconto.programma.nomeIstituzione }} -
              rischio calcolato
              {{
                toUpperCase(rendicontiCampionati.rendiconto.rischioCalcolato)
              }}.
            </v-card-text>
            <v-card-text class="float-end">
              <!--<router-link
                :to="{
                  name: 'dettaglioRendiconto',
                  params: { idRendiconto: this.idStrutture, idStrutture: -1 },
                }"
              >
                dettaglio
              </router-link>-->
              Dettaglio
            </v-card-text>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="blue lighten-5">
            <div class="d-flex mb-12 bg-surface-variant">
              <v-sheet class="ma-2 pa-2 me-auto blue lighten-5">
                Percentuale <br />Campionamento 4%
              </v-sheet>
              <v-sheet class="ma-2 pa-2 blue lighten-5">
                <v-btn
                  text
                  @click="estendiCampione(rendicontiCampionati, index)"
                >
                  Estendi Campione</v-btn
                >
                <!--<dialog-estendi-percentuale
                  :id-rendiconto="parseInt(rendicontiCampionati.rendiconto.id)"
                /> scommentato in quanto non tilizzabile-->
              </v-sheet>
            </div>
            <div>
              <v-card-text>
                <h3>Allegato</h3>
                <h3>Allegato</h3>
                <br />
                <span>
                  Dichiarazione del soggetto attuatore propedeutica alla
                  presentazione del rendiconto di progetto
                  <v-btn
                    text
                    @click="
                      downloadFile(
                        rendicontiCampionati.rendiconto
                          .fileDocumentazioneFirmata.id,
                        rendicontiCampionati.rendiconto
                          .fileDocumentazioneFirmata.nomeOriginale
                      )
                    "
                  >
                    Scarica <v-icon> mdi-download </v-icon>
                  </v-btn>
                </span>
              </v-card-text>
            </div>
            <v-card-text
              v-for="(
                corsiCampionati, element
              ) in rendicontiCampionati.corsiCampionati"
              :key="element"
              class="mt-1"
            >
              <v-card-title
                v-html="
                  '<h4> ID CORSO - <b> ' +
                  corsiCampionati.corsoCampionato.id +
                  ' </b> </h4>'
                "
                class="mt-1"
              />
              <v-data-table
                :items="corsiCampionati.certificatoCampionato"
                :headers="infoStudentiCertificatiCampionati"
                hide-default-footer
                :items-per-page="-1"
                ><!--eslint-disable -->
                <template v-slot:item.nomeCognome="{ item }">
                  <v-card-text v-html="item.studente.nome + ' ' + item.studente.cognome + ''" />
                </template>
                <template v-slot:item.istitutoScolastico="{ item }">
                  <v-card-text v-if="anno === 2022" v-html="corsiCampionati.corsoCampionato.accordo.istituto.denominazioneScuola" />
                  <v-card-text v-else v-html="item.studente.corsiStudente[0].istituto.denominazioneScuola" />
                </template>
                <template v-slot:item.importoTariffa="{ item }">
                  <v-card-text> 16.67 €</v-card-text>
                </template>
                <template v-slot:item.valoreAttribuito="{ item }">
                  <v-card-text> {{  item.valoreAttribuito }} €</v-card-text>
                </template>
                <template v-slot:item.attestatoFrequenza="{ item }">
                 <v-btn max-height="20" style="font-size:13px;" text @click="downloadFile(item.fileReferenteIstituto.id, item.fileReferenteIstituto.nomeOriginale)"> Scarica  </v-btn>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="justify-end">
        <v-btn
          class="mr-2"
          @click="creaNuovoCampione"
          :disabled="disabilitazionePulsanteNuovoCampionae"
        >
          Crea nuovo campione</v-btn
        >
        <v-btn
          @click="dialogOperazioneFinalizzaCampione.value = true"
          class="primary"
          :disabled="disabilitazionePulsanteFinalizza"
        >
          Finalizza campione</v-btn
        >

        <alert-component
          testo="La procedura di finalizza potrebbe richiedere maggior tempo, qualora il numero di corsi e attestati da elaborare sia elevato."
          type="warning"
          class="ml-4 mt-2"
          :width="800"
        />

        <dialog-conferma
          :text-conferma="dialogOperazioneFinalizzaCampione.testo"
          v-model="dialogOperazioneFinalizzaCampione.value"
          @callback="finalizzaCampione"
          :dialog.sync="dialogOperazioneFinalizzaCampione.value"
          @close-dialog="dialogOperazioneFinalizzaCampione.value = false"
        />

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
          {{ snackbar.text }}
        </v-snackbar>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-progress-circular color="blue" indeterminate />
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import { toUpperCase } from "uri-js/dist/esnext/util";
//import DialogEstendiPercentuale from "@/components/Controlli/DialogEstendiPercentualeComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";

export default {
  name: "finalizzaCampione",
  components: {
    AlertComponent,
    //DialogEstendiPercentuale,
    DialogConferma,
    BreadcrumbComponent,
  },
  data: () => ({
    arrayCampioneEstratto: [],
    loadingRendicontiEstratti: false,
    percentualeCampionamento: 0.04,
    disabilitazionePulsanteFinalizza: false,
    disabilitazionePulsanteNuovoCampionae: false,
    campioneOriginale: null,
    snackbar: {
      value: false,
      text: "",
      color: "",
    },
    dialogOperazioneFinalizzaCampione: {
      value: false,
      testo:
        "Si sta procedendo alla creazione del verbale di campionamento, con i dati dell'ultimo campione estratto." +
        " Si desidera confermare?",
    },
    infoStudentiCertificatiCampionati: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Istituto scolastico",
        value: "istitutoScolastico",
      },
      { text: "Codice Fiscale", value: "studente.cf", sortable: false },
      { text: "Totale ore", align: "center", value: "totaleOreStudente" },
      { text: "% ore freq", align: "center", value: "percentualeOreSvolte" },
      { text: "Importo tariffa", align: "center", value: "importoTariffa" },
      { text: "Valore attributivo", value: "valoreAttribuito" },
      { text: "Attestato di frequenza", value: "attestatoFrequenza" },
    ],
    formFinalizzaCampione: {},
  }),
  computed: {
    loading: function () {
      return this.loadingRendiconti;
    },
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Verbali",
            disabled: false,
            to: `/scrivania/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Rendiconti",
            disabled: false,
            to: `/scrivania/controlli/nuovo-controllo`,
            exact: true,
            link: true,
          },
          {
            text: "Finalizza Campione",
            disabled: true,
            to: `/scrivania/controlli/finalizza-campione`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState([
      "idStrutture",
      "anno",
      "nomeStruttura",
      "campioneEstratto",
      "campioneAggiornato",
    ]),
    ...mapGetters([]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    nomeStruttura() {
      this.initialize();
    },
    campioneEstratto() {
      this.loadingRendicontiEstratti = false;
      this.initialize();
    },
    campioneAggiornato() {
      this.loadingRendicontiEstratti = false;
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    toUpperCase,
    initialize() {
      if (this.anno && this.campioneAggiornato) {
        const campioneAggiornato = this.$store.state.campioneAggiornato;
        const campioneOriginale = JSON.parse(this.campioneEstratto);

        if (campioneAggiornato && campioneOriginale) {
          this.arrayCampioneEstratto = JSON.parse(campioneAggiornato);
          this.campioneOriginale = campioneOriginale;
          this.loadingRendicontiEstratti = true;
        }
      }
    },
    setValueSnackbar(esito, text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    estendiCampione(rendicontoCampionato, index) {
      // trasmetto alla pagina le informazioni necessarie del rendiconto, dei corsi campionati e certificati estratti
      this.$router.push({
        name: "EstendiCampione",
        params: {
          idRendiconto: rendicontoCampionato.rendiconto.id,
          rendicontoCampione: rendicontoCampionato,
          indiceRendiconto: index,
          statoCampione: "daSalvare",
        },
      });
    },
    async creaNuovoCampione() {
      const idRendiconti = this.arrayCampioneEstratto.map((element) => {
        return element.rendiconto.id;
      });

      this.disabilitazionePulsanteNuovoCampionae = true;
      this.disabilitazionePulsanteFinalizza = true;

      let formBody = new FormData();
      formBody.append("rendiconti", JSON.stringify(idRendiconti));
      formBody.append(
        "percentualeCampionamento",
        this.percentualeCampionamento
      );

      await ControlliRepository.generaVerbaleCampionamento(
        this.anno,
        false,
        formBody
      )
        .then((data) => {
          if (data) {
            this.loadingRendicontiEstratti = false;
            const campioneEstratto = JSON.stringify(data);
            this.$store.commit("setCampioneEstratto", campioneEstratto);
            this.setValueSnackbar(
              true,
              "Un nuovo campionamento è stato estratto e generato",
              "success"
            );
            this.disabilitazionePulsanteNuovoCampionae = false;
            this.disabilitazionePulsanteFinalizza = false;
            this.initialize();
          }
        })
        .catch((reason) => {
          this.disabilitazionePulsanteNuovoCampionae = false;
          this.disabilitazionePulsanteFinalizza = false;
          console.log(reason);
        });
    },
    async finalizzaCampione() {
      this.disabilitazionePulsanteFinalizza = true;
      this.disabilitazionePulsanteNuovoCampionae = true;

      let idCorsiEstrattiDaAlgoritmo = this.getIdCorsiEstrattiDaAlgoritmo();
      let idAttestatiEstrattiDaAlgoritmo =
        this.getIdAttestatiEstrattiDaAlgoritmo();

      const formBody = new FormData();
      formBody.append(
        "rendicontiCorsiCampionati",
        JSON.stringify(this.arrayCampioneEstratto)
      );

      formBody.append(
        "idCorsiDaAlgoritmo",
        JSON.stringify(idCorsiEstrattiDaAlgoritmo)
      );

      formBody.append(
        "idAttestatiEstrattiDaAlgoritmo",
        JSON.stringify(idAttestatiEstrattiDaAlgoritmo)
      );

      await ControlliRepository.generaVerbaleCampionamento(
        this.anno,
        true,
        formBody
      )
        .then((data) => {
          if (data) {
            this.$router.push({
              name: "VerbaleCampionamento",
              params: {
                anno: this.anno,
                idVerbale: data,
              },
            });
          }
        })
        .catch((reason) => {
          this.disabilitazionePulsanteFinalizza = false;
          this.disabilitazionePulsanteNuovoCampionae = false;
          console.log(reason);
        });
    },
    async downloadFile(idFile, nomeOriginale) {
      const res = MinisteroRepository.downloadFile(
        this.anno,
        idFile,
        nomeOriginale
      );

      if (Array.isArray(res)) {
        this.setValueSnackbar(true, res[0], "red-darken-4");
      }
    },
    getIdCorsiEstrattiDaAlgoritmo() {
      let idCorsiEstrattiDaAlgoritmo = [];

      this.campioneOriginale.forEach((element) => {
        idCorsiEstrattiDaAlgoritmo.concat(
          element.corsiCampionati.map((element1) => {
            idCorsiEstrattiDaAlgoritmo.push(element1.corsoCampionato.id);
          })
        );
      });
      return idCorsiEstrattiDaAlgoritmo;
    },
    getIdAttestatiEstrattiDaAlgoritmo() {
      let attestatiEstratti = [];
      //let idAttestatiEstratti = [];

      this.campioneOriginale.forEach((element) => {
        element.corsiCampionati.forEach((element1) => {
          // dato che dall'algoritmo viene ritornato solo un attestato per corso
          attestatiEstratti.push(element1.certificatoCampionato[0].id);
        });
      });

      return attestatiEstratti;
    },
  },
};
</script>
<style scoped>
p {
  font-size: 15px;
}
</style>
