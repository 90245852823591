<template>
  <v-main>
    <v-card class="ma-8 pa-8" elevation="4">
      <v-card-title v-html="'Richiesta di chiarimenti'"></v-card-title>
      <div class="py-5">
        <!-- v-if="loadingPage" -->
        <v-card elevation="5" v-if="rendiconto">
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="richiesta in rendiconto.richiesteChiarimentiUdm"
              :key="richiesta.id"
            >
              <v-expansion-panel-header color="white" class="black--text">
                <v-row>
                  <v-col cols="3">
                    <v-card-text>
                      Richiesta ID:
                      <br />
                      <br />
                      <b>{{ richiesta.id }}</b>
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      Soggetto attuatore
                      <br />
                      <br />
                      <b>
                        {{ rendiconto.programma.nomeIstituzione }}
                      </b>
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      Ruolo amministrazione
                      <br />
                      <br />
                      <b>
                        {{
                          getRuoloAmministrazione(richiesta.richiedente.ruoli)
                        }}
                      </b>
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      Data
                      <br />
                      <br />
                      <b> {{ getDate(richiesta.dataCreazione) }} </b>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pt-5">
                  <v-row class="mt-2">
                    <v-col>
                      <h4 class="blue--text">
                        Descrizione richiesta di integrazioni e modifiche:
                      </h4>
                      <p>
                        {{ richiesta.descrizione }}
                      </p>
                    </v-col>
                    <v-col class="d-flex flex-row-reverse my-auto" cols="4"
                      ><div v-if="richiesta.fileRichiesta">
                        <a @click="getFileAllegato(richiesta, 'richiesta')">
                          <v-icon> mdi-tray-arrow-down </v-icon> Download file
                          allegato alla Richiesta
                        </a>
                      </div>
                      <div v-else>
                        <p>
                          <v-icon> mdi-paperclip-off </v-icon>Nessun allegato
                          caricato
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="richiesta.risposta">
                  <v-row class="mt-2">
                    <v-col>
                      <h4 class="red--text">Risposta:</h4>
                      <div class="pt-2">
                        <p>
                          {{ richiesta.risposta }}
                        </p>
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-row-reverse my-auto" cols="4"
                      ><div v-if="richiesta.fileAllegato">
                        <a @click="getFileAllegato(richiesta, 'risposta')">
                          <v-icon> mdi-tray-arrow-down </v-icon> Download file
                          allegato alla Risposta
                        </a>
                      </div>
                      <div v-else>
                        <p>
                          <v-icon> mdi-paperclip-off </v-icon>Nessun allegato
                          caricato
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-card v-else class="text-center">
          <v-card-actions class="justify-center">
            <alert-component
              testo="Nessuna richiesta di integrazione/modifica creata"
              tipo-alert="info"
              :width="800"
            />
          </v-card-actions>
        </v-card>
      </div>
      <div v-if="!readOnly">
        <v-form
          lazy-validation
          ref="formRichiestaModifiche"
          v-model="valid"
          :rules="validateField.descrizione"
        >
          <v-textarea
            auto-grow
            filled
            background-color="grey lighten-2"
            color="black"
            label="Descrizione"
            v-model="formRichiestaModifiche.descrizione"
            :rules="validateField.descrizione"
          />

          <v-file-input
            label="Carica file allegato alla richiesta in pdf/p7m (facoltativo)"
            @change="uploadFile($event)"
          ></v-file-input>

          <v-card-actions>
            <v-btn
              color="primary"
              @click="dialogSalva = true"
              :disabled="!valid"
            >
              Conferma
            </v-btn>
            <v-btn class="ml-4" @click="closeDialog">Annulla</v-btn>
          </v-card-actions>
        </v-form>
        <dialog-conferma
          title="Conferma nuova richiesta"
          text-conferma="Confermando l'operazione la richiesta sarà inviata all'incaricato del controllo della DG controlli. Continuare?"
          @callback="creaRichiesta()"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />
        <v-snackbar
          v-model="snackbar"
          :timeout="3000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-card>
  </v-main>
</template>
<script>
import AlertComponent from "@/components/Common/AlertComponent.vue";
import DialogConferma from "@/components/DialogConferma";
import { mapState } from "vuex";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "contenutoDialogRichiestaModificaUdmComponent",
  computed: {
    ...mapState(["anno"]),
  },
  components: {
    AlertComponent,
    DialogConferma,
  },
  created() {
    this.initialize();
  },
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    idStrutture: null,
    loadingPage: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    formRichiestaModifiche: {
      descrizione: null,
      dialogConferma: false,
    },
    valid: false,
    validateField: {
      descrizione: [
        (v) => !!v || "Campo obbligatorio",
        (v) =>
          (v && v.length <= 1000) || "Numero di caratteri elevato (max 1000)",
      ],
    },
  }),
  methods: {
    initialize() {
      this.idStrutture = this.rendiconto ? this.rendiconto.idStrutture : null;
    },
    async creaRichiesta() {
      if (this.$refs.formRichiestaModifiche.validate()) {
        let richiesta = { ...this.formRichiestaModifiche };
        const formData = new FormData();
        formData.append("form", JSON.stringify(richiesta));
        formData.append("file", this.file);
        try {
          await ControlliUdmRepository.createNuovaRichiestaChiarimentiUdm(
            parseInt(this.anno),
            this.rendiconto.id,
            formData
          );
          this.formRichiestaModifiche.descrizione = null;
          this.$emit("richiesta-aggiunta");
          this.snackbarText = "Richiesta di chiarimenti inserita";
          this.snackbarColor = "success";
          this.snackbar = true;
          setTimeout(() => {
            this.$emit("close-dialog");
          }, 3000);
        } catch (e) {
          console.log(e);
          if (e.response.status === 415) {
            this.snackbarText = e.response.data[0];
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          } else {
            this.snackbarText =
              "Problema durante il salvataggio, contattare il supporto se il problema persiste";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          }
        }
      }
    },
    getRuoloAmministrazione(arrayRuoli) {
      if (arrayRuoli.some((item) => item.id === 8)) {
        return "Responsabile del controllo - UDM";
      } else if (arrayRuoli.some((item) => item.id === 9)) {
        return "Incaricato del controllo - UDM";
      } else {
        return "--";
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    uploadFile(event) {
      this.file = event;
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    async getFileAllegato(richiesta, tipoAllegato) {
      let nomeFile =
        tipoAllegato === "richiesta"
          ? richiesta.fileRichiesta.nomeOriginale
          : richiesta.fileAllegato.nomeOriginale;
      await ControlliUdmRepository.getFileRispostaRichiestaUdm(
        this.idStrutture,
        richiesta.id,
        nomeFile,
        tipoAllegato
      );
    },
  },
};
</script>
