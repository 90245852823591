var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-main',[_c('div',{staticClass:"ma-6 pa-6"},[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_c('div',{},[_c('StepRendicontazioni',{attrs:{"numero-step":4}})],1),_c('h1',[_vm._v("Firma Checklist")])]),(_vm.loadingComponents)?_c('v-main',[(_vm.rendiconto.assegnazioneControlloUdm)?_c('DatiFirmatario',{attrs:{"responsabile-controllo":_vm.rendiconto.assegnazioneControlloUdm.incaricato}}):_c('v-card-text',[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Attenzione nessun incricato di controllo è stato assegnato a questo rendiconto")])],1)],1):_vm._e(),(_vm.loadingComponents && _vm.rendiconto)?_c('v-main',[_c('riepilogo-generale-rendiconto-component',{attrs:{"rendiconto":_vm.rendiconto}})],1):_vm._e(),(_vm.loadingComponents)?_c('v-main',[_c('CaricamentoFileChecklistUdm',{attrs:{"rendiconto":_vm.rendiconto,"tipo-caricamento":1},on:{"callback":_vm.aggiornamentoVerificaRendiconto,"inizializzazionePagina":_vm.inizializzazionePagina}}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.value),callback:function ($$v) {_vm.$set(_vm.snackbar, "value", $$v)},expression:"snackbar.value"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1):_vm._e(),(_vm.rendiconto)?_c('v-main',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"w-100 align-center justify-center",attrs:{"color":"white"}},[_c('router-link',{attrs:{"to":{
              name: 'verificaChecklistsRendicontoUdm',
              params: { idRendiconto: _vm.idRendicontoSelezionato },
            }}},[_vm._v(" Indietro ")])],1)],1),_c('v-col',{attrs:{"cols":"2"}}),(_vm.verificaRendiconto.fileVerificaCheklist)?_c('v-col',{attrs:{"cols":"5"}},[_c('router-link',{staticClass:"v-btn primary float-end",attrs:{"disabled":_vm.verificaRendiconto.fileVerificaCheklist == null,"to":{
            name: 'verificaVerbaleEsitoControlliRendicontoUdm',
            params: { idRendiconto: this.idRendicontoSelezionato },
          }}},[_c('span',{staticClass:"ma-1 pa-1"},[_vm._v(" Continua ")])])],1):_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"v-btn primary float-end",attrs:{"color":"primary","disabled":true},domProps:{"innerHTML":_vm._s('Continua')}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }