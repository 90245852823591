<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row class="mb-4">
      <v-col>
        <h1>Anagrafica rendiconto di progetto</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col> ID Rendiconto</v-col>
      <v-col> Quadrimestre</v-col>
      <v-col> Data Trasmissione</v-col>
      <v-col> Importo richiesto</v-col>
    </v-row>
    <v-row v-if="rendiconto" class="mb-6">
      <v-col>
        <b> {{ rendiconto.id }}</b></v-col
      >
      <v-col>
        <b>
          {{ rendiconto.quadrimestre.tipologiaQuadrimestre.id ?? "" }}°
          Quadrimeste ({{
            rendiconto.quadrimestre.tipologiaQuadrimestre.descrizione ?? ""
          }})</b
        ></v-col
      >
      <v-col
        ><b> {{ getDate(rendiconto.dataTrasmissione) }}</b>
      </v-col>
      <v-col
        ><b>
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(rendiconto.importoRichiesto)
          }}
        </b>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="mb-4" style="color: gray; padding: 5px">
          Dichiarazione del soggetto attuatore propedeutica alla presentazione
          del rendiconto di progetto
        </h4>
        <a v-if="rendiconto" @click="downloadPdf">
          Scarica
          <v-icon> mdi-tray-arrow-down </v-icon>
        </a>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col>
        <h1 style="margin-top: 75px">Dettaglio corsi</h1>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="corsi"
      :items-per-page="10"
      class="elevation-1"
      :loading="loadingDettaglio"
      loading-text="Caricamento dati in corso..."
      ><!-- eslint-disable -->
        <template v-slot:item.corso="{ item }">
          <span>{{ item.corso.corso.id }}</span>
        </template> 
        <template v-slot:item.actions="{ item }">
          <router-link
              class="v-btn"
              :to="{
                    name: 'dettaglioCorso',
                    params: {
                      //capofila: capofila,
                      idCorso: Number(item.corso.id),
                      idRendiconto: Number(idRendiconto),
                    },
                  }"
          >
            Dettaglio corso
            <v-icon></v-icon>
          </router-link>
        </template>
      <!-- eslint-enable -->
    </v-data-table>
    <div class="mt-4">
      <v-btn text class="blue--text" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna a lista elenco rendiconti
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "dettaglioRendiconto",
  components: { BreadcrumbComponent },
  data: () => ({
    loadingDettaglio: false,
    loadingRendiconti: false,
    corsi: [],
    rendiconto: null,
    headers: [
      {
        text: "ID corso",
        align: "start",
        value: "corso.id",
      },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    idRendiconto: {
      type: [String, Number],
      required: true,
    },
    idStrutture: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters(["isRoleUdm"]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/${this.isRoleUdm ? "udm/" : ""}controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Rendiconti",
            disabled: false,
            to: `/scrivania/${
              this.isRoleUdm ? "udm/" : ""
            }controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Nuovo controllo",
            disabled: false,
            to: `/scrivania/${
              this.isRoleUdm ? "udm/" : ""
            }controlli/nuovo-controllo`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio rendiconto",
            disabled: true,
            to: `/scrivania/controlli/lista-rendiconti/nuovo-controllo/dettaglioRendiconto`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  methods: {
    initialize() {
      this.getCorsiRendiconto();
      this.getRendiconto();
    },
    async getRendiconto() {
      this.loadingRendiconti = true;
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.loadingRendiconti = false;
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    async getCorsiRendiconto() {
      this.loadingDettaglio = true;
      this.corsi = await RendicontiRepository.getListaCorsiRendiconto(
        this.idStrutture,
        this.idRendiconto
      );
      this.loadingDettaglio = false;
    },
    async downloadPdf() {
      await MinisteroRepository.downloadFile(
        this.rendiconto.anno,
        this.rendiconto.fileDocumentazioneFirmata.id,
        this.rendiconto.fileDocumentazioneFirmata.nomeOriginale
      );
    },
  },
};
</script>

<style scoped></style>
