const filtersNumber = [
  {
    name: "formatDate",
    execute: function (value) {
      if (value) {
        return new Date(value).toLocaleDateString();
      }
      return null;
    },
  },
  {
    name: "formatDateLong",
    execute: function (value) {
      if (value) {
        return new Date(value).toLocaleDateString(navigator.languages, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return null;
    },
  },
  {
    name: "formatDatetime",
    execute: function (value) {
      if (value) {
        return new Date(value).toLocaleString();
      }
      return null;
    },
  },
  {
    name: "formatDatetimeLong",
    execute: function (value) {
      if (value) {
        return new Date(value).toLocaleString(navigator.languages, {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      }
      return null;
    },
  },
];

export default filtersNumber;
