<template>
  <div>
    <v-card elevation="8" class="mx-auto" :loading="loading">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h2>Target Proposti</h2>
            <v-data-table
              :headers="headers"
              :items="target"
              class="elevation-1"
            >
              <!-- eslint-disable -->
              <template v-slot:item.numeroStudenti="{ item }">
                <div
                    class="text-start green--text"
                    v-if="item.numeroStudenti > item.targetOriginale.numeroStudenti">
                  {{ item.targetOriginale.numeroStudenti }} -> {{ item.numeroStudenti }}
                </div>
                <div class="text-start" v-else>{{ item.numeroStudenti }}</div>
              </template>
              <template v-slot:item.numeroCorsi="{ item }">
                <div
                    class="text-start green--text"
                    v-if="item.numeroCorsi > item.targetOriginale.numeroCorsi">
                  {{ item.targetOriginale.numeroCorsi }} -> {{ item.numeroCorsi }}
                </div>
                <div class="text-start" v-else>{{ item.numeroCorsi }}</div>
              </template>
              <template v-slot:item.numeroAccordi="{ item }">
                <div class="text-start green--text"
                    v-if="item.numeroAccordi > item.targetOriginale.numeroAccordi">
                  {{ item.targetOriginale.numeroAccordi }} -> {{ item.numeroAccordi }}
                </div>
                <div class="text-start" v-else>{{ item.numeroAccordi }}</div>
              </template>
              <template v-slot:item.attribuzioneFinanziaria="{ item }">
                <div class="text-start green--text"
                     v-if="item.attribuzioneFinanziaria > item.targetOriginale.attribuzioneFinanziaria">
                  {{ item.targetOriginale.attribuzioneFinanziaria }} -> {{ item.attribuzioneFinanziaria }}
                </div>
                <div class="text-start" v-else>{{ item.attribuzioneFinanziaria }}</div>
              </template>

<!--              <template v-slot:item.accetta="{ item }">-->
<!--                <v-btn-->
<!--                    :disabled="item.statoMinistero?.id !== undefined && item.statoMinistero?.id !== null || loading"-->
<!--                    color="success"-->
<!--                    @click="accettaDialog(item.idStrutture)">-->
<!--                  Accetta-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <template v-slot:item.rifiuta="{ item }">-->
<!--                <v-btn-->
<!--                    :disabled="item.statoMinistero?.id !== undefined && item.statoMinistero?.id !== null || loading"-->
<!--                    color="red"-->
<!--                    @click="rifiutaDialog(item.idStrutture)">-->
<!--                  Rifiuta-->
<!--                </v-btn>-->
<!--              </template>-->
              <template v-slot:item.statoIstituzione="{ item }">
                <v-chip
                    v-if=" 'statoIstituzione' in item && item.statoIstituzione.id === 1"
                    class="ma-2"
                    color="green"
                    text-color="white"
                >
                  {{ item.statoIstituzione.descrizione }}
                </v-chip>
                <v-chip
                    v-else-if=" 'statoIstituzione' in item && item.statoIstituzione.id === 2"
                    class="ma-2"
                    color="red"
                    text-color="white"
                >
                  {{ item.statoIstituzione.descrizione }}
                </v-chip>
                <v-chip
                    v-else-if=" 'statoIstituzione' in item && item.statoIstituzione.id === 3"
                    class="ma-2"
                >
                  {{ item.statoIstituzione.descrizione }}
                </v-chip>
                <v-chip
                    v-else
                    class="ma-2"
                    color="yellow"
                >
                  Riaperta
                </v-chip>
              </template>
              <!-- eslint-enable -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!--    <dialog-conferma-->
    <!--      @callback="accettaTarget"-->
    <!--      :dialog.sync="showDialogAccetta"-->
    <!--      @close-dialog="showDialogAccetta = false"-->
    <!--    />-->
    <!--    <dialog-conferma-->
    <!--      @callback="rifiutaTarget"-->
    <!--      :dialog.sync="showDialogRifiuta"-->
    <!--      @close-dialog="showDialogRifiuta = false"-->
    <!--    />-->
  </div>
</template>

<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import { mapState } from "vuex";
// import DialogConferma from "@/components/DialogConferma";

export default {
  name: "TargetView",
  components: {
    // DialogConferma,
  },
  data: () => ({
    loading: true,
    headers: [
      {
        text: "Istituzione",
        align: "start",
        sortable: true,
        value: "nomeStrutture",
      },
      {
        text: "ID strutture",
        value: "idStrutture",
        class: "id-strutture",
        cellClass: "id-strutture",
      },
      { text: "Numero Accordi", value: "numeroAccordi" },
      { text: "Numero Corsi", value: "numeroCorsi" },
      { text: "Numero Studenti", value: "numeroStudenti" },
      { text: "Attribuzione Finanziaria", value: "attribuzioneFinanziaria" },
      // { text: "", value: "accetta", sortable: false },
      // { text: "", value: "rifiuta", sortable: false },
      { text: "Stato Istituzione", value: "statoIstituzione" },
    ],
    target: [],
    showDialogAccetta: false,
    showDialogRifiuta: false,
    idStruttureSelected: null,
  }),
  computed: {
    ...mapState(["anno"]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno != null) {
        this.getTarget();
      }
    },
    async getTarget() {
      this.loading = true;
      this.target = await MinisteroRepository.getTarget(this.anno);
      this.loading = false;
    },
    async controllaAzione(result, value) {
      return (
        result?.statoMinistero !== undefined &&
        result?.statoMinistero.id === parseInt(value)
      );
    },
    //funzione implementata ma non usata nel 2022
    async accettaTarget() {
      this.loading = true;
      const result = await MinisteroRepository.accettaTarget(
        this.idStruttureSelected,
        this.anno
      );
      const ok = await this.controllaAzione(result, 1);
      if (ok) {
        this.target = await MinisteroRepository.getTarget(this.anno);
      }
      this.loading = false;
    },
    accettaDialog(idStrutture) {
      this.idStruttureSelected = idStrutture;
      this.showDialogAccetta = true;
    },
    rifiutaDialog(idStrutture) {
      this.idStruttureSelected = idStrutture;
      this.showDialogRifiuta = true;
    },
    //funzione implementata ma non usata nel 2022
    async rifiutaTarget() {
      this.loading = true;
      const result = await MinisteroRepository.rifiutaTarget(
        this.idStruttureSelected,
        this.anno
      );
      const ok = await this.controllaAzione(result, 2);
      if (ok) {
        this.target = await MinisteroRepository.getTarget(this.anno);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.id-strutture {
  display: none;
}
</style>
