<template>
  <v-card elevation="3" class="text-center" v-if="rendicontoSelezionato">
    <h3 class="font-weight-bold text-left sottotitolo">
      Anagrafica rendiconto di progetto
    </h3>
    <v-row>
      <v-col class="w-25">
        <v-card-text> <b> Data Presentazione </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> N° Rendiconto </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b> Periodo di riferimento </b>
        </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> Importo Rendicontato </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b> CUP </b>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="w-25">
        <p>
          {{
            rendicontoSelezionato.dataTrasmissione
              ? getDate(rendicontoSelezionato.dataTrasmissione)
              : "Non ancora trasmesso."
          }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>{{ rendicontoSelezionato.id }}</p>
      </v-col>
      <v-col class="w-25">
        <p>
          {{
            rendicontoSelezionato.quadrimestre.tipologiaQuadrimestre.descrizione
          }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>{{ numberFormat(rendicontoSelezionato.importoRichiesto) }}</p>
      </v-col>
      <!--<v-col class="w-25">
        <p>
          {{
            rendiconto.ibanBancario != null
              ? rendiconto.ibanBancario
              : rendiconto.contoTesoreria
          }}
        </p>
      </v-col>-->
      <v-col class="w-25">
        <v-card-text>
          <p>{{ rendicontoSelezionato.programma.cup }}</p>
        </v-card-text>
      </v-col>
    </v-row>
    <div v-if="udm">
      <v-row>
        <v-col cols="2">
          <v-card-text>
            <b> Campione Esteso dalla DG controlli</b>
          </v-card-text>
        </v-col>
        <v-col cols="3">
          <v-card-text>
            <b> Allegato </b><br />
            <small
              >Dichiarazione del soggetto attuatore propedeutica alla
              presentazione del progetto</small
            >
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-card-text>
            <p>{{ isCampioneEsteso ? "Si" : "No" }}</p>
          </v-card-text>
        </v-col>
        <v-col cols="3">
          <v-card-text>
            <a v-if="rendiconto" @click="downloadPdf">
              Scarica
              <v-icon> mdi-tray-arrow-down </v-icon>
            </a>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "riepilogoGeneraleRendiconto",
  components: {},
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
    udm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    rendicontoSelezionato: null,
    verbaliEstensione: null,
    isCampioneEsteso: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.rendiconto) {
        this.rendicontoSelezionato = this.rendiconto;
        this.campioneEstesoDg();
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    async downloadPdf() {
      await MinisteroRepository.downloadFile(
        this.rendiconto.anno,
        this.rendiconto.fileDocumentazioneFirmata.id,
        this.rendiconto.fileDocumentazioneFirmata.nomeOriginale
      );
    },
    async campioneEstesoDg() {
      this.verbaliEstensione = await ControlliUdmRepository.campioneEstesoDg(
        this.rendiconto.anno,
        this.rendiconto.id
      );
      this.isCampioneEsteso = this.verbaliEstensione.length >= 0;
    },
    numberFormat(number) {
      if (number) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(number);
      }
      return "";
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
