<template>
  <v-container fluid>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main>
      <v-row class="mb-6">
        <v-col cols="9">
          <h1 style="color: #1a237e">Checklist e Esito</h1>
        </v-col>
        <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="6">
          <step-verifica-formale-udm
            :numero-step="2"
          ></step-verifica-formale-udm>
        </v-col>
      </v-row>
    </v-main>
    <v-main v-if="rendiconto">
      <RiepilogoGeneraleRendicontoComponent :rendiconto="rendiconto" />
    </v-main>
    <v-main>
      <v-card v-if="loadingPage" elevation="4">
        <v-card-title v-html="'Compila Checklist'" />
        <v-form ref="formChecklistVerifica" lazy-validation>
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(tipoChecklist, index) in tipiChecklistVerifica"
              :key="index"
            >
              <v-expansion-panel-header
                color="blue lighten-4"
                class="blue--text mb-5 w-100"
              >
                <v-row>
                  <v-col cols="8">
                    <v-card-text
                      class="float-start"
                      v-html="tipoChecklist.descrizione"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h3 class="align-end">
                      <v-chip
                        outlined
                        pill
                        v-html="
                          getStatoChecklist(formChecklistVerifica[index]).text
                        "
                        :color="
                          getStatoChecklist(formChecklistVerifica[index]).color
                        "
                        class="white--text"
                      />
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-main
                  v-for="(domande, indexDomande) in domandeTooltipChecklist[
                    index
                  ]"
                  :key="indexDomande"
                >
                  <v-card-text>
                    <b>{{ indexDomande + 1 }}. </b>
                    <span v-html="domande.domanda" />
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon dark v-bind="attrs" v-on="on">
                          <v-icon color="grey lighten-1">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </template>
                      <span v-html="domande.tooltip" />
                    </v-tooltip>
                  </v-card-text>
                  <v-row>
                    <v-col cols="4" style="border: 2px solid black">
                      <v-autocomplete
                        :items="itemsSelectChecklist"
                        item-text="text"
                        item-value="value"
                        v-model="
                          formChecklistVerifica[index][indexDomande].conferma
                        "
                        :rules="validateField.fieldConferma"
                        class="align-center"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-textarea
                        outlined
                        class="w-50 h-25"
                        v-model="
                          formChecklistVerifica[index][indexDomande]
                            .documentiVerificati
                        "
                        label="Elenco dei documenti verificati"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-textarea
                        outlined
                        class="w-50 h-25"
                        v-model="
                          formChecklistVerifica[index][indexDomande].note
                        "
                        label="Note"
                      />
                    </v-col>
                  </v-row>
                </v-main>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card>
    </v-main>
    <v-main>
      <v-card elevation="4" class="" v-if="rendiconto">
        <v-card-title v-html="'Esito checklist'" />
        <v-form ref="formChecklistEsito" lazy-validation>
          <v-row class="ma-5">
            <v-col cols="3">
              <v-autocomplete
                :items="itemsSelectEsitoChecklist"
                item-text="text"
                item-value="value"
                v-model="formChecklistEsito.esito"
                :rules="validateField.fieldConferma"
                @input="validateTextFieldEsiti"
                @change="validateTextFieldEsiti"
              />
            </v-col>
          </v-row>
          <v-row class="ma-5">
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.osservazione"
                label="Osservazioni"
                :rules="[validateTextFieldEsiti(1)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.raccomandazione"
                label="Raccomandazioni"
                :rules="[validateTextFieldEsiti(2)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.segnalazioneIrregolarita"
                label="Segnalazione Irregolarità"
                :rules="[validateTextFieldEsiti(3)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-progress-circular
        v-else
        indeterminate
        color="blue"
      ></v-progress-circular>
    </v-main>
    <v-main>
      <v-row>
        <v-col cols="2">
          <router-link
            class="v-btn float-start"
            v-html="'Indietro'"
            :to="{
              name: 'VerificaFormaleUdmStepUnoView',
              params: { idRendiconto: idRendicontoSelezionato },
            }"
          >
            <span class="ma-1 pa-1"> Indietro </span>
          </router-link>
        </v-col>
        <v-col cols="8" />
        <v-col cols="2">
          <v-btn color="primary" @click="dialogConferma = true">
            Salva e continua
          </v-btn>
        </v-col>
      </v-row>
      <dialog-conferma
        @close-dialog="dialogConferma = false"
        @callback="onSubmitCompilazioneChecklist()"
        :dialog.sync="dialogConferma"
      />
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import StepVerificaFormaleUdm from "@/components/Controlli/Udm/VerificheFormali/StepVerificaFormaleUdm";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import RiepilogoGeneraleRendicontoComponent from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";

export default {
  name: "VerificaFormaleUdmChecklistsView",
  components: {
    BreadcrumbComponent,
    DialogConferma,
    StepVerificaFormaleUdm,
    RiquadroProfiloRuolo,
    RiepilogoGeneraleRendicontoComponent,
  },
  data: () => ({
    loadingPage: false,
    tipiChecklistVerifica: [],
    idRendicontoSelezionato: null,
    rendiconto: null,
    dialogConferma: false,
    snackbar: {
      text: null,
      value: false,
      color: null,
    },
    verificaFormaleRendiconto: null,
    itemsSelectChecklist: [
      { text: "Seleziona", value: -1 },
      { text: "SI", value: 1 },
      { text: "NO", value: 2 },
      { text: "NA", value: 3 },
    ],
    itemsSelectEsitoChecklist: [
      { text: "Seleziona", value: -1 },
      { text: "Positivo", value: 1 },
      { text: "Negativo", value: 2 },
      { text: "Parzialmente positivo", value: 3 },
    ],
    formChecklistEsito: {
      esito: -1,
      importoRendicontato: null,
      osservazione: null,
      raccomandazione: null,
      segnalazioneIrregolarita: null,
      importoAmmissibile: null,
      importoNonAmmissibile: null,
    },
    formChecklistVerifica: [
      // form della checklist 1,
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Rendiconto delle spese;\n -  Avviso;\n - Decreto di concessione e relativi allegati.",
          note: null,
          tipoChecklist: 1,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Richieste di proroga;\n - Concessione proroga.",
          note: null,
          tipoChecklist: 1,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Rendiconto delle spese;\n - Documentazione amministrativo-contabile; \n - Dichiarazione sostitutiva di atto notorio (DSAN).",
          note: null,
          tipoChecklist: 1,
          domanda: 3,
        },
      ],
      // form della checklist di tipo 2, 8 domande e dunque 8 oggetti form
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 3,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 4,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 5,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 6,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 7,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Attestazione/flag informatico;\n - Checklist di autocontrollo del Soggetto Attuatore.",
          note: null,
          tipoChecklist: 2,
          domanda: 8,
        },
      ],
      // form della checklist di tipo 3, 3 domande
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo;\n - Scheda progettuale;\n - Documentazione amministrativo-contabile;\n Sistema informatico;\n - Altro.",
          note: null,
          tipoChecklist: 3,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo;\n - Scheda progettuale;\n - Documentazione amministrativo-contabile;\n - Altro.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Certificazioni/autocertificazioni richieste in fase di presentazione della documentazione di regolare esecuzione.",
          note: null,
          tipoChecklist: 3,
          domanda: 3,
        },
      ],
      // form della checklist di tipo 4
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Avviso e relativi allegati;\n - Decreto attuativo;\n - Decreto di concessione del finanziamento;\n - Atti sottoscritti dal Soggetto attuatore;\n - Eventuali proroghe/modifiche concesse; \n - Programma di Orientamento.",
          note: null,
          tipoChecklist: 4,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Piattaforma “Orientamento 2026”;\n - Registro presenze.",
          note: null,
          tipoChecklist: 4,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Elenco alunni partecipanti;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata ;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 4,
          domanda: 3,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Decreto di concessione;\n - Totale Disposizioni di pagamento.",
          note: null,
          tipoChecklist: 4,
          domanda: 4,
        },
      ],
    ],
    domandeTooltipChecklist: [
      // domande e tooltipi checklist 1
      [
        {
          domanda:
            "Il rendiconto delle spese è stato presentato dal Soggetto attuatore nei termini e nelle modalità indicata dall’Avviso, Decreto di concessione e relativi allegati?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Rendiconto delle spese</li><li>Avviso</li> <li>Decreto di concessione e relativi allegati</li></ul>",
        },
        {
          domanda:
            "Sono state approvate eventuali proroghe delle attività progettuali richieste dal beneficiario?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Richieste di proroga</li><li> Concessione proroga</li></ul> ",
        },
        {
          domanda:
            "Il soggetto attuatore ha presentato una specifica dichiarazione attestante che le spese oggetto di rendiconto non hanno beneficiato di ulteriori finanziamenti?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Rendiconto delle spese</li><li> Documentazione amministrativo-contabile</li><li>Dichiarazione sostitutiva di atto notorio (DSAN)</li></ul> ",
        },
      ],
      // domande e tooltipi checklist 2
      [
        {
          domanda:
            "Il Soggetto Attuatore ha attestato lo svolgimento dei controlli di regolarità amministrativo-contabile?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche ex ante sul titolare effettivo? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche ex ante sul conflitto di interessi?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sull’assenza di doppio finanziamento sulle spese esposte a rendiconto?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto delle condizionalità del PNRR?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto dei principi trasversali del PNRR?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto degli ulteriori requisiti del PNRR connessi alla Misura a cui è associato il progetto?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto del principio del DNSH anche qualora tale principio costituisca un requisito esplicito dell’intervento espressamente indicato nelle condizionalità di cui all’Annex alla CID ed agli Operational Arrangements?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Attestazione/flag informatico</li><li> Checklist di autocontrollo del Soggetto Attuatore</li></ul> ",
        },
      ],
      // domande e tooltip checklist 3
      [
        {
          domanda:
            "Il soggetto attuatore ha trasmesso i dati relativi agli indicatori di milestone e target, intermedi e finali, di progetto nel rispetto della tempistica e delle modalità previste dal Si.Ge.Co.? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Scheda progettuale </li><li> Documentazione amministrativo-contabile </li> <li>Sistema informatico</li> <li>Altro</li></ul>",
        },
        {
          domanda:
            "È stato verificato il rispetto di quanto previsto dal PNRR in materia di informazione e pubblicità, secondo quanto disposto dall’art. 34, Reg. (UE) 2021/241? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Scheda progettuale </li><li> Documentazione amministrativo-contabile </li> <li>Altro</li></ul>",
        },
        {
          domanda:
            "Laddove pertinente, è stata verificata la presenza della documentazione probatoria relativa al rispetto dell’applicazione del principio “Do Not Significant Harm” (DNSH). In particolare, sono state fornite le certificazioni/autocertificazioni richieste in fase di presentazione della documentazione di regolare esecuzione o altro provvedimento di approvazione delle attività eseguite/beni forniti?",
          tooltip:
            "Oggetto del controllo <br /> <ul><li>  Certificazioni/autocertificazioni richieste in fase di presentazione della documentazione di regolare esecuzione;</li></ul>",
        },
      ],
      // domande e tooltipi checklist 4
      [
        {
          domanda:
            "Il progetto risulta concluso nei termini previsti dall’Avviso/Decreto di concessione e relativi allegati/Atti sottoscritti dal Soggetto attuatore e dal progetto approvato e dalla normativa comunitaria e nazionale di riferimento?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Avviso</li><li> Decreto di concessione e relativi allegati </li> <li> Atti sottoscritti dal Soggetto attuatore</li><li> Eventuali proroghe/modifiche concesse</li><li> Scheda progettuale</li> <li>Relazione tecnica finale del progetto</li></ul>",
        },
        {
          domanda:
            "Il Soggetto attuatore ha presentato la documentazione attestante la conclusione delle attività di progetto prevista dall’Avviso/Decreto di concessione/Atti sottoscritti? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Avviso</li><li> Decreto di concessione e relativi allegati </li> <li> Atti sottoscritti dal Soggetto attuatore</li><li> Eventuali proroghe/modifiche concesse</li><li> Scheda progettuale</li> <li>Relazione tecnica finale del progetto</li></ul>",
        },
        {
          domanda:
            "L’esperto Tecnico Scientifico ha valutato positivamente il progetto realizzato, confermando il conseguimento degli obiettivi fissati?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Relazione tecnica finale del progetto</li> <li>Check-List verifica avanzamento fisico</li></ul>",
        },
        {
          domanda:
            "La spesa rendicontata, sommata all’importo precedentemente liquidato al Soggetto attuatore, rientra nei massimali previsti dal decreto di concessione del finanziamento?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Avviso</li><li> Decreto di concessione e relativi allegati </li> <li> Scheda progettuale</li> <li>Check-List verifica avanzamento fisico</li></ul>",
        },
      ],
    ],
    validateField: {
      fieldConferma: [
        (v) => v > 0 || "E' obbligatoria la selezione di uno dei tre campi.",
      ],
    },
  }),
  computed: {
    ...mapState(["anno", "campioneEstratto", "campioneAggiornato"]),
    ...mapGetters([
      "isRoleResponsabileControllo",
      "isRoleConsulenza",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/udm/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Controllo formale rendiconti",
          disabled: false,
          to: `/scrivania/udm/controlli/controllo-formale-rendiconti`,
          exact: true,
          link: true,
        },
        {
          text: "Checklist",
          disabled: true,
          to: `/scrivania/udm/controlli/controllo-formale-rendiconti/${this.idRendiconto}/verifiche-checklist`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.isRoleIncaricatoControllo || this.isRoleConsulenza) &&
        this.$route.params.idRendiconto
      ) {
        this.idRendicontoSelezionato = this.$route.params.idRendiconto;
        this.getTipoChecklistVerificaFormaleRendiconto();
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getTipoChecklistVerificaFormaleRendiconto() {
      this.tipiChecklistVerifica =
        await RendicontiRepository.getTipologieChecklistVerificaFormaleRendiconto();
      this.loadingPage = true;
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleControlliFormaliUdm(
          parseInt(this.anno),
          this.idRendicontoSelezionato,
          2
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.verificaFormaleRendiconto =
          this.rendiconto.verificaFormaleRendiconto;
        this.verificaFormaleRendiconto.checklistVerificaRendiconto.sort(
          (a, b) => {
            return a.id - b.id;
          }
        );
        if (this.verificaFormaleRendiconto.stepVerifica >= 2) {
          this.predisponiValoriForm();
        } else {
          this.loadinPage = true;
        }
        //this.predisponiValoriForm();
      }
    },
    predisponiValoriForm() {
      // le checklist sono state già compilate in precedenza
      const checkListRendicontoVerifica =
        this.verificaFormaleRendiconto.checklistVerificaRendiconto;

      checkListRendicontoVerifica.forEach((element, index) => {
        // setto i valori di conferma, note e documenti verificati  con i valori della verifica di rendiconto inserita
        this.formChecklistVerifica[index].map(
          (elementForm, indexElementForm) => {
            // ogni elemento inserito nella verifica,
            // lo recupero in base alla chiave dell'oggetto di verifica calcolata,
            const confermaInserita = this.convalidaConfermaChecklistInserita(
              element["conferma" + (indexElementForm + 1).toString()]
            );
            elementForm.conferma = confermaInserita;
            elementForm.note =
              element["note" + (indexElementForm + 1).toString()];
            elementForm.documentiVerificati =
              element["verificaDocumenti" + (indexElementForm + 1).toString()];
            return elementForm;
          }
        );
      });

      this.formChecklistEsito.esito = this.convalidaEsitoChecklist(
        this.verificaFormaleRendiconto.esitoControllo
      );
      this.formChecklistEsito.osservazione =
        this.verificaFormaleRendiconto.osservazione;
      this.formChecklistEsito.raccomandazione =
        this.verificaFormaleRendiconto.raccomandazione;
      this.formChecklistEsito.segnalazioneIrregolarita =
        this.verificaFormaleRendiconto.segnalazioneIrregolarita;
      this.loadinPage = true;
    },
    convalidaConfermaChecklistInserita(value) {
      if (value == null) {
        return 3;
      } else if (value === false) {
        return 2;
      } else {
        return 1;
      }
    },
    convalidaEsitoChecklist(esito) {
      if (esito === "Positivo") {
        return 1;
      } else if (esito === "Negativo") {
        return 2;
      } else {
        return 3;
      }
    },
    getStatoChecklist(valueForm) {
      let numeroDomandeTotali = valueForm.length;
      let numeroDomandeCompilate = 0;

      valueForm.forEach((element) => {
        if (element.conferma > 0) {
          numeroDomandeCompilate += 1;
        }
      });

      if (numeroDomandeCompilate === numeroDomandeTotali) {
        // tutte le domande sono state compilate
        return { color: "success", text: "Compilato" };
      } else if (
        numeroDomandeCompilate !== 0 &&
        numeroDomandeCompilate !== numeroDomandeTotali
      ) {
        // il numero di domande compilate è diverso da 0, ma allo stesso tempo diverso dal numero totale a cui si deve rispondere
        return { color: "purple-darken-1", text: "In fase di compilazione" };
      } else {
        return {
          color: "red",
          text: "Da compilare",
        };
      }
    },
    onSubmitCompilazioneChecklist() {
      if (
        this.$refs.formChecklistVerifica.validate() &&
        this.$refs.formChecklistEsito.validate()
      ) {
        let controlloCompilazione = this.controlloCompilazioneChecklist();
        if (controlloCompilazione) {
          this.modificaRendicontoVerifica();
        } else {
          this.setValueSnackbar(
            false,
            "Impossibile procedere. Tutte le checklist non sono state compilate.",
            "red"
          );
        }
      } else {
        this.setValueSnackbar(
          false,
          "Impossibile procedere. Verificare eventuali errori o mancate compilazioni delle checklist di verifica",
          "red"
        );
      }
    },
    controlloCompilazioneChecklist() {
      let allChecklistCompilabili = [];

      this.formChecklistVerifica.forEach((element) => {
        element.forEach((domandeChecklist) => {
          allChecklistCompilabili.push(domandeChecklist.conferma);
        });
      });

      let checklistNonCompilate = allChecklistCompilabili.filter(
        (element) => parseInt(element) === -1
      );

      return checklistNonCompilate.length === 0;
    },
    setValueSnackbar(esito, text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    async modificaRendicontoVerifica() {
      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.idRendicontoSelezionato;
      const idVerificaFormaleRendiconto =
        this.rendiconto.verificaFormaleRendiconto.id;

      const formBody = new FormData();
      formBody.append("checklist", JSON.stringify(this.formChecklistVerifica));
      formBody.append("esito", JSON.stringify(this.formChecklistEsito));

      await RendicontiRepository.modificaVerificaFormaleRendiconto(
        idStrutture,
        idRendiconto,
        idVerificaFormaleRendiconto,
        2,
        formBody
      )
        .then((data) => {
          if (data) {
            setTimeout(() => this.goToNextStep(), 1500);
            this.setValueSnackbar(
              true,
              "Le checklist sono state aggiunte/ modificate.",
              "success"
            );
          }
        })
        .catch((reason) => {
          if (reason.response.status === 500) {
            this.setValueSnackbar(
              (false,
              "Un errore di server è stato riscontrato nella richiesta di modifica della checklist",
              "red")
            );
          } else {
            this.setValueSnackbar((false, reason.response.data[0], "red"));
          }
        });
    },
    goToNextStep() {
      this.$router.push({
        name: "VerificaFormaleUdmFirmaChecklistView",
        params: { idRendiconto: this.idRendicontoSelezionato },
      });
    },
    validateTextFieldEsiti(tipo) {
      if (this.formChecklistEsito.esito) {
        const esito = this.formChecklistEsito.esito;
        let campoDaConvalidare = null;
        let esitoConvalida = false;

        if (esito === 2 || esito === 3) {
          switch (tipo) {
            case 1:
              campoDaConvalidare = this.formChecklistEsito.osservazione;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;

            case 2:
              campoDaConvalidare = this.formChecklistEsito.raccomandazione;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;

            case 3:
              campoDaConvalidare =
                this.formChecklistEsito.segnalazioneIrregolarita;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;
          }

          if (esitoConvalida) {
            return true;
          } else {
            return "Il campo è obbligatorio, quando l'esito è negativo o parzialmente positivo.";
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
