<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="2">
            <v-btn v-bind="attrs" v-on="on" class="blue--text ma-2 pa-2"
              ><v-icon class="mx-2"> mdi-loupe </v-icon> Import dei dati SAP
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center"
          >Import dei dati SAP (Excel)
        </v-card-title>

        <v-card-text>
          <v-main>
            <v-alert border="top" colored-border type="info" elevation="2">
              Prima di procedere con il caricamento del file assicurarsi che
              siano verificate le seguenti indicazioni:
              <ul>
                <li v-for="(info, index) in infoUpload" :key="index">
                  <span v-html="info" />
                </li>
              </ul>
              <br />
              Al termine dell'operazione verrà scaricato un file di testo con
              gli eventuali errori da correggere e la pagina verrà ricaricata.
              In assenza di errori il file risulterà vuoto. In caso di errori di
              inserimento sarà necessario effettuare le opportune correzioni e
              ricaricare il file.
              <br />
              <br />
              Si ricorda, infine, che:
              <ul>
                <li>
                  Gli importi inseriti tramite file saranno sommati a quelli
                  precedentemente caricati in piattaforma
                </li>
                <li>
                  se nello stesso file sono presenti più righe riportanti lo
                  stesso codice CUP il sistema inserirà direttamente la somma di
                  questi con un unico import.
                </li>
              </ul>
            </v-alert>
          </v-main>
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form" lazy-validation v-model="validForm">
              <v-row>
                <v-col cols="4">
                  <v-alert type="warning" max-width="300" class="alert-warning">
                    La procedura potrebbe richiedere alcuni secondi.
                  </v-alert></v-col
                >
                <v-col cols="8">
                  <v-file-input
                    v-model="file"
                    placeholder="Upload your documents"
                    label="File dati SAP"
                    show-size
                    prepend-icon="mdi-paperclip"
                    accept=".xlsx"
                    :rules="rulesForm.formFile"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col cols="7"></v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn
                color="primary"
                @click="dialogSalva = true"
                :disabled="!validForm || activeOperation"
              >
                Invia
              </v-btn></v-card-actions
            >
            <dialog-conferma
              @callback="onSubmitUploadFile"
              :dialog.sync="dialogSalva"
              @close-dialog="dialogSalva = false"
            />
            <v-snackbar :color="snackbar.color" v-model="snackbar.value">
              {{ snackbar.text }}</v-snackbar
            >
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-btn color="primary" @click="dialog = false"> Annulla </v-btn>
            </v-card-actions></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import { mapState } from "vuex";
import AssegnazioneRisorseRepository from "@/api/ministero/AssegnazioneRisorseRepository";

export default {
  name: "DialogImportDatiSap",
  computed: {
    ...mapState(["anno"]),
  },
  components: {
    DialogConferma,
  },
  data: () => ({
    dialog: false,
    file: null,
    dialogSalva: false,
    rulesForm: {
      formFile: [(v) => !!v || " E' obbligatoria la compilazione del campo"],
    },
    infoUpload: [
      `il file deve contenere solo ed esclusivamente le colonne: Codice CUP (colonna A), importo DP (colonna B).<br>`,
      `Nella prima riga è necessario inserire l'intestazione della colonna e cioè la scritta Codice CUP (colonna A1), importo DP (colonna B1)`,
    ],
    snackbar: {
      text: "",
      color: "",
      value: false,
    },
    validForm: false,
    activeOperation: false,
  }),
  methods: {
    async onSubmitUploadFile() {
      if (!this.$refs.form.validate()) {
        this.snackbar = {
          value: true,
          text: "Il file non è stato caricato. Impossibile procedere!",
          color: "red accent-2",
        };
      } else if (this.anno === null) {
        this.snackbar = {
          value: true,
          text: "Impostare l'anno accademico di riferimento",
          color: "red accent-2",
        };
      } else {
        this.activeOperation = true;
        const formBody = new FormData();
        formBody.append("file", this.file);

        await AssegnazioneRisorseRepository.uploadFile(this.anno ?? 0, formBody)
          // eslint-disable-next-line no-unused-vars
          .then((data) => {
            this.activeOperation = false;
            this.$emit("upload-completato");
            this.file = null;
            this.dialog = false;
          })
          .catch((reason) => {
            if (reason.response.status === 400) {
              this.snackbar = {
                text: reason.response.data[0],
                color: "red accent-2",
                value: true,
              };
            } else {
              this.snackbar = {
                text: "Un errore è stato riscontrato nella richiesta. Verificare che il formato del file sia xlsx. In alternativa contattare il supporto per la segnalazione dell'errore ",
                color: "red accent-2",
                value: true,
              };
            }
            this.activeOperation = false;
            this.file = null;
          });
      }
    },
  },
};
</script>

<style scoped>
.alert-warning {
  font-size: 14px;
}
</style>
