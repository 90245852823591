<template>
  <v-container fluid v-if="anno">
    <!--<v-main>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
  </v-main>-->
    <v-main>
      <div class="ma-6 pa-6">
        <BreadcrumbComponent :breadcrumbs="breadcrumbs"></BreadcrumbComponent>
      </div>
      <div class="ma-6 pa-6">
        <StepRendicontazioni :numero-step="2" />
      </div>
      <h1>Anagrafiche</h1>
    </v-main>

    <v-card elevation="5" v-if="rendiconto">
      <v-form>
        <v-main class="ma-4 pa-4">
          <v-card-title>
            Anagrafica Amministrazione centrale titolare di interventi
          </v-card-title>
          <anagrafica-rendiconto-progetto :editable="true" />
        </v-main>
        <v-main class="ma-4 pa-4">
          <v-card-title> Anagrafica Intervento </v-card-title>
          <anagrafica-intervento
            v-if="costoProgettoAnnuale"
            :editable="true"
            :rendiconto="rendiconto"
            :costoTotale="costoProgettoAnnuale.assegnazioneTotale"
          />
        </v-main>
        <v-main class="ma-4 pa-4"
          ><v-card-title> Rendiconti precedentemente presentati </v-card-title>
          <AnagraficaRendicontiPrecedentiComponent :rendiconto="rendiconto" />
        </v-main>
      </v-form>
    </v-card>
    <v-progress-circular v-else indeterminate color="blue" />

    <v-card v-if="rendiconto" elevation="4">
      <v-main>
        <riepilogo-generale-rendiconto :rendiconto="rendiconto" />
      </v-main>
      <v-main>
        <table-attestati-campionati
          v-if="verbaleCampionamentoRendiconto"
          :con-menu-azioni="false"
          :headers-table="headersTable"
          :items-attestati="getAttestatiCampionatiRendiconto()"
        />
      </v-main>
      <v-main>
        <v-row>
          <v-col cols="5">
            <router-link
              class="v-btn ma-3 pa-3"
              v-html="'Indietro'"
              :to="{
                name: 'verificaRendicontoStepUno',
                params: { idRendiconto: this.idRendiconto },
              }"
            >
              <span class=""> Indietro </span>
            </router-link>
          </v-col>
          <v-col cols="2" />
          <v-col cols="5">
            <router-link
              class="v-btn primary ma-3 pa-3 float-end"
              :to="{
                name: 'verificaChecklistsRendiconto',
                params: { idRendiconto: this.idRendiconto },
              }"
            >
              <span> Continua </span>
            </router-link>
          </v-col>
        </v-row>
      </v-main>
    </v-card>
  </v-container>
  <v-container v-else>
    <v-alert type="warning">
      E' necessaria la selezione dell'anno associato al rendiconto che si sta
      verificando
    </v-alert>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Controlli/VerificheRendiconti/StepRendicontazioni.vue";
import RiepilogoGeneraleRendiconto from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import { mapGetters, mapState } from "vuex";
import TableAttestatiCampionati from "@/components/Controlli/VerificheRendiconti/TableAttestatiCampionatiComponent.vue";
import AnagraficaRendicontoProgetto from "@/components/Controlli/VerificheRendiconti/AnagraficaRendicontoProgettoComponent.vue";
import AnagraficaIntervento from "@/components/Controlli/VerificheRendiconti/AnagraficaInterventoComponent.vue";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import AnagraficaRendicontiPrecedentiComponent from "@/components/Controlli/VerificheRendiconti/AnagraficaRendicontiPrecedentiComponent.vue";

export default {
  name: "verificaAnagraficheRendiconto",
  components: {
    AnagraficaRendicontiPrecedentiComponent,
    BreadcrumbComponent,
    AnagraficaIntervento,
    AnagraficaRendicontoProgetto,
    TableAttestatiCampionati,
    RiepilogoGeneraleRendiconto,
    StepRendicontazioni,
  },
  computed: {
    ...mapState(["anno", "campioneAggiornato", "campioneEstratto"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleResponsabileControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Lista verbali di campionamento",
          disabled: false,
          to: `/scrivania/controlli/nuovo-controllo`,
          exact: true,
          link: true,
        },
        {
          text: "Verifica Rendiconto",
          disabled: false,
          to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
          exact: true,
          link: true,
        },
        {
          text: "Anagrafica interventi",
          disabled: true,
          to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche-anagrafiche`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    idRendiconto: null,
    rendiconto: null,
    verbaleCampionamentoRendiconto: null,
    loadingPage: false,
    formVerificaAnagrafica: {
      // campi rendiconti precedenti presentati
      importiTotaliRendicontiPrcedenti: null,
      importiTotaliCostiPrecedenti: null,
    },
    headersTable: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Istituto scolastico",
        value: "scuolaStudente",
      },
      {
        text: "Codice Fiscale",
        value: "certificato.studente.cf",
        sortable: false,
      },
      { text: "Estratto da Algoritmo", value: "estrattoDaAlgoritmo" },
      {
        text: "Id corso",
        value: "corsoCampionato.id",
      },
      {
        text: "Totale ore",
        align: "center",
        value: "certificato.totaleOreStudente",
      },
      {
        text: "% ore freq",
        align: "center",
        value: "certificato.percentualeOreSvolte",
      },
      {
        text: "Importo tariffa",
        align: "center",
        value: "importoTariffa",
      },
      { text: "Valore attribuito", value: "valoreAttribuito" },
      { text: "Esito", value: "tipologiaEsitoVerificaCertificato.descrizione" },
      { text: "Importo Ammesso", value: "importoAmmesso" },
    ],
    costoProgettoAnnuale: null,
  }),
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto != null) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
        //this.getRendicontiVerificatiPrecedenti();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliRepository.getSingoloVerbaleCampionamentoRendiconto(
          parseInt(this.anno),
          this.idRendiconto,
          2
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        await this.getCostoTotaleProgetto();
        this.loadinPage = true;
      }
    },
    getAttestatiCampionatiRendiconto() {
      // recupero tutti i corsi che sono estratti o selezionati per il rendiconto.
      const corsiCampionati =
        this.verbaleCampionamentoRendiconto.corsiCampionati;
      let allAttestatiCampionatiCorsi = [];

      corsiCampionati.forEach((elementCorso) => {
        const attestatiCampionatiSingoloCorso =
          elementCorso.certificatiCampionati;
        if (
          attestatiCampionatiSingoloCorso &&
          attestatiCampionatiSingoloCorso.length
        ) {
          attestatiCampionatiSingoloCorso.forEach((elementAttestato) => {
            elementAttestato.corsoCampionato = elementCorso.corsoOrientamento;
            allAttestatiCampionatiCorsi.push(elementAttestato);
          });
        }
      });

      return allAttestatiCampionatiCorsi;

      // per ogni corso campionato recupero invece gli attestati.
    },
    async getCostoTotaleProgetto() {
      const idStrutture = this.rendiconto.idStrutture;
      const anno = this.rendiconto.anno;
      const capofila = this.rendiconto.capofila;

      this.costoProgettoAnnuale =
        await MinisteroRepository.getCostoAnnualeProgettoIstituzione(
          idStrutture,
          anno,
          capofila
        );
    },
  },
};
</script>
