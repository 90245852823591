<template>
  <v-card elevation="4" flat outlined>
    <v-card-title v-html="'Dati del firmatario'" />
    <!--<v-row>
      <v-col cols="10"></v-col>
      <v-col cols="1">
        <v-btn class="red--text ma-4 pa-4" icon>
          Ripristina <v-icon color="red"> mdi-restart</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn class="blue--text w-25 ma-4 pa-4" icon>
          Modifica<v-icon color="blue">mdi-pencil-circle</v-icon>
        </v-btn></v-col
      >
    </v-row>-->
    <v-row>
      <v-col cols="3">
        <v-card-text style="font-size: 20px">
          Nome <br />
          <br />
          <b style="font-size: 18px">
            {{ responsabileControllo.anagrafica.nome }}
          </b>
        </v-card-text>
      </v-col>
      <v-col cols="3">
        <v-card-text style="font-size: 20px">
          Cognome <br />
          <br />
          <b style="font-size: 18px"
            >{{ responsabileControllo.anagrafica.cognome }}
          </b></v-card-text
        ></v-col
      >
      <v-col cols="3">
        <v-card-text style="font-size: 20px">
          Ruolo <br />
          <br />
          <b style="font-size: 18px"> DG controlli </b>
        </v-card-text>
      </v-col>
      <v-col cols="3">
        <v-card-text style="font-size: 20px">
          Recapito telefonico <br />
          <br />
          <b style="font-size: 18px">
            {{
              responsabileControllo.anagrafica.telefono != null
                ? responsabileControllo.anagrafica.telefono
                : ""
            }}</b
          >
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card-text style="font-size: 20px">
          Email <br />
          <br />
          <b style="font-size: 18px">
            {{
              responsabileControllo.anagrafica.email != null
                ? responsabileControllo.anagrafica.email
                : ""
            }}</b
          >
        </v-card-text>
      </v-col>
      <v-col cols="9"></v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "DatiFirmatario",
  components: {},
  props: {
    responsabileControllo: {
      type: Object,
      require: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    loadingComponent: false,
  }),
  methods: {
    initialize() {
      if (this.responsabileControllo) {
        this.loadingComponent = true;
      }
    },
  },
};
</script>
<style scoped>
v-card-text {
  font-size: 16px;
}
</style>
