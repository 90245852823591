var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.selezionabili)?_c('v-main',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headersRendiconti,"items":_vm.rendiconti,"show-select":_vm.selezionabili},on:{"input":function($event){return _vm.handleChildVariables('input')},"keyup":function($event){return _vm.handleChildVariables('keyup')}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [(_vm.isRoleResponsabileControllo || _vm.isRoleConsulenza)?_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.assegnazioneControllo !== null,"disabled":item.assegnazioneControllo !== null},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.inviatoRegis",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.inviatoRegis ? 'Si' : 'No')}})]}},{key:"item.dataTrasmissione",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.dataTrasmissione ? _vm.getDateToString(item.dataTrasmissione) : 'Non disponibile')}})]}},{key:"item.azioni",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.menuAction),function(itemMenu,i){return _c('v-list-item',{key:i,staticClass:"item-menu",attrs:{"disabled":_vm.controlloDisabilitazione(
                    i,
                    item,
                    item.stato,
                    item.assegnazioneControllo ?? 0
                  )}},[_c('v-list-item-content',[(itemMenu.path)?_c('v-list-item-action',{on:{"click":function($event){_vm.goToNextPage(
                        itemMenu.path,
                        parseInt(item.idStrutture),
                        item.capofila,
                        parseInt(item.id),
                        itemMenu
                      )}}},[_vm._v(" "+_vm._s(itemMenu.text)+" ")]):_c('v-list-item-action',{domProps:{"innerHTML":_vm._s(itemMenu.text)},on:{"click":function($event){return _vm.gestioneClickItemMenu(i, item)}}})],1)],1)}),1)],1)],1)]}},{key:"item.importoRichiesto",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoRichiesto ?? null))+" ")])]}},{key:"item.importoAmmesso",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoAmmesso ?? null)))])]}}],null,false,2888352414),model:{value:(_vm.rendicontiSelezionati),callback:function ($$v) {_vm.rendicontiSelezionati=$$v},expression:"rendicontiSelezionati"}})],1):_c('v-main',[_c('v-data-table',{attrs:{"headers":_vm.headersRendiconti,"items":_vm.rendiconti},scopedSlots:_vm._u([{key:"item.inviatoRegis",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.inviatoRegis ? 'Si' : 'No')}})]}},{key:"item.dataTrasmissione",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.dataTrasmissione ? _vm.getDateToString(item.dataTrasmissione) : 'Non disponibile')}})]}},{key:"item.importoRichiesto",fn:function( { item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formattaImporto(item.importoRichiesto ?? null))+" ")])]}},{key:"item.importoAmmesso",fn:function( { item }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formattaImporto(item.importoAmmesso ?? null))}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }