import Repository from "../Repository";

export default {
  async getProgramma(idStrutture, anno, idProgramma) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/programma/${idProgramma}`;
    return (await Repository.get(url)).data;
  },
  async getProgrammiTutti(anno) {
    const url = `api/ministero/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getProgrammi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getAllProgrammi(anno) {
    const url = `api/ministero/programmi/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getProgrammiMonitorati(anno) {
    const url = `api/ministero/programmi-monitorati-mensilmente/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async findProgrammaById(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/programmi/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
};
