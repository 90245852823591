var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-main',[_c('h1',[_vm._v("Firma Verbale di campionamento")])]),(_vm.loadingComponents)?_c('div',[(_vm.loadingComponents)?_c('v-main',[(_vm.rendiconto.assegnazioneControlloUdm.incaricato)?_c('DatiFirmatario',{attrs:{"responsabile-controllo":_vm.rendiconto.assegnazioneControlloUdm.incaricato}}):_vm._e()],1):_vm._e(),(_vm.loadingComponents)?_c('v-main',[_c('CaricamentoFileVerbaleEstensioneUdm',{attrs:{"rendiconto":_vm.rendiconto,"verbale":_vm.verbaleEstensione,"tipo-caricamento":1},on:{"callback":_vm.salvaVerbale,"inizializzazionePagina":_vm.inizializzazionePagina}})],1):_vm._e(),(_vm.rendiconto)?_c('v-main',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"w-100 align-center justify-center",attrs:{"color":"white"}},[_c('router-link',{attrs:{"to":{
                name: 'verbaleEstensioneUdm',
                params: { idRendiconto: _vm.idRendicontoSelezionato },
              }}},[_vm._v(" Indietro ")])],1)],1),_c('v-col',{attrs:{"cols":"2"}}),(_vm.verbaleEstensione.fileVerbaleEsteso)?_c('v-col',{attrs:{"cols":"5"}},[_c('router-link',{staticClass:"v-btn primary float-end",attrs:{"disabled":_vm.verbaleEstensione.fileVerbaleEsteso == null,"to":{
              name: 'verificaRendicontoUdmStepUno',
              params: { idRendiconto: this.idRendicontoSelezionato },
            }}},[_c('span',{staticClass:"ma-1 pa-1"},[_vm._v(" Continua ")])])],1):_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"v-btn primary float-end",attrs:{"color":"primary","disabled":true},domProps:{"innerHTML":_vm._s('Continua')}})],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":5000,"shaped":"","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,false,1070695757),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1):_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }