<template>
  <v-container fluid>
    <v-row class="mt-8">
      <v-col cols="8">
        <h1>Verbale di campionamento</h1>
        <p>
          Qui è possibile visualizzare e completare il verbale di ESTENSIONE del
          campionamento così da procedere con i controlli sugli attestati di
          frequenza.
        </p>
      </v-col>
      <v-col cols="4">
        <RiquadroProfiloRuolo />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div
      class="mt-8"
      v-if="verbaleCampionamentoRendiconto && verbalePrincipale"
    >
      <v-form ref="form" lazy-validation v-model="validForm">
        <v-card class="px-6 pb-4 mb-6" elevation="5">
          <v-row class="mb-4 mt-8">
            <v-col>
              <h1>Riferimenti verbale</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col> ID Verbale</v-col>
            <v-col> Data verbale</v-col>
            <v-col> Responsabile dell'unità di controllo</v-col>
          </v-row>
          <v-row v-if="verbaleEstensione" class="mb-6">
            <v-col>
              <b> {{ verbaleEstensione.id }} </b></v-col
            >
            <v-col>
              <b>
                {{
                  verbaleEstensione.dataCreazione
                    ? getDate(verbaleEstensione.dataCreazione)
                    : "Data non disponibile"
                }}
              </b></v-col
            >
            <v-col
              ><b>
                {{
                  verbalePrincipale.responsabileDelControllo.anagrafica.cognome
                }}
                {{ verbalePrincipale.responsabileDelControllo.anagrafica.nome }}
              </b>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="px-6 pb-4" elevation="5">
          <v-row class="mb-4">
            <v-col>
              <h1>Anagrafica</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col> Missione</v-col>
            <v-col> Componente</v-col>
            <v-col> Investimento</v-col>
            <v-col> Modalità di attuazione</v-col>
          </v-row>
          <v-row class="mb-6">
            <v-col> <b> 4</b></v-col>
            <v-col> <b> 1</b></v-col>
            <v-col
              ><b>
                1.6 - Orientamento attivo nella transizione scuola-università</b
              >
            </v-col>
            <v-col> <b> A regia</b></v-col>
          </v-row>
        </v-card>
        <v-row class="my-8">
          <v-col>
            <h4>1.PREMESSA</h4>
            <p>
              Il presente verbale descrive la procedura di individuazione del
              campione di operazioni da sottoporre a verifica documentale ai
              sensi dell’articolo 22 del Reg. (UE) 241/2021, in coerenza con la
              “Metodologia di campionamento” e al documento di “Analisi del
              rischio specifico delle misure di competenza del PNRR” adottate
              dal Ministero.
            </p>
          </v-col>
        </v-row>
        <v-row class="my-8">
          <v-col>
            <h4>2.ESTENSIONE DEL CAMPIONE</h4>
            <p>
              A seguito dell'estrazione del campione di cui al verbale
              <b> {{ verbalePrincipale.id }} </b> del
              <b>
                {{
                  verbalePrincipale.dataCreazione
                    ? getDate(verbalePrincipale.dataCreazione)
                    : "Data non disponibile"
                }} </b
              >, è stata effettuata la verifica delle spese/costi maturati
              secondo le procedure in essere.
            </p>
            <p>
              Al termine delle verifiche, sulla base dei risultati dei controlli
              inseriti nel sistema informatico, la percentuale di errore del
              controllo espletata sul rendiconto
              <b>{{ idRendiconto }}</b> del
              <b>
                {{
                  verbaleCampionamentoRendiconto.rendiconto.dataTrasmissione
                    ? getDate(
                        verbaleCampionamentoRendiconto.rendiconto
                          .dataTrasmissione
                      )
                    : "Data non disponibile"
                }}</b
              >
              è risultata superiore al 10%, pertanto, l'Unità di controllo in
              coerenza con le disposizioni previste dalla metodologia ha
              provveduto ad estendere il campione, in modo discrezionale, ovvero
              sulla base
            </p>
            <v-row class="pa-4">
              <v-col>
                <v-textarea
                  v-model="verbaleEstensione.motivazione"
                  outlined
                  :rules="rules.validazioneTesto"
                  class="mb-0"
                  hint="Massimo 250 caratteri"
                  name="motivazione"
                  label="Motivazione *"
                  counter
                ></v-textarea>
              </v-col>
            </v-row>
            <p>
              attraverso il sistema informatico, per un ammontare corrispondente
              all'importo ritenuto irregolare in esito alle attività di
              controllo sul rendiconto sopra richiamato.
            </p>
            <p>
              In relazione al nuovo campione, il Responsabile del controllo ha
              accertato il rispetto dei seguenti requisiti minimi:
            </p>
            <ol>
              <li>
                almeno metà delle ulteriori spese/costi maturati sono afferenti
                a Soggetti per i quali le verifiche precedenti hanno dato esito
                irregolare;
              </li>
              <li>
                almeno metà delle ulteriori spese/costi irregolari sono
                afferenti a tipologie di spesa/costo per le quali le verifiche
                precedenti hanno dato esito irregolare;
              </li>
            </ol>
            <p>
              Il Responsabile del controllo, verificato che il campione
              estratto, rispetta i requisiti minimi, ne conferma la validazione
              e accerta la regolarità delle operazioni di estrazione.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex flex-row-reverse">
            <p>
              <i
                >Allegato 3 - Estensione del campione di controllo a seguito del
                tasso di errore > 10%</i
              >
            </p>
          </v-col>
        </v-row>
      </v-form>
      <CampioneEstesoComponent
        :verbale-campione-rendiconto="verbaleCampionamentoRendiconto"
        :anno="anno"
      />
      <v-row class="mt-8">
        <v-col cols="12" class="d-flex flex-row-reverse">
          <v-btn
            class="primary"
            @click="dialogSalva = true"
            text
            :disabled="verbaleEstensione.motivazione === null"
          >
            Firma verbale
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-card class="text-center" :loading="!verbaleCampionamentoRendiconto">
        <v-card-actions class="justify-center">
          <alert-component
            testo="Attendere mentre viene caricato il verbale"
            tipo-alert="info"
          />
        </v-card-actions>
      </v-card>
    </div>
    <dialog-conferma
      title="Salvataggio verbale"
      @callback="salvaVerbale"
      :dialog.sync="dialogSalva"
      @close-dialog="dialogSalva = false"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import DialogConferma from "@/components/DialogConferma.vue";
import CampioneEstesoComponent from "@/components/Controlli/VerificheRendiconti/CampioneEstesoComponent.vue";

import { mapState } from "vuex";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "verbaleEstensioneUdm",
  components: {
    RiquadroProfiloRuolo,
    AlertComponent,
    DialogConferma,
    CampioneEstesoComponent,
  },
  computed: {
    ...mapState(["anno"]),
  },
  data: () => ({
    loadinPage: false,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    verbaleEstensione: {
      motivazione: null,
    },
    verbalePrincipale: null,
    corsiCampionati: [],
    certificatiCampionati: [],
    idRendiconto: null,
    idVerbale: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    validForm: false,
    rules: {
      validazioneTesto: [
        (v) =>
          (v && v.length < 500) ||
          "Il campo è obbligatorio e non può superare i 500 caratteri",
      ],
      validazioneCampoNecessario: [(v) => !!v || "Campo richiesto"],
    },
  }),
  watch: {
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        this.$route.params.idRendiconto &&
        this.anno &&
        this.$route.params.idVerbale
      ) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.idVerbale = this.$route.params.idVerbale;
        this.getVerbaleCampionamentoRendiconto();
        this.getVerbaleCampione();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
          parseInt(this.anno),
          this.idRendiconto,
          7
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.corsiCampionati =
          this.verbaleCampionamentoRendiconto.corsiCampionati;
        let arrayVerbali =
          this.verbaleCampionamentoRendiconto.rendiconto.verificaRendicontoUdm
            .verbaleEstensione;
        let idVerbale = this.idVerbale;
        this.verbaleEstensione = arrayVerbali.find(function (oggetto) {
          return oggetto.id === idVerbale;
        });
        this.loadinPage = true;
      }
    },
    async getVerbaleCampione() {
      this.verbalePrincipale =
        await ControlliUdmRepository.getVerbaleCampioneUdmByRendiconto(
          parseInt(this.anno),
          this.idRendiconto
        );
    },
    async salvaVerbale() {
      if (this.validForm) {
        let verbale = { ...this.verbaleEstensione };
        const formData = new FormData();
        formData.append("formVerbaleEstensione", JSON.stringify(verbale));
        try {
          await ControlliUdmRepository.salvaVerbaleEstensioneUdm(
            this.anno,
            this.verbaleEstensione.id,
            false,
            formData
          );
          await this.$router.push({
            name: "firmaVerbaleEstensioneUdm",
            params: {
              anno: this.anno,
              idRendiconto: this.idRendiconto,
              idVerbale: this.verbaleEstensione.id,
            },
          });
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>

<style scoped></style>
