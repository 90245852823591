import Repository from "../Repository";

export default {
  /*async confermaMonitoraggioMensile(idStrutture, anno, capofila) {
    const url = `api/ministero/${idStrutture}/monitoraggio/anno/${anno}/capofila/${capofila}/monitoraggi`;
    return (await Repository.post(url, null)).data;
  },*/
  async getMonitoraggiProgramma(idStrutture, idProgramma) {
    const url = `api/istituto/${idStrutture}/monitoraggio/programmi-orientamento/${idProgramma}/monitoraggi`;

    return (await Repository.get(url)).data;
  },
};
