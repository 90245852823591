<template>
  <v-container fluid>
    <v-main>
      <div class="ma-6 pa-6">
        <BreadcrumbComponent :breadcrumbs="breadcrumbs"></BreadcrumbComponent>
      </div>
      <div class="">
        <StepRendicontazioni :numero-step="3" />
      </div>
      <h1>Checklist e Esito</h1>
    </v-main>
    <v-main v-if="rendiconto">
      <riepilogo-generale-rendiconto-component :rendiconto="rendiconto" />
    </v-main>
    <v-main>
      <v-card v-if="loadingPage" elevation="4">
        <v-card-title v-html="'Compila Checklist'" />
        <v-form ref="formChecklistVerifica" lazy-validation>
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(tipoChecklist, index) in tipiChecklistVerifica"
              :key="index"
            >
              <v-expansion-panel-header
                color="blue lighten-4"
                class="blue--text mb-5 w-100"
              >
                <v-row>
                  <v-col cols="8">
                    <v-card-text
                      class="float-start"
                      v-html="tipoChecklist.descrizione"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h3 class="align-end">
                      <v-chip
                        outlined
                        pill
                        v-html="
                          getStatoChecklist(formChecklistVerifica[index]).text
                        "
                        :color="
                          getStatoChecklist(formChecklistVerifica[index]).color
                        "
                        class="white--text"
                      />
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-main
                  v-for="(domande, indexDomande) in domandeTooltipChecklist[
                    index
                  ]"
                  :key="indexDomande"
                >
                  <v-card-text>
                    <b>{{ indexDomande + 1 }}. </b>
                    <span v-html="domande.domanda" />
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon dark v-bind="attrs" v-on="on">
                          <v-icon color="grey lighten-1">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </template>
                      <span v-html="domande.tooltip" />
                    </v-tooltip>
                  </v-card-text>
                  <v-row>
                    <v-col cols="4" style="border: 2px solid black">
                      <v-autocomplete
                        :items="itemsSelectChecklist"
                        item-text="text"
                        item-value="value"
                        v-model="
                          formChecklistVerifica[index][indexDomande].conferma
                        "
                        :rules="validateField.fieldConferma"
                        class="align-center"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-textarea
                        outlined
                        class="w-50 h-25"
                        v-model="
                          formChecklistVerifica[index][indexDomande]
                            .documentiVerificati
                        "
                        label="Elenco dei documenti verificati"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-textarea
                        outlined
                        class="w-50 h-25"
                        v-model="
                          formChecklistVerifica[index][indexDomande].note
                        "
                        label="Note"
                      />
                    </v-col>
                  </v-row>
                </v-main>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card>
    </v-main>
    <v-main>
      <v-card elevation="4" class="" v-if="rendiconto">
        <v-card-title v-html="'Esito checklist'" />
        <v-form ref="formChecklistEsito" lazy-validation>
          <v-row class="ma-5">
            <v-col cols="3">
              <v-autocomplete
                :items="itemsSelectEsitoChecklist"
                item-text="text"
                item-value="value"
                v-model="formChecklistEsito.esito"
                :rules="validateField.fieldConferma"
                @input="validateTextFieldEsiti"
                @change="validateTextFieldEsiti"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                readonly
                disabled
                label="Importo rendicontato"
                v-model="formChecklistEsito.importoRendicontato"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                readonly
                disabled
                label="Importo non ammissibile"
                v-model="formChecklistEsito.importoNonAmmissibile"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                readonly
                disabled
                label="Importo Ammissibile"
                v-model="formChecklistEsito.importoAmmissibile"
              />
            </v-col>
          </v-row>
          <v-row class="ma-5">
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.osservazione"
                label="Osservazioni"
                :rules="[validateTextFieldEsiti(1)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.raccomandazione"
                label="Raccomandazioni"
                :rules="[validateTextFieldEsiti(2)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                outlined
                class="w-50 h-25"
                v-model="formChecklistEsito.segnalazioneIrregolarita"
                label="Segnalazione Irregolarità"
                :rules="[validateTextFieldEsiti(3)]"
                :input="validateTextFieldEsiti(1)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-progress-circular
        v-else
        indeterminate
        color="blue"
      ></v-progress-circular>
    </v-main>
    <v-main>
      <v-row>
        <v-col cols="2">
          <router-link
            class="v-btn float-start"
            v-html="'Indietro'"
            :to="{
              name: 'verificaAnagraficheRendicontoUdm',
              params: { idRendiconto: idRendicontoSelezionato },
            }"
          >
            <span class="ma-1 pa-1"> Indietro </span>
          </router-link>
        </v-col>
        <v-col cols="8" />
        <v-col cols="2">
          <v-btn color="primary" @click="dialogConferma = true">
            Salva e continua
          </v-btn>
        </v-col>
      </v-row>
      <dialog-conferma
        @close-dialog="dialogConferma = false"
        @callback="onSubmitCompilazioneChecklist()"
        :dialog.sync="dialogConferma"
      />
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import StepRendicontazioni from "@/components/Controlli/VerificheRendiconti/StepRendicontazioni.vue";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import RiepilogoGeneraleRendicontoComponent from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";

export default {
  name: "verificaChecklistsRendicontoUdm",
  components: {
    BreadcrumbComponent: BreadcrumbComponent,
    DialogConferma,
    StepRendicontazioni,
    RiepilogoGeneraleRendicontoComponent,
  },
  data: () => ({
    isFinale: null,
    loadingPage: false,
    tipiChecklistVerifica: [],
    idRendicontoSelezionato: null,
    rendiconto: null,
    dialogConferma: false,
    snackbar: {
      text: null,
      value: false,
      color: null,
    },
    verificaRendiconto: null,
    itemsSelectChecklist: [
      { text: "Seleziona", value: -1 },
      { text: "SI", value: 1 },
      { text: "NO", value: 2 },
      { text: "NA", value: 3 },
    ],
    itemsSelectEsitoChecklist: [
      { text: "Seleziona", value: -1 },
      { text: "Positivo", value: 1 },
      { text: "Negativo", value: 2 },
      { text: "Parzialmente positivo", value: 3 },
    ],
    formChecklistEsito: {
      esito: -1,
      importoRendicontato: null,
      osservazione: null,
      raccomandazione: null,
      segnalazioneIrregolarita: null,
      importoAmmissibile: null,
      importoNonAmmissibile: null,
    },
    formChecklistVerifica: [
      // form della checklist 1,
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n Decreto attuativo;\n Decreto di assegnazione definitivo delle risorse;\nRendiconto di Progetto.",
          note: null,
          tipoChecklist: 1,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Rendiconto di progetto;\n - Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto.",
          note: null,
          tipoChecklist: 1,
          domanda: 2,
        },
      ],
      // form della checklist di tipo 2, 7 domande e dunque 7 oggetti form
      [
        {
          conferma: -1,
          documentiVerificati:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto.",
          note: null,
          tipoChecklist: 2,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
          note: null,
          tipoChecklist: 2,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
          note: null,
          tipoChecklist: 2,
          domanda: 3,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
          note: null,
          tipoChecklist: 2,
          domanda: 4,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
          note: null,
          tipoChecklist: 2,
          domanda: 5,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto.",
          note: null,
          tipoChecklist: 2,
          domanda: 6,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n  - Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto;\n - Dichiarazione di conformità al principio DNSH e alla pertinente normativa ambientale dell’UE e nazionale.",
          note: null,
          tipoChecklist: 2,
          domanda: 7,
        },
      ],
      // form della checklist di tipo 3, 7 domande
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo;\n - Metodologia di campionamento delle spese;\n - Verbale di estrazione del campione.",
          note: null,
          tipoChecklist: 3,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Rendiconto di progetto;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n - Registri di presenza dei corsi rilevabili dalla piattaforma informatica.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Rendiconto di Progetto;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n - Registri di presenza dei corsi rilevabili dalla piattaforma informatica.",
          note: null,
          tipoChecklist: 3,
          domanda: 3,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n - Alunni iscritti ai corsi rendicontati e censiti nella piattaforma informatica;\n - Piattaforma 'Anagrafe Nazionale degli Studenti (ANS)'",
          note: null,
          tipoChecklist: 3,
          domanda: 4,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Registi di presenza all’interno della Piattaforma 'Orientamento 2026';\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 5,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 6,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Programma di Orientamento;\n - Accordi con le scuole;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 7,
        },
      ],
      // form della checklist di tipo 4
      [
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Avviso e relativi allegati;\n - Decreto attuativo;\n - Decreto di concessione del finanziamento;\n - Atti sottoscritti dal Soggetto attuatore;\n - Eventuali proroghe/modifiche concesse; \n - Programma di Orientamento.",
          note: null,
          tipoChecklist: 4,
          domanda: 1,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Piattaforma “Orientamento 2026”;\n - Registro presenze.",
          note: null,
          tipoChecklist: 4,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Elenco alunni partecipanti;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata ;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
        {
          conferma: -1,
          documentiVerificati:
            "Oggetto del controllo:\n - Decreto di concessione;\n - Totale Disposizioni di pagamento.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
      ],
    ],
    domandeTooltipChecklist: [
      // domande e tooltipi checklist 1
      [
        {
          domanda:
            "Il rendiconto di progetto è stato presentato dal Soggetto Attuatore nei termini e nelle modalità indicate dal Decreto attuativo di riferimento?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Decreto attuativo</li><li>Decreto di assegnazione definitivo delle risorse</li> <li>Rendiconto di Progetto</li></ul>",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha presentato una dichiarazione attestante che i costi maturati esposti nel presente rendiconto non hanno beneficiato di ulteriori finanziamenti",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Rendiconto di progetto</li><li> Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto</li></ul> ",
        },
      ],
      // domande e tooltipi checklist 2
      [
        {
          domanda:
            "Il Soggetto Attuatore ha attestato lo svolgimento dei controlli di regolarità amministrativo-contabile?",
          tooltip:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche ex ante sul titolare effettivo? ",
          tooltip:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sull’assenza di doppio finanziamento sui costi maturati esposti a rendiconto?",
          tooltip:
            " Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto.",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto delle condizionalità del PNRR? ",
          tooltip:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto dei principi trasversali del PNRR?",
          tooltip:
            "Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto degli ulteriori requisiti del PNRR connessi alla Misura a cui è associato il progetto?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto  </li></ul>",
        },
        {
          domanda:
            "Il Soggetto Attuatore ha attestato le verifiche sul rispetto del principio del DNSH e sulla conformità delle attività svolte alla pertinente normativa ambientale comunitaria e nazionale?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto  </li> <li>  Dichiarazione di conformità al principio DNSH e alla pertinente normativa ambientale dell’UE e nazionale </li></ul>",
        },
      ],
      // domande e tooltip checklist 3
      [
        {
          domanda:
            "In caso di ricorso al campionamento dei corsi costituenti il rendiconto da sottoporre a verifica è stata applicata la metodologia adottata dall’Unità di Missione del PNRR?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Metodologia di campionamento delle spese </li><li> Verbale di estrazione del campione </li></ul>",
        },
        {
          domanda: "I corsi di orientamento hanno avuto una durata di 15 ore? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Rendiconto di progetto </li><li> Elenco dei corsi e dettaglio della tipologia di formazione erogata; </li> <li> Registri di presenza dei corsi rilevabili dalla piattaforma informatica</li></ul>",
        },
        {
          domanda:
            "Le lezioni si sono svolte in presenza per almeno i 2/3 del monte ore complessivo? ",
          tooltip:
            "Oggetto del controllo <br /> <ul><li> Rendiconto di Progetto</li> <li> Elenco dei corsi e dettaglio della tipologia di formazione erogata; </li> <li> Registri di presenza dei corsi rilevabili dalla piattaforma informatica;</li></ul>",
        },
        {
          domanda:
            "I corsi sono stati rivolti ad alunni frequentanti gli ultimi 3 anni della scuola secondaria di secondo grado?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Elenco dei corsi e dettaglio della tipologia di formazione erogata; </li><li> Alunni iscritti ai corsi rendicontati e censiti nella piattaforma informatica.</li><li> Piattaforma “Anagrafe Nazionale degli Studenti (ANS)”</li></ul>",
        },
        {
          domanda:
            "Sono stati rilasciati gli Attestati di Frequenza ai partecipanti che hanno raggiunto il limite minimo di frequenza previsto dai Decreti ministeriali (70%)?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>  Registi di presenza all’interno della Piattaforma “Orientamento 2026”</li><li> Elenco dei corsi e dettaglio della tipologia di formazione erogata;</li> <li>Attestati di frequenza</li></ul>",
        },
        {
          domanda:
            "Gli attestati risultano firmati dal Referente dell’Istituzione per il Programma o dal Referente del Corso?",
          tooltip:
            "Oggetto del controllo <br /> <ul><li>Attestati di frequenza</li></ul>",
        },
        {
          domanda:
            "La documentazione prodotta dal Soggetto Attuatore riporta esplicitamente i riferimenti a: <ul> <li> PNRR </li> <li>Codice Unico di Progetto</li>  <li>Missione</li> <li>Componente</li> <li>Investimento</li></ul>",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>Programma di Orientamento</li><li> Accordi con le scuole </li> <li>Attestati di frequenza </li></ul>",
        },
      ],
      // domande e tooltipi checklist 4
      [
        {
          domanda:
            "Il progetto risulta concluso nei termini previsti dall’Avviso/Decreto di concessione del finanziamento e relativi allegati? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Avviso e relativi allegati  </li><li> Decreto attuativo </li> <li> Decreto di concessione del finanziamento </li><li>Atti sottoscritti dal Soggetto attuatore </li><li> Eventuali proroghe/modifiche concesse</li><li> Programma di Orientamento</li></ul>",
        },
        {
          domanda:
            "La documentazione attestante le attività svolte risulta correttamente caricata nella piattaforma informatica del Ministero? ",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Piattaforma 'Orientamento 2026' </li><li>  Registro presenze </li></ul>",
        },
        {
          domanda:
            "La documentazione prodotta dal Soggetto Attuatore a conclusione delle attività progettuali riporta esplicitamente i riferimenti a:<ul><li> PNRR</li> <li>Codice Unico di Progetto;</li> <li>Missione, Componente, Investimento.</li></ul>",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li>  Elenco alunni partecipanti; </li><li> Elenco dei corsi e dettaglio della tipologia di formazione erogata. </li> <li>Attestati di frequenza</li></ul>",
        },
        {
          domanda:
            "L’importo oggetto del presente rendiconto sommato a quanto precedentemente erogato in favore del Soggetto Attuatore rientra nei limiti dell’importo complessivo finanziato?",
          tooltip:
            "Oggetto del controllo <br /> <ul> <li> Decreto di concessione  </li><li> Totale Disposizioni di pagamento </li></ul>",
        },
      ],
    ],
    validateField: {
      fieldConferma: [
        (v) => v > 0 || "E' obbligatoria la selezione di uno dei tre campi.",
      ],
    },
  }),
  computed: {
    ...mapState(["anno", "campioneEstratto", "campioneAggiornato"]),
    ...mapGetters([
      "isRoleResponsabileControllo",
      "isRoleConsulenza",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/udm/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Lista verbali di campionamento",
          disabled: false,
          to: `/scrivania/udm/controlli/lista-verbali`,
          exact: true,
          link: true,
        },
        {
          text: "Verifica Rendiconto",
          disabled: false,
          to: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/${this.idRendiconto}/verifiche`,
          exact: true,
          link: true,
        },
        {
          text: "Anagrafica interventi",
          disabled: false,
          to: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm/${this.idRendiconto}/verifiche-anagrafiche-udm`,
          exact: true,
          link: true,
        },
        {
          text: "Compilazione checklist",
          disabled: true,
          to: ``,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.isRoleIncaricatoControllo || this.isRoleConsulenza) &&
        this.$route.params.idRendiconto
      ) {
        this.idRendicontoSelezionato = this.$route.params.idRendiconto;
        this.getTipoChecklistVerificaRendiconto();
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getTipoChecklistVerificaRendiconto() {
      this.tipiChecklistVerifica =
        await RendicontiRepository.getTipologieChecklistVerificaRendicontoUdm();
      this.loadingPage = true;
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
          parseInt(this.anno),
          this.idRendicontoSelezionato,
          3
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        //se il rendiconto è finale mostro la sezione D, altrimenti la rimuovo
        this.nascondiSezioneD(this.rendiconto.finale);
        this.isFinale = this.rendiconto.finale;
        this.calcolaValoriEsitiImporti();
        this.verificaRendiconto = this.rendiconto.verificaRendicontoUdm;
        this.verificaRendiconto.checklistVerificaRendiconto.sort((a, b) => {
          return a.id - b.id;
        });
        if (this.verificaRendiconto.stepVerifica >= 3) {
          this.predisponiValoriForm();
        } else {
          this.loadinPage = true;
        }
        //this.predisponiValoriForm();
      }
    },
    nascondiSezioneD(isFinale) {
      if (!isFinale) {
        this.tipiChecklistVerifica.pop();
        this.formChecklistVerifica.pop();
      }
    },
    predisponiValoriForm() {
      // le checklist sono state già compilate in precedenza
      const checkListRendicontoVerifica =
        this.verificaRendiconto.checklistVerificaRendiconto;

      this.formChecklistEsito.esito = this.convalidaEsitoChecklist(
        this.verificaRendiconto.esitoControllo
      );
      this.formChecklistEsito.osservazione =
        this.verificaRendiconto.osservazione;
      this.formChecklistEsito.raccomandazione =
        this.verificaRendiconto.raccomandazione;
      this.formChecklistEsito.segnalazioneIrregolarita =
        this.verificaRendiconto.segnalazioneIrregolarita;

      checkListRendicontoVerifica.forEach((element, index) => {
        // setto i valori di conferma, note e documenti verificati  con i valori della verifica di rendiconto inserita
        this.formChecklistVerifica[index].map(
          (elementForm, indexElementForm) => {
            // ogni elemento inserito nella verifica,
            // lo recupero in base alla chiave dell'oggetto di verifica calcolata,
            const confermaInserita = this.convalidaConfermaChecklistInserita(
              element["conferma" + (indexElementForm + 1).toString()]
            );
            elementForm.conferma = confermaInserita;
            elementForm.note =
              element["note" + (indexElementForm + 1).toString()];
            elementForm.documentiVerificati =
              element["verificaDocumenti" + (indexElementForm + 1).toString()];
            return elementForm;
          }
        );
      });
      this.loadinPage = true;
    },
    convalidaConfermaChecklistInserita(value) {
      if (value == null) {
        return 3;
      } else if (value === false) {
        return 2;
      } else {
        return 1;
      }
    },
    convalidaEsitoChecklist(esito) {
      if (esito === "Positivo") {
        return 1;
      } else if (esito === "Negativo") {
        return 2;
      } else {
        return 3;
      }
    },
    getStatoChecklist(valueForm) {
      let numeroDomandeTotali = valueForm.length;
      let numeroDomandeCompilate = 0;

      valueForm.forEach((element) => {
        if (element.conferma > 0) {
          numeroDomandeCompilate += 1;
        }
      });

      if (numeroDomandeCompilate === numeroDomandeTotali) {
        // tutte le domande sono state compilate
        return { color: "success", text: "Compilato" };
      } else if (
        numeroDomandeCompilate !== 0 &&
        numeroDomandeCompilate !== numeroDomandeTotali
      ) {
        // il numero di domande compilate è diverso da 0, ma allo stesso tempo diverso dal numero totale a cui si deve rispondere
        return { color: "purple-darken-1", text: "In fase di compilazione" };
      } else {
        return {
          color: "red",
          text: "Da compilare",
        };
      }
    },
    onSubmitCompilazioneChecklist() {
      if (
        this.$refs.formChecklistVerifica.validate() &&
        this.$refs.formChecklistEsito.validate()
      ) {
        let controlloCompilazione = this.controlloCompilazioneChecklist();
        if (controlloCompilazione) {
          this.modificaRendicontoVerifica();
        } else {
          this.setValueSnackbar(
            false,
            "Impossibile procedere. Tutte le checklist non sono state compilate.",
            "red"
          );
        }
      } else {
        this.setValueSnackbar(
          false,
          "Impossibile procedere. Verificare eventuali errori o mancate compilazioni delle checklist di verifica",
          "red"
        );
      }
    },
    controlloCompilazioneChecklist() {
      let allChecklistCompilabili = [];

      this.formChecklistVerifica.forEach((element) => {
        element.forEach((domandeChecklist) => {
          allChecklistCompilabili.push(domandeChecklist.conferma);
        });
      });

      let checklistNonCompilate = allChecklistCompilabili.filter(
        (element) => parseInt(element) === -1
      );

      return checklistNonCompilate.length === 0;
    },
    setValueSnackbar(esito, text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    async modificaRendicontoVerifica() {
      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.idRendicontoSelezionato;
      const idVerificaRendiconto = this.rendiconto.verificaRendicontoUdm.id;

      let sezioneD = [
        {
          conferma: null,
          documentiVerificati:
            "Oggetto del controllo:\n - Avviso e relativi allegati;\n - Decreto attuativo;\n - Decreto di concessione del finanziamento;\n - Atti sottoscritti dal Soggetto attuatore;\n - Eventuali proroghe/modifiche concesse; \n - Programma di Orientamento.",
          note: null,
          tipoChecklist: 4,
          domanda: 1,
        },
        {
          conferma: null,
          documentiVerificati:
            "Oggetto del controllo:\n - Piattaforma “Orientamento 2026”;\n - Registro presenze.",
          note: null,
          tipoChecklist: 4,
          domanda: 2,
        },
        {
          conferma: null,
          documentiVerificati:
            "Oggetto del controllo:\n - Elenco alunni partecipanti;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata ;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
        {
          conferma: null,
          documentiVerificati:
            "Oggetto del controllo:\n - Elenco alunni partecipanti;\n - Elenco dei corsi e dettaglio della tipologia di formazione erogata ;\n - Attestati di frequenza.",
          note: null,
          tipoChecklist: 3,
          domanda: 2,
        },
      ];
      let checklistVerifica = [...this.formChecklistVerifica];
      if (!this.isFinale) {
        checklistVerifica.push(sezioneD);
      }
      checklistVerifica.push(sezioneD);
      const formBody = new FormData();
      formBody.append("checklist", JSON.stringify(checklistVerifica));
      formBody.append("esito", JSON.stringify(this.formChecklistEsito));

      await RendicontiRepository.modificaVerificaRendicontoUdm(
        idStrutture,
        idRendiconto,
        idVerificaRendiconto,
        3,
        formBody
      )
        .then((data) => {
          if (data) {
            setTimeout(() => this.goToNextStep(), 1500);
            this.setValueSnackbar(
              true,
              "Le checklist sono state aggiunte/ modificate.",
              "success"
            );
          }
        })
        .catch((reason) => {
          if (reason.response.status === 500) {
            this.setValueSnackbar(
              (false,
              "Un'errore di server è stato riscontrato nella richiesta di modifica della checklist",
              "red")
            );
          } else {
            this.setValueSnackbar((false, reason.response.data[0], "red"));
          }
        });
    },
    goToNextStep() {
      this.$router.push({
        name: "firmaChecklistVerificaRendicontoUdm",
        params: { idRendiconto: this.idRendicontoSelezionato },
      });
    },
    validateTextFieldEsiti(tipo) {
      if (this.formChecklistEsito.esito) {
        const esito = this.formChecklistEsito.esito;
        let campoDaConvalidare = null;
        let esitoConvalida = false;

        if (esito === 2 || esito === 3) {
          switch (tipo) {
            case 1:
              campoDaConvalidare = this.formChecklistEsito.osservazione;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;

            case 2:
              campoDaConvalidare = this.formChecklistEsito.raccomandazione;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;

            case 3:
              campoDaConvalidare =
                this.formChecklistEsito.segnalazioneIrregolarita;
              esitoConvalida =
                campoDaConvalidare &&
                campoDaConvalidare.length &&
                campoDaConvalidare.trim().length > 0;
              break;
          }

          if (esitoConvalida) {
            return true;
          } else {
            return "Il campo è obbligatorio, quando l'esito è negativo o parzialmente positivo.";
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    calcolaValoriEsitiImporti() {
      const importoRendicontato = this.rendiconto.importoRichiesto;
      this.formChecklistEsito.importoRendicontato = importoRendicontato;

      const arrayImportiCalcolatiInVerifica =
        this.rendiconto.verificaRendicontoUdm
          .importiCalcolatiVerificheRendiconti;

      if (arrayImportiCalcolatiInVerifica.length) {
        let importoNonAmmissibile = 0;
        arrayImportiCalcolatiInVerifica.forEach((element) => {
          importoNonAmmissibile += element.importoNonAmmesso;
        });
        this.formChecklistEsito.importoNonAmmissibile = importoNonAmmissibile;
        this.formChecklistEsito.importoAmmissibile =
          importoRendicontato - importoNonAmmissibile;
      } else {
        this.formChecklistEsito.importoAmmissibile = 0;
        this.formChecklistEsito.importoNonAmmissibile = 0;
      }
    },
  },
};
</script>
