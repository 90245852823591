<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main class="ma-4 pa-4">
      <v-row class="mb-8">
        <v-col cols="8">
          <h1 style="color: #1a237e">Dettagli chiarimenti Udm</h1>
          <p>
            Visualizza i dettagli delle richieste di chiarimenti da parte
            dell'UDM.
          </p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
      </v-row>
    </v-main>
    <div v-if="loadinPage">
      <ContenutoDialogRichiestaModificaUdmComponent
        :rendiconto="this.rendiconto"
        :read-only="readOnly"
      />
      <v-row>
        <v-col cols="2">
          <TornaIndietroComponent text="Indietro" />
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ContenutoDialogRichiestaModificaUdmComponent from "@/components/Controlli/Udm/VerificheFormali/ContenutoDialogRichiestaModificheUdmComponent";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "chiarimentiUdm",
  components: {
    ContenutoDialogRichiestaModificaUdmComponent,
    AlertComponent,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    TornaIndietroComponent,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: false,
            to: `/scrivania/udm/controlli/verbali-campionamento-rendiconti-udm`,
            exact: true,
            link: true,
          },
          {
            text: "Dettagli chiarimenti UDM",
            disabled: true,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti-udm/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  data: () => ({
    readOnly: true,
    loadinPage: false,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    verificaRendiconto: null,
    corsiCampionati: [],
    idRendiconto: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
          parseInt(this.anno),
          this.idRendiconto,
          1
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.loadinPage = true;
      }
    },
  },
};
</script>
