<template>
  <v-row class="pb-6">
    <v-col cols="6">
      <table>
        <tr>
          <td>
            <p class="ma-0">Profilo:</p>
          </td>
        </tr>
        <tr>
          <td>
            <h4>{{ isRoleMinistero ? "MUR" : "Consulenza" }}</h4>
          </td>
        </tr>
      </table>
    </v-col>
    <v-col cols="6">
      <table>
        <tr>
          <td>
            <p class="ma-0">Ruolo:</p>
          </td>
        </tr>
        <tr>
          <td>
            <h4 v-for="ruolo in descrizioni" :key="ruolo">
              {{ ruolo }}
            </h4>
          </td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "RiquadroProfiloRuolo",
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleUdm",
    ]),
  },
  data: () => ({
    descrizioni: [],
  }),
  created() {
    this.findRole();
  },
  methods: {
    findRole() {
      const descrizioni = this.user.roles.map((ruolo) => {
        if (ruolo === "ROLE_RESPONSABILE_CONTROLLO") {
          return "Responsabile del Controllo";
        } else if (ruolo === "ROLE_INCARICATO_CONTROLLO") {
          return "Incaricato del controllo";
        } else if (ruolo === "ROLE_UDM") {
          return "UDM";
        }
        return null;
      });
      this.descrizioni = descrizioni.filter(
        (descrizione) => descrizione !== null
      );
    },
  },
};
</script>

<style scoped></style>
