import { render, staticRenderFns } from "./StudentiConCertificato.vue?vue&type=template&id=804ca07c&scoped=true&"
import script from "./StudentiConCertificato.vue?vue&type=script&lang=js&"
export * from "./StudentiConCertificato.vue?vue&type=script&lang=js&"
import style0 from "./StudentiConCertificato.vue?vue&type=style&index=0&id=804ca07c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "804ca07c",
  null
  
)

export default component.exports