<template>
  <v-container fluid>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row class="mt-8">
      <v-col cols="8">
        <h1>Verbale di campionamento</h1>
        <p>
          Qui è possibile visualizzare e completare il verbale di campionamento
          così da procedere con i controlli sugli attestati di frequenza.
        </p>
      </v-col>
      <v-col cols="4">
        <RiquadroProfiloRuolo />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <div class="mt-8" v-if="loadingPage">
      <v-card
        elevation="5"
        v-if="verbaleControllo && verbaleControllo.length"
        :loading="loadingVerbale"
      >
        <v-expansion-panels accordion :value="0">
          <v-expansion-panel
            v-for="(verbaleCampione, index) in verbaleControllo"
            :key="index"
          >
            <v-expansion-panel-header color="blue" class="white--text">
              <v-row>
                <v-col cols="2">
                  <v-card-text>
                    Id Verbale <br />
                    <br />
                    <b> {{ verbaleCampione.id }} </b>
                  </v-card-text>
                </v-col>
                <v-col cols="5">
                  <v-card-text>
                    Periodo di riferimento delle attività dal
                    <br />
                    <br />
                    <b>
                      {{
                        periodoDal ? getTextDate(periodoDal) : "Non Disponibile"
                      }}
                      al
                      {{
                        periodoAl ? getTextDate(periodoAl) : "Non Disponibile"
                      }}
                    </b>
                  </v-card-text>
                </v-col>
                <v-col cols="3">
                  <v-card-text>
                    Data Creazione Campione <br />
                    <br />
                    <b> {{ getTextDate(verbaleCampione.dataCreazione) }} </b>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="form" lazy-validation v-model="validForm">
                <v-row class="mb-4 mt-8">
                  <v-col>
                    <h1>Riferimenti verbale</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col> ID Verbale</v-col>
                  <v-col> Data verbale</v-col>
                  <v-col> Responsabile dell'unità di controllo</v-col>
                </v-row>
                <v-row class="mb-6">
                  <v-col>
                    <b> {{ verbaleCampione.id }}</b></v-col
                  >
                  <v-col>
                    <b>
                      {{ getTextDate(verbaleCampione.dataCreazione) }}</b
                    ></v-col
                  >
                  <v-col
                    ><b>
                      {{ verbale.responsabileDelControllo.anagrafica.cognome }}
                      {{ verbale.responsabileDelControllo.anagrafica.nome }}</b
                    >
                  </v-col>
                </v-row>

                <v-row class="mb-4">
                  <v-col>
                    <h1>Anagrafica</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col> Missione</v-col>
                  <v-col> Componente</v-col>
                  <v-col> Investimento</v-col>
                  <v-col> Modalità di attuazione</v-col>
                </v-row>
                <v-row class="mb-6">
                  <v-col> <b> 4</b></v-col>
                  <v-col> <b> 1</b></v-col>
                  <v-col
                    ><b>
                      1.6 - Orientamento attivo nella transizione
                      scuola-università</b
                    >
                  </v-col>
                  <v-col> <b> A regia</b></v-col>
                </v-row>

                <v-row class="my-8">
                  <v-col>
                    <h4>1.PREMESSA</h4>
                    <p>
                      Il presente verbale descrive la procedura di
                      individuazione del campione di operazioni da sottoporre a
                      verifica documentale ai sensi dell’articolo 22 del Reg.
                      (UE) 241/2021, in coerenza con la “Metodologia di
                      campionamento” e al documento di “Analisi del rischio
                      specifico delle misure di competenza del PNRR” adottate
                      dal Ministero.
                    </p>

                    <h4>2.OPERAZIONI DI CAMPIONAMENTO</h4>
                    <p>
                      Le operazioni di campionamento sono state effettuate
                      mediante il sistema informatico
                      <strong>'Portale Orientamento 2026'</strong>, messo a
                      disposizione dall’Amministrazione con metodo non
                      statistico basato su un’analisi del rischio. In
                      adempimento a quanto disposto dalla Metodologia di
                      campionamento adottata dall’Amministrazione si è proceduto
                      al campionamento secondo le seguenti fasi:
                    </p>
                    <ol class="mb-4">
                      <li>Acquisizione della popolazione;</li>
                      <li>
                        Individuazione del tasso di copertura del campione;
                      </li>
                      <li>Composizione del campione;</li>
                      <li>Estensione del campione.</li>
                    </ol>
                    <h4>2.1 Acquisizione della popolazione</h4>
                    <p>
                      Ai fini della definizione della popolazione da cui
                      estrarre il campione l’Unità di Controllo, in conformità
                      con quanto indicato nella Metodologia di campionamento ha
                      considerato <b>{{ listaRendiconti.length }}</b> rendiconti
                      presentati nel periodo dal
                      <b
                        >{{
                          periodoDal
                            ? getTextDate(periodoDal)
                            : "Non Disponibile"
                        }}
                        al
                        {{
                          periodoAl ? getTextDate(periodoAl) : "Non Disponibile"
                        }}</b
                      >, per un ammontare complessivo di
                      <strong>€ {{ costiMaturati | formatNumber }} </strong>. In
                      particolare, in base ad apposite funzionalità del sistema
                      informatico è stata selezionata la popolazione oggetto di
                      controllo
                      {{ verbale.descrizioneValutazione }}
                    </p>
                    <p>
                      Pertanto, la popolazione da cui estrarre il campione da
                      sottoporre a verifica è rappresentata da
                      <b>{{ listaRendiconti.length }}</b> rendiconti del periodo
                      di riferimento dal
                      <b
                        >{{
                          periodoDal
                            ? getTextDate(periodoDal)
                            : "Non Disponibile"
                        }}
                        al
                        {{
                          periodoAl ? getTextDate(periodoAl) : "Non Disponibile"
                        }}</b
                      >, per un importo complessivo pari a
                      <strong>€ {{ costiMaturati | formatNumber }} </strong>.
                    </p>

                    <h4>
                      2.2 Individuazione del tasso di copertura del campione
                    </h4>
                    <p>
                      Al fine di definire la dimensione minima del campione, è
                      stata applicata alla popolazione sopra definita la
                      percentuale corrispondente alla rischiosità della Misura,
                      definita nel documento
                      <i
                        >“Analisi del rischio specifico delle misure PNRR di
                        competenza del MUR”</i
                      >, che nel caso specifico è pari al <b>4%</b>.
                    </p>
                    <p>
                      Per quanto sopra rappresentato, la popolazione campionaria
                      di riferimento è costituito da
                      <b>{{ corsiOrientamento }}</b> corsi di orientamento, di
                      cui all’allegato 1 del presente documento.
                    </p>

                    <h4>2.3 Composizione del campione</h4>
                    <p>
                      Esaminata la composizione della popolazione e
                      propedeuticamente all’individuazione del campione da
                      sottoporre a verifica, il controllore sulla base delle
                      valutazioni e del proprio giudizio professionale ha
                      ritenuto;
                    </p>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          label="Selezione obbligatoria *"
                          :readonly="readOnly"
                          v-model="verbale.tipologiaComposizioneCampione"
                          :rules="rules.validazioneCampoNecessario"
                        >
                          <v-radio
                            v-for="tipo in tipiComposizioneCampione"
                            :key="tipo.id"
                            :label="tipo.descrizione"
                            :value="tipo"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row
                      class="pa-4"
                      v-if="
                        verbale.tipologiaComposizioneCampione &&
                        verbale.tipologiaComposizioneCampione.id === 2
                      "
                    >
                      <v-textarea
                        v-model="verbale.valutazioneComposizione"
                        outlined
                        :readonly="readOnly"
                        :rules="rules.validazioneTesto"
                        class="mb-0"
                        hint="Massimo 500 caratteri"
                        name="valutazioneComposizione"
                        label="Descrivere le valutazioni che hanno condotto alla scelta discrezionale degli elementi da sottoporre a verifica *"
                        counter
                      ></v-textarea>
                    </v-row>

                    <v-row>
                      <v-col>
                        <p>
                          Si è proceduto ad associare, attraverso il sistema
                          informatico della Misura di riferimento, a ciascun
                          Soggetto Attuatore/Realizzatore e a ogni spesa/costo
                          maturato il livello di rischiosità, applicando i
                          punteggi a tutti i criteri previsti dalla metodologia
                          di campionamento.
                        </p>
                      </v-col>
                    </v-row>

                    <h4>2.4 Estrazione del campione</h4>
                    <p>
                      Determinata la numerosità del campione, attraverso le
                      funzionalità implementate nel sistema informatico
                      <strong>'Portale Orientamento 2026'</strong>, si è
                      proceduto ad estrarre le operazioni da verificare, tenuto
                      conto dei parametri e dei vincoli sia imposti dalla
                      metodologia di campionamento, sia dalle eventuali
                      valutazioni discrezionali del controllore come sopra
                      descritto. Con particolare riferimento ai vincoli imposti
                      dalla metodologia, il Responsabile del controllo ha
                      accertato che il campione rispetta i seguenti requisiti
                      minimi:
                    </p>
                    <ol>
                      <li>
                        tutti i rendiconti presentati sono stati estratti;
                      </li>
                      <li>
                        il campione estratto è composto, laddove applicabile,
                        <ul>
                          <li>
                            per almeno per il 60% da Soggetti classificati a
                            rischiosità elevata;
                          </li>
                          <li>
                            per almeno il 30% da Soggetti a rischiosità media;
                          </li>
                          <li>
                            per almeno il 10% da soggetti a rischiosità bassa.
                          </li>
                        </ul>
                      </li>
                      <li>
                        la percentuale di spesa/costi maturati/corsi erogati
                        individuata sulla base del criterio precedente, ove
                        pertinente, è composta per ciascun soggetto che
                        rendiconta:
                        <ul>
                          <li>
                            per almeno il 60% da tipologie di spese a
                            rischiosità elevata;
                          </li>
                          <li>
                            per almeno il 30% da tipologie di spese a
                            rischiosità media;
                          </li>
                          <li>
                            per almeno il 10% da tipologie di spese/costi
                            maturati a rischiosità bassa.
                          </li>
                        </ul>
                      </li>
                      <li>
                        per i criteri di cui ai punti elenco 2 e 3 sopra
                        richiamati, se la popolazione oggetto di analisi non
                        presenta importi sufficienti riferiti alla classe di
                        rischio più alta, la differenza è estratta dalla classe
                        di rischio subito inferiore.
                      </li>
                    </ol>
                    <p>
                      Il campione estratto in modo casuale è riportato nel
                      dettaglio nell’Allegato 2 del presente documento. Il
                      Responsabile del controllo verificato che il campione
                      estratto, rispetta i requisiti minimi, ne conferma la
                      validazione e accerta la regolarità delle operazioni di
                      estrazione.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-4">
                    <v-alert dense outlined type="warning" elevation="2">
                      <u
                        >Il caricamento dei dati potrebbe impiegare diversi
                        secondi</u
                      >
                    </v-alert>
                  </v-col>
                </v-row>
                <PopolazioneCampioneUdmComponent
                  :id-verbale="parseInt(verbale.id)"
                  :anno="anno"
                />
                <CampioneEstrattoUdmComponent
                  :id-verbale="parseInt(verbale.id)"
                  :anno="anno"
                />
                <div v-if="corsiScelti && corsiScelti.length">
                  <CampioneSceltoComponent
                    :id-verbale="parseInt(verbale.id)"
                    :anno="anno"
                  />
                </div>
                <v-row class="mt-8 mb-8">
                  <v-col cols="5">
                    <v-row>
                      <v-col
                        ><h5 class="text-uppercase">
                          Download verbale PDF da firmare
                        </h5></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="4" sm="4" md="4">
                        <small v-if="!verbale.tipologiaComposizioneCampione"
                          >Compilare il form prima di scaricare il file</small
                        >
                        <div
                          class="mt-5"
                          v-if="verbale.tipologiaComposizioneCampione"
                        >
                          <a @click="downloadPDF()">
                            <v-icon>mdi-file-download-outline</v-icon> Scarica
                            il verbale in PDF
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1"
                    ><v-icon large>mdi-arrow-right-bold-circle-outline</v-icon>
                  </v-col>
                  <v-col cols="5">
                    <v-row>
                      <v-col
                        ><h5 class="text-uppercase">
                          Caricamento verbale PDF firmato
                        </h5></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-file-input
                          v-if="!readOnly"
                          label="Carica il file in formato .pdf* "
                          :rules="rules.validazioneCampoNecessario"
                          @change="uploadFile($event)"
                          :required="true"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row v-if="readOnly">
                      <v-col>
                        <a
                          @click="
                            downloadFile(
                              verbale.fileVerbale.id,
                              verbale.fileVerbale.nomeOriginale
                            )
                          "
                        >
                          <v-icon>mdi-file-download-outline</v-icon> Scarica
                          verbale firmato
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-row>
                    <v-col
                      v-if="!readOnly"
                      cols="12"
                      class="d-flex flex-row-reverse"
                    >
                      <v-btn
                        v-if="isRoleResponsabileControllo || isRoleConsulenza"
                        class="primary"
                        @click="dialogSalva = true"
                        text
                        :disabled="!validForm"
                      >
                        Vai alla verifica
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card v-else class="text-center" :loading="loadingVerbale">
        <v-card-actions class="justify-center">
          <alert-component
            testo="Attendere mentre viene caricato il verbale"
            tipo-alert="info"
          />
        </v-card-actions>
      </v-card>
      <div v-if="readOnly" class="mt-6">
        <TornaIndietroComponent text="Torna alla lista verbali" />
      </div>
      <dialog-conferma
        title="Salvataggio verbale"
        @callback="salvaVerbale"
        :dialog.sync="dialogSalva"
        @close-dialog="dialogSalva = false"
      />
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import DialogConferma from "@/components/DialogConferma";
import PopolazioneCampioneUdmComponent from "@/components/Controlli/Udm/PopolazioneCampioneUdmComponent";
import CampioneEstrattoUdmComponent from "@/components/Controlli/Udm/CampioneEstrattoUdmComponent";
import CampioneSceltoComponent from "@/components/Controlli/CampioneSceltoComponent";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
export default {
  name: "VerbaleCampionamentoUdm",
  components: {
    PopolazioneCampioneUdmComponent,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    AlertComponent,
    DialogConferma,
    CampioneEstrattoUdmComponent,
    CampioneSceltoComponent,
    TornaIndietroComponent,
  },
  props: {
    anno: {
      type: Number,
      required: false,
      default: 2022,
    },
    idVerbale: {
      type: Number,
      required: false,
      default: 61,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loadingPage: false,
    loadingVerbale: false,
    verbaleControllo: [],
    costiMaturati: null,
    tipiComposizioneCampione: [],
    verbale: {
      anno: 0,
      dataCreazione: null,
      dataFineAttivita: null,
      dataFirma: null,
      dataInizioAttivita: null,
      firmato: 0,
      id: 0,
      valutazioneComposizione: null,
      tipologiaComposizioneCampione: {
        id: null,
        descrizione: null,
      },
    },
    verbaleCopy: [],
    verbaleCopyDue: [],
    verbaleCampionato: [],
    verbaleCampionatoScelto: [],
    listaRendiconti: [],
    corsiVerbale: [],
    corsiCampionati: [],
    corsiScelti: [],
    corsiOrientamento: 0,
    periodoDal: null,
    periodoAl: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    validForm: false,
    rules: {
      validazioneTesto: [
        (v) =>
          (v && v.length < 500) ||
          "Il campo è obbligatorio e non può superare i 500 caratteri",
      ],
      validazioneCampoNecessario: [(v) => !!v || "Campo richiesto"],
    },
    tipologia1: {
      id: 1,
      descrizione:
        "di NON includere unità campionarie scelte discrezionalmente e procedere all’estrazione in modo casuale dalla popolazione sopra individuata;",
    },
    tipologia2: {
      id: 2,
      descrizione:
        "di INCLUDERE nel campione da sottoporre a verifica le unità campionarie di cui all’allegato 2 bis del presente documento, tenuto conto",
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Rendiconti",
            disabled: true,
            to: `/scrivania/controlli/lista-rendiconti/${this.anno}`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "capofila", "isCampioneEsteso"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getVerbale(this.anno, this.idVerbale);
        this.getTipologiaComposizioneCampione();
      }
    },
    async getVerbale(anno, idVerbale) {
      this.loadingVerbale = true;
      this.verbaleControllo =
        await ControlliUdmRepository.getSingoloVerbaleCampionamentoUdm(
          anno,
          idVerbale
        );
      this.verbale = this.verbaleControllo[0];
      if (this.verbale.tipologiaComposizioneCampione == null) {
        if (this.isCampioneEsteso) {
          this.verbale.tipologiaComposizioneCampione = { ...this.tipologia2 };
        } else {
          this.verbale.tipologiaComposizioneCampione = { ...this.tipologia1 };
        }
      }
      this.verbaleCopy = [...this.verbale.verbaleCampionamentoRendiconto];
      this.verbaleCopyDue = [...this.verbale.verbaleCampionamentoRendiconto];
      this.loadingPage = true;
      this.listaRendiconti =
        this.verbaleControllo[0].verbaleCampionamentoRendiconto.map(
          (element) => {
            return element.rendiconto;
          }
        );
      //trovo i periodi di lavorazione del verbale (prima e ultima dataTrasmissione)
      this.trovaPeridiVerbale(this.listaRendiconti);
      let corsiCampionati =
        this.verbaleControllo[0].verbaleCampionamentoRendiconto.map(
          (element) => {
            return element.corsiCampionati;
          }
        );
      //trovo totale costi del verbale
      this.costiMaturati = this.listaRendiconti.reduce(
        (accumulator, object) => {
          return accumulator + object.importoRichiesto;
        },
        0
      );
      this.corsiVerbale = this.listaRendiconti.map((element) => {
        return element.corsiRendiconto;
      });
      let corsi = [];
      let corsiCampionatiArray = [];
      let corsiCampionatiAlgoritmoArray = [];
      let corsiSelezionatiArray = [];
      this.corsiVerbale.forEach(function (item) {
        corsi = corsi.concat(item);
      });
      //voce totale dei corsi della popolazione
      this.corsiOrientamento = corsi.length;
      corsiCampionati.forEach(function (item) {
        corsiCampionatiArray = corsiCampionatiArray.concat(item);
      });
      corsiCampionatiArray.forEach(function (item) {
        if (item.estrattoDaAlgoritmo) {
          corsiCampionatiAlgoritmoArray =
            corsiCampionatiAlgoritmoArray.concat(item);
        } else {
          corsiSelezionatiArray = corsiSelezionatiArray.concat(item);
        }
      });
      this.corsiCampionati = corsiCampionatiAlgoritmoArray;
      this.corsiScelti = corsiSelezionatiArray;
      this.loadingVerbale = true;
    },
    getTextDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      } else {
        return "";
      }
    },
    async getTipologiaComposizioneCampione() {
      this.tipiComposizioneCampione =
        await ControlliRepository.getTipologiaComposizioneCampione();
      this.loadingPage = true;
    },
    uploadFile(event) {
      this.file = event;
    },
    async salvaVerbale() {
      if (this.validForm && this.file) {
        let verbale = {
          ...this.verbale,
          periodoDal: this.periodoDal,
          periodoAl: this.periodoAl,
        };
        const formData = new FormData();
        formData.append("formVerbale", JSON.stringify(verbale));
        formData.append("file", this.file);
        try {
          await ControlliUdmRepository.salvaVerbaleCampionamentoUdm(
            this.anno,
            this.idVerbale,
            formData
          );

          this.snackbarText = "Verbale salvato con successo";
          this.snackbarColor = "success";
          this.validate = true;
          setTimeout(() => {
            this.$router.push({
              name: "ListaVerbaliRendicontiUdm",
              params: {
                anno: this.anno,
              },
            });
          }, 3000); // Wait for 3000 milliseconds (3 seconds)
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async downloadPDF() {
      this.verbale = {
        ...this.verbale,
        costiMaturati: this.costiMaturati,
        numeroCorsiOrientamento: this.corsiOrientamento,
        popolazione: this.listaRendiconti,
        periodoDal: this.periodoDal,
        periodoAl: this.periodoAl,
      };
      let verbale = { ...this.verbale };
      const data = new FormData();
      data.append("verbalePDF", JSON.stringify(verbale));
      await ControlliUdmRepository.downloadTemplatePdfVerbale(this.anno, data);
    },
    trovaPeridiVerbale(listaRendiconti) {
      // Trova la data di trasmissione più alta e più bassa
      const { dataTrasmissioneMinima, dataTrasmissioneMassima } =
        listaRendiconti.reduce(
          (acc, item) => {
            // Confronta la data corrente con la data minima salvata
            if (
              !acc.dataTrasmissioneMinima ||
              item.dataTrasmissione < acc.dataTrasmissioneMinima
            ) {
              acc.dataTrasmissioneMinima = item.dataTrasmissione;
            }

            // Confronta la data corrente con la data massima salvata
            if (
              !acc.dataTrasmissioneMassima ||
              item.dataTrasmissione > acc.dataTrasmissioneMassima
            ) {
              acc.dataTrasmissioneMassima = item.dataTrasmissione;
            }

            return acc;
          },
          { dataTrasmissioneMinima: null, dataTrasmissioneMassima: null }
        );
      this.periodoDal = dataTrasmissioneMinima;
      this.periodoAl = dataTrasmissioneMassima;
    },
    /*getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },*/
    async downloadFile(idFile, nomeOriginale) {
      const res = MinisteroRepository.downloadFile(
        this.anno,
        idFile,
        nomeOriginale
      );

      if (Array.isArray(res)) {
        this.setValueSnackbar(true, res[0], "red-darken-4");
      }
    },
  },
};
</script>
