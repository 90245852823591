<template>
  <v-card elevation="4" class="mt-4">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredPrograms"
        item-key="programmaOrientamento.id"
        loading-text="Il sistema sta caricando le informazioni"
        no-results-text="Nessun dato presente"
      >
        <!-- eslint-disable -->
        <template v-slot:item.assFinanziariaProgramma="{ item }">
          <span v-html="getFormattedAmount(item.assFinanziariaInc + item.assFinanziariaProgramma )" />
        </template>
        <template v-slot:item.targetStudenti="{ item }">
          {{getTargetStudenti(item.alunniTarget, item.numeroAlunniCoinvolti)}}
        </template>
        <template v-slot:item.capofila="{ item }">
          {{ item.capofila ? "Si" : "No" }}
        </template>
        <template v-slot:item.fileProgrammaOrientamento="{ item }">
          <a @click="getFileToDownload(
              item.anno,
              item.nomeFileProgrammaOrientamento,
               item.idFileProgramma)">
             Atto d'obbligo
          </a>
        </template>
        <template v-slot:item.fileConvenzione="{ item }">
          <a
              v-if="item.idFileConvenzione"
              @click="getFileToDownload(
              item.anno,
              'convenzione_' + item.nomeIstituzione + '_' + item.anno + '.pdf',
               item.idFileConvenzione)">
            Convenzione
          </a>
        </template>
        <template v-slot:item.azioni="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs}">
              <v-btn
                  class="v-icon"
                  icon
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon> mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                    class="item-menu">
                  <v-list-item-content>
                    <v-list-item-action  @click="apriDialog(item)">
                      Vai al Programma di Orientamento
                    </v-list-item-action>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <!-- eslint-disable -->
      </v-data-table>

      <v-dialog
          v-if="dialogDettaglioProgramma"
          max-width="1000"
          v-model="dialogDettaglioProgramma"
      >
        <DialogDettaglioProgrammaOrientamento
            :anno="datiDialogProgramma.anno"
            :id-strutture="datiDialogProgramma.idStrutture"
            :capofila="datiDialogProgramma.capofila"
            @chiusuraDialog="dialogDettaglioProgramma = false"

        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import DialogDettaglioProgrammaOrientamento from "@/components/AssegnazioneTargetControlli/DialogDettaglioProgrammaOrientamentoComponent.vue";

export default {
  name: "riepilogoDueAssegnazioneRisorse",
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.getFilteredData();
    },
    anno() {
      this.getFilteredData();
    },
  },
  components: { DialogDettaglioProgrammaOrientamento },
  props: {
    programmi: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.getFilteredData();
  },
  data: () => ({
    headers: [
      { text: "CUP", value: "cup", align: "start" },
      {
        text: "NOME ISTITUZIONE",
        value: "nomeIstituzione",
      },
      {
        text: "N° ALUNNI COINVOLTI",
        value: "targetStudenti",
      },
      {
        text: "N° CORSI ASSEGNATI",
        value: "numeroCorsi",
      },
      {
        text: "N° ACCORDI SCUOLE",
        value: "numeroScuole",
      },
      {
        text: "ASSEGNAZIONE FINALE",
        value: "assFinanziariaProgramma",
      },
      {
        text: "IN CONV.",
        value: "capofila",
      },
      {
        text: "ATTO D'OBBLIGO",
        value: "fileProgrammaOrientamento",
      },
      {
        text: "CONVENZIONE",
        value: "fileConvenzione",
      },
      {
        text: "AZIONI",
        value: "azioni",
      },
    ],
    filteredPrograms: [],
    dialogDettaglioProgramma: false,
    datiDialogProgramma: {
      idStrutture: null,
      anno: null,
      capofila: null,
    },
  }),
  methods: {
    getFilteredData() {
      this.filteredPrograms = this.programmi.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.idStrutture === this.idStrutture
            : element.idStrutture > 0) &&
          (this.anno != null ? element.anno === this.anno : element.anno > 0)
        );
      });
    },
    async getFileToDownload(anno, nomeOriginale, idFile) {
      await MinisteroRepository.downloadFile(anno, idFile, nomeOriginale);
    },
    apriDialog(itemProgramma) {
      this.datiDialogProgramma.idStrutture = itemProgramma.idStrutture;
      this.datiDialogProgramma.anno = itemProgramma.anno;
      this.datiDialogProgramma.capofila = itemProgramma.capofila;
      this.dialogDettaglioProgramma = true;
    },
    getFormattedAmount(importo) {
      if (!importo) return 0 + " €";
      return (
        parseFloat(importo).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " €"
      );
    },
    getTargetStudenti(incremento, alunni) {
      return incremento ? incremento + alunni : alunni;
    },
  },
};
</script>
