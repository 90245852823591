<template>
  <v-card elevation="5">
    <v-card-title> Dettaglio Programma di Orientamento </v-card-title>
    <v-card-text v-if="loadingContentDialog">
      <v-main>
        <v-card-subtitle>UNIVERSITA'/AFAM</v-card-subtitle>
        <v-row>
          <v-col cols="3"
            >CUP <br />
            <b> {{ programmaOrientamento.cup }}</b></v-col
          >
          <v-col cols="3">
            Anno Scolastico <br />
            <b> {{ programmaOrientamento.anno }}</b></v-col
          >
          <v-col cols="3"
            >Istituzione<br />
            <b> {{ programmaOrientamento.nomeIstituzione }}</b></v-col
          >
          <v-col cols="3"
            >Nome Legale Rappresentante <br />
            <b>
              {{
                programmaOrientamento.legaleRappresentante.nomeRappresentante
              }}</b
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="3"
            >Cognome Legale Rappresentante<br />
            <b>
              {{
                programmaOrientamento.legaleRappresentante.cognomeRappresentante
              }}</b
            ></v-col
          >
          <v-col cols="3">
            CF Legale Rappresentante <br />
            <b>
              {{
                programmaOrientamento.legaleRappresentante
                  .codiceFiscaleRappresentante
              }}</b
            ></v-col
          >
          <v-col cols="3" />
          <v-col cols="3" />
        </v-row>
      </v-main>
      <v-main>
        <v-card-subtitle> PROGRAMMA</v-card-subtitle>
        <v-row>
          <v-col cols="3">
            Nome Ref. dell'Istituzione per il programma
            <br />
            <b> {{ programmaOrientamento.nomeReferente }}</b>
          </v-col>

          <v-col cols="3">
            Cognome Ref. dell'Istituzione per il programma
            <br />
            <b> {{ programmaOrientamento.cognomeReferente }}</b>
          </v-col>
          <v-col cols="3">
            Numero di corsi di orientamento programmato <br />
            <b> {{ programmaOrientamento.numeroCorsi }}</b></v-col
          >
          <v-col cols="3">
            Ore di Orientamento programmato <br />
            <b> {{ programmaOrientamento.numeroOre }}</b></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="3">
            N° scuole coinvolte <br />
            <b> {{ programmaOrientamento.numeroScuole }}</b></v-col
          >
          <v-col cols="3">
            Licei <br />
            <b> {{ programmaOrientamento.numeroLicei }}</b></v-col
          >
          <v-col cols="3">
            Istituti tecnici <br />
            <b> {{ programmaOrientamento.numeroIstTecnici }}</b></v-col
          >
          <v-col cols="3">
            Istituti Professionali <br />
            <b> {{ programmaOrientamento.numeroIstProfessionali }}</b>
          </v-col></v-row
        >
        <v-row>
          <v-col cols="6">
            Data di avvio del programma <br />
            <b
              v-html="
                getDateToIsoString(
                  programmaOrientamento.dataInizioProgramma ?? null
                )
              "
            ></b>
          </v-col>
          <v-col cols="6">
            Data di fine del programma <br />
            <b
              v-html="
                getDateToIsoString(
                  programmaOrientamento.dataFimeProgramma ?? null
                )
              "
            ></b></v-col
        ></v-row>
      </v-main>
      <v-main>
        <v-card-subtitle> ALLEGATO</v-card-subtitle>
        <v-row>
          <v-col></v-col>
        </v-row>
      </v-main>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="warning"
        outlined
        v-html="'Chiudi finestra di dialogo'"
        @click="$emit('chiusuraDialog')"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import ProgrammiRepository from "@/api/ministero/ProgrammiRepository";

export default {
  name: "dialogDettaglioProgrammaOrientamentoComponent",
  components: {},
  props: {
    idStrutture: {
      type: Number,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
    capofila: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    programmaOrientamento: null,
    loadingContentDialog: null,
  }),
  methods: {
    initialize() {
      if (this.idStrutture && this.anno && this.capofila >= 0) {
        this.getSingoloProgramma();
      }
    },
    async getSingoloProgramma() {
      this.programmaOrientamento = await ProgrammiRepository.findProgrammaById(
        this.idStrutture,
        this.anno,
        this.capofila ? "capofila" : "istituzione"
      );
      this.programmaOrientamento = this.programmaOrientamento[0];
      this.loadingContentDialog = true;
    },
    getDateToIsoString(date) {
      return new Date(date).toISOString().split("T")[0];
    },
  },
};
</script>
