<template>
  <v-container fluid>
    <v-main class="pa-0">
      <v-card elevation="4">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title
                >Campione delle operazioni estratte in modo
                casuale</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="mt-8" elevation="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(vcr, index) in verbale"
                    :key="index"
                  >
                    <v-expansion-panel-header color="blue" class="white--text">
                      <v-card-text class="float-start">
                        <v-row>
                          <v-col cols="1">
                            N°
                            <b> {{ index + 1 }} </b>
                          </v-col>
                          <v-col cols="2">
                            ID RENDICONTO -
                            <b> {{ vcr.rendiconto.id }} </b>
                          </v-col>
                          <v-col cols="2">
                            CUP -
                            <b> {{ vcr.rendiconto.programma.cup }} </b>
                          </v-col>
                          <v-col cols="2">
                            {{ vcr.rendiconto.programma.nomeIstituzione }}
                          </v-col>
                          <v-col cols="1">
                            Nr corsi campionati -
                            <b> {{ vcr.corsiCampionati.length }} </b>
                          </v-col>
                          <v-col cols="1">
                            Nr attestati campionati -
                            {{ vcr.rendiconto.nrAttestatiCampionati }}
                            <b> </b>
                          </v-col>
                          <v-col cols="1">
                            Importo campionato -
                            {{
                              new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(vcr.rendiconto.importiCampionati)
                            }}
                            <b> </b>
                          </v-col>
                          <v-col cols="1">
                            - rischio calcolato :
                            {{ vcr.indiceRischio }}.
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="blue lighten-5">
                      <div class="d-flex mb-12 bg-surface-variant">
                        <v-sheet class="ma-2 pa-2 me-auto blue lighten-5">
                          Percentuale <br />Campionamento 4%
                        </v-sheet>
                      </div>
                      <v-card-text
                        v-for="corsiCampionati in vcr.corsiCampionati"
                        :key="corsiCampionati.id"
                        class="mt-1"
                      >
                        <v-card-title
                          v-html="
                            '<h4> ID CORSO - <b> ' +
                            corsiCampionati.corsoOrientamento.id +
                            ' </b> </h4>'
                          "
                          class="mt-1"
                        />
                        <v-data-table
                          :items="corsiCampionati.certificatiCampionati"
                          :headers="infoStudentiCertificatiCampionati"
                          hide-default-footer
                          :items-per-page="-1"
                          ><!--eslint-disable -->
                          <template v-slot:item.nomeCognome="{ item }">
                            <v-card-text v-html="item.certificato.studente.nome + ' ' + item.certificato.studente.cognome + ''" />
                          </template>
                          <template v-slot:item.istitutoScolastico="{ item }">
                            <v-card-text v-if="anno === 2022" v-html="corsiCampionati.corsoOrientamento.accordo.istituto.denominazioneScuola" />
                            <v-card-text v-else v-html="item.certificato.studente.corsiStudente[0].istituto.denominazioneScuola" />
                          </template>
                          <template v-slot:item.attestatoFrequenza="{ item }">
                            <v-btn max-height="20" style="font-size:13px;" text @click="downloadFile(item.certificato.fileReferenteIstituto.id, item.certificato.fileReferenteIstituto.nomeOriginale)"> Scarica  </v-btn>
                          </template>
                          <!-- eslint-enable -->
                        </v-data-table>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-main>
  </v-container>
</template>
<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import ControlliRepository from "@/api/ministero/ControlliRepository";

export default {
  name: "CampioneEstrattoComponent",
  components: {},
  props: {
    idVerbale: {
      type: Number,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    verbaleCampionato: [],
    verbaleControllo: [],
    importiCorsi: null,
    verbale: null,
    infoStudentiCertificatiCampionati: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Istituto scolastico",
        value: "istitutoScolastico",
      },
      {
        text: "Codice Fiscale",
        value: "certificato.studente.cf",
        sortable: false,
      },
      { text: "Attestato di frequenza", value: "attestatoFrequenza" },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getVerbale(this.anno, this.idVerbale);
      }
    },
    async getVerbale(anno, idVerbale) {
      this.loadingVerbale = true;
      this.verbale = await ControlliRepository.getVerbaleCampioneEstrattoScelto(
        anno,
        idVerbale,
        "estratto"
      );
      this.loadingVerbale = false;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    async downloadFile(idFile, nomeOriginale) {
      const res = MinisteroRepository.downloadFile(
        this.anno,
        idFile,
        nomeOriginale
      );

      if (Array.isArray(res)) {
        this.setValueSnackbar(true, res[0], "red-darken-4");
      }
    },
  },
};
</script>
