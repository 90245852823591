<template>
  <v-container fluid>
    <v-main>
      <div class="ma-6 pa-6">
        <BreadcrumbComponent :breadcrumbs="breadcrumbs"></BreadcrumbComponent>
      </div>
      <div class="">
        <StepRendicontazioni :numero-step="4" />
      </div>
      <h1>Firma Checklist</h1>
    </v-main>
    <v-main v-if="loadingComponents">
      <DatiFirmatario
        v-if="rendiconto.assegnazioneControllo"
        :responsabile-controllo="rendiconto.assegnazioneControllo.incaricato"
      />
      <v-card-text v-else>
        <v-alert type="warning">
          Attenzione nessun incricato di controllo è stato assegnato a questo
          rendiconto</v-alert
        >
      </v-card-text>
    </v-main>
    <v-main v-if="loadingComponents">
      <CaricamentoFile
        :rendiconto="rendiconto"
        :tipo-caricamento="1"
        @callback="aggiornamentoVerificaRendiconto"
        @inizializzazionePagina="inizializzazionePagina"
      />
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
    <v-main v-if="rendiconto">
      <v-row>
        <v-col cols="5">
          <v-btn color="white" class="w-100 align-center justify-center">
            <router-link
              :to="{
                name: 'verificaChecklistsRendiconto',
                params: { idRendiconto: idRendicontoSelezionato },
              }"
            >
              Indietro
            </router-link></v-btn
          ></v-col
        >
        <v-col cols="2"></v-col>
        <v-col v-if="verificaRendiconto.fileVerificaCheklist" cols="5">
          <router-link
            class="v-btn primary float-end"
            :disabled="verificaRendiconto.fileVerificaCheklist == null"
            :to="{
              name: 'verificaVerbaleEsitoControlliRendiconto',
              params: { idRendiconto: this.idRendicontoSelezionato },
            }"
          >
            <span class="ma-1 pa-1"> Continua </span>
          </router-link>
        </v-col>
        <v-col v-else cols="5">
          <v-btn
            color="primary"
            v-html="'Continua'"
            class="v-btn primary float-end"
            :disabled="true"
          ></v-btn>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import StepRendicontazioni from "@/components/Controlli/VerificheRendiconti/StepRendicontazioni.vue";
import { mapGetters, mapState } from "vuex";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import CaricamentoFile from "@/components/Controlli/VerificheRendiconti/CaricamentoFileComponent.vue";
import DatiFirmatario from "@/components/Controlli/VerificheRendiconti/DatiFirmatarioComponent.vue";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
export default {
  name: "firmaChecklistVerificaRendiconto",
  components: {
    DatiFirmatario,
    CaricamentoFile,
    BreadcrumbComponent,
    StepRendicontazioni,
  },
  computed: {
    ...mapState(["anno", "campioneAggiornato", "campioneEstratto", "user"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Lista verbali di campionamento",
          disabled: false,
          to: `/scrivania/controlli/nuovo-controllo`,
          exact: true,
          link: true,
        },
        {
          text: "Verifica Rendiconto",
          disabled: false,
          to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
          exact: true,
          link: true,
        },
        {
          text: "Anagrafica interventi",
          disabled: false,
          to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche-anagrafiche`,
          exact: true,
          link: true,
        },
        {
          text: "Compilazione checklist",
          disabled: false,
          to: `/scrivania/controlli/verbali-campionamento-rendiconti/:idRendiconto/verifiche-checklists`,
          exact: true,
          link: true,
        },
        {
          text: "Compilazione checklist",
          disabled: true,
          to: ``,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.loadinPage = false;
      this.initialize();
    },
  },
  data: () => ({
    loadingComponents: false,
    idRendicontoSelezionato: null,
    rendiconto: null,
    verbaleCampionamento: null,
    verificaRendiconto: null,
    snackbar: {
      text: null,
      value: false,
      color: null,
    },
    form: {
      uploadedFiles: null,
      currentStep: 6,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.isRoleConsulenza || this.isRoleIncaricatoControllo) &&
        this.$route.params.idRendiconto
      ) {
        this.idRendicontoSelezionato = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliRepository.getSingoloVerbaleCampionamentoRendiconto(
          parseInt(this.anno),
          this.idRendicontoSelezionato,
          4
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.verificaRendiconto = this.rendiconto.verificaRendiconto;
        this.loadingComponents = true;
      }
    },
    async aggiornamentoVerificaRendiconto(form) {
      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.rendiconto.id;
      const idVerifica = this.rendiconto.verificaRendiconto.id;

      const step = 4;

      const formBody = new FormData();
      formBody.append("fileVerificaChecklistRendiconto", form.uploadedFiles);

      await RendicontiRepository.modificaVerificaRendiconto(
        idStrutture,
        idRendiconto,
        idVerifica,
        step,
        formBody
      )
        .then((data) => {
          if (data) {
            //setTimeout(() => this.goToNextStep(), 1500);
            this.setValueSnackbar(
              "Il file di verifica delle checklist è stato caricato correttamente.",
              "success"
            );
            this.inizializzazionePagina();
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.setValueSnackbar(
            "E' stato riscontrato un errore di server durante la richiesta.",
            "red"
          );
        });
    },
    setValueSnackbar(testo, color) {
      this.snackbar.text = testo;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    inizializzazionePagina() {
      this.loadingComponents = false;
      this.initialize();
    },
    goToNextStep() {},
  },
};
</script>
