import Repository from "../Repository";
export default {
  async getVerbaliControlliRendiconti(anno) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento`;
    return (await Repository.get(url)).data;
  },
  async generaVerbaleCampionamento(anno, salvataggio, formBody) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento?salvataggio=${salvataggio}`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getTipologiaComposizioneCampione() {
    const url = `api/generici/verbali-campionamento/tipologia-composizione-campione`;
    return (await Repository.get(url)).data;
  },
  async getAllAttestatiCorsiCampionati(anno, formBody) {
    const url = `api/ministero/anno/${anno}/corsi-campionati/attestati`;
    const headers = { "Content-type": "multipart/form-data" };

    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getSingoloVerbaleControlliRendiconti(anno, idVerbale) {
    const url = `api/ministero/anno/${anno}/verbale-campionamento/${idVerbale}`;
    return (await Repository.get(url)).data;
  },
  async salvaVerbaleCampionamento(anno, idVerbale, data) {
    const url = `api/ministero/anno/${anno}/update-verbale-campionamento/${idVerbale}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getSingoloVerbaleCampionamentoRendiconto(
    anno,
    idRendiconto,
    stepVerifica
  ) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento-rendiconti/${idRendiconto}?stepVerifica=${stepVerifica}`;
    return (await Repository.get(url)).data;
  },
  async estendiPercentualeCampione(anno, idRendiconto, formBody) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento-rendiconti/${idRendiconto}/percentuali-campione`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, formBody, headers)).data;
  },
  async assegnaIncarico(anno, idUtente, data) {
    const url = `api/ministero/anno/${anno}/assegna-incarico-controllo/${idUtente}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getAssegnazioniIncarico(anno) {
    const url = `api/ministero/anno/${anno}/assegnazioni-incarichi`;
    return (await Repository.get(url)).data;
  },
  async accettaIncarico(anno, data) {
    const url = `api/ministero/anno/${anno}/accetta-incarico-controllo`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getRendicontiCorsiEAttestatiFirmati(anno, idRendiconto) {
    const url = `api/ministero/anno/${anno}/rendiconti/${idRendiconto}/corsi-attestati`;
    return (await Repository.get(url)).data;
  },
  async getDichiarazioneAssenzaConflitto(anno) {
    const url = `api/ministero/anno/${anno}/dichiarazione-assenza-conflitti`;
    return (await Repository.get(url)).data;
  },
  async uploadDichiarazione(anno, data) {
    const url = `api/ministero/anno/${anno}/upload-dichiarazione-assenza-conflitti`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getDichiarazioneFirmata(anno, idIncaricato) {
    const url = `api/ministero/anno/${anno}/dichiarazione-firmata/${idIncaricato}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download =
        "Dichiarazione_Assenza_conflitti_interessi_" +
        { idIncaricato } +
        ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getListaRendicontiVerbale(anno, idVerbale) {
    const url = `api/ministero/anno/${anno}/lista-rendiconti/${idVerbale}`;
    return (await Repository.get(url)).data;
  },
  async getVerbaleCampioneEstrattoScelto(anno, idVerbale, TipoCampione) {
    const url = `api/ministero/anno/${anno}/verbale-campione-estratto-scelto/${idVerbale}/${TipoCampione}`;
    return (await Repository.get(url)).data;
  },
  async aggiornaAttestatiCampionati(anno, idVerbaleRendiconto, formBody) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento-rendiconti/${idVerbaleRendiconto}/corsi-campionati/certificati`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async estendiCampioneVerbaleRendiconto(anno, idVerbaleRendiconto, formBody) {
    const url = `api/ministero/anno/${anno}/verbali-campionamento-rendiconti/${idVerbaleRendiconto}/corsi-attestati-campionati`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getVerbaleCampioneByRendiconto(anno, idRendiconto) {
    const url = `api/ministero/anno/${anno}/verbale-primario/${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async salvaVerbaleEstensione(anno, idVerbale, firma, data) {
    const url = `api/ministero/anno/${anno}/update-verbale-estensione/${idVerbale}/?firma=${firma}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async eliminaFileVerbaleEstensione(anno, idVerbale) {
    const url = `api/ministero/anno/${anno}/delete-file-verbale-estensione/${idVerbale}`;
    return (await Repository.delete(url)).data;
  },
  async getTemplatePdfVerbaleEstensione(anno, idRendiconto, idVerbale) {
    const url = `api/ministero/anno/${anno}/rendiconto/${idRendiconto}/verbale-estensione-file/${idVerbale}`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.get(url, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "pdf_verbale_estensione" + idVerbale + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async updateVerificaRendiconto(anno, idVerifica, firma, data) {
    const url = `api/ministero/anno/${anno}/update-verifica-rendiconto/${idVerifica}/?firma=${firma}`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async eliminaFileVerbaleEsito(anno, idVerifica) {
    const url = `api/ministero/anno/${anno}/delete-file-verbale-esito/${idVerifica}`;
    return (await Repository.delete(url)).data;
  },
  async terminaVerifica(anno, idRendiconto, idVerifica) {
    const url = `api/ministero/anno/${anno}/${idRendiconto}/termina-verifica/${idVerifica}`;
    return (await Repository.post(url)).data;
  },
  async getTemplatePdfVerbaleEsito(anno, idRendiconto, idVerbale) {
    const url = `api/ministero/anno/${anno}/rendiconto/${idRendiconto}/verbale-esito-file/${idVerbale}`;
    const headers = { "Content-type": "multipart/form-data" };

    const res = await Repository.get(url, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "pdf_verbale_esito_rendiconto" + idVerbale + ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async createNuovaRichiestaIntegrazioni(anno, idRendiconto, data) {
    const url = `api/ministero/anno/${anno}/${idRendiconto}/nuova-richiesta-integrazioni`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async getVerificheRendicontiIstituzioniPrecedenti(
    idStrutture,
    anno,
    capofila,
    idRendiconto
  ) {
    const url = `api/ministero/anno/${anno}/rendiconti/${idStrutture}/profilo/${capofila}/verifiche?idRendiconto=${idRendiconto}`;
    return (await Repository.get(url)).data;
  },
  async esportaSuExcelRendicontiCampionati(anno, udm, idVerbale, formBody) {
    // Imposta udm a false solo se non è settato
    udm = udm || false;
    const url = `api/ministero/anno/${anno}/verbale-campionamento/${idVerbale}/rendiconti-campionati/excel?udm=${udm}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });

    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = `rendiconti_${
        udm ? "udm_" : ""
      }campionati_verbale_${idVerbale}.xlsx`;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getFileRispostaRichiesta(
    idStrutture,
    richiesta,
    nomeFileOriginale,
    tipoAllegato
  ) {
    const url = `api/generici/${idStrutture}/rendiconti/${richiesta}/file-allegato?tipoAllegato=${tipoAllegato}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getFileEistoVerifica(idStrutture, verifica, nomeFileOriginale) {
    const url = `api/generici/${idStrutture}/rendiconti/${verifica}/file-esito-verifica`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
};
