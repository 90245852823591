<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
      <v-row>
        <v-col cols="8">
          <h1>Incarichi assegnati</h1>
          <p>
            Lista dei rendiconti con dettaglio del soggetto a cui è stato
            assegnato l'incarico.
          </p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="my-4">
        <v-col col="8">
          <v-text-field
            solo
            v-model="ricerca"
            append-icon="mdi-magnify"
            label="Cerca per N° rendiconto"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="assegnazioni"
            :search="ricerca"
            :items-per-page="10"
            class="elevation-1"
            :loading="loadingRendiconti"
            loading-text="Caricamento dati in corso..."
            no-data-text="Nessun rendiconto disponibile"
            ><!-- eslint-disable -->
            <template v-slot:item.incaricato="{ item }">
              <span>{{item.incaricato.anagrafica.cognome}} {{item.incaricato.anagrafica.nome}}</span>
            </template>
            <template v-slot:item.dataAssegnazione="{ item }">
              <span>{{item.dataAssegnazione | formatDate}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="v-icon"
                      v-bind="attrs"
                      v-on="on"
                      :icon="true"
                  >
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item class="item-menu">
                      <v-list-item-content>
                        <v-list-item-action
                          @click="
                            downloadDichiarazione(parseInt(item.incaricato.id))
                          "
                        >
                          Visualizza dichiarazione
                        </v-list-item-action>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      class="item-menu"
                      :disabled="item.dataAccettazione"
                    >
                      <v-list-item-content>
                        <v-list-item-action
                          @click="eliminaAssegnazione(parseInt(item.id))"
                        >
                          Revoca incarico
                        </v-list-item-action>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <div class="mt-6">
        <TornaIndietroComponent text="Torna a Controlli" />
      </div>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ControlliRepository from "@/api/ministero/ControlliRepository";

export default {
  name: "IncarichiAssegnatiView",
  data: () => ({
    loadingRendiconti: false,
    assegnazioni: [],
    ricerca: "",
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    headers: [
      {
        text: "ID rendiconto",
        align: "start",
        value: "rendiconto.id",
      },
      {
        text: "Soggetto attuatore",
        value: "rendiconto.programma.nomeIstituzione",
      },
      { text: "Data assegnazione", value: "dataAssegnazione" },
      { text: "Soggetto incaricato", value: "incaricato" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  components: {
    TornaIndietroComponent,
    RiquadroProfiloRuolo,
    BreadcrumbComponent,
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Incarichi assegnati",
            disabled: true,
            to: `/scrivania/controlli/incarichi-assegnati`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getAssegnazioni(this.anno);
      }
    },
    async getAssegnazioni(anno) {
      this.loadingRendiconti = true;
      this.assegnazioni = await ControlliRepository.getAssegnazioniIncarico(
        anno
      );
      this.loadingRendiconti = false;
    },
    async downloadDichiarazione(idIncaricato) {
      try {
        await ControlliRepository.getDichiarazioneFirmata(
          this.anno,
          idIncaricato
        );
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.snackbarText =
            "L'incaricato non ha ancora caricato la dichiarazione";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>
