var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"8"}},[_c('h1',[_vm._v("Dettaglio dichiarazione")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('RiquadroProfiloRuolo')],1),_c('v-spacer')],1),(_vm.loadingData)?_c('v-row',[_c('v-col',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue"}})],1)],1):_vm._e(),(!_vm.loadingData)?_c('div',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.getFileRiepilogoDichiarazioni(0, 'b')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Esporta Excel ")],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.getFileRiepilogoDichiarazioni(1, 'b')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" Esporta PDF ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dichiarazione.conti,"items-per-page":10,"loading":_vm.loadingData,"loading-text":"Caricamento dati in corso..."},scopedSlots:_vm._u([{key:"item.statoConto",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","pill":"","color":_vm.colorState(item.stato)}},[_vm._v(" "+_vm._s(item.stato.descrizione)+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                        name: 'dettaglioContoView',
                        params: {
                          conto: item,
                          idDichiarazione: _vm.dichiarazione.id,
                          idStrutture: _vm.dichiarazione.idStrutture,
                          editable: _vm.editable,
                        },
                      }}},[_vm._v(" Dettagli ")])]}}],null,false,2570899236)},[_vm._v("--> ")]),_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('h3',[_vm._v("Allegati")])])],1),_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(" Dichiarazione di tracciabilità dei flussi finanziari "),_c('b',[_vm._v(_vm._s(_vm.dichiarazione.fileDocumentazioneFirmata.nomeOriginale))])]),_c('div',[_c('a',{on:{"click":function($event){return _vm.getFileDichiarazione()}}},[_c('v-icon',[_vm._v("mdi-file-download-outline")]),_vm._v(" Scarica il file ")],1)])])],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-6"},[_c('v-col',[_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push(`/scrivania/flussi-finanziari`)}}},[_c('v-icon',[_vm._v("mdi-arrow-left-thin")]),_vm._v("Torna alla lista delle dichiarazioni ")],1)],1),(_vm.editable && !_vm.loadingData)?_c('v-col',{staticClass:"d-flex flex-row-reverse"},[(_vm.dichiarazione.stato.id === 1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogConferma = true}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-eye-check")]),_vm._v(" Presa visione ")],1):_vm._e()],1):_vm._e()],1),_c('dialog-conferma',{attrs:{"dialog":_vm.dialogConferma},on:{"callback":function($event){return _vm.presaVisione()},"update:dialog":function($event){_vm.dialogConferma=$event},"close-dialog":function($event){_vm.dialogConferma = false}}}),_c('v-snackbar',{attrs:{"timeout":3000,"shaped":"","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }