<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar color="primary" dark class="text-h6">{{ title }}</v-toolbar>
        <v-card-text>
          <div class="pa-4"><span v-html="textConferma" /></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Annulla </v-btn>
          <v-btn color="green darken-1" text @click="handleOk"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogConferma",
  props: {
    // editedId: Number,
    dialog: Boolean,
    title: {
      type: String,
      required: false,
      default: "Attenzione!",
    },
    textConferma: {
      type: String,
      required: false,
      default: "Desideri confermare l'operazione selezionata?",
    },
    textSuccess: {
      type: String,
      required: false,
      default: "Operazione conclusa con successo!",
    },
    textError: {
      type: String,
      required: false,
      default: "Errore durante l'operazione. Contattare il supporto.",
    },
    response: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    response: {
      deep: true,
      handler: function () {
        //se cambia la response devo gestire la risposta
      },
    },
  },
  computed: {},
  data() {
    return {};
  },

  methods: {
    handleOk(event) {
      // Prevent modal from closing
      event.preventDefault();
      this.$emit("callback");
      this.$emit("close-dialog");
    },
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
