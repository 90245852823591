<template>
  <v-card v-if="idStrutture && idCorso" elevation="4" class="pa-8">
    <v-row class="mb-4 mt-2">
      <v-col>
        <h1>Dettaglio corso</h1>
      </v-col>
    </v-row>
    <!-- Riepilogo corso-->
    <v-row class="">
      <v-col>
        <h3 class="blue--text">Riepilogo corso</h3>
      </v-col>
    </v-row>
    <!-- prima riga -->
    <v-row class="">
      <v-col cols="3">CUP </v-col>
      <v-col cols="3">ID Corso </v-col>
      <v-col cols="3">Data di avvio del corso </v-col>
      <v-col cols="3">Data conclusione del corso </v-col>
    </v-row>
    <v-row v-if="corso" class="font-weight-bold pb-8">
      <v-col cols="3"
        ><span
          v-html="programmaOrientamento ? programmaOrientamento.cup : '--'"
        />
      </v-col>
      <v-col cols="3">{{ corso.id }} </v-col>
      <v-col cols="3">{{ corso.dataInizio | formatDate }} </v-col>
      <v-col cols="3">{{ corso.dataFine | formatDate }} </v-col>
    </v-row>
    <!-- seconda riga -->
    <v-row class="">
      <v-col cols="3">Istituzione </v-col>
      <v-col cols="3">Nome Legale rappresentante </v-col>
      <v-col cols="3">Cognome Legale rappresentante </v-col>
      <v-col cols="3">CF legale rappresentante </v-col>
    </v-row>
    <v-row class="font-weight-bold pb-8" v-if="legaleRappresentante">
      <v-col cols="3">
        <span
          v-html="
            programmaOrientamento ? programmaOrientamento.nomeIstituzione : '--'
          "
        />
      </v-col>
      <v-col cols="3">{{ legaleRappresentante.nomeRappresentante }} </v-col>
      <v-col cols="3">{{ legaleRappresentante.cognomeRappresentante }} </v-col>
      <v-col cols="3"
        >{{ legaleRappresentante.codiceFiscaleRappresentante }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">Nome referente del programma </v-col>
      <v-col cols="6">Cognome referente del programma </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <b>
          <span v-html="referenteProgramma ? referenteProgramma.nome : ''" />
        </b>
      </v-col>
      <v-col cols="6">
        <b>
          <span v-html="referenteProgramma ? referenteProgramma.cognome : ''" />
        </b>
      </v-col>
    </v-row>
    <!-- terza riga -->
    <v-row class="">
      <v-col cols="3">Nome referente di corso Università/AFAM </v-col>
      <v-col cols="3">Cognome referente di corso Università/AFAM </v-col>
      <v-col cols="3"></v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row v-if="corso" class="font-weight-bold pb-8">
      <v-col cols="3">{{ corso.referente.anagrafica.nome }} </v-col>
      <v-col cols="3">{{ corso.referente.anagrafica.cognome }} </v-col>
      <v-col cols="3"> </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
    <!-- quarta riga -->
    <!--<div v-if="corso">  INFORMAZIONI SUGLI ACCORDI MOSTRATE IN FONDO ALLA PAGINA
      <v-card-title> Dettaglio Accordo/Accordi Scuola </v-card-title>
      <v-row class="">
        <v-col cols="3">Istituto scolastico </v-col>
        <v-col cols="3">Tipologia istituto </v-col>
        <v-col cols="3">Regione istituto scolastico</v-col>
        <v-col cols="3">Città istituto scolastico</v-col>
      </v-row>
      <v-row v-if="corso.anno === 2022" class="font-weight-bold pb-8">
        <v-col cols="3"
          >{{ corso.accordo.istituto.denominazioneScuola }}
        </v-col>
        <v-col cols="3"
          >{{ corso.accordo.istituto.tipologiaGradoIstruzioneScuola }}
        </v-col>
        <v-col cols="3">{{ corso.accordo.istituto.regione }} </v-col>
        <v-col cols="3">{{ corso.accordo.istituto.descrizioneComune }} </v-col>
      </v-row>
      <div
        v-else
        v-for="accordo in corso.accordi"
        :key="accordo.id"
        class="font-weight-bold pb-8"
      >
        <v-row>
          <v-col cols="3">{{ accordo.istituto.denominazioneScuola }} </v-col>
          <v-col cols="3"
            >{{ accordo.istituto.tipologiaGradoIstruzioneScuola }}
          </v-col>
          <v-col cols="3">{{ accordo.istituto.regione }} </v-col>
          <v-col cols="3">{{ accordo.istituto.descrizioneComune }} </v-col>
        </v-row>
        <hr />
      </div>
    </div>-->
    <!-- Quinta riga -->
    <v-row class="">
      <v-col cols="6">Nome referente scolastico </v-col>
      <v-col cols="6">Cognome referente scolastico </v-col>
    </v-row>
    <v-row v-if="corso" class="font-weight-bold pb-8">
      <v-col cols="6">{{ corso.nomeReferenteScolastico }} </v-col>
      <v-col cols="6">{{ corso.cognomeReferenteScolastico }} </v-col>
    </v-row>
    <v-divider />
    <!-- Sesta riga -->
    <v-row class="">
      <v-col cols="4">N° studenti coinvolti</v-col>
      <v-col cols="4">N° studenti coinvolti con DSA </v-col>
      <v-col cols="4">N° docenti scuola</v-col>
    </v-row>
    <v-row v-if="corso" class="font-weight-bold pb-8">
      <v-col cols="4">{{ corso.numeroStudenti }} </v-col>
      <v-col cols="4">{{ corso.numeroStudentiDsa }} </v-col>
      <v-col cols="4">{{ corso.numeroDocenti }}</v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <v-row v-if="corso" class="pb-8">
      <v-col cols="4">
        Numero studenti censiti <br />
        <strong> {{ studentiCorso.length }} </strong>
      </v-col>
      <v-col cols="4">
        Totale ore studenti con attestato <br />
        <strong> {{ sumOreStudentiConAttestato }}</strong>
      </v-col>
      <v-col cols="4">
        Numero studenti con attestato <br />
        <strong> {{ nStudentiConAttestato }}</strong>
      </v-col>
    </v-row>
    <v-row v-if="corso && lezioniCorso" class="pb-8">
      <v-col cols="4">
        Data prima lezione <br />
        <strong v-if="lezioniCorso && lezioniCorso.length"
          >{{
            formatDate(
              lezioniCorso && lezioniCorso.length ? lezioniCorso[0].data : null
            )
          }}
        </strong></v-col
      >
      <v-col cols="4">
        Data ultima lezione <br />
        <strong v-if="lezioniCorso && lezioniCorso.length">
          {{ formatDate(lezioniCorso[lezioniCorso.length - 1].data) }}
        </strong>
      </v-col>
      <v-col cols="4">
        Data termine corso <br />
        <strong v-if="corso"> {{ formatDate(corso.dataTermineCorso) }} </strong>
      </v-col>
    </v-row>

    <!-- Component elenco lezioni -->
    <v-main class="my-4" v-if="loadingComponentLezioni">
      <h3 class="blue--text">Elenco lezioni</h3>
      <ElencoLezioniComponent :lezioni="lezioniCorso" :corso="corso" />
    </v-main>
    <v-main
      class="my-4"
      v-if="loadingComponentLezioni && loadingComponentStudenti"
    >
      <h3 class="blue--text">Elenco studenti con attestato</h3>
      <StudentiConCertificato
        :lezioni="lezioniCorso"
        :studenti-corso="studentiCorso"
        :id-strutture="idStrutture"
      />
    </v-main>
    <v-main v-if="corso">
      <h3 class="blue--text">Accordo</h3>
      <v-card>
        <v-card-text v-if="corso.anno === 2022">
          <DettaglioAccordo :accordo="corso.accordo" />
        </v-card-text>
        <v-card-text
          v-else
          v-for="(accordo, index) in corso.accordi"
          :key="index"
        >
          <DettaglioAccordo :accordo="accordo" />
          <v-divider />
        </v-card-text>
      </v-card>
    </v-main>
    <FormatCorso v-if="corso" :corso="corso"></FormatCorso>

    <v-card-actions class="mt-5" v-if="isDialog">
      <v-btn
        class="w-50 h-75"
        v-html="'Chiudi'"
        color="primary"
        @click="closeDialog"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CorsiRepository from "@/api/CorsiRepository";
import StudentiConCertificato from "@/components/Controlli/DettaglioCorso/StudentiConCertificato";
import FormatCorso from "@/components/Controlli/DettaglioCorso/FormatCorso";
import ElencoLezioniComponent from "@/components/Controlli/DettaglioCorso/ElencoLezioniComponent";
import IstitutoRepository from "@/api/IstitutoRepository";
import DettaglioAccordo from "@/components/Controlli/DettaglioCorso/DettaglioAccordo.vue";

export default {
  name: "dettaglioCorsoComponent",
  components: {
    DettaglioAccordo,
    StudentiConCertificato,
    FormatCorso,
    ElencoLezioniComponent,
  },
  data: () => ({
    legaleRappresentante: null,
    loadingDettaglio: false,
    corso: null,
    nominativoReferente: null, // referente di corso
    referenteProgramma: null,
    lezioniCorso: [],
    loadingComponentLezioni: false,
    studentiCorso: [],
    loadingComponentStudenti: false,
    studentiConAttestato: {},
    nStudentiConAttestato: 0,
    sumOreStudentiConAttestato: null,
    programmaOrientamento: null,
  }),
  props: {
    idCorso: {
      type: [Number, String],
      required: true,
    },
    idStrutture: {
      type: [String, Number],
      required: true,
    },
    isDialog: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([]),
    loading: function () {
      return this.loadingDettaglio;
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    idCorso() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null && this.idCorso) {
        this.getCorso();
        this.getLezioniCorso(this.idStrutture, this.idCorso);
        this.getStudenti();
      }
    },
    async getCorso() {
      this.loadingDettaglio = true;
      this.corso = await CorsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      if (this.corso) {
        this.getProgrammaOrientamento();
      }
      this.nominativoReferente = `${this.corso.referente.anagrafica.cognome} ${this.corso.referente.anagrafica.nome}`;
      let dataInizioFormattata;
      dataInizioFormattata = this.corso.dataInizio.split("T");
      this.corso.dataInizio = dataInizioFormattata[0];
      let dataFineFormattata;
      dataFineFormattata = this.corso.dataFine.split("T");
      this.corso.dataFine = dataFineFormattata[0];
      this.loadingDettaglio = false;
      await this.getReferenteProgramma(this.idStrutture, this.corso.capofila);
    },
    async getLegaleRappresentanteAttivo() {
      const idProgrammaOrientamento = this.programmaOrientamento.id;
      this.legaleRappresentante =
        await IstitutoRepository.getLegaleRappresentante(
          this.idStrutture,
          idProgrammaOrientamento
        );
    },
    closeDialog() {
      // il dialog lo sto chiudendo, per cui non faccio visualizzare più gli altri component
      this.loadingDettaglio = false;
      this.loadingComponentLezioni = false;
      this.loadingComponentStudenti = false;
      this.$emit("callback");
    },
    async getReferenteProgramma(idStrutture, capofila) {
      this.referenteProgramma =
        await IstitutoRepository.getReferenteProgrammaIstituzione(
          idStrutture,
          capofila
        );
    },
    async getLezioniCorso(idStrutture, idCorso) {
      this.loadingComponentLezioni = false;
      this.lezioniCorso = await CorsiRepository.getLezioniCorso(
        idStrutture,
        idCorso
      );
      if (this.lezioniCorso.length) {
        this.loadingComponentLezioni = true;
      }
    },
    async getStudenti() {
      this.loadingComponentStudenti = false;
      this.studentiCorso = await CorsiRepository.getStudentiCorso(
        this.idStrutture,
        this.idCorso
      );
      if (this.studentiCorso && this.studentiCorso.length) {
        this.loadingComponentStudenti = true;
        this.getStudentiConAttestato();
      }
    },
    formatDate(data) {
      if (data) {
        return new Date(data).toLocaleDateString("it-IT");
      }
      return "";
    },
    getStudentiConAttestato: function () {
      this.studentiCorso.forEach((element) => {
        if (
          element.certificatoReferenteIstituto &&
          element.dataFirmaAttestato
        ) {
          this.studentiConAttestato[element.cf] = element;
        }
      });
      this.nStudentiConAttestato = Object.keys(
        this.studentiConAttestato
      ).length;
      if (this.nStudentiConAttestato > 0) {
        this.sommaOreStudentiConAttestato();
      }
    },
    sommaOreStudentiConAttestato() {
      let sommaOreStudenteConAttestato = 0;
      this.lezioniCorso.forEach((lezione) => {
        if (lezione.lezioniStudenti && lezione.lezioniStudenti.length) {
          let studentiLezione = lezione.lezioniStudenti;
          studentiLezione.forEach((presenzaStudente) => {
            let cfStudente = presenzaStudente.studente.cf;
            if (this.studentiConAttestato[cfStudente]) {
              // lo studente ha l'attestato, calcolo la somma delle ore che ha fatto
              sommaOreStudenteConAttestato += presenzaStudente.oreSvolte ?? 0;
            }
          });
        }
      });
      this.sumOreStudentiConAttestato = sommaOreStudenteConAttestato;
    },
    getProgrammaOrientamento() {
      this.programmaOrientamento =
        this.corso.anno === 2022
          ? this.corso.accordo.programmaOrientamento
          : this.corso.accordi[0].programmaOrientamento;
      this.getLegaleRappresentanteAttivo();
    },
  },
};
</script>

<style scoped></style>
