<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main class="ma-4 pa-4">
      <v-row class="mb-8">
        <v-col cols="8">
          <h1 style="color: #1a237e">Dettagli chiarimenti Udm</h1>
          <p>
            Visualizza i dettagli delle richieste di chiarimenti da parte
            dell'UDM e inserimento risposta all'incaricato del controllo
            dell'UDM.
          </p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
      </v-row>
    </v-main>
    <div v-if="loadinPage">
      <div v-if="rendiconto">
        <template>
          <v-card v-if="richieste.length" :loading="loadinPage">
            <v-expansion-panels focusable>
              <v-expansion-panel v-for="(item, i) in richieste" :key="i">
                <v-expansion-panel-header>
                  <template>
                    <v-row>
                      <v-col cols="2">
                        <v-card-text>
                          Richiesta
                          <br />
                          <br />
                          <b>
                            {{ item.id }}
                          </b>
                        </v-card-text>
                      </v-col>
                      <v-col cols="2">
                        <v-card-text>
                          Richiedente
                          <br />
                          <br />
                          <b>
                            {{ richiedente }}
                          </b>
                        </v-card-text>
                      </v-col>
                      <v-col cols="3">
                        <v-card-text>
                          Ruolo richiedente
                          <br />
                          <br />
                          <b>
                            {{ getRuoloRichiedente(item.richiedente.ruoli) }}
                          </b>
                        </v-card-text>
                      </v-col>
                      <v-col cols="3">
                        <v-card-text>
                          Data
                          <br />
                          <br />
                          <b> {{ getDate(item.dataCreazione) }} </b>
                        </v-card-text>
                      </v-col>
                      <v-col cols="2">
                        <br />
                        <br />
                        <v-chip
                          v-if="item.conclusa === true"
                          pill
                          color="success"
                        >
                          Conclusa
                        </v-chip>
                        <v-chip v-else pill color="warning"> Nuova </v-chip>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-2">
                    <v-col cols="8">
                      <h4>Descrizione richiesta di chiarimento:</h4>
                      <div class="pt-2">
                        <p>
                          {{ item.descrizione }}
                        </p>
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-row-reverse my-auto" cols="4"
                      ><div v-if="item.fileRichiesta">
                        <a @click="getFileAllegato(item, 'richiesta')">
                          <v-icon> mdi-tray-arrow-down </v-icon> Download file
                          allegato alla Richiesta
                        </a>
                      </div>
                      <div v-else>
                        <p>
                          <v-icon> mdi-paperclip-off </v-icon>Nessun allegato
                          caricato
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-form
                        lazy-validation
                        ref="form"
                        v-model="valid"
                        :rules="validateField.rispostaSa"
                      >
                        <v-textarea
                          outlined
                          hint="Massimo 1000 caratteri"
                          name="dettagliCorso"
                          label="Risposta:"
                          counter
                          auto-grow
                          v-model="item.risposta"
                          :rules="validateField.rispostaSa"
                          :disabled="item.conclusa === true"
                        />
                        <v-row>
                          <v-col cols="8">
                            <v-file-input
                              :placeholder="getPlaceholderInputFile(item)"
                              :disabled="item.conclusa === true"
                              @change="uploadFile($event)"
                            ></v-file-input>
                          </v-col>
                          <v-col
                            class="d-flex flex-row-reverse my-auto"
                            v-if="item.conclusa === true"
                            cols="4"
                            ><div v-if="item.fileAllegato">
                              <a @click="getFileAllegato(item, 'risposta')">
                                <v-icon> mdi-tray-arrow-down </v-icon> Download
                                file allegato
                              </a>
                            </div>
                            <div v-else>
                              <p>
                                <v-icon> mdi-paperclip-off </v-icon>Nessun
                                allegato caricato
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-card-actions v-if="item.conclusa !== true">
                          <v-row>
                            <v-col cols="12" class="d-flex flex-row-reverse">
                              <v-btn
                                color="primary"
                                @click="dialogSalva = true"
                                :disabled="!valid"
                              >
                                Conferma
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                        <dialog-conferma
                          title="Conferma risposta e concludi richiesta"
                          text-conferma="Confermando l'operazione la risposta sarà inviata e la richiesta non sarà più modificabile. Continuare?"
                          @callback="updateRichiesta(item)"
                          :dialog.sync="dialogSalva"
                          @close-dialog="dialogSalva = false"
                        />
                      </v-form>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <v-card class="mx-6" v-else>
            <v-card-text class="justify-centerr">
              Non ci sono richieste di chiarimenti da parte
              dell'UDM</v-card-text
            >
          </v-card>
          <v-snackbar
            v-model="snackbar"
            :timeout="3000"
            shaped
            :color="snackbarColor"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </template>
      </div>
      <div v-else>
        <v-progress-linear indeterminate color="blue"></v-progress-linear>
      </div>
      <v-row>
        <v-col cols="2" class="mt-4">
          <TornaIndietroComponent text="Indietro" />
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import DialogConferma from "@/components/DialogConferma";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "dettagliRisposteChiarimentiUdm",
  components: {
    AlertComponent,
    DialogConferma,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    TornaIndietroComponent,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: false,
            to: `/scrivania/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Dettagli Chiarimenti Udm",
            disabled: true,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  data: () => ({
    readOnly: true,
    loadinPage: false,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    richieste: null,
    verificaRendiconto: null,
    corsiCampionati: [],
    idRendiconto: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    formRichiestaModifiche: {
      rispostaSa: null,
      disabled: false,
    },
    valid: false,
    validateField: {
      rispostaSa: [
        (v) => !!v || "Campo obbligatorio",
        (v) =>
          (v && v.length <= 1000) || "Numero di caratteri elevato (max 1000)",
      ],
    },
    idUtente: 0,
    loadingDettaglio: true,
    corsi: [],
    richiedente: "DG",
    tipoControlloRendiconto: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getTipoControllo();
      }
    },
    async getTipoControllo() {
      this.tipoControlloRendiconto =
        await ControlliUdmRepository.tipoControlloRendiconto(
          parseInt(this.anno),
          this.idRendiconto
        );
      if (this.tipoControlloRendiconto) {
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        this.tipoControlloRendiconto === "sostanziale"
          ? await ControlliUdmRepository.getSingoloVerbaleCampionamentoRendicontoUdm(
              parseInt(this.anno),
              this.idRendiconto,
              1
            )
          : await ControlliUdmRepository.getSingoloVerbaleControlliFormaliUdm(
              parseInt(this.anno),
              this.idRendiconto,
              1
            );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.richieste = this.rendiconto.richiesteChiarimentiUdm;
        this.loadinPage = true;
      }
    },
    getPlaceholderInputFile(item) {
      if (item.fileAllegato) {
        return item.fileAllegato.nomeOriginale;
      } else {
        return "Caricamento allegato in Pdf/p7m (facoltativo)";
      }
    },
    async getFileAllegato(richiesta, tipoAllegato) {
      let nomeFile =
        tipoAllegato === "richiesta"
          ? richiesta.fileRichiesta.nomeOriginale
          : richiesta.fileAllegato.nomeOriginale;
      await ControlliUdmRepository.getFileRispostaRichiestaUdm(
        this.verbaleCampionamentoRendiconto.idStrutture,
        richiesta.id,
        nomeFile,
        tipoAllegato
      );
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    uploadFile(event) {
      this.file = event;
    },
    getRuoloRichiedente(arrayRuoli) {
      if (arrayRuoli.some((item) => item.id === 8)) {
        return "Responsabile del controllo UDM";
      } else if (arrayRuoli.some((item) => item.id === 9)) {
        return "Incaricato del controllo UDM";
      } else {
        return "--";
      }
    },
    async updateRichiesta(richiesta) {
      if (richiesta.risposta) {
        const formData = new FormData();
        formData.append("form", JSON.stringify(richiesta));
        formData.append("file", this.file);
        try {
          await ControlliUdmRepository.updateRichiestaUdm(
            parseInt(this.anno),
            richiesta.id,
            formData
          );
          this.formRichiestaModifiche.disabled = true;

          this.snackbarText = "Risposta al chiarimento inviata";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.initialize();
        } catch (e) {
          console.log(e);
          if (e.response.status === 415) {
            this.snackbarText = e.response.data[0];
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          } else {
            this.snackbarText =
              "Problema durante il salvataggio, contattare il supporto se il problema persiste";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          }
        }
      }
    },
  },
};
</script>
