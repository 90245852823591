<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <h1 style="padding-top: 25px">Lista Rendiconti</h1>
    <p>
      L'utente cliccando sul tasto estrai campione potrà campionare gli
      attestati all'interno dei rendiconti da controllare
    </p>
    <!-- <v-col sm="3" md="3">
      <a class="v-btn" @click="downloadExcel()">
        <v-icon> mdi-file-excel</v-icon>
        <span> Esporta Excel</span>
      </a>
      <a class="v-btn" @click="downloadPdf()">
        <v-icon> mdi-file-pdf-box</v-icon>
        <span> Esporta PDF</span>
      </a>
    </v-col> -->
    <v-card elevation="4" class="px-4 pb-4" v-if="loadingRendiconti">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="rendiconti"
          :items-per-page="10"
          class="elevation-1"
          loading-text="Caricamento dati in corso..."
          ><!-- eslint-disable -->
        <template v-slot:item.cup="{ item }">
          <span>{{ item.programma.cup }}</span>
        </template>
        <template v-slot:item.nomeIstituzione="{ item }">
          <span>{{ item.programma.nomeIstituzione }}</span>
        </template>
        <template v-slot:item.quadrimestre="{ item }">
          {{ item.quadrimestre.tipologiaQuadrimestre.descrizione }}
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span>{{ item.dataTrasmissione | formatDate }}</span>
        </template>
        <template v-slot:item.importoRichiesto="{ item }">
          <span>{{ item.importoRichiesto | formatNumber }} €</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
              >
                <router-link
                    class="v-btn"
                    :to="{
                          name: itemMenu.nameComponent,
                          params: {
                                // capofila: capofila,
                                idRendiconto: Number(item.id),
                                idStrutture: Number(item.idStrutture),
                              },
                            }"
                >
                  {{ itemMenu.text }}
                  <v-icon> {{ itemMenu.icon }}</v-icon>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </template><!-- eslint-enable -->
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="rendiconti && rendiconti.length">
        <v-row>
          <v-col cols="4">
            <alert-component
              testo="La procedura di generazione potrebbe richiedere alcuni secondi"
              type="warning"
            />
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="estraiCampione"
              :disabled="disabilitazionePulsanteEstraiCampione"
              color="primary"
              class="float-end"
              v-html="'Estrai Campione'"
            />
            <v-snackbar v-model="snackbar.value" :color="snackbar.color">
              {{ snackbar.testo }}
            </v-snackbar>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-else>
        <alert-component
          testo="Impossibile generare il campione. Tutti i rendiconti sono stati associati a un verbale e sono in fase di verifica."
          :type="'info'"
        />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-progress-linear indeterminate color="blue" />
    </v-card>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";

export default {
  name: "nuovoVerbaleControllo",
  components: { AlertComponent, BreadcrumbComponent },
  computed: {
    loading: function () {
      return this.loadingRendiconti;
    },
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Rendiconti",
            disabled: false,
            to: `/scrivania/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Nuovo controllo",
            disabled: true,
            to: `/scrivania/controlli/lista-rendiconti/nuovo-controllo`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno", "nomeStruttura"]),
    ...mapGetters([]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    nomeStruttura() {
      this.initialize();
    },
  },
  data: () => ({
    loadingRendiconti: false,
    loadingPage: false,
    rendiconti: [],
    rendicontiFiltrato: [],
    datiExcel: {
      cup: null,
      //nomeIstituzione: null,
      quadrimestre: null,
      dataTrasmissione: null,
      importoRichiesto: null,
    },
    headers: [
      {
        text: "ID RENDICONTO",
        align: "start",
        value: "id",
        sortable: true,
      },
      { text: "CUP", value: "cup", sortable: true },
      { text: "UNIVERSITÀ/AFAM", value: "nomeIstituzione", sortable: true },
      { text: "QUADRIMESTRE", value: "quadrimestre", sortable: true },
      {
        text: "DATA TRASMISSIONE RENDICONTO",
        value: "dataTrasmissione",
        sortable: true,
      },
      { text: "IMPORTO RICHIESTO", value: "importoRichiesto", sortable: true },
      { text: "AZIONI", value: "actions", sortable: false },
    ],
    menuAction: [
      {
        text: "Dettaglio rendiconto",
        ambitoOperazione: "dettaglioRendiconto",
        icon: null,
        nameComponent: "dettaglioRendiconto",
      },
    ],
    disabilitazionePulsanteEstraiCampione: false,
    snackbar: {
      testo: "",
      colorL: "",
      value: false,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getRendiconti();
      }
    },
    async getRendiconti() {
      this.loadingRendiconti = false;
      this.rendiconti =
        await RendicontiRepository.getListaRendicontiSenzaFiltraggio(this.anno);
      this.loadingRendiconti = true;
    },
    async estraiCampione() {
      const rendicontiDaVerificare = this.rendiconti.map((element) => {
        return element.id;
      });
      const formBody = new FormData();
      formBody.append("rendiconti", JSON.stringify(rendicontiDaVerificare));
      formBody.append("percentualeCampionamento", 0.04);
      this.disabilitazionePulsanteEstraiCampione = true;
      await ControlliRepository.generaVerbaleCampionamento(
        this.anno,
        false,
        formBody
      )
        .then((data) => {
          if (data) {
            const campioneEstratto = JSON.stringify(data);
            // estratto il campione, questo lo associo alle due variabili nello store. Il primo sarà quello originale; il secondo sarà quello aggiornato
            this.$store.commit("setCampioneEstratto", campioneEstratto);
            this.$store.commit("setCampioneAggiornato", campioneEstratto);
            this.$router.push({ name: "finalizzaCampione" });
          }
        })
        .catch((reason) => {
          if (reason.response.status === 409) {
            this.setValueSnackbar(false, reason.response.data[0]);
          } else {
            this.setValueSnackbar(
              false,
              "Un errore di server è stato riscontrato nella generazione del campione"
            );
          }
          this.disabilitazionePulsanteEstraiCampione = false;
        });
    },
    setValueSnackbar(esito, testo) {
      let color = esito ? "success" : "red accent-2";
      this.snackbar.testo = testo;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    // async downloadExcel() {
    //   let datiExcel = { ...this.rendicontiFiltrato };
    //   const data = new FormData();
    //   data.append("datiExcel", JSON.stringify(datiExcel));
    //   await RendicontiRepository.scaricaListaRendicontiExcel(data);
    // },
    // async downloadPdf() {
    //   let datiPdf = { ...this.rendicontiFiltrato };
    //   const data = new FormData();
    //   data.append("datiPdf", JSON.stringify(datiPdf));
    //   await RendicontiRepository.scaricaListaRendicontiPdf(data);
    // },
  },
};
</script>
<style scoped>
.mb-1 {
  min-height: 5px;
  padding: 20px;
  max-width: 86%;
}

.v-btn {
  padding: 25px;
}
</style>
