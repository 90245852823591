<template>
  <v-sheet>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Missione"
          disabled
          v-model="formAnagraficaIntervento.missione"
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Componente"
          disabled
          v-model="formAnagraficaIntervento.componente"
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Misura/sub misura"
          disabled
          v-model="formAnagraficaIntervento.misura"
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Titolo Intervento"
          disabled
          v-model="formAnagraficaIntervento.investimento" /></v-col
    ></v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Modalità di attuazione"
          v-model="formAnagraficaIntervento.modalitaAttuazione"
          disabled
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Soggetto Attuatore"
          disabled
          v-model="formAnagraficaIntervento.soggettoAttuatore"
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Tagging"
          v-model="formAnagraficaIntervento.tagging"
          disabled
          placeholder=""
      /></v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Data di avvio"
          v-model="formAnagraficaIntervento.dataAvvio"
          disabled
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Data di conclusione"
          v-model="formAnagraficaIntervento.dataConclusione"
          disabled
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Costo totale progetto(€)"
          v-model="formAnagraficaIntervento.costoTotaleProgetto"
          disabled
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Di cui costo ammesso PNRR (€)"
          v-model="formAnagraficaIntervento.costoTotaleProgetto"
          disabled
      /></v-col>
      <v-col cols="3">
        <v-text-field
          label="Sede amm. in cui si conservano i documenti"
          v-model="formAnagraficaIntervento.sedeAmministrativa"
          disabled /></v-col
    ></v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "AnagraficaIntervento",
  components: {},
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    rendiconto: {
      type: Object,
      required: true,
    },
    costoTotale: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    formAnagraficaIntervento: {
      missione: "M4 - Istruzione e ricerca",
      componente:
        "C1 - Potenziamento offerta servizi di istruzione dagli Asili nido alle Università",
      misura: "Investimento 1.6",
      investimento: "Orientamento attivo nella transizione scuola-università",
      modalitaAttuazione: "A regia",
      soggettoAttuatore: null,
      tagging: null,
      dataAvvio: new Date(2022, 10, 1).toLocaleString().split(",")[0],
      dataConclusione: new Date(2023, 7, 31).toLocaleString().split(",")[0],
      costoTotaleProgetto: null,
      sedeAmministrativa: "Portale “Orientamento 2026”",
      indirizzoSedeAmministrativa: null,
    },
    loadingComponent: false,
  }),
  methods: {
    initialize() {
      if (this.rendiconto) {
        this.formAnagraficaIntervento.soggettoAttuatore =
          this.rendiconto.programma.nomeIstituzione;
        this.formAnagraficaIntervento.costoTotaleProgetto = this.formatNumber(
          this.costoTotale
        );

        this.loadingComponent = true;
      }
    },
    formatNumber(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },
  },
};
</script>
