<template>
  <v-alert dense :type="tipoAlert" :width="width">
    <span v-html="testo" />
  </v-alert>
</template>
<script>
export default {
  name: "AlertComponent",
  props: {
    testo: {
      type: String,
      required: true,
    },
    tipoAlert: {
      type: String,
      required: false,
      default: "warning",
    },
    width: {
      type: Number,
      required: false,
    },
  },
};
</script>
