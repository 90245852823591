<template>
  <v-card elevation="4">
    <v-card-title v-html="'Dettaglio Rendicontazioni'" />
    <v-card-text>
      Sezione in cui è possibile visualizzare i dettagli dei rendiconti di
      progetto e delle richieste di anticipo.
    </v-card-text>

    <v-card elevation="2" class="pa-2 ma-2" v-if="loadingCardRichiesteAnticipo">
      <v-card-subtitle> Richiesta di Anticipo </v-card-subtitle>
      <v-card-text v-if="richiestaAnticipo">
        <v-row>
          <v-col cols="3" class="columnRichieste">
            ID richiesta <br />
            <b> {{ richiestaAnticipo.id }} </b>
          </v-col>
          <v-col cols="3" class="columnRichieste">
            Data trasmissione <br />
            <b>
              {{
                richiestaAnticipo.dataInvio
                  ? getFormattedDate(richiestaAnticipo.dataInvio)
                  : ""
              }}
            </b>
          </v-col>
          <v-col cols="3" class="columnRichieste">
            Importo richiesto <br />
            <b> {{ richiestaAnticipo.importo }} </b>
          </v-col>
          <v-col cols="3" class="columnRichieste">
            Importo erogato <br />
            <b> - </b>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="columnRichieste">
            <h3>Allegato <br /></h3>
            <b> {{ richiestaAnticipo.idFile.nomeOriginale }} </b> <br />
            <v-btn
              link
              outlined
              @click="
                downlaodFileRichiestaAnticipo(
                  richiestaAnticipo.anno,
                  richiestaAnticipo.idFile.id,
                  richiestaAnticipo.idFile.nomeOriginale
                )
              "
            >
              Scarica <v-icon> mdi-download </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="columnRichieste" />
        </v-row>
      </v-card-text>
      <v-alert type="info"> Nessuna richiesta di anticipo creata. </v-alert>
    </v-card>

    <v-card elevation="4" v-if="loadingCardRendicontazioni">
      <v-card-subtitle> Dettaglio rendicontazioni </v-card-subtitle>
      <v-data-table
        v-if="rendicontazioni.length"
        :items="rendicontazioni"
        :headers="headers"
        item-key="id"
      >
        <!-- eslint-disable -->
        <template v-slot:item.importoRichiesto="{ item }">
          {{ item.importoRichiesto ? getFormattedAmount(item.importoRichiesto) : 0}} €
        </template>
        <template v-slot:item.importoAmmessoDG="{ item }">
          {{ item.importoAmmesso ? getFormattedAmount(item.importoAmmesso) : 0}} €
        </template>
        <template v-slot:item.importoAmmessoUDM="{ item }">
          0 €
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          {{ item.dataTrasmissione ? getFormattedDate(item.dataTrasmissione) : '-'}}
        </template>
        <!--eslint-enable -->
      </v-data-table>
      <v-card-text v-else>
        <v-alert type="info"> Nessuna rendicontazione è stata creata. </v-alert>
      </v-card-text>
    </v-card>

    <v-card-actions>
      <v-btn
        color="warning"
        v-html="'Chiudi finestra di dialogo'"
        @click="$emit('chiusuraDialog')"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import IstitutoRepository from "@/api/IstitutoRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "dettaglioRendicontazioni",
  components: {},
  props: {
    idStrutture: {
      type: Number,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
    capofila: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    rendicontazioni: [],
    richiestaAnticipo: null,
    loadingComponent: false,
    loadingCardRendicontazioni: false,
    loadingCardRichiesteAnticipo: false,
    headers: [
      {
        text: "CUP",
        value: "programma.cup",
      },
      {
        text: "ISTITUZIONE",
        value: "programma.nomeIstituzione",
      },
      {
        text: "ID RENDICONTO DI PROGETTO",
        value: "id",
      },
      {
        text: "DATA TRASMISSIONE",
        value: "dataTrasmissione",
      },
      {
        text: "IMPORTO RENDICONTATO",
        value: "importoRichiesto",
      },
      {
        text: "IMPORTO-AMMESSO-DG",
        value: "importoAmmessoDG",
      },
      {
        text: "IMPORTO-AMMESSO-UDM",
        value: "importoAmmessoUDM",
      },
      {
        text: "RENDICONTO A SALDO",
        value: "rendicontoASaldo",
      },
      {
        text: "AZIONI",
        value: "azioni",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture && this.anno) {
        this.getRendicontazioni();
        this.getRichiestaAnticipo();
      }
    },
    async getRendicontazioni() {
      this.rendicontazioni =
        await RendicontiRepository.getRendicontiAnnualiIstituzione(
          this.idStrutture,
          this.anno,
          this.capofila ? "capofila" : "istituzione"
        );
      this.loadingCardRendicontazioni = true;
    },
    async getRichiestaAnticipo() {
      const richiestaAnticipo =
        await IstitutoRepository.getRichiesteAnticipoSaldoAteneo(
          this.idStrutture,
          this.anno,
          this.capofila
        );
      if (richiestaAnticipo.length) {
        this.richiestaAnticipo = richiestaAnticipo[0];
      }
      this.loadingCardRichiesteAnticipo = true;
    },
    getFormattedDate(dataInvio) {
      return new Date(dataInvio).toISOString().split("T")[0];
    },
    getFormattedAmount(importo) {
      return parseFloat(importo).toFixed(2);
    },
    async downlaodFileRichiestaAnticipo(anno, idFile, nomeFile) {
      await MinisteroRepository.downloadFile(anno, idFile, nomeFile);
    },
  },
};
</script>
