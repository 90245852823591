<template>
  <v-checkbox
    v-model="capofila"
    class="mx-4"
    flat
    label="Capofila?"
    solo-inverted
    @change="cambiaCapofila()"
  ></v-checkbox>
</template>

<script>
export default {
  name: "SelezionaCapofila",
  data() {
    return {
      capofila: false,
    };
  },
  methods: {
    cambiaCapofila() {
      const capofila = this.capofila ? "capofila" : "istituto";
      this.$store.dispatch("loadCapofila", capofila);
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  margin: 0 auto;
}
</style>
