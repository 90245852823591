<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <h1>Scrivania</h1>
      <v-card elevation="8" class="mx-auto my-8" :loading="loading">
        <v-container fluid>
          <td style="border-right: floralwhite">
            <v-card-title>Dati di Monitoraggio</v-card-title>
            <br />
            <v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="v-btn"
                  :to="`/scrivania/dati-di-monitoraggio`"
                >
                  Vai <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </v-card-actions>
            </v-form>
          </td>
          <td>
            <v-card-text>
              N° di programmi di orientamento <br />
              per cui sono stati confermati i dati
              <br />
              <h3>{{ programmiMese }}/ {{ tuttiProgrammi }}</h3>
            </v-card-text>
          </td>
          <td>
            <v-card-text>
              Dati di monitoraggio <br />
              mensili al <br />
              <h2>{{ dataMonitoraggioAl }}</h2></v-card-text
            >
          </td>
        </v-container>
      </v-card>
      <v-spacer></v-spacer>
      <v-row>
        <v-col class="float-left">
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Assegnazione delle risorse e target
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile visualizzare per singolo CUP
              l'assegnazione delle risorse e dei target
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link class="v-btn" :to="`/scrivania/risorse-target`">
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="float-right">
          <v-card v-if="!isRoleDgUdm" elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Controlli
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile effettuare il controllo sul Decreto di
              concessione del finanziamento e sui rendiconti di progetto.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="!isRoleUdm"
                class="v-btn"
                :to="`/scrivania/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
              <router-link
                v-if="isRoleUdm"
                class="v-btn"
                :to="`/scrivania/udm/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isRoleRup || isRoleConsulenza" cols="6" class="float-left">
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-bank-check</v-icon>
              Dichiarazione di tracciabilità dei flussi finanziari <br />(art.3
              ss L. 136/2010)
            </v-card-title>
            <v-card-text>
              Sezione in cui il Rappresentante Legale dell'istituzione può
              inserire la dichiarazione di tracciabilità dei flussi finanziari e
              indicare il/i conto/i dedicati ai movimenti finanziari
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="isRoleRup || isRoleConsulenza"
                class="v-btn"
                :to="`/scrivania/flussi-finanziari/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          v-if="isRoleConsulenza || isRoleDgUdm"
          cols="6"
          class="float-right"
        >
          <v-card elevation="8" height="100%" width="100%">
            <v-card-title>
              <v-icon class="mr-2">mdi-wallet</v-icon>
              Controlli UDM
            </v-card-title>
            <v-card-text class>
              Sezione in cui è possibile effettuare il controllo sul Decreto di
              concessione del finanziamento e sui rendiconti di progetto.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link
                v-if="isRoleConsulenza || isRoleDgUdm"
                class="v-btn"
                :to="`/scrivania/udm/controlli/`"
              >
                Vai <v-icon>mdi-arrow-right-thin</v-icon>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProgrammiRepository from "@/api/ministero/ProgrammiRepository";

export default {
  name: "ScrivaniaView",
  components: {},
  data: () => ({
    tuttiProgrammi: null,
    programmiMese: null,
    loading: false,
    meseMonitoraggio: null,
    programmiTuttiConfermaMeseCorrente: [],
    dataMonitoraggioAl: null,
    dataTermineMonitoraggio: null,
    loadingProgrammi: false,
    bottoneDisabilitato: {
      check1: true,
      check2: true,
    },
    dialogConferma: false,
    showCard: false,
    dialogConfermaMonitoraggio: false,
    datiRiepilogo: [],
    ultimoMonitoraggio: null,
    dataUltimaConferma: null,
    programma: null,
    monitoraggi: null,
    //monitoraggi: [],
    snackbar: {
      text: null,
      color: "green",
      value: false,
    },
    finestraCompilazione: null,
    checkBtnDisabled1: true,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleUdm",
      "isRoleDgUdm",
      "isRoleRup",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.getProgrammiTutti();
      this.getDateToShow();
      this.getProgrammiMonitoratiMese();
    },
    getDateToShow() {
      const dateOdierna = new Date();

      this.dataTermineMonitoraggio = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth(),
        10
      ).toLocaleDateString();

      // data da mostrare nella colonna data di monitoraggio al
      this.dataMonitoraggioAl = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth() - 1,
        30
      ).toLocaleDateString();
      this.meseMonitoraggio = this.dataMonitoraggioAl.split("/")[1];
    },
    async getProgrammiTutti() {
      this.loadingProgrammi = true;
      const programmi = await ProgrammiRepository.getAllProgrammi(this.anno);
      this.tuttiProgrammi = programmi.length;
      this.loadingProgrammi = false;
    },
    async getProgrammiMonitoratiMese() {
      this.loadingProgrammi = true;
      const programmi = await ProgrammiRepository.getProgrammiMonitorati(
        this.anno
      );
      this.programmiMese = programmi.length;
      this.loadingProgrammi = false;
    },
  },
};
</script>

<style>
.id-strutture {
  display: none;
}
</style>
