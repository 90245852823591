<template>
  <v-container>
    <v-card v-if="loadingCard" class="mx-auto mt-8 px-6 pb-6 pb-6">
      <v-card-title> SOSTITUISCI IL LEGALE RAPPRESENTANTE </v-card-title>
      <v-data-table
        :headers="headersTableLegealiRappresentanti"
        :items="legaliRappresentanti"
        :items-per-page="2"
        ><!-- eslint-disable -->
        <template v-slot:item.emailRappresentante="{ item }">
          <span v-html="item.emailRappresentante ? item.emailRappresentante : 'Non disponibile'" />
        </template>
        <template v-slot:item.fileNomina="{ item }">
          <span v-if="item.fileNomina">
            <a @click="downloadPDF(item, item.fileNomina.nomeOriginale)">
            <v-icon>mdi-paperclip</v-icon>
          </a>
          </span>
          <span v-else v-html="'Non disponibile'"></span>
        </template>
        <template v-slot:item.attivo="{ item }">
          <span v-text="checkLegaleAttivo(item.programmi)" />
        </template>
      </v-data-table><!-- eslint-enable -->

      <v-card-subtitle>
        <b> Dettagli Nuovo Legale Rappresentante </b></v-card-subtitle
      >
      <v-main class="mx-auto mt-8 px-6 pb-6 pb-6">
        <v-form ref="formLegale" lazy-validation v-model="validForm1">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="formLegaleRappresentante.nomeRappresentante"
                label="Nome Legale Rappresentante"
                :rules="validateField.fieldRequired"
              ></v-text-field>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4">
              <v-text-field
                label="Cognome Legale Rappresentante"
                :rules="validateField.fieldRequired"
                v-model="formLegaleRappresentante.cognomeRappresentante"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Codice Fiscale"
                :rules="validateField.fieldCodiceFiscale"
                v-model="formLegaleRappresentante.codiceFiscaleRappresentante"
                @keyup="toUpperCase"
                @change="toUpperCase"
              ></v-text-field>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4">
              <v-text-field
                label="Email"
                placeholder="mariorossi@gmail.com"
                type="email"
                :rules="validateField.fieldEmail"
                v-model="formLegaleRappresentante.emailRappresentante"
                @keyup="toLowerCase"
                @change="toLowerCase"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-subtitle> <b> Decreto di Nomina </b></v-card-subtitle>
          <v-row>
            <v-col cols="4">
              <v-file-input
                label="Allega"
                v-model="fileNominaLegaleRappresentante"
                accept=".pdf"
                :rules="validateField.fieldRequired"
              >
              </v-file-input>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                v-text="'Sostituisci'"
                :disabled="operazioneInCorso"
                @click="dialog1 = true"
              />
              <v-alert class="mt-5" type="warning" width="400">
                La procedura può richiedere alcuni secondi per via della
                gestione di creazione delle utenze associate al nuovo Legale
                Rappresentante
              </v-alert>
            </v-col>
          </v-row>
          <DialogConferma
            @callback="onSubmitModificaLegaleRappresentante"
            :dialog.sync="dialog1"
            @close-dialog="dialog1 = false"
          />
          <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{ snackbar.text }}</v-snackbar
          >
        </v-form>
      </v-main>
    </v-card>
    <v-card v-else>
      <v-alert type="warning">
        Per procedere all'operazione è necessario selezionare l'ateneo e l'anno
        accademico del programma di orientamento.
      </v-alert>
    </v-card>
  </v-container>
</template>
<script>
import DialogConferma from "@/components/DialogConferma";
import { mapState } from "vuex";
import CodiceFiscale from "codice-fiscale-js";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "modificaLegaleRappresentante",
  components: { DialogConferma },
  computed: {
    ...mapState(["idStrutture", "anno", "nomeStruttura"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
    nomwStruttura() {
      this.initialize();
    },
  },
  props: {},
  data: () => ({
    validForm1: false,
    validForm2: false,
    loadingCard: false,
    legaliRappresentanti: [],
    headersTableLegealiRappresentanti: [
      {
        text: "NOME LEGALE RAPPRESENTANTE",
        align: "start",
        sortable: false,
        value: "nomeRappresentante",
      },
      { text: "COGNOME LEGALE RAPPRESENTANTE", value: "cognomeRappresentante" },
      {
        text: "CODICE FISCALE RAPPRESENTANTE",
        value: "codiceFiscaleRappresentante",
      },
      { text: "EMAIL RAPPRESENTANTE", value: "emailRappresentante" },
      { text: "DECRETO DI NOMINA", value: "fileNomina" },
      { text: "ATTUALE", value: "attivo" },
    ],
    formLegaleRappresentante: {
      nomeRappresentante: null,
      cognomeRappresentante: null,
      codiceFiscaleRappresentante: null,
      emailRappresentante: null,
      nomeStruttura: null,
    },
    fileNominaLegaleRappresentante: null,
    validateField: {
      fieldRequired: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
      ],
      fieldCodiceFiscale: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) =>
          (v && v.length === 16) ||
          "Il codice fiscale deve contenere 16 CARATTERI.",
      ],
      fieldEmail: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) => /.+@.+/.test(v) || "Inserire un indirizzo E-mail valido",
      ],
    },
    dialog1: false,
    snackbar: {
      color: null,
      text: null,
      value: false,
    },
    operazioneInCorso: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture && this.anno) {
        this.operazioneInCorso = false;
        this.getLegaliRappresentantiAteneo();
        this.loadingCard = true;
      } else {
        this.loadingCard = false;
      }
    },
    async getLegaliRappresentantiAteneo() {
      MinisteroRepository.getLegaliRappresentanti(
        parseInt(this.idStrutture)
      ).then((data) => {
        if (data && data.length > 0) {
          this.legaliRappresentanti = data;
        }
      });
    },
    toUpperCase() {
      this.formLegaleRappresentante.codiceFiscaleRappresentante =
        this.formLegaleRappresentante.codiceFiscaleRappresentante.toUpperCase();
    },
    toLowerCase() {
      this.formLegaleRappresentante.emailRappresentante =
        this.formLegaleRappresentante.emailRappresentante.toLowerCase();
    },
    async downloadPDF(item, nomeFile) {
      return MinisteroRepository.downloadPdfNominaLegaleRappresentante(
        item.id,
        nomeFile
      );
    },
    onSubmitModificaLegaleRappresentante() {
      this.operazioneInCorso = true;
      if (this.$refs.formLegale.validate()) {
        this.formLegaleRappresentante.nomeStruttura = this.nomeStruttura;

        let convalidcodiceFiscaleRappresentante = null;
        try {
          // eslint-disable-next-line no-unused-vars
          convalidcodiceFiscaleRappresentante = new CodiceFiscale(
            this.formLegaleRappresentante.codiceFiscaleRappresentante
          );
        } catch (err) {
          this.operazioneInCorso = false;
          return (this.snackbar = {
            value: true,
            text: "Codice fiscale del Legale Rappresentante non valido!",
            color: "red accent-2",
          });
        }

        const formBody = new FormData();
        formBody.append(
          "legaleRappresentante",
          JSON.stringify(this.formLegaleRappresentante)
        );
        formBody.append(
          "documentoDiNomina",
          this.fileNominaLegaleRappresentante
        );
        MinisteroRepository.modificaLegaleRappresentante(
          this.idStrutture,
          this.anno,
          formBody
        )
          .then((data) => {
            if (data) {
              this.snackbar = {
                value: true,
                text: "Rappresentante legale modificato con successo!!",
                color: "success",
              };
              this.$refs.formLegale.reset();
              this.initialize();
            }
          })
          .catch((reason) => {
            this.operazioneInCorso = false;
            if (reason.response.status === 400) {
              this.snackbar = {
                value: true,
                text: reason.response.data[0],
                color: "red accent-2",
              };
            }
            if (reason.response.status === 500) {
              this.snackbar = {
                value: true,
                text: "Un errore è stato riscontrato nella creazione dell'utenza associata al legale rappresentante",
                color: "red accent-2",
              };
            }
          });
      }
    },
    checkLegaleAttivo(programmiLegaleRappresentante) {
      /* filtro i programmi di orientamento associati al legale, dove lui non è più attivo*/
      /* se ci sono programmi di orientamento dove lui non è più attivo,
          vuol dire che è stato sostituito
         viceversa è lui l'ultimo rappresentante legale attivo */
      const programmi = programmiLegaleRappresentante.filter(
        (element) =>
          element.attivo === false && element.data_disattiazione == null
      );
      return programmi.length > 0 ? "NO" : "SI";
    },
  },
};
</script>

<style scoped>
.v-form {
  margin: 0 auto;
}
</style>
