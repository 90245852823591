var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(!_vm.anno)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Attenzione! È necessario selezionare un anno accademico. ")]):_vm._e(),(_vm.anno)?_c('div',[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h1',[_vm._v("Controlli")]),_c('p',[_vm._v(" Sezione in cui è possibile effettuare il controllo sul Decreto di concessione del finanziamento e sui rendiconti di progetto. ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('RiquadroProfiloRuolo')],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"d-flex flex-column align-self-center fill-height",attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clipboard-check-outline")]),_vm._v(" Controllo decreto di concessione al finanziamento ")],1),_c('v-card-text',{staticClass:"d-flex flex-column align-self-center fill-height"},[_vm._v(" Sezione in cui è possibile controllare il decreto di concessione al finanziamento. ")]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{staticClass:"v-btn",attrs:{"to":``}},[_vm._v(" Vai "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"d-flex flex-column align-self-center fill-height",attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clipboard-text-outline")]),_vm._v(" Controllo rendiconto di progetto ")],1),_c('v-card-text',{staticClass:"d-flex flex-column align-self-center fill-height"},[_vm._v(" Sezione in cui è possibile controllare i rendiconti di progetto. ")]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                name: 'ListaVerbaliRendicontiUdm',
                params: { anno: this.anno },
              }}},[_vm._v(" Vai "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"d-flex flex-column align-self-center fill-height",attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clipboard-text-outline")]),_vm._v(" Controllo formale rendiconti di progetto ")],1),_c('v-card-text',{staticClass:"d-flex flex-column align-self-center fill-height"},[_vm._v(" Sezione in cui è possibile controllare formalmente i rendiconti di progetto non campionati. ")]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                //name: 'ListaRendicontiControlloFormaleUdm',
                name: 'ListaVerbaliFormaleUdm',
                params: { anno: this.anno },
              }}},[_vm._v(" Vai "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"d-flex flex-column align-self-center fill-height",attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clipboard-check-outline")]),_vm._v(" Rendiconti assegnati ")],1),_c('v-card-text',{staticClass:"d-flex flex-column align-self-center fill-height"},[_vm._v(" Sezione in cui è possibile controllare i rendiconti di progetto che sono stati assegnati dal Responsabile di Progetto. ")]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{staticClass:"v-btn",attrs:{"to":`/scrivania/udm/controlli/rendiconti-assegnati-udm`}},[_vm._v(" Vai "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[(
            _vm.isRoleResponsabileControllo || _vm.isRoleConsulenza || _vm.isRoleDgUdm
          )?_c('v-card',{staticClass:"d-flex flex-column align-self-center fill-height",attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clipboard-check-outline")]),_vm._v(" Incarichi assegnati ")],1),_c('v-card-text',{staticClass:"d-flex flex-column align-self-center fill-height"},[_vm._v(" Sezione in cui è possibile visualizzare gli incarichi assegnati. ")]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{staticClass:"v-btn",attrs:{"to":`/scrivania/udm/controlli/incarichi-assegnati-udm`}},[_vm._v(" Vai "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }