<template>
  <v-container fluid v-if="anno">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main class="ma-4 pa-4">
      <v-row class="mb-8">
        <v-col cols="8">
          <h1 style="color: #1a237e">Verifica Rendiconto</h1>
          <p>Compila il verbale di esito dei controlli sul rendiconto</p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
      </v-row>
      <step-rendicontazioni
        v-if="!readOnly"
        :numero-step="5"
      ></step-rendicontazioni>
      <v-row class="mb-8">
        <v-col>
          <h2 style="color: #1a237e">Verbale esito controlli</h2>
        </v-col>
      </v-row>
      <v-card-title>
        Anagrafica Amministrazione centrale titolare di interventi
      </v-card-title>
      <anagrafica-rendiconto-progetto :editable="true" />
    </v-main>
    <div v-if="loadinPage">
      <v-main class="ma-4 pa-4">
        <v-card-title class="px-0"> Anagrafica Intervento </v-card-title>
        <anagrafica-intervento
          v-if="rendiconto && costoProgettoAnnuale"
          :editable="true"
          :rendiconto="rendiconto"
          :costo-totale="costoProgettoAnnuale.assegnazioneTotale"
        />
      </v-main>
      <v-main class="ma-4 pa-4">
        <v-row>
          <v-col>
            <v-card-title class="py-4"> Riferimenti verbale </v-card-title>
          </v-col>
        </v-row>
        <riferimenti-verbale v-if="rendiconto" :rendiconto="rendiconto" />
      </v-main>
      <!-- SEZIONE 1 -->
      <v-form ref="form" lazy-validation v-model="validForm">
        <v-main class="ma-4 pa-4">
          <h4 style="color: #1a237e" class="pb-4">
            SEZIONE 1 - RIFERIMENTI NORMATIVI
          </h4>
          <p>
            Le verifiche di cui al presente verbale sono state svolte in
            conformit&agrave; a quanto previsto dall&rsquo;articolo 22 del
            Regolamento (UE) 2021/241 del parlamento europeo e del consiglio del
            12 febbraio 2021 che istituisce il dispositivo per la ripresa e la
            resilienza. L&rsquo;attivit&agrave; &egrave; stata condotta in
            coerenza con la normativa unionale e nazionale applicabile in
            materia e con particolare riferimento, a titolo non esaustivo, alle
            disposizioni di cui:
          </p>
          <ul>
            <li>
              al Regolamento (UE) 2021/241 e al decreto-legge n. 77 del 31
              maggio 2021, come&nbsp; modificato dalla legge 29 luglio 2021, n.
              108;
            </li>
            <li>
              al principio di sana gestione finanziaria secondo quanto
              disciplinato nel Regolamento finanziario (UE, Euratom) 2018/1046 e
              nell’articolo 22 del Regolamento (UE) 2021/241, in particolare in
              materia di prevenzione dei conflitti di interessi, delle frodi,
              della corruzione e di recupero e restituzione dei fondi che sono
              stati indebitamente assegnati;
            </li>
            <li>
              al Regolamento (UE) n. 651/2014 della Commissione, del 17 giugno
              2014, che dichiara alcune categorie di aiuti compatibili con il
              mercato interno in applicazione degli articoli 107 e 108 del
              trattato;
            </li>
            <li>
              agli obblighi in materia di comunicazione e informazione previsti
              dall’articolo 34 del Regolamento (UE) 2021/241;
            </li>
            <li>
              al principio del “Do No Significant Harm (DNSH)” a norma
              dell’articolo 17 del Regolamento (UE) 2020/852 nonché dei principi
              trasversali previsti dal PNRR, quali, tra gli altri, il principio
              del contributo all’obiettivo climatico e digitale (c.d. tagging),
              il principio di parità di genere e l’obbligo di protezione e
              valorizzazione dei giovani, pena la possibilità di sospensione
              oppure di revoca del finanziamento nel caso di accertamento della
              violazione di tali principi generali;
            </li>
            <li>
              alle indicazioni previste dalla legislazione nazionale
              applicabile, ivi comprese quelle previste dal Codice dell'ambiente
              (Decreto Legislativo 3 aprile 2006, n. 152 e ss.mm.ii.), ed
              eventualmente di sottoporre le attività progettuali pertinenti
              agli adempimenti previsti dalla normativa vigente tra le quali la
              Valutazione di impatto ambientale (VIA), l’Autorizzazione
              integrata Ambientale (AIA) e l’Autorizzazione Unica Ambientale
              (AUA);
            </li>
            <li>
              alle disposizioni del decreto del Presidente della Repubblica 5
              febbraio 2018, n.22 in materia di ammissibilità delle spese;
            </li>
            <li>
              al rispetto del principio di parità di genere in relazione agli
              articoli 2, 3, paragrafo 3, del TUE, 8, 10, 19 e 157 del TFUE, e
              21 e 23 della Carta dei diritti fondamentali dell’Unione europea,
              producendo dati relativi ai destinatari effettivi dei progetti
              anche disaggregati per genere;
            </li>
            <li>
              alle disposizioni del decreto legge 9 giugno 2021, n.80 in merito
              al rafforzamento della capacità amministrativa delle pubbliche
              amministrazioni;
            </li>
            <li>
              alla normativa che disciplina i contratti di appalto e di
              concessione delle amministrazioni (d.lgs. n. 50/2016 e
              ss.mm.ii./d.lgs 36/2023);
            </li>
            <li>
              alle disposizioni del decreto legislativo 30 marzo 2001, n. 165 e
              ss.mm.ii. in materia di reclutamento del personale alle dipendenze
              delle Amministrazioni pubbliche;
            </li>
            <li>
              alle disposizioni della Legge 30 dicembre 2010 n.240 e ss.mm.ii.
              in materia di organizzazione delle università, di personale
              accademico e reclutamento, nonché delega al Governo per
              incentivare la qualità e l'efficienza del sistema universitario;
            </li>
            <li>
              alle disposizioni del decreto legislativo 25 novembre 2016 n.218 e
              ss.mm.ii. in materia di semplificazione delle attività degli enti
              pubblici di ricerca ai sensi dell'articolo 13 della legge 7
              agosto;
            </li>
            <li>
              alle disposizioni del decreto legislativo 21 novembre 2007, n. 231
              e ss.mm.ii. in materia di antiriciclaggio;
            </li>
            <li>
              alle disposizioni del decreto legge 24 febbraio 2023, n.13,
              convertito con modificazioni dalla L. 21 aprile 2023, n. 41;
            </li>
            <li>
              alle disposizioni sul Codice Unico di Progetto (CUP) di cui
              all’articolo 11, comma 2-bis, della legge n. 3/2003 così come
              integrato dall'art. 41, comma 1, decreto-legge n. 76/2020,
              convertito, con modificazioni, dalla legge n. 120/2020 e delibera
              CIPE n. 63/2020;
            </li>
            <li>
              alle disposizioni sul Codice identificativo di gara (CIG) di cui
              all’art. 3, comma 5 della legge 13 agosto 2010, n. 136;
            </li>
            <li>
              all’Avviso di riferimento e alle relative FAQ interpretative;
            </li>
            <li>alle Circolari emanate dal MEF in materia;</li>
            <li>
              alle Linee Guida per la rendicontazione adottate dal Ministero e
              destinate ai soggetti attuatori degli interventi finanziati;
            </li>
            <li>alle altre norme applicabili.</li>
          </ul>

          <!-- SEZIONE 2 -->

          <h4 style="color: #1a237e" class="pt-8 pb-4">
            SEZIONE 2 - AMBITO DEL CONTROLLO
          </h4>
          <p>
            Le verifiche hanno riguardato l’esame della documentazione
            amministrativo – contabile di un campione di spese esposte/costi
            maturati e delle relative procedure da cui originano, estratto
            nell’ambito del rendiconto in oggetto presentato dal Soggetto
            Attuatore per un importo complessivo pari a<b>
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  verbaleCampionamentoRendiconto.rendiconto.importoRichiesto
                )
              }}</b
            >
            ripartito come da tabella seguente:
          </p>
          <v-card class="mb-6">
            <v-data-table
              :items="verbaleCampionamentoRendiconto.rendiconto.corsiRendiconto"
              :headers="headerSezione2"
              :items-per-page="5"
            >
              <!--eslint-disable -->
          <template v-slot:item.cup="{ item }">
            {{ verbaleCampionamentoRendiconto.rendiconto.programma.cup }}
          </template>
          <template v-slot:item.soggettoAttuatore="{ item }">
            {{ verbaleCampionamentoRendiconto.rendiconto.programma.nomeIstituzione }}
          </template>
          <template v-slot:item.importoCorso="{ item }">
            {{ new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(item.importoCorso) }}
          </template>
          <!-- eslint-enable-->
            </v-data-table>
          </v-card>
          <p>
            Le spese esposte/costi maturati oggetto di verifica sono state/i
            campionate/i secondo la metodologia indicata nel documento
            “Metodologia di campionamento” adottata dal Ministero. Al riguardo,
            si rappresenta che ai fini della composizione del campione si è
            ritenuto opportuno includere discrezionalmente alcuni Soggetti/spese
            esposte/costi maturati tenuto conto della necessità di:
            <i>(barrare gli elementi valutati)</i>
          </p>
          <v-row>
            <v-col>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato1"
                label="Svolgere specifici follow-up a seguito di precedenti verifiche on desk o in loco"
              ></v-checkbox>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato2"
                label="Necessità di monitorare eventuali azioni correttive o, comunque, di miglioramento"
              ></v-checkbox>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato3"
                label="di dare seguito a segnalazioni pervenute (da organismi di controllo, dai canali di
                  whistleblowing, etc.)"
              ></v-checkbox>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato4"
                label="esigenza di controllare le principali procedure di selezione del personale e dei fornitori"
              ></v-checkbox>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato5"
                label="esigenza di controllare particolari voci di spesa"
              ></v-checkbox>
              <v-checkbox
                :readonly="readOnly"
                v-model="verificaRendiconto.elementoValutato6"
                label="considerare ulteriori elementi, meglio specificati di seguito"
              ></v-checkbox>
              <v-row class="pa-4" v-if="verificaRendiconto.elementoValutato6">
                <v-textarea
                  :readonly="readOnly"
                  v-model="verificaRendiconto.ulterioreElemento"
                  outlined
                  :rules="rules.validazioneTesto"
                  class="mb-0"
                  hint="Massimo 250 caratteri"
                  name="ulterioreElemento"
                  label="Specifica ulteriori elementi *"
                  counter
                ></v-textarea>
              </v-row>
            </v-col>
          </v-row>
        </v-main>
        <v-main class="ma-4 pa-4">
          <v-row>
            <v-col>
              <p>
                La tabella di cui all’allegato 1 riporta il campione di
                operazioni, estratto in base ai requisiti minimi previsti dalla
                metodologia di campionamento e agli ulteriori elementi
                discrezionali sopra indicati, nonché il riferimento alle
                procedure da cui originano le spese/costi maturati, oggetto di
                verifica documentale da parte dell’Unità di controllo
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex flex-row-reverse">
              <p>
                <i
                  >Allegato 1 - Dettaglio delle voci di spesa/corsi maturati
                  estratti nell'ambito del rendiconto oggetto di controllo</i
                >
              </p>
            </v-col>
          </v-row>
          <v-card class="my-2">
            <v-data-table
              :items="certificatiCampionati"
              :headers="headerCertificati"
              :loading="loadingTableAttestati"
              ><!--eslint-disable -->
        <template v-slot:item.cup="{ item }">
          <v-card-text
              v-html="rendiconto.programma.cup"
          />
        </template>
        <template v-slot:item.soggettoAttuatore="{ item }">
          <v-card-text v-html="rendiconto.programma.nomeIstituzione" />
        </template>
        <template v-slot:item.valoreAttribuito="{ item }">
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(item.certificato.valoreAttribuito)
          }}        </template>
          <!-- eslint-enable-->
            </v-data-table>
          </v-card>

          <!-- SEZIONE 3 -->

          <h4 style="color: #1a237e" class="pt-8 pb-4">
            SEZIONE 3 - MODALITÀ DI SVOLGIMENTO
          </h4>
          <p>
            Le verifiche sono state condotte allo scopo di garantire la
            correttezza, la regolarità e la legittimità dell’esecuzione degli
            interventi, sotto l’aspetto amministrativo, contabile e finanziario,
            nonché la coerenza con la normativa comunitaria e nazionale
            applicabile e con le previsioni dell’Avviso di riferimento e dei
            documenti che regolano il rapporto fra il Ministero e il Soggetto
            Attuatore (Atto d’obbligo, FAQ, Linee guide operative, ecc).
          </p>
          <p>
            In particolare, attraverso un’analisi “on desk” della documentazione
            trasmessa dal Soggetto attuatore, attraverso il sistema informatico
            implementato per la specifica Misura, è stata accertata:
          </p>
          <ul>
            <li>
              la presenza dell’attestazione del Soggetto Attuatore circa le
              verifiche svolte propedeuticamente alla trasmissione del
              rendiconto di progetto;
            </li>
            <li>
              la sussistenza della documentazione amministrativa relativa alla
              rendicontazione di progetto dell’investimento, che dimostra il suo
              corretto finanziamento a valere sul PNRR (relativa missione,
              componente, misura, investimento e/o riforma);
            </li>
            <li>
              la completezza e la correttezza formale della rendicontazione di
              progetto e della documentazione giustificativa a supporto
              dell’effettivo sostenimento della spesa (fatture quietanzate o
              documentazione contabile equivalente) ai sensi di quanto disposto
              dalla normativa di riferimento nazionale e dell’Unione, dalla
              Misura/Investimento del PNRR, dal bando di gara/avviso/atto di
              affidamento, dalle specifiche linee guida per la rendicontazione;
            </li>
            <li>
              la riferibilità della spesa sostenuta e rendicontata dal Soggetto
              attuatore al progetto/investimento oggetto di contributo (CUP,
              CIG, titolo intervento etc.);
            </li>
            <li>
              la conformità alle pertinenti norme in materia di ammissibilità
              delle voci di spesa in riferimento alle tipologie, al periodo di
              ammissibilità e ai limiti di spesa ammesse dalla normativa di
              riferimento nazionale e dell’Unione, dal PNRR, dal bando di
              gara/avviso/atto di affidamento, dal contratto/convenzione e da
              sue eventuali varianti/adeguamenti;
            </li>
            <li>
              la conformità alla normativa sulla tracciabilità finanziaria ex
              L.136/2010 in relazione alle spese sostenute;
            </li>
            <li>
              l’assenza del doppio finanziamento tramite le funzionalità delle
              piattaforme disponibili e attraverso l’accertamento nei
              giustificativi di spesa della dicitura (o del timbro di
              annullamento nel caso di fatturazione non elettronica) indicante
              il PNRR, il titolo ed il codice della misura/investimento, il CUP
              e il CIG ove pertinente;
            </li>
            <li>
              la conformità della spesa, ove pertinente, con le norme sugli
              aiuti di Stato, con le norme ambientali e con quelle sulle pari
              opportunità e la non discriminazione;
            </li>
            <li>
              la coerenza, ove applicabile, delle procedure di
              selezione/reclutamento e stipula di contratti con la normativa
              vigente in materia (Dlgs 165/2001, codice dei contratti pubblici,
              L.240/2010, DL 80/2021, ecc.);
            </li>
            <li>
              la rilevazione delle “titolarità effettive” dei destinatari finali
              dei fondi del PNRR in adempimento all’art. 22 del Reg. (UE)
              2021/241, paragrafo 2, lettera d) tramite le funzionalità delle
              piattaforme disponibili;
            </li>
          </ul>
          <p>
            e inoltre:<br />
            In caso di rendicontazione a “costi reali”:
          </p>
          <ul>
            <li>
              la correttezza della documentazione giustificativa di spesa dal
              punto di vista civilistico e fiscale;
            </li>
            <li>
              la congruità della spesa, ovvero che i costi siano commisurati
              alla dimensione economica dell’intervento (coerenza con il quadro
              economico di progetto);
            </li>
            <li>
              l’adozione in coerenza con il Decreto interministeriale n. 116 del
              24/01/2018 per quanto riguarda il personale, e con la specifica
              metodologia e linee guida applicabili alla Misura di riferimento;
            </li>
          </ul>
          <p>
            ovvero, in caso di impiego di Opzioni di Semplificazione dei Costi
            (OSC):
          </p>
          <ul>
            <li>
              la corretta applicazione della metodologia di determinazione delle
              opzioni semplificata di costo adottata;
            </li>
            <li>
              la completezza e la correttezza formale della rendicontazione di
              progetto e della documentazione giustificativa a supporto
              dell’effettiva realizzazione delle attività svolte (report
              attività svolte, output prodotti, ecc) ai sensi di quanto disposto
              dalla normativa di riferimento nazionale e dell’Unione, dalla
              Misura/Investimento del PNRR, dal bando di gara/avviso/atto di
              affidamento, dalle specifiche linee guida per la rendicontazione.
            </li>
          </ul>

          <p>In caso di saldo è stata, inoltre, accertata:</p>
          <ul>
            <li>
              la conclusione del progetto entro i termini previsti
              dall’Avviso/Decreto di concessione/atti sottoscritti dal Soggetto
              Attuatore o da successive autorizzazioni di proroghe;
            </li>
            <li>
              la sussistenza della documentazione attestante la conclusione
              delle attività progettuali prevista dall’Avviso di
              riferimento/Decreto di concessione/Atto d’obbligo, dalle
              specifiche Linee Guida per la rendicontazione e da altri
              atti/provvedimenti emanati dall’Ufficio di gestione;
            </li>
            <li>
              la positiva valutazione dell’Esperto Tecnico Scientifico o di
              altro organo competente in materia scientifica (ad es. ANVUR), ove
              pertinente, in merito alla conclusione e al conseguimento degli
              obiettivi finali del progetto;
            </li>
            <li>
              la coerenza dell’importo complessivamente rendicontato e ritenuto
              ammissibile con il quadro economico del progetto approvato e nei
              limiti dell’importo finanziato.
            </li>
            <li>
              l’esistenza di un sistema di contabilità separata o una
              codificazione contabile adeguata per tutte le transazioni relative
              al progetto;
            </li>
            <li>
              l’applicazione dell’articolo 34 del Reg. (UE) 2021/241 in materia
              di informazione e pubblicità.
            </li>
          </ul>

          <!-- SEZIONE 4 -->

          <h4 style="color: #1a237e" class="pt-8 pb-4">
            SEZIONE 4 - ESITO DEL CONTROLLO
          </h4>
          <p>
            In esito alla verifica documentale, l’incaricato della verifica
            <b>
              {{
                verbaleCampionamentoRendiconto.rendiconto.assegnazioneControllo
                  .incaricato.anagrafica.nome
              }}
              {{
                verbaleCampionamentoRendiconto.rendiconto.assegnazioneControllo
                  .incaricato.anagrafica.cognome
              }} </b
            >, nel dichiarare l’assenza di qualsiasi forma di conflitto di
            interesse sulle attività espletate, esprime in relazione al
            rendiconto in oggetto il seguente giudizio:
          </p>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="verificaRendiconto.esitoControllo"
                label="Esito del controllo"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex flex-row-reverse">
              <p>
                <i
                  >Allegato 2 - Riepilogo dei costi maturati oggetto del
                  controllo ritenuti NON ammissibili</i
                >
              </p>
            </v-col>
          </v-row>

          <v-card
            v-if="certificatiNonAmmessi && certificatiNonAmmessi.length > 0"
            class="my-2"
          >
            <v-data-table
              :items="certificatiNonAmmessi"
              :headers="headerCertificatiNonAmmessi"
              :loading="loadingTableAttestati"
              no-data-text="Nessun attestato NON ammissibile"
              ><!--eslint-disable -->
              <template v-slot:item.cup="{ item }">
                <v-card-text
                    v-html="rendiconto.programma.cup"
                />
              </template>
              <template v-slot:item.soggettoAttuatore="{ item }">
                <v-card-text v-html="rendiconto.programma.nomeIstituzione" />
              </template>
              <template v-slot:item.importoAmmesso="{ item }">
                {{
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(item.importoAmmesso)
                }}
              </template>
              <template v-slot:item.valoreAttribuito="{ item }">
                {{
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(item.certificato.valoreAttribuito)
                }}
              </template>

              <template v-slot:item.importoNonAmmesso="{ item }">
                {{ getImportoNonAmmesso(item.certificato.valoreAttribuito, item.importoAmmesso) }}
              </template>
              <!-- eslint-enable-->
            </v-data-table>
          </v-card>
          <v-card v-else class="mt-4 mb-6">
            <v-card-text class="text-center">
              Non ci sono attestati che risultano essere NON
              ammissibili</v-card-text
            >
          </v-card>

          <v-row
            v-if="certificatiNonAmmessi && certificatiNonAmmessi.length > 0"
          >
            <v-col>
              <p>
                rimandando alla tabella di cui all’allegato 2 al presente
                verbale per il dettaglio delle spese/costi maturati oggetto del
                controllo ritenute/i NON ammissibili, con indicazione della
                relativa motivazione.
              </p>
              <p>
                In ordine a quanto sopra esposto, si rappresenta quanto segue:
              </p>
              <v-row class="pa-4">
                <v-textarea
                  v-model="verificaRendiconto.motivazioni"
                  outlined
                  :rules="rules.validazioneTesto"
                  class="mb-0"
                  hint="Massimo 250 caratteri"
                  name="motivazioni"
                  label="Motivazione *"
                  counter
                ></v-textarea>
              </v-row>
            </v-col>
          </v-row>

          <!-- SEZIONE 5 -->

          <h4 style="color: #1a237e" class="pt-8 pb-4">
            SEZIONE 5 - CONSIDERAZIONI FINALI
          </h4>
          <p>
            Sulla base delle valutazioni espresse, si riconosce l’importo
            complessivo pari a
            <b>
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  verbaleCampionamentoRendiconto.rendiconto.verificaRendiconto
                    .importoAmmissibile
                )
              }}</b
            >, (comprensivo dell’eventuale quota privata e dell’IVA) e in merito
            all’importo di
            <b>{{
              verificaRendiconto.importoNonAmmissibile
                ? new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    verbaleCampionamentoRendiconto.rendiconto.verificaRendiconto
                      .importoNonAmmissibile
                  )
                : " 0 € "
            }}</b>
            ritenuto non ammissibile, si chiede al Soggetto Attuatore di
            presentare eventuali controdeduzioni, corredate da documentazione
            integrativa, avverso le risultanze della verifica entro e non oltre
            <b>10</b> giorni dal ricevimento del presente verbale.
          </p>
        </v-main>
        <v-main>
          <v-row>
            <v-col cols="2">
              <TornaIndietroComponent text="Indietro" />
            </v-col>
            <v-col cols="8"></v-col>
            <v-col v-if="!readOnly" cols="2">
              <v-btn
                class="primary"
                :disabled="
                  certificatiNonAmmessi.length > 0 &&
                  !verificaRendiconto.motivazioni
                "
                @click="dialogSalva = true"
                text
              >
                Firma verbale
              </v-btn>
            </v-col>
            <v-col v-else cols="2">
              <v-btn
                class="primary"
                v-html="'Scarica il File in PDF'"
                @click="downloadVerbaleEsito()"
              />
            </v-col>
          </v-row>
          <dialog-conferma
            title="Salvataggio verbale"
            @callback="salvaVerbale"
            :dialog.sync="dialogSalva"
            @close-dialog="dialogSalva = false"
          />
          <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            shaped
            :color="snackbarColor"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-main>
      </v-form>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
  <v-container v-else>
    <alert-component
      class="text-center"
      testo="E' necessaria la selezione dell'anno accademico!"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import StepRendicontazioni from "@/components/Controlli/VerificheRendiconti/StepRendicontazioni.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import AnagraficaRendicontoProgetto from "@/components/Controlli/VerificheRendiconti/AnagraficaRendicontoProgettoComponent.vue";
import AnagraficaIntervento from "@/components/Controlli/VerificheRendiconti/AnagraficaInterventoComponent.vue";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import RiferimentiVerbale from "@/components/Controlli/VerificheRendiconti/RiferimentiVerbaleComponent";
import DialogConferma from "@/components/DialogConferma.vue";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "verificaVerbaleEsitoControlliRendiconto",
  components: {
    RiferimentiVerbale,
    StepRendicontazioni,
    AlertComponent,
    BreadcrumbComponent,
    RiquadroProfiloRuolo,
    AnagraficaRendicontoProgetto,
    AnagraficaIntervento,
    TornaIndietroComponent,
    DialogConferma,
  },
  computed: {
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista verbali di campionamento",
            disabled: false,
            to: `/scrivania/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Verifica Rendiconto",
            disabled: false,
            to: `/scrivania/controlli/verbali-campionamento-rendiconti/${this.idRendiconto}/verifiche`,
            exact: true,
            link: true,
          },
        ];
      }
    },
  },
  mounted() {
    this.readOnly = this.$route.query.readOnly;
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  data: () => ({
    readOnly: false,
    loadinPage: false,
    verbaleCampionamentoRendiconto: null,
    rendiconto: null,
    verificaRendiconto: null,
    corsiCampionati: [],
    certificatiNonAmmessi: [],
    certificatiCampionati: [],
    idRendiconto: null,
    validForm: false,
    loadingTableAttestati: true,
    rules: {
      validazioneTesto: [
        (v) =>
          (v && v.length < 250) ||
          "Il campo è obbligatorio e non può superare i 250 caratteri",
      ],
      validazioneCampoNecessario: [(v) => !!v || "Campo richiesto"],
    },
    headerSezione2: [
      {
        text: "CUP",
        value: "cup",
      },
      {
        text: "Soggetto attuatore",
        value: "soggettoAttuatore",
      },
      {
        text: "Importo rendicontato",
        value: "importoCorso",
      },
    ],
    headerCertificati: [
      {
        text: "CUP",
        value: "cup",
      },
      {
        text: "Soggetto attuatore",
        value: "soggettoAttuatore",
      },
      {
        text: "ID Corso",
        value: "corsoCampionato.id",
      },
      {
        text: "ID Alunno",
        value: "certificato.studente.id",
      },
      {
        text: "Importo richiesto",
        align: "center",
        value: "valoreAttribuito",
      },
      //{ text: "Importo Ammesso", value: "certificato.importoAmmesso" },
    ],
    headerCertificatiNonAmmessi: [
      {
        text: "CUP",
        value: "cup",
      },
      {
        text: "Soggetto attuatore",
        value: "soggettoAttuatore",
      },
      {
        text: "ID Corso",
        value: "corsoCampionato.id",
      },
      {
        text: "ID Alunno",
        value: "certificato.studente.id",
      },
      {
        text: "Importo richiesto",
        align: "center",
        value: "valoreAttribuito",
      },
      {
        text: "Importo ammesso",
        align: "center",
        value: "importoAmmesso",
      },
      {
        text: "Importo NON ammesso",
        align: "center",
        value: "importoNonAmmesso",
      },
      {
        text: "Motivazione",
        align: "center",
        value: "motivazione",
      },
      //{ text: "Importo Ammesso", value: "certificato.importoAmmesso" },
    ],
    dialogVerificaAttestato: false,
    attestatoInVerifica: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    costoProgettoAnnuale: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto && this.anno) {
        this.idRendiconto = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliRepository.getSingoloVerbaleCampionamentoRendiconto(
          parseInt(this.anno),
          this.idRendiconto,
          5
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        await this.getCostoTotaleProgetto();
        this.verificaRendiconto =
          this.verbaleCampionamentoRendiconto.rendiconto.verificaRendiconto;
        this.corsiCampionati =
          this.verbaleCampionamentoRendiconto.corsiCampionati;
        this.certificatiCampionati = this.getAttestatiCampionati(
          this.corsiCampionati
        );
        let certificati = [...this.certificatiCampionati];
        this.certificatiNonAmmessi = certificati.filter(
          (item) => item.tipologiaEsitoVerificaCertificato.id !== 1
        );
        this.loadinPage = true;
      }
    },
    getAttestatiCampionati(corsiCampionati) {
      let attestatiCampionati = [];
      corsiCampionati.forEach((element) => {
        const arrCertificatiCampionatiCorso = element.certificatiCampionati;
        arrCertificatiCampionatiCorso.forEach((element1) => {
          element1.corsoCampionato = element.corsoOrientamento;
          attestatiCampionati.push(element1);
        });
      });
      this.loadingTableAttestati = false;
      return attestatiCampionati;
    },
    async salvaVerbale() {
      if (this.validForm) {
        let verifica = { ...this.verificaRendiconto };
        const formData = new FormData();
        formData.append("formVerificaRendiconto", JSON.stringify(verifica));
        try {
          await ControlliRepository.updateVerificaRendiconto(
            this.anno,
            this.verificaRendiconto.id,
            false,
            formData
          );
          await this.$router.push({
            name: "firmaVerbaleEsito",
            params: {
              anno: this.anno,
              idRendiconto: this.idRendiconto,
            },
          });
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    getImportoNonAmmesso(importoRichiesto, importoAmmesso) {
      let importo = parseFloat(importoRichiesto) - parseFloat(importoAmmesso);
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(importo);
    },
    goToNextStep() {
      this.$router.push({
        name: "",
        params: { idRendiconto: this.idRendiconto },
      });
    },
    async getCostoTotaleProgetto() {
      const idStrutture = this.rendiconto.idStrutture;
      const anno = this.rendiconto.anno;
      const capofila = this.rendiconto.capofila;

      this.costoProgettoAnnuale =
        await MinisteroRepository.getCostoAnnualeProgettoIstituzione(
          idStrutture,
          anno,
          capofila
        );
    },
    async downloadVerbaleEsito() {
      const idStrutture = this.rendiconto.idStrutture;
      await ControlliRepository.getFileEistoVerifica(
        idStrutture,
        this.verificaRendiconto.id,
        this.verificaRendiconto.fileVerificaRendiconto.nomeOriginale
      );
    },
  },
};
</script>
