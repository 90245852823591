<template>
  <v-card v-if="!lezioni || !lezioni.length">
    <v-card-text> <h3>Non è stata registrata alcuna lezione.</h3></v-card-text>
  </v-card>
  <v-card class="pb-2" v-else>
    <v-main class="pb-0">
      <v-row class="px-3 py-6">
        <v-col sm="3" md="3">
          <a class="v-btn" @click="getFileRiepilogoLezioni(0)">
            <v-icon> mdi-file-excel</v-icon>
            <span> Esporta su Excel</span>
          </a>
        </v-col>
        <v-col sm="3" md="3">
          <a class="v-btn" @click="getFileRiepilogoLezioni(1)">
            <span>
              <v-icon> mdi-file-pdf-box </v-icon>
              Esporta su PDF
            </span></a
          >
        </v-col>
      </v-row>
    </v-main>
    <v-main class="pb-0" v-if="lezioniCorso && lezioniCorso.length > 0">
      <v-data-table
        :loading="loadingPage"
        loading-text="Caricamento elenco lezioni"
        :headers="tableLezioni"
        :items="lezioniCorso"
      >
        <!-- eslint-disable -->
        <template v-slot:item.data="{ item }">
          <span> {{convertData(item.data)}}</span>
        </template>
        <template v-slot:item.inPresenza="{ item }">
          <span> {{ item.inPresenza ? 'Si' : 'No'}}</span>
        </template>
        <template v-slot:item.docenti="{ item }">
          <span v-html="getElencoDocenti(item.docenti)"></span>
        </template>
        <template v-slot:item.azioni="{ item, index }">
          <v-dialog v-model="dialog[index]">
            <template v-slot:activator="{ on, attrs }">
              <a
                  v-bind="attrs"
                  v-on="on"
                  @click.self="showDialog(index, true)"
              >
                Visualizza Riepilogo
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Dettaglio Studenti
              </v-card-title>
              <v-data-table :headers="tableStudenti" :items="item.lezioniStudenti" item-key="cf">
                <template v-slot:item.nomeCognome="{ item }">
                  <span> {{ item.studente.nome }}  {{item.studente.cognome}}</span>
                </template>
                <template v-slot:item.studente.dataNascita="{ item }">
                  <span> {{ convertData(item.studente.dataNascita) }}</span>
                </template>
                <template v-slot:item.istituto="{ item }">
                  <span v-if="corso.anno === 2022" v-html="corso.accordo.istituto.denominazioneScuola" />
                  <span v-else v-html="getIstitutoStudente(item.studente)" />
                </template>
              </v-data-table>
              <v-card-actions>
                <v-btn @click="showDialog(index, false)" class="primary" width="300"> CHIUDI </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </v-main>
  </v-card>
</template>

<script>
import CorsiRepository from "@/api/CorsiRepository";
import { mapState } from "vuex";

export default {
  name: "elencoLezioniComponent",
  components: {},
  props: {
    lezioni: {
      type: Array,
      required: true,
    },
    corso: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["anno"]),
    loading: function () {
      return this.loadingPage;
    },
  },
  watch: {
    idCorso() {
      return this.initialize();
    },
    capofila() {
      return this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    lezioniCorso: null,
    loadingPage: false,
    dialog: [],
    errori: false,
    tableLezioni: [
      {
        text: "DATA LEZIONE",
        align: "start",
        value: "data",
        sortable: false,
      },
      { text: "ORE EROGATE", value: "oreErogate" },
      { text: "LEZIONE IN PRESENZA", value: "inPresenza" },
      { text: "DOCENTI ATENEO / ISTITUTO-AFAM", value: "docenti" },
      { text: "AZIONI", value: "azioni" },
    ],
    tableStudenti: [
      {
        text: "NOME E COGNOME",
        align: "start",
        value: "nomeCognome",
        sortable: false,
      },
      { text: "DATA DI NASCITA", value: "studente.dataNascita" },
      { text: "LUOGO DI NASCITA", value: "studente.comuneNascitaDescr" },
      { text: "COMUNE DI RESIDENZA", value: "studente.comuneResidenzaDescr" },
      { text: "CODICE FISCALE", value: "studente.cf" },
      { text: "GENERE", value: "studente.genere.descrizione" },
      { text: "ISTITUTO", value: "istituto" },
      { text: "ORE SVOLTE", value: "oreSvolte" },
    ],
  }),
  methods: {
    initialize() {
      if (this.lezioni) {
        this.lezioniCorso = this.lezioni.map((element, index) => {
          this.dialog[index] = false;
          return element;
        });
      }
    },
    convertData: function (data) {
      return new Date(data).toLocaleDateString();
    },
    getElencoDocenti(docenti) {
      if (docenti && docenti.length > 0) {
        let textDocenti = "";
        docenti.forEach(function (item, index) {
          let stringDocente = item.nome + " " + item.cognome;
          index !== docenti.length - 1
            ? (stringDocente += ", ")
            : (stringDocente += ".");
          textDocenti += stringDocente;
        });
        return textDocenti;
      } else {
        return "Nessun docente segnato in questa lezione";
      }
    },
    showDialog(index, value) {
      this.$set(this.dialog, index, value);
    },
    async getFileRiepilogoLezioni(tipoFile) {
      let idStrutture = this.corso.idStrutture;
      let idCorso = this.corso.id;
      let lezioniCorso = this.lezioniCorso.map((item) => {
        let lezione = item;
        delete lezione.corso;
        return lezione;
      });
      lezioniCorso = { ...this.lezioniCorso };
      const formData = new FormData();
      formData.append("lezioni", JSON.stringify(lezioniCorso));
      await CorsiRepository.downloadFileRiepilogoLezioni(
        idStrutture,
        idCorso,
        tipoFile,
        formData
      );
    },
    getIstitutoStudente(datiStudente) {
      // se lo studnete è associato a più corsi,
      // filtro l'array dei corsi associati allo studente,
      // recuperando l'istituto scolastico associato al corso di cui sto vedendo il dettaglio
      let corsiStudente = datiStudente.corsiStudente.length
        ? datiStudente.corsiStudente.filter((element) => {
            return element.id_corso === this.corso.id;
          })
        : "";
      return corsiStudente.length
        ? corsiStudente[0].istituto.denominazioneScuola
        : "--";
    },
  },
};
</script>
