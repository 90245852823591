<template>
  <v-list nav dense>
    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.routerUrl"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-list>
</template>

<script>
export default {
  name: "NavigationDrawer",
  data() {
    return {
      items: [
        { icon: "mdi-home", title: "Home", routerUrl: "/" },
        {
          icon: "mdi-target",
          title: "Target",
          routerUrl: "/target",
        },
        {
          icon: "mdi-account-convert",
          title: "Legali Rappresentanti",
          routerUrl: "/legali-rappresentanti",
        },
        /*{
          icon: "mdi-account-convert",
          title: "IBAN-Conti tesoreria",
          routerUrl: "/conti-bancari-istituti",
        },*/
        {
          icon: "mdi-desk",
          title: "Scrivania",
          routerUrl: "/scrivania",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
