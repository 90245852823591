<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row>
      <v-col cols="9">
        <h1 style="padding-top: 25px">Lista Rendiconti</h1>
        <p>
          L'utente potrà selezionare la popolazione da campionare attraverso
          apposita checkbox o inserendo la percentuale di rendiconti che il
          sistema selezionerà casualmente. Definita la popolazione, cliccando
          sul tasto estrai campione il sistema campionerà i corsi e gli
          attestati all'interno dei rendiconti da controllare applicando la
          metodologia di campionamento.
        </p>
      </v-col>
      <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="pb-0">
      <v-col class="pb-0">
        <p>Percentuale di rendiconti da selezionare (da 1% a 100%)</p>
      </v-col>
    </v-row>
    <v-row class="pt-0">
      <v-col cols="2" class="pt-0">
        <v-text-field
          v-model="percentuale"
          type="number"
          label="Inserisci percentuale"
          :min="1"
          :max="100"
          :rules="rules.percentuale"
          @change="calcolaRendiconti(percentuale)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="10" class="pt-0"></v-col>
    </v-row>
    <!-- <v-col sm="3" md="3">
      <a class="v-btn" @click="downloadExcel()">
        <v-icon> mdi-file-excel</v-icon>
        <span> Esporta Excel</span>
      </a>
      <a class="v-btn" @click="downloadPdf()">
        <v-icon> mdi-file-pdf-box</v-icon>
        <span> Esporta PDF</span>
      </a>
    </v-col> -->
    <div v-if="loadingRendiconti">
      <v-data-table
        :headers="headers"
        :items="rendiconti"
        :items-per-page="10"
        :single-select="false"
        :show-select="true"
        selectable-key="isSelezionabile"
        v-model="rendicontiSelezionati"
        class="elevation-1 pt-8"
        :loading="!loadingRendiconti"
        loading-text="Caricamento dati in corso..."
        no-data-text="Nessun rendiconto disponibile"
        ><!-- eslint-disable -->
        <template v-slot:item.cup="{ item }">
          <span>{{ item.programma.cup }}</span>
        </template>
        <template v-slot:item.nomeIstituzione="{ item }">
          <span>{{ item.programma.nomeIstituzione }}</span>
        </template>
        <template v-slot:item.quadrimestre="{ item }">
          {{ item.quadrimestre.tipologiaQuadrimestre.descrizione }}
        </template>
        <template v-slot:item.dataTrasmissione="{ item }">
          <span>{{ item.dataTrasmissione | formatDate }}</span>
        </template>
        <template v-slot:item.importoRichiesto="{ item }">
          <span>{{ item.importoRichiesto | formatNumber }} €</span>
        </template>
        <template v-slot:item.importoAmmessoDg="{ item }">
          <span>{{ item.verificaRendiconto.importoAmmissibile | formatNumber }} €</span>
        </template>
        <template v-slot:item.esteso="{ item }">
          <span>{{ item.verificaRendiconto.verbaleEstensione.length ? 'Si' : 'No' }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="v-icon"
                  v-bind="attrs"
                  v-on="on"
                  :icon="true"
              >
                <v-icon> mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  class="item-menu"
                  v-for="(itemMenu, i) in menuAction"
                  :key="i"
              >
                <router-link
                    class="v-btn"
                    :to="{
                          name: itemMenu.nameComponent,
                          params: {
                                // capofila: capofila,
                                idRendiconto: Number(item.id),
                                idStrutture: Number(item.idStrutture),
                              },
                            }"
                >
                  {{ itemMenu.text }}
                  <v-icon> {{ itemMenu.icon }}</v-icon>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </template><!-- eslint-enable -->
      </v-data-table>
      <div v-if="percentuale">
        <v-row class="mt-4">
          <v-col>
            <span> Totale importi rendiconti: </span>
          </v-col>
          <v-col>
            <span> Totale importi rendiconti selezionati: </span>
          </v-col>
          <v-col>
            <span> Calcolo della percentuale inserita su totale importi: </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip color="blue" outlined>
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(totaleImportiRendiconti)
              }}
            </v-chip>
          </v-col>
          <v-col>
            <v-chip color="blue" outlined>
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(totaleImportiRendicontiSelezionati)
              }}
            </v-chip>
          </v-col>
          <v-col>
            <v-chip color="blue" outlined>
              {{
                new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(calcoloPercentuale)
              }}
            </v-chip>
          </v-col>
        </v-row>
      </div>
      <v-row class="pt-8">
        <v-col>
          <span> Descrizione valutazione *</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> mdi-information </v-icon>
              </v-btn>
            </template>
            <span>
              Descrivere le valutazioni che hanno condotto alla scelta
              discrezionale degli elementi che compongono la popolazione oggetto
              di controllo</span
            >
          </v-tooltip>
          <v-textarea
            outlined
            v-model="descrizioneValutazione"
            :rules="rules.validazioneTesto"
            class="mb-0"
            hint="Massimo 250 caratteri"
            name="valutazioneComposizione"
            counter
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="pt-8" v-if="rendiconti && rendiconti.length">
        <v-row>
          <v-col cols="6">
            <alert-component
              testo="La procedura di generazione potrebbe richiedere alcuni secondi"
              type="warning"
            />
          </v-col>
          <v-col cols="6" class="d-flex flex-row-reverse">
            <v-btn
              v-if="
                (percentuale && percentuale >= 1 && percentuale <= 100) ||
                rendicontiSelezionati.length > 0
              "
              @click="estraiCampione"
              :disabled="
                disabilitazionePulsanteEstraiCampione ||
                (!descrizioneValutazione && !percentuale)
              "
              color="primary"
              class="float-end"
              v-html="'Estrai Campione'"
            />
            <v-btn
              v-else
              :disabled="true"
              color="primary"
              class="float-end"
              v-html="'Estrai Campione'"
            />
            <v-snackbar v-model="snackbar.value" :color="snackbar.color">
              {{ snackbar.testo }}
            </v-snackbar>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <alert-component
          testo="Impossibile generare il campione. Tutti i rendiconti sono stati associati a un verbale e sono in fase di verifica."
          :type="'info'"
        />
      </div>
    </div>
    <div v-else>
      <v-progress-linear indeterminate color="blue" />
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapGetters, mapState } from "vuex";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import controlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "nuovoVerbaleControlloUdm",
  components: { AlertComponent, BreadcrumbComponent, RiquadroProfiloRuolo },
  computed: {
    loading: function () {
      return this.loadingRendiconti;
    },
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Lista Rendiconti",
            disabled: false,
            to: `/scrivania/udm/controlli/lista-verbali`,
            exact: true,
            link: true,
          },
          {
            text: "Nuovo controllo",
            disabled: true,
            to: `/scrivania/controlli/lista-rendiconti/nuovo-controllo`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno", "nomeStruttura"]),
    ...mapGetters([]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    nomeStruttura() {
      this.initialize();
    },
  },
  data: () => ({
    totaleImportiRendiconti: 0,
    totaleImportiRendicontiSelezionati: 0,
    calcoloPercentuale: 0,
    infoCampionamento: {},
    descrizioneValutazione: null,
    rendicontiSelezionati: [],
    percentuale: null,
    loadingRendiconti: false,
    loadingPage: false,
    rendiconti: [],
    rendicontiFiltrato: [],
    datiExcel: {
      cup: null,
      //nomeIstituzione: null,
      quadrimestre: null,
      dataTrasmissione: null,
      importoRichiesto: null,
    },
    headers: [
      {
        text: "ID RENDICONTO",
        align: "start",
        value: "id",
        sortable: true,
      },
      { text: "CUP", value: "cup", sortable: true },
      { text: "UNIVERSITÀ/AFAM", value: "nomeIstituzione", sortable: true },
      { text: "QUADRIMESTRE", value: "quadrimestre", sortable: true },
      {
        text: "DATA TRASMISSIONE RENDICONTO",
        value: "dataTrasmissione",
        sortable: true,
      },
      { text: "IMPORTO RICHIESTO", value: "importoRichiesto", sortable: true },
      {
        text: "IMPORTO AMMESSO DG",
        value: "importoAmmessoDg",
        sortable: true,
      },
      {
        text: "STATO",
        value: "verificaRendiconto.esitoControllo",
        sortable: true,
      },
      {
        text: "CAMPIONE ESTESO",
        align: "center",
        value: "esteso",
        sortable: true,
      },
      { text: "AZIONI", value: "actions", sortable: false },
    ],
    menuAction: [
      {
        text: "Dettaglio rendiconto",
        ambitoOperazione: "dettaglioRendiconto",
        icon: null,
        nameComponent: "dettaglioRendiconto",
      },
    ],
    disabilitazionePulsanteEstraiCampione: false,
    snackbar: {
      testo: "",
      colorL: "",
      value: false,
    },
    rules: {
      percentuale: [
        (v) => !v || (v >= 1 && v <= 100) || "Inserisci un numero tra 1 e 100",
      ],
      validazioneTesto: [
        (v) =>
          (v && v.length < 250) ||
          "Il campo è obbligatorio e non può superare i 250 caratteri",
      ],
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getRendiconti();
      }
    },
    async getRendiconti() {
      this.loadingRendiconti = false;
      this.rendiconti =
        await ControlliUdmRepository.getListaRendicontiControllati(this.anno);
      this.loadingRendiconti = true;
    },
    calcolaRendiconti(percentuale) {
      if (percentuale) {
        let rendiconti = this.rendiconti;
        let sommaImporti = rendiconti.reduce(function (acc, rendiconto) {
          return acc + rendiconto.importoAmmesso;
        }, 0);

        let percentualeSomma = (percentuale / 100) * sommaImporti;
        console.log(
          "somma:" + sommaImporti + " | percentuale:" + percentualeSomma
        );
        // Clonazione dell'array rendiconti
        var arrayClonato = rendiconti.slice();

        // Shuffle dell'array (per ottenere ordine casuale)
        arrayClonato.sort(function () {
          return 0.5 - Math.random();
        });

        var somma = 0;
        var oggettiCasuali = [];
        /* //selezione dei rendiconti casuali che non superano la percentualeSomma
         for (var i = 0; i < arrayClonato.length; i++) {
           if (somma + arrayClonato[i].importoAmmesso <= percentualeSomma) {
             oggettiCasuali.push(arrayClonato[i]);
             somma += arrayClonato[i].importoAmmesso;
           }
         }*/
        // Selezione dei rendiconti casuali che superano la percentualeSomma
        var i = 0;
        while (i < arrayClonato.length) {
          if (somma >= percentualeSomma) {
            break;
          }
          oggettiCasuali.push(arrayClonato[i]);
          somma += arrayClonato[i].importoAmmesso;
          i++;
        }
        console.log(somma);
        this.totaleImportiRendiconti = sommaImporti;
        this.totaleImportiRendicontiSelezionati = somma;
        this.calcoloPercentuale = percentualeSomma;
        //copio i rendicontiCalcolati in rendicontiSelezionati visibili in dataTable
        this.rendicontiSelezionati = [...oggettiCasuali];
        //Setto tutti i rendiconti a NON selezionabili in dataTable
        this.rendiconti.map((element) => {
          element.isSelezionabile = this.rendicontiSelezionati.includes(
            element.id
          );
        });
        this.descrizioneValutazione =
          "dalla percentuale " +
          percentuale +
          "% applicata sulla totalità degli importi relativi ai rendiconti di progetto ritenuti ammissibili nel periodo di riferimento.";
      } else {
        //se la percentuale non è settata pulisco la selezione del DataTable e rendo tutti i rendiconti selezionaili
        this.rendicontiSelezionati = [];
        this.rendiconti.map((element) => {
          element.isSelezionabile = true;
        });
        this.descrizioneValutazione = null;
        this.totaleImportiRendiconti = 0;
        this.totaleImportiRendicontiSelezionati = 0;
        this.calcoloPercentuale = 0;
      }
    },
    async estraiCampione() {
      //
      this.infoCampionamento = {
        percentualeRendiconti: this.percentuale || null,
        descrizioneValutazione: this.descrizioneValutazione || null,
      };
      const rendicontiDaVerificare = this.rendicontiSelezionati;
      const idRendicontiDaVerificare = rendicontiDaVerificare.map((element) => {
        return element.id;
      });
      const formBody = new FormData();
      formBody.append("rendiconti", JSON.stringify(idRendicontiDaVerificare));
      formBody.append("percentualeCampionamento", 0.04);
      this.disabilitazionePulsanteEstraiCampione = true;
      await controlliUdmRepository
        .generaVerbaleCampionamentoUdm(this.anno, false, formBody)
        .then((data) => {
          if (data) {
            const campioneEstrattoUdm = JSON.stringify(data);
            // estratto il campione, questo lo associo alle due variabili nello store. Il primo sarà quello originale; il secondo sarà quello aggiornato
            this.$store.commit("setCampioneEstrattoUdm", campioneEstrattoUdm);
            this.$store.commit("setCampioneAggiornatoUdm", campioneEstrattoUdm);
            this.$store.commit(
              "setInfoCampionamentoUdm",
              this.infoCampionamento
            );
            this.$router.push({ name: "finalizzaCampioneUdm" });
          }
        })
        .catch((reason) => {
          if (reason.response.status === 409) {
            this.setValueSnackbar(false, reason.response.data[0]);
          } else {
            this.setValueSnackbar(
              false,
              "Un errore di server è stato riscontrato nella generazione del campione"
            );
          }
          this.disabilitazionePulsanteEstraiCampione = false;
        });
    },
    setValueSnackbar(esito, testo) {
      let color = esito ? "success" : "red accent-2";
      this.snackbar.testo = testo;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    // async downloadExcel() {
    //   let datiExcel = { ...this.rendicontiFiltrato };
    //   const data = new FormData();
    //   data.append("datiExcel", JSON.stringify(datiExcel));
    //   await RendicontiRepository.scaricaListaRendicontiExcel(data);
    // },
    // async downloadPdf() {
    //   let datiPdf = { ...this.rendicontiFiltrato };
    //   const data = new FormData();
    //   data.append("datiPdf", JSON.stringify(datiPdf));
    //   await RendicontiRepository.scaricaListaRendicontiPdf(data);
    // },
  },
};
</script>
<style scoped>
.mb-1 {
  min-height: 5px;
  padding: 20px;
  max-width: 86%;
}

.v-btn {
  padding: 25px;
}
</style>
