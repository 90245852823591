<template>
  <v-menu transition="slide-y-transition" bottom v-if="user && keycloak">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="purple" color="primary" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
        <span>{{ user.name }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item key="2" :href="keycloak.createAccountUrl()" target="_blank">
        <v-list-item-title>Account</v-list-item-title>
      </v-list-item>
      <v-list-item key="1" :href="keycloak.createLogoutUrl()">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>

      <v-subheader>Impersonificazione</v-subheader>
      <v-list-item :href="urlIstituto">
        <v-list-item-title>Vai a Istituto</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$store.getters.isRoleConsulenza" :href="urlConsulenza">
        <v-list-item-title>Ritorna a Consulenza</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "InfoPersona",
  computed: {
    ...mapState(["user", "keycloak"]),
  },
  data() {
    return {
      urlIstituto: process.env.VUE_APP_ISTITUTO_URL,
      urlConsulenza: process.env.VUE_APP_CONSULENZA_URL,
    };
  },
};
</script>

<style scoped></style>
