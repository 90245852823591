var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BreadcrumbComponent',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('h1',[_vm._v("Anagrafica rendiconto di progetto")])])],1),_c('v-row',[_c('v-col',[_vm._v(" ID Rendiconto")]),_c('v-col',[_vm._v(" Quadrimestre")]),_c('v-col',[_vm._v(" Data Trasmissione")]),_c('v-col',[_vm._v(" Importo richiesto")])],1),(_vm.rendiconto)?_c('v-row',{staticClass:"mb-6"},[_c('v-col',[_c('b',[_vm._v(" "+_vm._s(_vm.rendiconto.id))])]),_c('v-col',[_c('b',[_vm._v(" "+_vm._s(_vm.rendiconto.quadrimestre.tipologiaQuadrimestre.id ?? "")+"° Quadrimeste ("+_vm._s(_vm.rendiconto.quadrimestre.tipologiaQuadrimestre.descrizione ?? "")+")")])]),_c('v-col',[_c('b',[_vm._v(" "+_vm._s(_vm.getDate(_vm.rendiconto.dataTrasmissione)))])]),_c('v-col',[_c('b',[_vm._v(" "+_vm._s(new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", }).format(_vm.rendiconto.importoRichiesto))+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('h4',{staticClass:"mb-4",staticStyle:{"color":"gray","padding":"5px"}},[_vm._v(" Dichiarazione del soggetto attuatore propedeutica alla presentazione del rendiconto di progetto ")]),(_vm.rendiconto)?_c('a',{on:{"click":_vm.downloadPdf}},[_vm._v(" Scarica "),_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1):_vm._e()])],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('h1',{staticStyle:{"margin-top":"75px"}},[_vm._v("Dettaglio corsi")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.corsi,"items-per-page":10,"loading":_vm.loadingDettaglio,"loading-text":"Caricamento dati in corso..."},scopedSlots:_vm._u([{key:"item.corso",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.corso.corso.id))])]}},{key:"item.actions",fn:function({ item }){return [_c('router-link',{staticClass:"v-btn",attrs:{"to":{
                  name: 'dettaglioCorso',
                  params: {
                    //capofila: capofila,
                    idCorso: Number(item.corso.id),
                    idRendiconto: Number(_vm.idRendiconto),
                  },
                }}},[_vm._v(" Dettaglio corso "),_c('v-icon')],1)]}}])}),_c('div',{staticClass:"mt-4"},[_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left-thin")]),_vm._v("Torna a lista elenco rendiconti ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }