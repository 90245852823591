<template>
  <v-card class="mx-auto" :loading="loading">
    <v-card-title>News</v-card-title>
    <template v-for="(item, index) in items">
      <v-list-item
        :key="item.id"
        :class="item.importante === true ? 'pink--text' : ''"
      >
        <template>
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-medium">
                {{ item.data | formatDateLong }}
              </span>
              -
              {{ item.titolo }}
            </v-list-item-title>

            <v-list-item-subtitle
              class="text--primary"
              v-text="item.sottotitolo"
            ></v-list-item-subtitle>

            <v-list-item-subtitle v-html="item.testo"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>

      <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
    </template>
  </v-card>
</template>

<script>
import IstitutoRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "NewsComponent",
  props: {
    idStrutture: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data: () => ({
    loading: true,
    items: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getNews();
    },
    async getNews() {
      this.loading = true;
      this.items = await IstitutoRepository.getNews(this.idStrutture);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
  flex: 1 1 100%;
  overflow: auto;
  text-overflow: unset;
  white-space: unset;
}

.v-list-item:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
