<template>
  <v-autocomplete
    v-if="strutture"
    :items="strutture"
    class="mx-4"
    flat
    hide-details
    item-text="name"
    item-value="idStrutture"
    v-model="strutturaSelezionata"
    label="Struttura..."
    @change="setIdStrutture"
    solo-inverted
    return-object
  >
  </v-autocomplete>
</template>
<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
export default {
  name: "selezionaStruttura",
  components: {},
  data: () => ({
    strutture: null,
    strutturaSelezionata: null,
  }),
  created() {},
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getStruttureRaggruppate();
    },
    async getStruttureRaggruppate() {
      MinisteroRepository.getStruttureRaggruppate().then((data) => {
        if (data) {
          this.strutture = data;
        }
      });
    },
    setIdStrutture() {
      this.$store.dispatch(
        "loadIdStrutture",
        this.strutturaSelezionata
          ? parseInt(this.strutturaSelezionata.idStrutture)
          : null
      );

      this.setNomeStruttura();
    },
    setNomeStruttura() {
      this.$store.dispatch(
        "loadNomeStruttura",
        this.strutturaSelezionata ? this.strutturaSelezionata.name : null
      );
    },
  },
};
</script>
