import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#2196f3",
        secondary: "#795548",
        accent: "#9c27b0",
        error: "#f44336",
        warning: "#ff9800",
        info: "#607d8b",
        success: "#4caf50",
      },
    },
  },
};

export default new Vuetify(opts);
