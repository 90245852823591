<template>
  <v-container fluid>
    <v-main class="pa-0">
      <v-card elevation="4">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title>Popolazione di riferimento</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels accordion class="py-8">
                <v-expansion-panel
                  v-for="(rendiconto, index) in listaRendiconti"
                  :key="index"
                >
                  <v-expansion-panel-header color="blue" class="white--text">
                    <v-card-text class="float-start">
                      <v-row class="py-0">
                        <v-col> N° </v-col>
                        <v-col> ID RENDICONTO </v-col>
                        <v-col> CUP </v-col>
                        <v-col> Università/AFAM </v-col>
                        <v-col> Numero di corsi rendicontati </v-col>
                        <v-col> Numero di attestati rendicontati </v-col>
                        <v-col> Importo rendicontato </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-row class="py-0">
                        <v-col class="py-0">
                          <b> {{ rendiconto.indice }} </b>
                        </v-col>
                        <v-col class="py-0">
                          <b> {{ rendiconto.idRendiconto }} </b>
                        </v-col>
                        <v-col>
                          <b> {{ rendiconto.cup }}</b>
                        </v-col>
                        <v-col>
                          <b> {{ rendiconto.univ }}</b>
                        </v-col>
                        <v-col>
                          <b> {{ rendiconto.nrCorsiRendicontati }}</b>
                        </v-col>
                        <v-col>
                          <b> {{ rendiconto.nrAttestati }}</b>
                        </v-col>
                        <v-col>
                          <b>
                            {{
                              new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(rendiconto.importoRichiesto)
                            }}</b
                          >
                        </v-col>
                        <v-col> <u>DETTAGLIO</u> </v-col>
                      </v-row>
                    </v-card-text>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-main>
                      <v-data-table
                        :items="rendiconto.corsiRendiconto"
                        :headers="headersCorsi"
                      >
                        ><!--eslint-disable -->
                        <template v-slot:item.importoCorso="{ item }">
                          <span>€ {{item.importoCorso}} </span>
                        </template>
                      </v-data-table>
                    </v-main>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-main>
  </v-container>
</template>
<script>
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  name: "PopolazioneCampioneUdmComponent",
  components: {},
  props: {
    idVerbale: {
      type: Number,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    listaRendiconti: [],
    corsi: [],
    headersCorsi: [
      {
        text: "ID Corso",
        align: "center",
        sortable: true,
        value: "id_corso",
      },
      {
        text: "Importo corso",
        align: "center",
        sortable: false,
        value: "importoCorso",
      },
      /*{
        text: "Dettaglio corso",
        align: "center",
        sortable: false,
        value: "idCorso",
      },*/
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getListaRendiconti(this.anno, this.idVerbale);
      }
    },
    async getListaRendiconti(anno, idVerbale) {
      this.loadingVerbale = true;
      this.listaRendiconti =
        await ControlliUdmRepository.getListaRendicontiVerbaleUdm(
          anno,
          idVerbale
        );
      this.loadingVerbale = false;
    },
  },
};
</script>
