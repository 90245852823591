<template>
  <v-container fluid>
    <v-main class="pa-0">
      <v-card elevation="4">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title
                >Estensione del campione di controllo a seguito del tasso di
                errore > 10%</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="mt-8" elevation="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header color="blue" class="white--text">
                      <v-card-text class="float-start">
                        <v-row>
                          <v-col cols="2">
                            ID RENDICONTO -
                            <b>
                              {{ verbaleCampioneRendiconto.rendiconto.id }}
                            </b>
                          </v-col>
                          <v-col cols="2">
                            CUP -
                            <b>
                              {{
                                verbaleCampioneRendiconto.rendiconto.programma
                                  .cup
                              }}
                            </b>
                          </v-col>
                          <v-col cols="2">
                            {{
                              verbaleCampioneRendiconto.rendiconto.programma
                                .nomeIstituzione
                            }}
                          </v-col>
                          <v-col cols="1">
                            Nr corsi campionati -
                            <b>
                              {{ corsiEstesiDaVerifica.length }}
                            </b>
                          </v-col>
                          <v-col cols="1">
                            Nr attestati campionati - {{ nrAttestati }}
                            <b> </b>
                          </v-col>
                          <v-col cols="1">
                            Importo campionato -
                            {{
                              new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(importiCampionato)
                            }}
                            <b> </b>
                          </v-col>
                          <v-col cols="1">
                            - rischio calcolato :
                            {{ verbaleCampioneRendiconto.indiceRischio }}.
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="blue lighten-5">
                      <div class="d-flex mb-12 bg-surface-variant">
                        <v-sheet class="ma-2 pa-2 me-auto blue lighten-5">
                          Percentuale <br />Campionamento 4%
                        </v-sheet>
                      </div>
                      <v-card-text
                        v-for="corsiCampionati in corsiEstesiDaVerifica"
                        :key="corsiCampionati.id"
                        class="mt-1"
                      >
                        <v-card-title
                          v-html="
                            '<h4> ID CORSO - <b> ' +
                            corsiCampionati.corsoOrientamento.id +
                            ' </b> </h4>'
                          "
                          class="mt-1"
                        />
                        <v-data-table
                          :items="corsiCampionati.certificatiCampionati"
                          :headers="infoStudentiCertificatiCampionati"
                          hide-default-footer
                          :items-per-page="-1"
                          ><!--eslint-disable -->
                          <template v-slot:item.nomeCognome="{ item }">
                            <v-card-text v-html="item.certificato.studente.nome + ' ' + item.certificato.studente.cognome + ''" />
                          </template>
                          <template v-slot:item.istitutoScolastico="{ item }">
                            <v-card-text v-html="corsiCampionati.corsoOrientamento.accordo.istituto.denominazioneScuola" />
                          </template>
                          <template v-slot:item.estesoDaVerifica="{ item }">
                            <v-card-text>{{ item.estesoDaVerifica ? 'Si' : 'No' }}</v-card-text>
                          </template>
                          <template v-slot:item.attestatoFrequenza="{ item }">
                            <v-btn max-height="20" style="font-size:13px;" text @click="downloadFile(item.certificato.fileReferenteIstituto.id, item.certificato.fileReferenteIstituto.nomeOriginale)"> Scarica  </v-btn>
                          </template>
                          <!-- eslint-enable -->
                        </v-data-table>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-main>
  </v-container>
</template>
<script>
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "CampioneEstrattoComponent",
  components: {},
  props: {
    verbaleCampioneRendiconto: {
      type: Object,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    verbaleCampionato: [],
    corsiEstesiDaVerifica: [],
    importiCampionato: null,
    verbale: null,
    nrAttestati: 0,
    idVerbaleCampioneRendiconto: null,
    verbaleCampionamentoRendicontoSoloEsteso: null,
    infoStudentiCertificatiCampionati: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Istituto scolastico",
        value: "istitutoScolastico",
      },
      {
        text: "Codice Fiscale",
        value: "certificato.studente.cf",
        sortable: false,
      },
      {
        text: "Totale ore",
        value: "certificato.totaleOreStudente",
        sortable: false,
      },
      {
        text: "Valore attribuito",
        value: "certificato.valoreAttribuito",
        sortable: false,
      },
      {
        text: "Esteso da verifica",
        value: "estesoDaVerifica",
        sortable: false,
      },
      { text: "Attestato di frequenza", value: "attestatoFrequenza" },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.verbaleCampioneRendiconto) {
        this.idVerbaleCampioneRendiconto = this.verbaleCampioneRendiconto.id;
        this.elaboraDati();
      }
    },
    elaboraDati() {
      this.verbaleCampioneRendiconto.corsiCampionati.forEach(function (corso) {
        corso.certificatiCampionati = corso.certificatiCampionati.filter(
          (element) => {
            return element.estesoDaVerifica;
          }
        );
      });
      let corsiEstesiDaVerifica =
        this.verbaleCampioneRendiconto.corsiCampionati.filter((element) => {
          return element.estesoDaVerifica;
        });
      let certificatiPerCorso = corsiEstesiDaVerifica.map((item) => {
        return item.certificatiCampionati.length;
      });
      this.nrAttestati = certificatiPerCorso.reduce(
        (acc, valore) => acc + valore,
        0
      );
      let totCertificati = 0;
      corsiEstesiDaVerifica.forEach(function (corso) {
        corso.certificatiCampionati.forEach(function (element) {
          totCertificati += element.certificato.valoreAttribuito;
        });
      });
      this.importiCampionato = totCertificati;
      this.corsiEstesiDaVerifica = corsiEstesiDaVerifica;
    },
    getDateToString(date) {
      return new Date(date).toLocaleDateString();
    },
    async downloadFile(idFile, nomeOriginale) {
      const res = MinisteroRepository.downloadFile(
        this.anno,
        idFile,
        nomeOriginale
      );

      if (Array.isArray(res)) {
        this.setValueSnackbar(true, res[0], "red-darken-4");
      }
    },
  },
};
</script>
