import Repository from "@/api/Repository";

export default {
  async getGeneriStudente() {
    const url = `api/generici/generi-studente`;
    return (await Repository.get(url)).data;
  },
  async getTipiFormazione() {
    const url = `api/generici/tipi-formazione`;
    return (await Repository.get(url)).data;
  },
  async getTipiAttivita() {
    const url = `api/generici/tipi-attivita`;
    return (await Repository.get(url)).data;
  },
  async getCorsoOrientamento(idStrutture, anno, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/corso/${idCorso}`;
    return (await Repository.get(url)).data;
  },
  async getStudentiCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/lista-studenti-corso`;
    return (await Repository.get(url)).data;
  },
  async getLezioniCorso(idStrutture, idCorso) {
    let url = `api/istituto/${idStrutture}/corsi/${idCorso}/riepilogo-lezioni`;
    return (await Repository.get(url)).data;
  },
  async getRuoliDocente() {
    const url = `api/generici/ruoli-docente`;
    return (await Repository.get(url)).data;
  },
  async getDocentiCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/lista-docenti-corso`;
    return (await Repository.get(url)).data;
  },
  async getListaSsdSad(idStrutture) {
    const url = `api/generici/${idStrutture}/lista-ssd-sad`;
    return (await Repository.get(url)).data;
  },
  async getNumeroCertificati(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/certificati-ottenuti`;
    return (await Repository.get(url)).data;
  },
  async getFileCertificato(idStrutture, certificato, nomeFileOriginale) {
    const url = `api/istituto/${idStrutture}/corsi/${certificato}/file-certificato`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = nomeFileOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async terminaCorso(idStrutture, idCorso) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/termina`;
    return await Repository.put(url, null);
  },
  async getAllCorsi(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/anno/${anno}/${capofila}/tutti-corsi`;
    return (await Repository.get(url)).data;
  },
  async getNumeriCertificati(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/corsi/${anno}/${capofila}/numeri-certificati`;
    return (await Repository.post(url)).data;
  },
  async getReferentiCorsoStruttura(idStrutture) {
    const url = `api/istituto/${idStrutture}/corsi/referenti-corso`;
    return (await Repository.get(url)).data;
  },
  async downloadFileRiepilogoLezioni(
    idStrutture,
    idCorso,
    tipoFile,
    bodyLezioniCorso
  ) {
    const url = `api/istituto/${idStrutture}/corsi/${idCorso}/riepilogo-lezioni/${tipoFile}`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, bodyLezioniCorso, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      const extension = tipoFile === 0 ? ".xlsx" : ".pdf";
      fileLink.download = "Riepilogo_corso_" + idCorso + extension;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async getSottoAccordiScuolaByAccordo(idStrutture, accordo) {
    const url = `api/istituto/${idStrutture}/accordi-scuola/${accordo}/sotto-accordi-scuola`;
    return (await Repository.get(url)).data;
  },
};
