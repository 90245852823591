<template>
  <v-row class="grey lighten-3 rounded-l white--text">
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-row>
</template>

<script>
export default {
  name: "BreadcrumbComponent",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
