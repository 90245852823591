<template>
  <v-container fluid>
    <v-main class="mb-4">
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <div v-if="rendicontoCorsiCampionati">
      <h1>Modifica attestati campionati</h1>
      <RiepilogoGeneraleRendiconto
        :rendiconto="rendicontoCampionato.rendiconto"
        class="mt-3"
      />
    </div>
    <v-card v-if="loadingPanelCorsi" elevation="4" class="mt-5">
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="(corsi, index) in rendicontoCorsiCampionati"
          :key="index"
          class="mb-5"
        >
          <v-expansion-panel-header color="blue" class="white--text">
            <div class="d-flex flex-row mb-6 bg-surface-variant">
              <v-sheet class="ma-2 pa-2" color="blue">
                <h2
                  v-html="'ID CORSO - <b>' + corsi.corso.id + '</b>'"
                  class="white--text mt-1"
                />
              </v-sheet>
              <v-sheet class="ma-2 pa-2" color="blue">
                <v-checkbox
                  v-model="corsi.selezionato"
                  color="white"
                  :disabled="corsi.isSelezionabile"
                  class="mt-1"
                />
              </v-sheet>
              <v-sheet class="ma-2 pa-2" color="blue">
                <span class="white--text">
                  Aggiunta del corso al campione
                </span>
              </v-sheet>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="blue lighten-5">
            <v-data-table
              class="mt-4"
              :items="corsi.attestati"
              :headers="infoStudentiCertificatiCampionati"
              show-select
              v-model="corsi.attestatiSelezionati"
              item-key="id"
              hide-default-footer
              :items-per-page="-1"
              selectable-key="isSelezionabile"
              ><!--eslint-disable -->
             <!-- <template v-slot:item.data-table-select="{item}">
                <v-checkbox :disabled="checkSelezioneAttestato(item, index)"
                            :input-value="item.selezionato != null ? item.selezionato : item.selezionato = false"
                            v-model="item.selezionato"
                            @click="addAttestato(item.selezionato, item, index)" />
              </template>-->
              <template v-slot:item.nomeCognome="{ item }">
                <v-card-text
                  v-html="item.studente.nome + ' ' + item.studente.cognome + ''"
                />
              </template>
              <template v-slot:item.istitutoScolastico="{ item }">
                <v-card-text v-if="anno === 2022" v-html="corsi.corso.accordo.istituto.denominazioneScuola" />
                <v-card-text v-else v-html="item.studente.corsiStudente[0].istituto.denominazioneScuola" />
              </template>
              <template v-slot:item.percentualeOreSvolte="{ item }">
                <v-card-text v-html="item.percentualeOreSvolte + ' %'" />
              </template>
              <template v-slot:item.valoreAttribuito="{ item }">
                <v-card-text v-html="item.valoreAttribuito + ' €'" />
              </template>
              <template v-slot:item.importoTariffa="{ item }">
                <v-card-text v-html="'16.67 €'" />
              </template>
              <template v-slot:item.dettaglio="{ item }">
                <router-link
                  :to="{
                    name: 'dettaglioCorso',
                    params: {
                      idRendiconto: idRendicontoSelezionato,
                      idCorso: corsi.corso.id,
                      idStrutture: item.idStrutture,
                    },
                  }"
                >
                  Dettaglio
                </router-link>
              </template><!--eslint-enable -->
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-btn type="primary" @click="dialogConfermaCampione = true">
          Conferma Campione</v-btn
        >
        <dialog-conferma
          @callback="confermaCampione"
          :dialog.sync="dialogConfermaCampione"
          @close-dialog="dialogConfermaCampione = false"
        />
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
          {{ snackbar.text }}
        </v-snackbar>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text class="justify-centerr">
        Il Sistema sta caricando i corsi associati al rendiconto e gli attestati
        associati</v-card-text
      >
      <v-progress-linear indeterminate color="blue" />
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import RiepilogoGeneraleRendiconto from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import DialogConferma from "@/components/DialogConferma.vue";
//import RendicontiRepository from "@/api/ministero/RendicontiRepository";

export default {
  name: "EstendiCampione",
  components: {
    DialogConferma,
    RiepilogoGeneraleRendiconto,
    BreadcrumbComponent,
  },
  props: {
    // l'oggetto rendiconto con i corsi e attestati campionati
    rendicontoCampione: {
      type: Object,
      required: true,
    },
    // l'indice del rendiconto campionato memorizzato nello store
    // DA UTILIZZARE SOLO nell'estrazione del campione, non nella parte di modifica
    indiceRendiconto: {
      type: Number,
      required: false,
      default: -1,
    },
    statoCampione: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState([
      "idStrutture",
      "anno",
      "campioneEstratto",
      "campioneAggiornato",
    ]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Lista Verbali",
          disabled: false,
          to: `/scrivania/controlli/lista-verbali`,
          exact: true,
          link: true,
        },
        {
          text: "Lista Rendiconti",
          disabled: false,
          to: `/scrivania/controlli/nuovo-controllo`,
          exact: true,
          link: true,
        },
        {
          text: "Finalizza Campione",
          disabled: false,
          to: `/scrivania/controlli/finalizza-campione`,
          exact: true,
          link: true,
        },
        {
          text: "Estendi campione",
          disabled: true,
          to: `/scrivania/controlli/estendi-campione`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  data: () => ({
    idRendicontoSelezionato: null,
    rendicontoCampionato: null,
    corsiDaCampionare: [], // i corsi estratti dall'algoritmo e/o quelli selezionati dall'estendi campione
    attestatiDaCampionare: [], // gli attestati estratti dall'algoritmo e/o quelli selezionati dall'estendi campione,
    corsiEstratti: [], // i corsi estratti dall'algoritmo e NON DESELEZIONABILI
    attestatiEstratti: [], // gli attestati estratti dall'algoritmo e NON DESELEZIONABILI

    rendicontoCorsiCampionati: null,
    /* i corsi campionati, con tutti i certificati per corso*/
    loadingPanelCorsi: false,
    dialogConfermaCampione: false,
    snackbar: {
      value: false,
      color: "",
      text: "",
    },
    infoStudentiCertificatiCampionati: [
      {
        text: "Nome e Cognome",
        value: "nomeCognome",
      },
      {
        text: "Istituto scolastico",
        value: "istitutoScolastico",
      },
      {
        text: "Codice Fiscale",
        value: "studente.cf",
        sortable: false,
      },
      { text: "Totale ore", align: "center", value: "totaleOreStudente" },
      {
        text: "% ore freq",
        align: "center",
        value: "percentualeOreSvolte",
      },
      { text: "Importo tariffa", align: "center", value: "importoTariffa" },
      { text: "Valore attributivo", value: "valoreAttribuito" },
      { text: "Dettaglio", value: "dettaglio" },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.idRendiconto) {
        this.idRendicontoSelezionato = parseInt(
          this.$route.params.idRendiconto
        );
        if (this.statoCampione !== "daSalvare") {
          this.inizializzazionePaginaTipoDue();
        } else {
          this.inizializzazionePaginaTipoUno();
        }
      }
    },
    /* funzioni utilizzate quando si accede alla pagina da finalizza campione e dove il verbale di campionamento non è stato ancora creato */
    inizializzazionePaginaTipoUno() {
      this.rendicontoCampionato = this.rendicontoCampione;
      // recupero i corsi e gli attestati estratti dall'algoritmo
      this.getCorsiAttestatiEstrattiDaAlgoritmo();
      // ora recupero i corsi e gli attestati estratti dall'algoritmo di campionamento o selezionati con l'estendi campione
      this.getCorsiAttestatiEstrattiOSelezionati();
      this.getAllCorsiEAttestatiRendiconto(this.idRendicontoSelezionato);
    },
    /**
     * Aggiungo l'id dei corsi e
     * degli attestati agli array che tengono traccia dei dati ottenuti dall'algoritmo
     * di campionamento e/o selezionati in precedenza in questa pagina. Sono differenziati in quanto
     * questi possono essere deselezionati
     */
    getCorsiAttestatiEstrattiOSelezionati() {
      const campioneModificato = JSON.parse(this.campioneAggiornato)[
        this.indiceRendiconto
      ];
      campioneModificato.corsiCampionati.forEach((element) => {
        this.corsiDaCampionare.push(element.corsoCampionato.id);
        this.attestatiDaCampionare = this.attestatiDaCampionare.concat(
          element.certificatoCampionato.map((element1) => {
            return element1.id;
          })
        );
      });
    },
    /**
     * Aggiungo l'id dei corsi e
     * degli attestati agli array che tengono traccia dei dati ottenuti dall'algoritmo
     *  e che non possono essere deselezionati
     */
    getCorsiAttestatiEstrattiDaAlgoritmo() {
      const campioneOriginaleEstratto = JSON.parse(this.campioneEstratto)[
        this.indiceRendiconto
      ];
      campioneOriginaleEstratto.corsiCampionati.forEach((element) => {
        this.corsiEstratti.push(element.corsoCampionato.id);
        this.attestatiEstratti = this.attestatiEstratti.concat(
          element.certificatoCampionato.map((element1) => {
            return element1.id;
          })
        );
      });
    },

    async getAllCorsiEAttestatiRendiconto(idRendiconto) {
      this.rendicontoCorsiCampionati =
        await ControlliRepository.getRendicontiCorsiEAttestatiFirmati(
          this.anno,
          idRendiconto
        );
      if (this.statoCampione === "salvato") {
        this.configuraArrayCorsiAttestatiTipoDue();
      } else {
        this.configuraArrayCorsiAttestatiTipoUno();
      }
      //this.loadingPanelCorsi = true;
    },
    /** configuro l'array di corsi e attestati associati, in modo che quelli estratti da algoritmo di campionamento non siano deselezionabili */
    configuraArrayCorsiAttestatiTipoUno() {
      this.rendicontoCorsiCampionati.map((element) => {
        // configuro l'array che conterrà gli attestati che l'utente seleziona per il corso specifico
        element.attestatiSelezionati = [];
        element.selezionato = this.corsiDaCampionare.includes(element.corso.id);
        element.isSelezionabile = this.corsiEstratti.includes(element.corso.id);

        if (
          (element.isSelezionabile || element.selezionato) &&
          element.attestati.length
        ) {
          element.attestati.map((element1) => {
            element1.selezionato = this.attestatiDaCampionare.includes(
              element1.id
            );
            element1.isSelezionabile = !this.attestatiEstratti.includes(
              element1.id
            );
            if (element1.selezionato) {
              element.attestatiSelezionati.push(element1);
            }
          });
        }
        return element;
      });
      this.loadingPanelCorsi = true;
    },
    confermaCampione() {
      if (this.statoCampione === "daSalvare") {
        this.gestioneOperazioneSalvataggioTipoUno();
      } else {
        this.gestioneOperazioneSalvataggioTipoDue();
      }
    },
    gestioneOperazioneSalvataggioTipoUno() {
      let corsiAttestatiDaCampionare = [];
      this.rendicontoCorsiCampionati.forEach((element) => {
        if (element.selezionato === true) {
          corsiAttestatiDaCampionare.push({
            corsoCampionato: element.corso,
            certificatoCampionato: element.attestatiSelezionati,
          });
        }
      });
      if (this.verificaSelezioneAttestati(corsiAttestatiDaCampionare)) {
        this.setJsonRendicontoCampionato(corsiAttestatiDaCampionare);
      } else {
        this.setValueSnackbar(
          false,
          "Errore. E' necessario verificare la selezione di almeno un'attestato, per ogni corso selezionato"
        );
      }
    },

    /**
     * Verifico che per ogni corso che è stato selezionato, ci sia almeno un attestato.
     * Filtro dunque l'array di corsi in base a se sono stati selezionati e se il numero di attestati selezionati è 0.
     * Se l'array è vuoto restituisco true ( controllo passato ), viceversa false
     */
    verificaSelezioneAttestati: function (corsiAttestatiDaCampionare) {
      let corsiConAttestatiNonSelezionati = corsiAttestatiDaCampionare.filter(
        (element) => {
          return element.certificatoCampionato.length < 1;
        }
      );
      return corsiConAttestatiNonSelezionati.length < 1;
    },
    setValueSnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    setJsonRendicontoCampionato(nuoviCorsiCampionamento) {
      let campioneAggiornato = JSON.parse(this.campioneAggiornato);

      /** ------------ gestione operazione quando il campione non è ancora salvato su db --------------------------**/
      if (this.statoCampione === "daSalvare") {
        if (this.indiceRendiconto == null || this.indiceRendiconto < 0) {
          this.setValueSnackbar(
            false,
            "Un errore è stato riscontrato nell'operazione di estensione del campione."
          );
        } else {
          campioneAggiornato[this.indiceRendiconto] = {
            rendiconto: this.rendicontoCampionato.rendiconto,
            corsiCampionati: nuoviCorsiCampionamento,
          };
          this.$store.commit(
            "setCampioneAggiornato",
            JSON.stringify(campioneAggiornato)
          );
          this.$router.push({ name: "finalizzaCampione" });
        }
        // eslint-disable-next-line no-empty
      } else {
      }
    },
    /** -----------------------------------------------------------------------------------------------------------------------------------
          funzioni utilizzate per la gestione della pagina quando il verbale di campionamento è stato creato
          ( accesso alla pagina dal primo stemp di verifica di rendiconti ( file src/views/Controlli/VerificheRendiconti/VerificaRendicontoStepUnoView.vue )
     --------------------------------------------------------------------------------------------------------------------------------------
     **/
    inizializzazionePaginaTipoDue() {
      this.rendicontoCampionato = this.rendicontoCampione;
      // recupero i corsi e attestati che non possono essere deselezionati
      this.getCorsiEAttestatiCampionatiVerbaleCampionamento();
      this.getAllCorsiEAttestatiRendiconto(this.idRendicontoSelezionato);
    },
    /**
     * configuro l'array di corsi e attestati associati in modo che, quelli già salvati sul db ( nel verbale di rendiconto ),memorizzati rispettivamente
     * negli array 'this.corsiEstratti' e 'this.attestatiEstratti',siano visibili all'utente e allo stesso tempo non deselezionabili.
     */
    configuraArrayCorsiAttestatiTipoDue() {
      this.rendicontoCorsiCampionati.map((element) => {
        element.attestatiSelezionati = [];
        if (this.corsiEstratti.includes(element.corso.id)) {
          // le prime due proprietà per la gestione front-end
          element.selezionato = true;
          element.isSelezionabile = true;
          // l'ultima proprietà per individuare quelli già presenti su db, utilizzata nel back-end
          element.campionato = true;
        } else {
          element.campionato = false;
        }

        if (
          (element.isSelezionabile || element.selezionato) &&
          element.attestati.length
        ) {
          element.attestati.map((element1) => {
            if (this.attestatiEstratti.includes(element1.id)) {
              element1.selezionato = true;
              element1.isSelezionabile = false;
              element1.campionato = true;
            } else {
              element1.campionato = false;
            }
            if (element1.selezionato) {
              element.attestatiSelezionati.push(element1);
            }
          });
        }
        return element;
      });
      this.loadingPanelCorsi = true;
    },
    getCorsiEAttestatiCampionatiVerbaleCampionamento() {
      this.rendicontoCampione.corsiCampionati.forEach((element) => {
        this.corsiEstratti.push(element.corsoOrientamento.id);
        this.attestatiEstratti = this.attestatiEstratti.concat(
          element.certificatiCampionati.map((element1) => {
            return element1.certificato.id;
          })
        );
      });
    },
    async gestioneOperazioneSalvataggioTipoDue() {
      let corsiAttestatiDaCampionare = [];
      this.rendicontoCorsiCampionati.forEach((element) => {
        if (element.selezionato === true) {
          corsiAttestatiDaCampionare.push({
            corsoCampionato: element.corso,
            certificatoCampionato: element.attestatiSelezionati,
            campionato: element.campionato,
          });
        }
      });

      if (!this.verificaSelezioneAttestati(corsiAttestatiDaCampionare)) {
        this.setValueSnackbar(
          false,
          "Errore. E' necessario verificare la selezione di almeno un'attestato, per ogni corso selezionato"
        );
      } else if (
        !this.verificaImportiAttestatiSelezionati(corsiAttestatiDaCampionare)
      ) {
        this.setValueSnackbar(
          false,
          "Errore. E' necessario selezionare attestati in modo che la somma dei loro valori sia maggiore" +
            " dell'ultimo totale di importi non ammessi"
        );
      } else {
        const idVerbaleRendiconto = this.rendicontoCampionato.id;

        const formBody = new FormData();
        formBody.append(
          "corsiAttestatiCampionati",
          JSON.stringify(corsiAttestatiDaCampionare)
        );

        await ControlliRepository.estendiCampioneVerbaleRendiconto(
          this.anno,
          idVerbaleRendiconto,
          formBody
        )
          .then((data) => {
            if (data) {
              setTimeout(() => {
                this.$router.push({
                  name: "verbaleEstensione",
                  params: {
                    anno: this.anno,
                    idRendiconto: this.idRendicontoSelezionato,
                    idVerbale: data,
                  },
                });
              }, 3000); // Wait for 3000 milliseconds (3 seconds)
            }
          })
          .catch((reason) => {
            if (reason.response.status === 500) {
              this.setValueSnackbar(
                false,
                "Un'errore server è stato riscontrato nella richiesta di estensione del campione"
              );
            }
          });
      }
    },
    // devo selezionare almeno un numero di attestati per cui la somma degli importi è almeno uguale alla ssoma degli importi non ammessi;
    verificaImportiAttestatiSelezionati(corsiDaCampionare) {
      const importoNonAmmessoTotale = parseFloat(
        this.rendicontoCampionato.importiCalcolati.importoNonAmmesso
      );
      let sommaValoriAttestatiSelezionati = 0;
      corsiDaCampionare.forEach((element) => {
        let attestatiSelezionati = element.certificatoCampionato;
        let sommaImportiCorrente = 0;
        attestatiSelezionati.forEach((element1) => {
          if (element1.campionato == null || !element1.campionato) {
            sommaImportiCorrente += parseFloat(element1.valoreAttribuito);
          }
        });
        sommaValoriAttestatiSelezionati += sommaImportiCorrente;
      });

      return importoNonAmmessoTotale <= sommaValoriAttestatiSelezionati;
    },
  },
};
</script>
