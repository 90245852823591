<template>
  <v-container>
    <v-card v-if="loadingCard">
      <v-card-title> Modifica il Codice IBAN / Conto di Tesoreria</v-card-title>
      <v-form ref="formTesoreria" lazy-validation v-model="valid">
        <v-main class="mx-auto mt-8 px-6 pb-6 pb-6">
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Codice IBAN / Conto di tesoreria Attuale"
                v-model="formContoTesoreria.codiceIban"
                readonly
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4">
              <v-text-field
                label="Nuovo Codice IBAN / Conto di tesoreria"
                v-model="formContoTesoreria.nuovoCodiceIban"
                :rules="validateField.fieldRequired"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-main>
        <v-main>
          <v-data-table
            :headers="headersRichieste"
            :items="richiesteAnticipoSaldo"
            show-select
            single-select
            ><!-- eslint-disable -->
            <template v-slot:item.ibanTesoreria=" { item }">
              <span v-html="item.ibanTesoreria ? item.ibanTesoreria : 'Non Inserita'"/>
            </template>
            <template v-slot:item.ibanContoBancario=" { item }">
              <span v-html="item.ibanContoBancario ? item.ibanContoBancario: 'Non Inserita'"/>
            </template>

          </v-data-table>

        </v-main>
        <v-main>
          <v-card-subtitle> <b> Allega documento </b></v-card-subtitle>
          <v-row>
            <v-col cols="4">
              <v-file-input
                label="Allega"
                v-model="formContoTesoreria.documentoContoTesoreria"
                :rules="validateField.fieldRequired"
                accept=".pdf"
              >
              </v-file-input>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                v-text="'Sostituisci'"
                @click="dialog2 = true"
              />
            </v-col>
          </v-row>
          <DialogConferma
            @callback="onSubmitModificaContoTesoreria"
            :dialog.sync="dialog2"
            @close-dialog="dialog2 = false"
          />
        </v-main>
      </v-form>
    </v-card>
    <v-card v-else>
      <v-alert type="warning">
        Per procedere all'operazione è necessario selezionare l'ateneo e l'anno
        accademico del programma di orientamento.
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
//import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import IstitutoRepository from "@/api/IstitutoRepository";
import DialogConferma from "@/components/DialogConferma";
import { mapState } from "vuex";

export default {
  name: "modificaIbanContoTesoreria",
  components: { DialogConferma },
  computed: {
    ...mapState(["idStrutture", "anno", "capofila"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
    capofila() {
      this.initialize();
    },
  },
  data: () => ({
    loadingCard: false,
    formContoTesoreria: {
      codiceIban: null,
      nuovoCodiceIban: null,
      documentoContoTesoreria: null,
    },
    validateField: {
      fieldRequired: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
      ],
      fieldCodiceFiscale: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) =>
          (v && v.length === 16) ||
          "Il codice fiscale deve contenere 16 CARATTERI.",
      ],
      fieldEmail: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) => /.+@.+/.test(v) || "Inserire un indirizzo E-mail valido",
      ],
    },
    dialog1: false,
    dialog2: false,
    richiesteAnticipoSaldo: [],
    snackbar: {
      color: null,
      text: null,
      value: false,
    },
    headersRichieste: [
      {
        text: "ID RICHIESTA ANTICIPO",
        value: "id",
        align: "start",
        sortable: false,
      },
      {
        text: "DATA INVIO",
        value: "dataInvio",
      },
      {
        text: "IBAN TESORERIA",
        value: "ibanTesoreria",
      },
      {
        text: "IBAN CONTO BANCANRIO",
        value: "ibanContoBancario",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture && this.anno && this.capofila) {
        const idStrutture = this.idStrutture;
        const anno = this.anno;
        const capofila = this.capofila;
        this.getRichiesteAnticipoSaldo(idStrutture, anno, capofila);
        this.loadingCard = true;
      }
    },
    async getRichiesteAnticipoSaldo(idStrutture, anno, capofila) {
      IstitutoRepository.getRichiesteAnticipoSaldoAteneo(
        idStrutture,
        anno,
        capofila
      ).then((data) => {
        if (data && data.length > 0) {
          this.richiesteAnticipoSaldo = data.map((element) => {
            element.selezionato = false;
            return element;
          });
        }
      });
    },
  },
};
</script>
