var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-4",attrs:{"elevation":"4"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredPrograms,"item-key":"programmaOrientamento.id","loading-text":"Il sistema sta caricando le informazioni","no-results-text":"Nessun dato presente"},scopedSlots:_vm._u([{key:"item.assFinanziariaProgramma",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getFormattedAmount(item.assFinanziariaInc + item.assFinanziariaProgramma ))}})]}},{key:"item.targetStudenti",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getTargetStudenti(item.alunniTarget, item.numeroAlunniCoinvolti))+" ")]}},{key:"item.capofila",fn:function({ item }){return [_vm._v(" "+_vm._s(item.capofila ? "Si" : "No")+" ")]}},{key:"item.fileProgrammaOrientamento",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.getFileToDownload(
            item.anno,
            item.nomeFileProgrammaOrientamento,
             item.idFileProgramma)}}},[_vm._v(" Atto d'obbligo ")])]}},{key:"item.fileConvenzione",fn:function({ item }){return [(item.idFileConvenzione)?_c('a',{on:{"click":function($event){return _vm.getFileToDownload(
            item.anno,
            'convenzione_' + item.nomeIstituzione + '_' + item.anno + '.pdf',
             item.idFileConvenzione)}}},[_vm._v(" Convenzione ")]):_vm._e()]}},{key:"item.azioni",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-icon",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{staticClass:"item-menu"},[_c('v-list-item-content',[_c('v-list-item-action',{on:{"click":function($event){return _vm.apriDialog(item)}}},[_vm._v(" Vai al Programma di Orientamento ")])],1)],1)],1)],1)],1)]}}])}),(_vm.dialogDettaglioProgramma)?_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialogDettaglioProgramma),callback:function ($$v) {_vm.dialogDettaglioProgramma=$$v},expression:"dialogDettaglioProgramma"}},[_c('DialogDettaglioProgrammaOrientamento',{attrs:{"anno":_vm.datiDialogProgramma.anno,"id-strutture":_vm.datiDialogProgramma.idStrutture,"capofila":_vm.datiDialogProgramma.capofila},on:{"chiusuraDialog":function($event){_vm.dialogDettaglioProgramma = false}}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }