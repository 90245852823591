<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row class="mt-8">
      <v-col cols="8">
        <h1>Dichiarazione di tracciabilità dei flussi finanziari</h1>
      </v-col>
      <v-col cols="4"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="my-2">
      <v-col cols="2">
        <v-btn
          text
          class="blue--text"
          @click="getFileRiepilogoDichiarazioni(0, 'a')"
          ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
          Esporta Excel
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          text
          class="blue--text"
          @click="getFileRiepilogoDichiarazioni(1, 'a')"
          ><v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
          Esporta PDF
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dichiarazioni"
          :items-per-page="10"
          class="elevation-1"
          :loading="loadingData"
          loading-text="Caricamento dati in corso..."
          no-data-text="Nessuna dichiarazione per l'anno o la struttura selezionata"
          ><!-- eslint-disable -->
          <template v-slot:item.statoDichiarazione="{ item }">
            <v-chip outlined pill :color="colorState(item.stato)">
              {{ item.stato.descrizione }}
            </v-chip>
          </template>
          <template v-slot:item.dataTrasmissione="{ item }">
            <span>{{item.dataTrasmissione | formatDate}}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="v-icon"
                    v-bind="attrs"
                    v-on="on"
                    :icon="true"
                >
                  <v-icon> mdi-dots-vertical</v-icon>
                </v-btn>
              </template><!-- eslint-enable -->
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item
                    class="item-menu"
                    v-for="(itemMenu, i) in menuAction"
                    :key="i"
                    :disabled="i === 1 && item.stato.id !== 1"
                    @click="gestioneMenuAzioni(item, i)"
                  >
                    {{ itemMenu }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import { mapGetters, mapState } from "vuex";
import DichiarazioneFlussiRepository from "@/api/ministero/DichiarazioneFlussiRepository";

export default {
  name: "dichiarazioniTracciabilitaView",
  data: () => ({
    dichiarazioni: [],
    loadingData: false,
    headers: [
      {
        text: "ID Dichiarazione",
        align: "start",
        value: "id",
      },
      {
        text: "Istituzione",
        value: "programma.nomeIstituzione",
        sortable: true,
      },
      { text: "Data dichiarazione", value: "dataTrasmissione", sortable: true },
      { text: "Stato", value: "statoDichiarazione", sortable: false },
      { text: "Azioni", value: "actions", sortable: false },
    ],
    menuAction: ["Dettagli" /*"Presa visione"*/],
  }),
  components: { BreadcrumbComponent, RiquadroProfiloRuolo },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Dichiarazione di tracciabilità dei flussi finanziari",
            disabled: true,
            to: `/scrivania/flussi-finanziari`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleDgUdm",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.getDichiarazioni();
    },
    async getDichiarazioni() {
      this.loadingData = true;
      let dichiarazioni = await DichiarazioneFlussiRepository.getDichiarazioni(
        this.idStrutture ?? 0,
        this.anno ?? 0
      );
      this.dichiarazioni = dichiarazioni.filter(
        (d) => d.trasmesso && d.dataTrasmissione
      );
      this.loadingData = false;
    },
    colorState(item) {
      switch (item.id) {
        case 1:
          return "warning";
        case 2:
          return "success";
        case 3:
          return "red";
        default:
          return "";
      }
    },
    gestioneMenuAzioni(dichiarazione, azione) {
      //menuAction: ["Dettagli" = 0, "Presa visione" = 1],
      if (azione === 1) {
        this.$router.push({
          name: "dettaglioDichiarazioneView",
          params: {
            idDichiarazione: dichiarazione.id,
            idStrutture: dichiarazione.idStrutture,
            editable: true,
          },
        });
      } else {
        this.$router.push({
          name: "dettaglioDichiarazioneView",
          params: {
            idDichiarazione: dichiarazione.id,
            idStrutture: dichiarazione.idStrutture,
            editable: false,
          },
        });
      }
    },
    async getFileRiepilogoDichiarazioni(tipoFile, tipoDati) {
      /* tipoDati:
       * a = dichiarazioni,
       * b = conti,
       * c = delegati,
       */
      const formData = new FormData();
      formData.append("dati", JSON.stringify(this.dichiarazioni));
      await DichiarazioneFlussiRepository.downloadFileRiepilogoDichiarazioni(
        this.idStrutture ?? 0,
        tipoFile,
        tipoDati,
        formData
      );
    },
  },
};
</script>

<style scoped></style>
