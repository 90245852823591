<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row class="mt-8">
      <v-col cols="8">
        <h1>Dettaglio dichiarazione</h1>
      </v-col>
      <v-col cols="4"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="mb-4">
      <v-col>
        <v-card>
          <v-card-title class="pb-8">
            <h4>Dati conto corrente o di tesoreria</h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3" class="">
                <p>
                  N° conto corrente
                  <br />
                  <br />
                  <b>{{ conto.contoTesoreriaPostale }}</b>
                </p>
              </v-col>
              <v-col cols="3">
                <p>
                  IBAN
                  <br />
                  <br />
                  <b>{{ conto.ibanAssociato }}</b>
                </p>
              </v-col>
              <v-col cols="3">
                <p>
                  Acceso presso
                  <br />
                  <br />
                  <b>{{ conto.accesoPresso }}</b>
                </p>
              </v-col>
              <v-col cols="3">
                <p>
                  Intestato a
                  <br />
                  <br />
                  <b>{{ conto.intestatario }}</b>
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12">
                <h4 class="text-uppercase">
                  Persone delegate ad operare sul suddetto conto:
                </h4>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="mt-2">
            <v-col cols="2">
              <v-btn
                text
                class="blue--text"
                @click="getFileRiepilogoDichiarazioni(0, 'c')"
                ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
                Esporta Excel
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                text
                class="blue--text"
                @click="getFileRiepilogoDichiarazioni(1, 'c')"
                ><v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
                Esporta PDF
              </v-btn>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-data-table
                :headers="headers"
                :items="conto.delegati"
                :items-per-page="10"
                class="elevation-1"
                loading-text="Caricamento dati in corso..."
                ><!-- eslint-disable -->
                <template v-slot:item.dataNascita="{ item }">
                  {{item.dataNascita | formatDate}}
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-btn text class="blue--text" @click="goToPreviousPage()">
        <v-icon>mdi-arrow-left-thin</v-icon>Torna indietro
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import DichiarazioneFlussiRepository from "@/api/ministero/DichiarazioneFlussiRepository";

export default {
  name: "dettaglioContoView",
  components: { BreadcrumbComponent },
  data: () => ({
    headers: [
      {
        text: "Nome",
        value: "nome",
      },
      { text: "Cognome", value: "cognome", sortable: true },
      { text: "Qualifica", value: "qualifica", sortable: false },
      { text: "Luogo di nascita", value: "luogoNascita", sortable: false },
      { text: "Data di nascita", value: "dataNascita", sortable: false },
      { text: "Codice Fiscale", value: "cf", sortable: false },
    ],
  }),
  props: {
    conto: {
      type: Object,
      required: true,
    },
    idDichiarazione: {
      type: Number,
      required: true,
    },
    idStrutture: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Dichiarazione di tracciabilità dei flussi finanziari",
            disabled: false,
            to: `/scrivania/flussi-finanziari`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio dichiarazione",
            disabled: true,
            to: `/scrivania/flussi-finanziari/dettaglio-dichiarazione`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio conto",
            disabled: true,
            to: `/scrivania/flussi-finanziari/dettaglio-dichiarazione`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleDgUdm",
    ]),
  },
  methods: {
    goToPreviousPage() {
      this.$router.push({
        name: "dettaglioDichiarazioneView",
        params: {
          idDichiarazione: this.idDichiarazione,
          idStrutture: this.idStrutture,
          editable: this.editable,
        },
      });
    },
    async getFileRiepilogoDichiarazioni(tipoFile, tipoDati) {
      const formData = new FormData();
      formData.append("dati", JSON.stringify(this.conto));
      await DichiarazioneFlussiRepository.downloadFileRiepilogoDichiarazioni(
        this.idStrutture ?? 0,
        tipoFile,
        tipoDati,
        formData
      );
    },
  },
};
</script>

<style scoped></style>
