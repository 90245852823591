var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-card',{staticClass:"ma-8 pa-8",attrs:{"elevation":"4"}},[_c('v-card-title',{domProps:{"innerHTML":_vm._s(
        'Verifica attestato dello studente ' +
        _vm.attestatoCampionato.certificato.studente.nome +
        ' ' +
        _vm.attestatoCampionato.certificato.studente.cognome
      )}}),_c('v-alert',{staticClass:"justify-center align-center",attrs:{"type":"info","max-width":"1400"}},[_vm._v(" La verifica del singolo attestato viene salvata in memoria solo dopo aver confermato le modifiche dopo il click sul pulsante di estendi campione o compilazione checklist(se attiva). ")]),_c('v-form',{ref:"formVerificaAttestato",attrs:{"lazy-validation":"","rules":_vm.validateField.esito},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{attrs:{"label":"Esito del controllo","rules":_vm.validateField.esito},on:{"input":_vm.setValueImportoAmmesso,"change":_vm.setValueImportoAmmesso},model:{value:(
              _vm.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id
            ),callback:function ($$v) {_vm.$set(_vm.formVerificaAttestato.tipologiaEsitoVerificaCertificato, "id", $$v)},expression:"\n              formVerificaAttestato.tipologiaEsitoVerificaCertificato.id\n            "}},_vm._l((_vm.opzioniVerificaAttestato),function(opzioniVerificaAttestato,indice){return _c('v-radio',{key:indice,attrs:{"label":opzioniVerificaAttestato.testo,"value":opzioniVerificaAttestato.valore}})}),1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"auto-grow":"","filled":"","background-color":"grey lighten-2","color":"black","label":"Motivazione","rules":[_vm.validateFieldMotivazione()]},model:{value:(_vm.formVerificaAttestato.motivazione),callback:function ($$v) {_vm.$set(_vm.formVerificaAttestato, "motivazione", $$v)},expression:"formVerificaAttestato.motivazione"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Importo ammesso","type":"number","disabled":_vm.formVerificaAttestato.tipologiaEsitoVerificaCertificato.id !== 3,"rules":[_vm.validateFieldImporto()]},on:{"input":_vm.validateFieldImporto},model:{value:(_vm.formVerificaAttestato.importoAmmesso),callback:function ($$v) {_vm.$set(_vm.formVerificaAttestato, "importoAmmesso", $$v)},expression:"formVerificaAttestato.importoAmmesso"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.verificaAttestato}},[_vm._v(" Verifica Attestato")]),_c('v-btn',{staticClass:"ml-4",on:{"click":_vm.closeDialog}},[_vm._v(" Indietro")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }