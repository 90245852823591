<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    <v-row class="mt-8">
      <v-col cols="8">
        <h1>Dettaglio dichiarazione</h1>
      </v-col>
      <v-col cols="4"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-if="loadingData">
      <v-col>
        <v-progress-linear indeterminate color="blue" />
      </v-col>
    </v-row>
    <div v-if="!loadingData">
      <v-row class="my-2">
        <v-col cols="2">
          <v-btn
            text
            class="blue--text"
            @click="getFileRiepilogoDichiarazioni(0, 'b')"
            ><v-icon class="mr-2"> mdi-microsoft-excel </v-icon>
            Esporta Excel
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            text
            class="blue--text"
            @click="getFileRiepilogoDichiarazioni(1, 'b')"
            ><v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
            Esporta PDF
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="dichiarazione.conti"
            :items-per-page="10"
            class="elevation-1"
            :loading="loadingData"
            loading-text="Caricamento dati in corso..."
            ><!-- eslint-disable -->
          <template v-slot:item.statoConto="{ item }">
            <v-chip outlined pill :color="colorState(item.stato)">
              {{ item.stato.descrizione }}
            </v-chip>
          </template>-->
          <template v-slot:item.actions="{ item }">
            <router-link
                class="v-btn"
                :to="{
                          name: 'dettaglioContoView',
                          params: {
                            conto: item,
                            idDichiarazione: dichiarazione.id,
                            idStrutture: dichiarazione.idStrutture,
                            editable: editable,
                          },
                        }"
            >
              Dettagli
            </router-link>
          </template>
          <!-- eslint-enable -->
          </v-data-table>
          <v-row class="my-2">
            <v-col cols="4">
              <h3>Allegati</h3>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="4">
              <p>
                Dichiarazione di tracciabilità dei flussi finanziari
                <b>{{
                  dichiarazione.fileDocumentazioneFirmata.nomeOriginale
                }}</b>
              </p>
              <div>
                <a @click="getFileDichiarazione()">
                  <v-icon>mdi-file-download-outline</v-icon> Scarica il file
                </a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-6">
      <v-col>
        <v-btn
          text
          class="blue--text"
          @click="$router.push(`/scrivania/flussi-finanziari`)"
        >
          <v-icon>mdi-arrow-left-thin</v-icon>Torna alla lista delle
          dichiarazioni
        </v-btn>
      </v-col>
      <v-col v-if="editable && !loadingData" class="d-flex flex-row-reverse">
        <v-btn
          v-if="dichiarazione.stato.id === 1"
          color="primary"
          @click="dialogConferma = true"
        >
          <v-icon class="pr-2">mdi-eye-check</v-icon> Presa visione
        </v-btn>
      </v-col>
    </v-row>
    <dialog-conferma
      @callback="presaVisione()"
      :dialog.sync="dialogConferma"
      @close-dialog="dialogConferma = false"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import { mapGetters, mapState } from "vuex";
import DichiarazioneFlussiRepository from "@/api/ministero/DichiarazioneFlussiRepository";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";
import DialogConferma from "@/components/DialogConferma.vue";

export default {
  name: "dettaglioDichiarazioneView",
  data: () => ({
    dichiarazione: null,
    conti: [],
    loadingData: false,
    dialogConferma: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    headers: [
      {
        text: "ID Conto Bancario",
        align: "start",
        value: "id",
      },
      { text: "IBAN", value: "ibanAssociato", sortable: false },
      { text: "Acceso presso", value: "accesoPresso", sortable: false },
      { text: "intestato a", value: "intestatario", sortable: false },
      { text: "Stato", value: "statoConto", sortable: false },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  components: { DialogConferma, BreadcrumbComponent, RiquadroProfiloRuolo },
  props: {
    idDichiarazione: {
      type: Number,
      required: true,
    },
    idStrutture: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Dichiarazione di tracciabilità dei flussi finanziari",
            disabled: false,
            to: `/scrivania/flussi-finanziari`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio dichiarazione",
            disabled: true,
            to: `/scrivania/flussi-finanziari/dettaglio-dichiarazione`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleDgUdm",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idDichiarazione && this.idStrutture) {
        this.getSingolaDichiarazione();
      }
    },
    async getSingolaDichiarazione() {
      this.loadingData = true;
      this.dichiarazione =
        await DichiarazioneFlussiRepository.getSingolaDichiarazione(
          this.idStrutture,
          this.idDichiarazione
        );
      this.loadingData = false;
    },
    colorState(item) {
      switch (item.id) {
        case 1:
          return "warning";
        case 2:
          return "success";
        case 3:
          return "danger";
        default:
          return "";
      }
    },
    async getFileDichiarazione() {
      await MinisteroRepository.downloadFile(
        this.dichiarazione.anno,
        this.dichiarazione.fileDocumentazioneFirmata.id,
        this.dichiarazione.fileDocumentazioneFirmata.nomeOriginale
      );
    },
    async presaVisione() {
      try {
        await DichiarazioneFlussiRepository.presaVisioneConti(
          this.dichiarazione.idStrutture,
          this.dichiarazione.id
        );
        this.snackbarText = "Procedura di presa visione eseguita correttamente";
        this.snackbarColor = "success";
        this.snackbar = true;
        setTimeout(() => {
          this.initialize();
        }, 3000);
      } catch (e) {
        console.log(e);
        this.snackbarText = "Errore nella procedura di presa visione.";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    async getFileRiepilogoDichiarazioni(tipoFile, tipoDati) {
      const formData = new FormData();
      formData.append("dati", JSON.stringify(this.dichiarazione));
      await DichiarazioneFlussiRepository.downloadFileRiepilogoDichiarazioni(
        this.idStrutture ?? 0,
        tipoFile,
        tipoDati,
        formData
      );
    },
  },
};
</script>

<style scoped></style>
