<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
      <v-row>
        <v-col cols="8">
          <h1>Incarichi assegnati</h1>
          <p>
            Lista dei rendiconti con dettaglio del soggetto a cui è stato
            assegnato l'incarico.
          </p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="" @click="esportaSuExcel()">
            Esporta su excel <v-icon class="pl-1"> mdi-file-excel </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col col="8">
          <v-text-field
            solo
            v-model="ricerca"
            append-icon="mdi-magnify"
            label="Cerca per N° rendiconto"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="assegnazioni"
            :search="ricerca"
            :items-per-page="10"
            class="elevation-1"
            :loading="loadingRendiconti"
            loading-text="Caricamento dati in corso..."
            no-data-text="Nessun rendiconto disponibile"
            ><!-- eslint-disable -->
            <template v-slot:item.incaricato="{ item }">
              <span>{{item.incaricato.anagrafica.cognome}} {{item.incaricato.anagrafica.nome}}</span>
            </template>
            <template v-slot:item.dataAssegnazione="{ item }">
              <span>{{item.dataAssegnazione | formatDate}}</span>
            </template>
            <template v-slot:item.dataAccettazione="{ item }">
              <span>{{item.dataAccettazione | formatDate}}</span>
            </template>
            <template v-slot:item.accettato="{ item }">
              <span>{{ item.statoAssegnazione ? 'Si' : 'No'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="v-icon"
                      v-bind="attrs"
                      v-on="on"
                      :icon="true"
                  >
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item class="item-menu">
                      <v-list-item-content>
                        <v-list-item-action
                          @click="
                            downloadDichiarazione(parseInt(item.incaricato.id))
                          "
                        >
                          Visualizza dichiarazione
                        </v-list-item-action>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isRoleConsulenza || isRoleResponsabileControllo"
                      class="item-menu"
                      :disabled="disableRevoca(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-action
                          @click="confermaEliminaAssegnazione(parseInt(item.id))"
                        >
                          Revoca incarico
                        </v-list-item-action>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>
      <dialog-conferma
        @callback="eliminaAssegnazione()"
        :dialog.sync="dialogRevoca"
        @close-dialog="dialogRevoca = false"
      />
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        shaped
        :color="snackbarColor"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <div class="mt-6">
        <TornaIndietroComponent text="Torna a Controlli" />
      </div>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import DialogConferma from "@/components/DialogConferma.vue";

export default {
  name: "IncarichiAssegnatiUdmView",
  data: () => ({
    idAssegnazione: 0,
    dialogRevoca: false,
    loadingRendiconti: false,
    assegnazioni: [],
    ricerca: "",
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    headers: [
      {
        text: "ID rendiconto",
        align: "start",
        value: "rendiconto.id",
      },
      {
        text: "Soggetto attuatore",
        value: "rendiconto.programma.nomeIstituzione",
      },
      { text: "Data assegnazione", value: "dataAssegnazione" },
      { text: "Soggetto incaricato", sortable: false, value: "incaricato" },
      { text: "Tipologia controllo", value: "tipo" },
      {
        text: "Incarico accettato",
        align: "center",
        sortable: false,
        value: "accettato",
      },
      { text: "Data accettazione", value: "dataAccettazione" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  components: {
    DialogConferma,
    TornaIndietroComponent,
    RiquadroProfiloRuolo,
    BreadcrumbComponent,
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Incarichi assegnati",
            disabled: true,
            to: `/scrivania/controlli/incarichi-assegnati`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getAssegnazioni(this.anno);
      }
    },
    async getAssegnazioni(anno) {
      this.loadingRendiconti = true;
      this.assegnazioni =
        await ControlliUdmRepository.getAssegnazioniIncaricoUdm(anno);
      this.loadingRendiconti = false;
    },
    async downloadDichiarazione(idIncaricato) {
      try {
        await ControlliRepository.getDichiarazioneFirmata(
          this.anno,
          idIncaricato
        );
      } catch (e) {
        console.log(e);
        if (e.response.status === 400) {
          this.snackbarText =
            "L'incaricato non ha ancora caricato la dichiarazione";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        }
      }
    },
    confermaEliminaAssegnazione(idAssegnazione) {
      this.dialogRevoca = true;
      this.idAssegnazione = idAssegnazione;
    },
    async eliminaAssegnazione() {
      try {
        await ControlliUdmRepository.eliminaAssegnazioneUdm(
          this.anno,
          this.idAssegnazione
        );
        this.idAssegnazione = 0;
        this.snackbarText = "Incarico revocato correttamente";
        this.snackbarColor = "success";
        this.snackbar = true;
        setTimeout(() => {
          this.initialize();
        }, 3000);
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "Errore durante la procedura di eliminazione incarico";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    disableRevoca(item) {
      if (
        item.dataAccettazione &&
        (item.rendiconto.verificaFormaleRendiconto ||
          item.rendiconto.verificaRendicontoUdm)
      ) {
        return true;
      }
      return false;
    },
    async esportaSuExcel() {
      const formBody = new FormData();
      formBody.append(
        "incarichiAssegnati",
        JSON.stringify({ incarichi: this.assegnazioni })
      );
      await ControlliUdmRepository.esportaSuExcelIncarichiAssegnati(
        this.anno,
        formBody
      );
    },
  },
};
</script>

<style scoped></style>
