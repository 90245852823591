<template>
  <v-row v-if="loadingComponent">
    <v-col cols="3">
      <v-text-field
        label="Importo totale rendicontato"
        v-model="
          formAnagraficaRendicontiPrecedenti.importiTotaliRendicontiPrecedenti
        "
        disabled
    /></v-col>
    <v-col cols="3"
      ><v-text-field
        label="Importo totale costi preced. ammessi"
        disabled
        v-model="
          formAnagraficaRendicontiPrecedenti.importiTotaliCostiPrecedenti
        "
    /></v-col>
    <v-col cols="6"></v-col>
  </v-row>
</template>
<script>
import ControlliRepository from "@/api/ministero/ControlliRepository";

export default {
  name: "AnagraficaRendicontiPrecedentiComponent",
  components: {},
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    formAnagraficaRendicontiPrecedenti: {
      // campi rendiconti precedenti presentati
      importiTotaliRendicontiPrecedenti: 0,
      importiTotaliCostiPrecedenti: 0,
    },
    loadingComponent: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.rendiconto) {
        this.getRendicontiVerificatiPrecedenti();
      }
    },
    async getRendicontiVerificatiPrecedenti() {
      // recupero l'id strutture e il profilo del soggetto attuatore che ha svolto il rendiconto
      const capofila = this.rendiconto.capofila;
      const idStrutture = this.rendiconto.idStrutture;
      const anno = this.rendiconto.anno;

      // recupero tutte le verifiche fatte su rendiconti precedenti, tranne che su quello che sto verificando ora
      const verificheRendicontiPrecedenti =
        await ControlliRepository.getVerificheRendicontiIstituzioniPrecedenti(
          idStrutture,
          anno,
          capofila,
          this.rendiconto.id
        );

      if (
        verificheRendicontiPrecedenti &&
        verificheRendicontiPrecedenti.length
      ) {
        this.calcolaSommaImportiRendicontiPrecedenti(
          verificheRendicontiPrecedenti
        );
      } else {
        this.loadingComponent = true;
      }
    },
    calcolaSommaImportiRendicontiPrecedenti(verificheRendicontiPrecedenti) {
      let sommaTotaleImportiAmmessi = 0;
      let sommaTotaleImportiRendicontati = 0;
      verificheRendicontiPrecedenti.forEach((element) => {
        sommaTotaleImportiRendicontati += element.rendiconto.importoRichiesto;
        sommaTotaleImportiAmmessi += element.rendiconto.importoAmmesso;
      });

      this.formAnagraficaRendicontiPrecedenti.importiTotaliCostiPrecedenti =
        this.formatNumber(sommaTotaleImportiAmmessi);
      this.formAnagraficaRendicontiPrecedenti.importiTotaliRendicontiPrecedenti =
        this.formatNumber(sommaTotaleImportiRendicontati);

      /*let sommaTotaleImportiAmmessi = 0;
      let sommaTotaleImportiRendicontati = 0;
      verificheRendicontiPrecedenti.forEach((element) => {
        let currentSum = 0;
        if (
          element.importiCalcolatiVerificheRendiconti &&
          element.importiCalcolatiVerificheRendiconti.length
        ) {
          const importiCalcolati = element.importiCalcolatiVerificheRendiconti;
          importiCalcolati.forEach((element1) => {
            currentSum += element1.importoAmmesso;
          });
        }
        sommaTotaleImportiAmmessi += currentSum;
        sommaTotaleImportiRendicontati += element.rendiconto.importoRichiesto;
      });
      this.formAnagraficaRendicontiPrecedenti.importiTotaliCostiPrecedenti = (
        parseFloat(this.rendiconto.importoRichiesto) +
        parseFloat(sommaTotaleImportiAmmessi)
      ).toFixed(2);
      this.formAnagraficaRendicontiPrecedenti.importiTotaliRendicontiPrecedenti =
        parseFloat(sommaTotaleImportiRendicontati).toFixed(2);*/

      this.loadingComponent = true;
    },
    formatNumber(importo) {
      if (importo) {
        return new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(importo);
      }
      return "";
    },
  },
};
</script>
