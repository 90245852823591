<template>
  <v-container fluid>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-main>
      <v-row class="mb-6">
        <v-col cols="9">
          <h1 style="color: #1a237e">Firma Checklist</h1>
        </v-col>
        <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="6">
          <step-verifica-formale-udm
            :numero-step="3"
          ></step-verifica-formale-udm>
        </v-col>
      </v-row>
    </v-main>
    <v-main v-if="loadingComponents">
      <DatiFirmatario
        v-if="rendiconto.assegnazioneControlloUdm"
        :responsabile-controllo="rendiconto.assegnazioneControlloUdm.incaricato"
      />
      <v-card-text v-else>
        <v-alert type="warning">
          Attenzione! Il rendiconto non è stato assegnato ad alcun incaricato di
          controllo.
        </v-alert>
      </v-card-text>
    </v-main>
    <v-main v-if="rendiconto">
      <RiepilogoGeneraleRendiconto :rendiconto="rendiconto" />
    </v-main>
    <v-main v-if="loadingComponents">
      <CaricamentoFileVerificaFormale
        :rendiconto="rendiconto"
        :tipo-caricamento="1"
        @callback="aggiornamentoVerificaFormaleRendiconto"
        @inizializzazionePagina="inizializzazionePagina"
      />
      <v-snackbar :color="snackbar.color" v-model="snackbar.value">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
    <v-main v-if="rendiconto">
      <v-row>
        <v-col cols="5">
          <v-btn color="white" class="w-100 align-center justify-center">
            <router-link
              :to="{
                name: 'VerificaFormaleUdmChecklistsView',
                params: { idRendiconto: idRendicontoSelezionato },
              }"
            >
              Indietro
            </router-link></v-btn
          ></v-col
        >
        <v-col cols="2"></v-col>
        <v-col v-if="verificaFormaleRendiconto.fileVerificaCheklist" cols="5">
          <v-btn
            color="primary"
            v-html="'Termina verifica'"
            class="v-btn primary float-end"
            @click="dialogConferma = true"
          ></v-btn>
          <!--<router-link
            class="v-btn primary float-end"
            :disabled="verificaFormaleRendiconto.fileVerificaCheklist == null"
            :to="{
              name: 'verificaVerbaleEsitoControlliRendiconto',
              params: { idRendiconto: this.idRendicontoSelezionato },
            }"
          >
            <span class="ma-1 pa-1"> Continua </span>
          </router-link>-->
        </v-col>
        <v-col v-else cols="5">
          <v-btn
            color="primary"
            v-html="'Continua'"
            class="v-btn primary float-end"
            :disabled="true"
          ></v-btn>
        </v-col>
      </v-row>
    </v-main>
    <dialog-conferma
      textConferma="Si sta procedendo con la <b>chiusura definivita</b> della verifica formale <br> Desideri confermare l'operazione selezionata?"
      :dialog.sync="dialogConferma"
      @callback="terminaVerificaFormale()"
      @close-dialog="dialogConferma = false"
    />
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";
import { mapGetters, mapState } from "vuex";
import DatiFirmatario from "@/components/Controlli/VerificheRendiconti/DatiFirmatarioComponent.vue";
import RendicontiRepository from "@/api/ministero/RendicontiRepository";
import StepVerificaFormaleUdm from "@/components/Controlli/Udm/VerificheFormali/StepVerificaFormaleUdm";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import CaricamentoFileVerificaFormale from "@/components/Controlli/Udm/VerificheFormali/CaricamentoFileVerificaFormaleComponent";
import DialogConferma from "@/components/DialogConferma.vue";
import RiepilogoGeneraleRendiconto from "@/components/Controlli/RiepilogoGeneraleRendicontoComponent.vue";

export default {
  name: "VerificaFormaleUdmFirmaChecklistView",
  components: {
    RiepilogoGeneraleRendiconto,
    CaricamentoFileVerificaFormale,
    DatiFirmatario,
    BreadcrumbComponent,
    StepVerificaFormaleUdm,
    RiquadroProfiloRuolo,
    DialogConferma,
  },
  computed: {
    ...mapState(["anno", "campioneAggiornato", "campioneEstratto", "user"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleResponsabileControllo",
      "isRoleIncaricatoControllo",
    ]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania`,
          exact: true,
          link: true,
        },
        {
          text: "Controlli",
          disabled: false,
          to: `/scrivania/udm/controlli`,
          exact: true,
          link: true,
        },
        {
          text: "Controllo formale rendiconti",
          disabled: false,
          to: `/scrivania/udm/controlli/controllo-formale-rendiconti`,
          exact: true,
          link: true,
        },
        {
          text: "Firma Checklist",
          disabled: true,
          to: `/scrivania/udm/controlli/controllo-formale-rendiconti/${this.idRendiconto}/verifiche-checklist`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.loadinPage = false;
      this.initialize();
    },
  },
  data: () => ({
    dialogConferma: false,
    loadingComponents: false,
    idRendicontoSelezionato: null,
    rendiconto: null,
    verbaleCampionamento: null,
    verificaFormaleRendiconto: null,
    snackbar: {
      text: null,
      value: false,
      color: null,
    },
    form: {
      uploadedFiles: null,
      currentStep: 6,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (
        (this.isRoleConsulenza || this.isRoleIncaricatoControllo) &&
        this.$route.params.idRendiconto
      ) {
        this.idRendicontoSelezionato = this.$route.params.idRendiconto;
        this.getVerbaleCampionamentoRendiconto();
      }
    },
    async getVerbaleCampionamentoRendiconto() {
      this.verbaleCampionamentoRendiconto =
        await ControlliUdmRepository.getSingoloVerbaleControlliFormaliUdm(
          parseInt(this.anno),
          this.idRendicontoSelezionato,
          3
        );
      if (this.verbaleCampionamentoRendiconto) {
        this.rendiconto = this.verbaleCampionamentoRendiconto.rendiconto;
        this.verificaFormaleRendiconto =
          this.rendiconto.verificaFormaleRendiconto;
        this.verificaFormaleRendiconto.checklistVerificaRendiconto.sort(
          (a, b) => {
            return a.id - b.id;
          }
        );
        this.loadingComponents = true;
      }
    },
    async aggiornamentoVerificaFormaleRendiconto(form) {
      const idStrutture = this.rendiconto.idStrutture;
      const idRendiconto = this.rendiconto.id;
      const idVerifica = this.rendiconto.verificaFormaleRendiconto.id;

      const step = 3;

      const formBody = new FormData();
      formBody.append(
        "fileVerificaFormaleChecklistRendiconto",
        form.uploadedFiles
      );

      await RendicontiRepository.modificaVerificaFormaleRendiconto(
        idStrutture,
        idRendiconto,
        idVerifica,
        step,
        formBody
      )
        .then((data) => {
          if (data) {
            //setTimeout(() => this.goToNextStep(), 1500);
            this.setValueSnackbar(
              "Il file di verifica formale delle checklist è stato caricato correttamente.",
              "success"
            );
            this.inizializzazionePagina();
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.setValueSnackbar(
            "E' stato riscontrato un errore di server durante la richiesta.",
            "red"
          );
        });
    },
    setValueSnackbar(testo, color) {
      this.snackbar.text = testo;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
    inizializzazionePagina() {
      this.loadingComponents = false;
      this.initialize();
    },
    goToNextStep() {},
    async terminaVerificaFormale() {
      try {
        const idVerifica = parseInt(
          this.rendiconto.verificaFormaleRendiconto.id
        );
        const idRendiconto = parseInt(this.rendiconto.id);
        await ControlliUdmRepository.terminaVerificaFormale(
          this.anno,
          idRendiconto,
          idVerifica
        );
        await this.$router.push({
          name: "ListaVerbaliFormaleUdm",
        });
      } catch (e) {
        console.log(e);
        if (e.response.status === 403) {
          this.snackbarText =
            "Impossibile terminare verifica per mancanza di esito finale";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        } else {
          this.snackbarText =
            "E' stato riscontrato un errore di server durante la richiesta.";
          this.snackbarColor = "red accent-2";
        }
      }
      this.snackbar = true;
    },
  },
};
</script>
