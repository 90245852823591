import Repository from "@/api/Repository";

export default {
  async getRichiesteAnticipoSaldoAteneo(idStrutture, anno, capofila) {
    const url = `api/istituto/${idStrutture}/richieste/anno/${anno}/${capofila}`;
    return (await Repository.get(url)).data;
  },
  async getLegaleRappresentante(idStrutture, idProgramma) {
    const url = `api/istituto/${idStrutture}/richieste/${idProgramma}/legale-rappresentante`;
    return (await Repository.get(url)).data;
  },
  async getReferenteProgrammaIstituzione(idStrutture, capofila) {
    const url = `api/istituto/${idStrutture}/profilo/${capofila}/referente-programma`;
    return (await Repository.get(url)).data;
  },
};
