<template>
  <v-card elevation="5" v-if="loadingComponent">
    <v-data-table :items="attestati" :headers="headersTable">
      <!--eslint-disable -->
      <template v-slot:item.scuolaStudente="{item}">
        <v-card-text v-if="item.corsoCampionato.anno === 2022" v-html="item.corsoCampionato.accordo.istituto.denominazioneScuola" />
        <v-card-text v-else v-html="item.certificato.studente.corsiStudente[0].istituto.denominazioneScuola" />
      </template>
      <template v-slot:item.nomeCognome="{ item }">
        <v-card-text
            v-html="item.certificato.studente.nome + ' ' + item.certificato.studente.cognome + ''"
        />
      </template>
      <template v-slot:item.estrattoDaAlgoritmo="{ item }">
        {{ item.estrattoDaAlgoritmo ? 'Si' : 'No'}}
      </template>
      <template v-slot:item.importoTariffa="{ item }">
        <v-card-text v-html="'16.67 €'" />
      </template>
      <template v-slot:item.istitutoScolastico="{ item }">
        <v-card-text v-if="anno === 2022" v-html="corsiCampionati.corsoCampionato.accordo.istituto.denominazioneScuola" />
        <v-card-text v-else v-html="item.certificato.studente.corsiStudente[0].istituto.denominazioneScuola" />
      </template>
      <template v-slot:item.tipologiaEsitoVerificaCertificato="{ item }">
        <v-card-text v-if="item.tipologiaEsitoVerificaCertificato" >
          {{ item.descrizioneEsito }}
        </v-card-text>
        <v-card-text v-else>
          In Attesa di verifica
        </v-card-text>
      </template>
      <template v-slot:item.valoreAttribuito="{item}">
        {{ item.certificato.valoreAttribuito }} €
      </template>
      <template v-slot:item.importoAmmesso="{item}">
        <span v-if="item.importoAmmesso != null" v-html="item.importoAmmesso + '€'" />
      </template>
      <template v-if="conMenuAzioni" v-slot:item.azioni="{ item, index }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="v-icon"
                v-bind="attrs"
                v-on="on"
                :icon="true"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template><!-- eslint-enable -->
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                class="item-menu"
                v-for="(itemMenu, i) in menuAction"
                :key="i"
                :disabled="
                  /*controlloDisabilitazione(
                      i,
                      item.stato,
                      item.assegnazioneControllo ?? 0
                    )*/
                  false
                "
                @click="gestioneMenuAzioniAttestato(item, i)"
                >{{ itemMenu }} </v-list-item
              ><!-- eslint-enable -->
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
      <!-- eslint-enable--></v-data-table
    >
    <v-card-actions>
      <v-dialog v-model="dialogVerificaAttestato">
        <ContenutoDialogVerifcaAttestato
          :attestato-campionato="attestatoInVerifica"
          @close-dialog="dialogVerificaAttestato = false"
          :dialog.sync="dialogVerificaAttestato"
          @callback="verificaSingoloAttestato"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>
<script>
import ContenutoDialogVerifcaAttestato from "@/components/Controlli/VerificheRendiconti/ContenutoDialogVerificaAttestatoComponent.vue";

export default {
  name: "TableAttestatiCampionati",
  components: { ContenutoDialogVerifcaAttestato },
  props: {
    itemsAttestati: {
      type: Array,
      required: false,
    },
    headersTable: {
      type: Array,
      required: true,
    },
    conMenuAzioni: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    attestati: [],
    menuAction: [
      "Dettaglio corso",
      "Verifica",
      "Dettagli Integrazione e modifiche",
      "Visualizza attestato",
    ],
    dialogVerificaAttestato: false,
    attestatoInVerifica: null,
    loadingComponent: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.itemsAttestati && this.itemsAttestati.length) {
        this.attestati = this.itemsAttestati;
        this.loadingComponent = true;
      }
    },
    gestioneMenuAzioniAttestato(item, index) {
      switch (index) {
        case 0:
          this.$router.push({
            name: "dettaglioCorso",
            params: {
              idRendiconto: this.rendiconto.id,
              idCorso: item.corsoCampionato.id,
              idStrutture: item.corsoCampionato.idStrutture,
            },
          });
          break;

        case 1:
          this.attestatoInVerifica = item;
          this.loadingTableAttestati = true;
          this.dialogVerificaAttestato = true;
          break;

        case 2:
          break;

        case 3:
          break;
      }
    },
    verificaSingoloAttestato(form, item) {
      this.loadingTableAttestati = true;
      item.motivazione = form.motivazione;
      item.tipologiaEsitoVerificaCertificato = form.esito;
      item.descrizioneEsito = form.descrizioneEsito;

      const idCertificato = item.certificato.id;
      this.certificatiCampionati = this.at.map((element) => {
        if (element.certificato.id === idCertificato) {
          element = item;
        }
        return element;
      });
      this.dialogVerificaAttestato = false;
      this.loadingTableAttestati = false;
    },
  },
};
</script>
