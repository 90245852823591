<template>
  <v-main>
    <v-stepper v-model="currentStep" color="primary" elevation="2">
      <v-stepper-header>
        <template v-for="(step, index) in steps">
          <v-stepper-step
            :key="index + 1"
            :step="index + 1"
            :complete="index < currentStep"
          >
            <span v-html="step" />
          </v-stepper-step>
          <v-divider
            v-if="index < steps.length - 1"
            :key="index + `divider`"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
  </v-main>
</template>
<script>
export default {
  name: "StepRendicontazioni",
  components: {},
  props: {
    numeroStep: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    steps: [
      "Campione attestati",
      "Anagrafiche",
      "Checklist e esito",
      "Firma checklist",
      "Verbale esito controlli",
    ],
    currentStep: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.numeroStep) {
        this.currentStep = this.numeroStep;
      }
    },
  },
};
</script>
