<template>
  <v-card elevation="3" class="text-center" v-if="rendicontoSelezionato">
    <v-row>
      <v-col class="w-25">
        <v-card-text> <b> ID Verbale </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> Data Verbale </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text> <b> N° Rendiconto </b> </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b> Periodo di riferimento </b>
        </v-card-text>
      </v-col>
      <v-col class="w-25">
        <v-card-text>
          <b>
            Importo Totale Rendicontato
            <i>(Comprensivo dell'eventuale quota privata)</i></b
          >
        </v-card-text>
      </v-col>
      <!--<v-col class="w-25">
        <v-card-text>
          <b> CUP </b>
        </v-card-text>
      </v-col>-->
    </v-row>
    <v-row>
      <v-col class="w-25">
        <p>{{ rendicontoSelezionato.verificaRendicontoUdm.id }}</p>
      </v-col>
      <v-col class="w-25">
        <p>
          {{
            rendicontoSelezionato.verificaRendicontoUdm.dataInizioControllo
              ? getDate(
                  rendicontoSelezionato.verificaRendicontoUdm
                    .dataInizioControllo
                )
              : "--."
          }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>{{ rendicontoSelezionato.id }}</p>
      </v-col>
      <v-col class="w-25">
        <p>
          {{
            rendicontoSelezionato.quadrimestre.tipologiaQuadrimestre.descrizione
          }}
        </p>
      </v-col>
      <v-col class="w-25">
        <p>
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(rendicontoSelezionato.importoRichiesto)
          }}
        </p>
      </v-col>
      <!--<v-col class="w-25">
        <v-card-text>
          <p>{{ rendicontoSelezionato.programma.cup }}</p>
        </v-card-text>
      </v-col>-->
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "riferimentiVerbaleUdm",
  components: {},
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rendicontoSelezionato: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.rendiconto) {
        this.rendicontoSelezionato = this.rendiconto;
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
<style scoped>
.sottotitolo {
  color: #1a237e;
  margin-left: 1%;
  font-weight: bold;
}
</style>
