<template>
  <v-row class="px-4" v-if="editable && loadingComponent">
    <v-col cols="3">
      <v-text-field
        label="Nome Amministrazione"
        v-model="formAnagraficaAmministrazione.nomeAmministrazione"
        disabled
    /></v-col>
    <v-col cols="3">
      <v-text-field
        label="Responsabile Unita' di Missione"
        disabled
        v-model="formAnagraficaAmministrazione.responsabileUnitaMissione"
    /></v-col>
    <v-col cols="3">
      <v-text-field
        label="DG Responsabile di misura"
        disabled
        v-model="formAnagraficaAmministrazione.responsabileDgDiMisura"
    /></v-col>
    <v-col cols="3">
      <v-text-field
        label="Responsabile di misura"
        disabled
        v-model="formAnagraficaAmministrazione.responsabileMisura"
    /></v-col>
  </v-row>
</template>
<script>
export default {
  name: "AnagraficaRendicontoProgetto",
  components: {},
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    formAnagraficaAmministrazione: {
      nomeAmministrazione: "Ministero dell'università e della ricerca",
      responsabileUnitaMissione: "Dr. Antonio Di Donato",
      responsabileDgDiMisura:
        "Direzione generale delle istituzioni della formazione superiore ",
      responsabileMisura: "D.ssa Marcella Gargano",
    },
    loadingComponent: false,
  }),
  methods: {
    initialize() {
      this.loadingComponent = true;
    },
  },
};
</script>
