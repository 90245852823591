<template>
  <div v-if="loadingComponent">
    <v-row class="px-4 mb-6">
      <v-col>
        <h2 class="titoloBlu">Riepilogo finanziario</h2>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="loadingComponent">
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Proposta risorse attribuibili</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.propostaRisorseAttribuibili)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Assegnazione finale</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.assegnazioneFinale)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Assegnazione aggiuntiva</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.assegnazioneAggiuntiva)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Importo anticipo erogato</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.importoAnticipoErogato)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Importo ammesso in rendiconti precedenti - DG Controlli</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.importoAmmessoDG)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Importo ammesso in rendiconti precedentri - UDM</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.importoAmmessoUDM)
            }}</span>
          </v-col>
        </v-row></v-col
      >
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Importo totale rendicontato</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.importoTotaleRendicontato)
            }}</span>
          </v-col>
        </v-row></v-col
      >
      <v-col cols="3">
        <v-row>
          <v-col class="pb-0">
            <span>Rendicontato a saldo</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="importo">{{ rendiconto.finale ? "Si" : "No" }}</span>
          </v-col>
        </v-row></v-col
      >
    </v-row>
    <v-row class="px-4 mt-6">
      <v-col>
        <h5 class="titoloBlu">STATO DI AVANZAMENTO RENDICONTAZIONE</h5>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col class="pb-0">
        <small
          ><b>
            {{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(riepilogo.importoTotaleRendicontato)
            }}</b
          ></small
        ></v-col
      >
      <v-col class="pb-0 d-flex flex-row-reverse">
        <small
          ><b>
            {{
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(risorseAssegnate)
            }}</b
          ></small
        >
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col class="pt-0">
        <v-progress-linear
          class="v-progress-linear"
          v-model="statoAvanzamento"
          height="25"
        >
          <strong>{{ Math.ceil(statoAvanzamento) }}%</strong>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col class="pt-1 d-flex flex-row-reverse">
        <span class="px-4"
          ><v-icon color="#2196F3">mdi-circle</v-icon> Importo
          rendicontato</span
        >
        <span
          ><v-icon color="#BCDFFB">mdi-circle</v-icon> Risorse assegnate</span
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";
import { mapState } from "vuex";

export default {
  name: "RiepilogoFinanziario",
  components: {},
  props: {
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["anno"]),
  },
  created() {
    this.initialize();
  },
  data: () => ({
    riepilogo: {
      propostaRisorseAttribuibili: 0,
      assegnazioneFinale: 0,
      assegnazioneAggiuntiva: 0,
      importoAnticipoErogato: 0,
      importoAmmessoDG: 0,
      importoAmmessoUDM: 0,
      importoTotaleRendicontato: 0,
      statoAvanzamento: 0,
    },
    statoAvanzamento: 0,
    risorseAssegnate: 0,
    loadingComponent: false,
  }),
  methods: {
    initialize() {
      this.getRiepilogoFinanziario();
    },
    async getRiepilogoFinanziario() {
      let idRendiconto = this.rendiconto ? this.rendiconto.id : null;
      let anno = this.anno;
      this.riepilogo = await ControlliUdmRepository.getRiepilogoFinanziario(
        anno,
        idRendiconto
      );
      // calcola il tasso percentuale
      let importoTotaleRendicontato = this.riepilogo.importoTotaleRendicontato;
      let importoTotaleAssegnato =
        this.riepilogo.assegnazioneFinale +
        this.riepilogo.assegnazioneAggiuntiva;
      this.statoAvanzamento =
        (importoTotaleRendicontato / importoTotaleAssegnato) * 100;
      this.risorseAssegnate = importoTotaleAssegnato;
      this.loadingComponent = true;
    },
  },
};
</script>
<style scoped>
.importo {
  font-weight: bolder;
  font-size: 1.5em;
  color: #2986cc;
}
.titoloBlu {
  color: #1a237e;
}
.v-progress-linear {
  pointer-events: none;
}
</style>
