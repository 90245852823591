<template>
  <v-card v-if="lezioni && studenti" class="my-4">
    <div>
      <v-row>
        <v-col class="pb-0">
          <v-data-table
            :headers="headers"
            :items="studentiAttestati"
            :items-per-page="5"
            :loading="loadingStudenti"
            loading-text="Caricamento studenti in corso"
            class="elevation-1 table-custom"
            ><!-- eslint-disable -->
            <template v-slot:item.dataNascita="{ item }">
              <span>{{item.dataNascita | formatDate}}</span>
            </template>
            <template v-slot:item.importo="{ item }">
              <span>16.67€</span>
            </template>
            <template v-slot:item.totOre="{ item }">
              {{ getTotOre(item.idStudente) }}
            </template>
            <template v-slot:item.percentualeOre="{ item }">
              <span style="color:red" v-if="getPercentualeOre(item.idStudente)<70" >
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
              <span v-else>
                {{ getPercentualeOre(item.idStudente) }} %
              </span>
            </template>
            <template v-slot:item.valore="{ item }">
              {{ calcolaImportoStudente(item.idStudente) }} €
            </template>
            <template v-slot:item.scarica="{ item }">
              <a v-if="item.certificatoReferenteIstituto" @click="getCertificato(item)">
                <v-icon> mdi-tray-arrow-down
                </v-icon>
              </a>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import CorsiRepository from "@/api/CorsiRepository";
export default {
  name: "StudentiConCertificatoComponent",
  components: {},
  props: {
    studentiCorso: {
      type: Array,
      required: true,
    },
    lezioni: {
      type: Array,
      required: true,
    },
    idStrutture: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    referenteIstituto: false,
    newGenere: null,
    generiStudente: [],
    keyStudenti: 0,
    idStudenteDelete: null,
    dialogDelete: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingStudenti: false,
    studenti: [],
    studentiAttestati: [],
    headers: [
      { text: "Id Attestato", value: "certificatoReferenteIstituto" },
      { text: "Cognome", value: "cognome" },
      { text: "Nome", value: "nome" },
      { text: "Codice Fiscale", value: "cf", sortable: false },
      { text: "Genere", value: "genereStudente" },
      { text: "Data di nascita", value: "dataNascita" },
      { text: "Comune di nascita", value: "nomeComuneNascita" },
      { text: "Comune di residenza", value: "nomeComuneResidenza" },
      { text: "Classe", align: "center", value: "classe" },
      { text: "Tot ore freq", align: "center", value: "totOre" },
      { text: "% ore freq", align: "center", value: "percentualeOre" },
      { text: "Importo tariffa", align: "center", value: "importo" },
      { text: "Valore attributivo", value: "valore" },
      { text: "Scarica attestato", value: "scarica", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno"]),
    readOnly() {
      return !!this.isReadOnly;
    },
    loading: function () {
      return this.loadingStudenti;
    },
  },
  watch: {
    keyStudenti() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    idCorso() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.studentiCorso && this.lezioni) {
        this.getGeneriStudente();
        this.getStudenti();
      }
    },
    async getStudenti() {
      this.loadingStudenti = true;
      this.studentiAttestati = this.studentiCorso.filter(
        (item) => item.certificatoReferenteIstituto
      );
      this.loadingStudenti = false;
    },
    async getGeneriStudente() {
      this.generiStudente = await CorsiRepository.getGeneriStudente();
    },
    getTotOre(idStudente) {
      let totOre = 0;
      this.lezioni.forEach(function (lezione) {
        lezione.lezioniStudenti.forEach(function (lezioneStudente) {
          if (lezioneStudente.studente.id === idStudente) {
            totOre = totOre + lezioneStudente.oreSvolte;
          }
        });
      });
      return totOre;
    },
    getPercentualeOre(idStudente) {
      let totOre = this.getTotOre(idStudente);
      //let totOreCorso = 15;
      let totOreCorso = 0;
      this.lezioni.forEach(function (lezione) {
        totOreCorso = totOreCorso + lezione.oreErogate;
      });
      //calcolo del tasso percentuale: (oreStudente · 100)/TotOreCorso senza decimali
      return Math.trunc((totOre * 100) / totOreCorso);
    },
    async getCertificato(certificato) {
      await CorsiRepository.getFileCertificato(
        this.idStrutture,
        certificato.idCertificato,
        certificato.certificatoReferenteIstituto.nomeOriginale +
          certificato.idStudente +
          "-" +
          certificato.cognome
      );
    },
    calcolaImportoStudente(idStudente) {
      const totOreStudente = this.getTotOre(idStudente);
      return totOreStudente === 15
        ? parseFloat(Math.floor(totOreStudente * 16.67)).toFixed(2)
        : parseFloat(totOreStudente * 16.67).toFixed(2);
    },
  },
};
</script>

<style scoped>
.table-custom {
  border-collapse: collapse;
  width: 100%;
}

.table-custom td,
.table-custom th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-custom tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-custom tr:hover {
  background-color: #ddd;
}

.table-custom th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ccc6c6;
  color: white;
}
</style>
