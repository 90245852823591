<template>
  <v-container fluid>
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-row>
      <v-col cols="9">
        <h1>Controllo formale rendiconti di progetto</h1>
      </v-col>
      <v-col cols="3"> <RiquadroProfiloRuolo /> </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <div v-if="loadingPage">
      <v-card
        elevation="5"
        v-if="verbaliControlli && verbaliControlli.length"
        :loading="loadingRendiconti"
      >
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(verbale, index) in verbaliControlli"
            :key="index"
          >
            <v-expansion-panel-header color="blue" class="white--text">
              <v-row>
                <v-col cols="2">
                  <v-card-text>
                    Id Verbale <br />
                    <br />
                    <b> {{ verbale.id }} </b>
                  </v-card-text>
                </v-col>
                <v-col cols="5">
                  <v-card-text>
                    Periodo di riferimento delle attività dal
                    <br />
                    <br />
                    <b>
                      {{
                        verbale.dataInizioAttivita
                          ? getTextDate(verbale.dataInizioAttivita)
                          : "Non Disponibile"
                      }}
                      al
                      {{
                        verbale.dataFineAttivita
                          ? getTextDate(verbale.dataFineAttivita)
                          : " Non disponibile"
                      }}
                    </b>
                  </v-card-text>
                </v-col>
                <v-col cols="3">
                  <v-card-text>
                    Data Creazione Campione <br />
                    <br />
                    <b> {{ getTextDate(verbale.dataCreazione) }} </b>
                  </v-card-text>
                </v-col>
                <v-col cols="2">
                  <v-card-text class="text-white">
                    <br />
                    <router-link
                      style="color: white"
                      class="text-white"
                      :to="{
                        name: 'VerbaleCampionamentoUdm',
                        params: {
                          anno: anno,
                          idVerbale: verbale.id,
                          readOnly: true,
                        },
                      }"
                    >
                      Dettaglio verbale
                      <v-icon dark class="mx-2">mdi-magnify</v-icon>
                    </router-link>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn
                class="ma-3"
                @click="
                  esportaSuExcel(
                    verbale.id,
                    verbale.verbaleControlloFormaleRendiconto
                  )
                "
              >
                Esporta su excel <v-icon> mdi-file-excel </v-icon>
              </v-btn>
              <ListaRendicontiControlloFormaleComponent
                :selezionabili="true"
                :rendiconti="
                  getRendicontiVerbale(
                    verbale.verbaleControlloFormaleRendiconto
                  )
                "
                @keyup="setRendicontiSelezionati"
                @input="setRendicontiSelezionati"
                @cambio-stato-regis="changeStatoRegis"
                :key="assegnazioneEseguita"
              />
              <v-card-actions>
                <v-row
                  v-if="rendicontiSelezionati && rendicontiSelezionati.length"
                >
                  <v-col cols="12" class="d-flex flex-row-reverse">
                    <dialog-assegna-incarichi-udm
                      :rendiconti-selezionati="rendicontiSelezionati"
                      v-if="isRoleResponsabileControllo || isRoleConsulenza"
                      @assegnazione-eseguita="assegnazioneEseguita += 1"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" class="d-flex flex-row-reverse">
                    <v-btn
                      v-if="isRoleResponsabileControllo || isRoleConsulenza"
                      :disabled="true"
                      class="primary"
                      text
                    >
                      Assegna incarico
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card v-else class="text-center">
        <v-card-actions class="justify-center">
          <alert-component
            testo="Non è stato creato alcun verbale di controllo"
            tipo-alert="info"
            :width="800"
          />
        </v-card-actions>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import ListaRendicontiControlloFormaleComponent from "@/components/Controlli/Udm/ListaRendicontiControlloFormaleComponent";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import DialogAssegnaIncarichiUdm from "@/components/Controlli/Udm/DialogAssegnaIncarichiUdm";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import ControlliUdmRepository from "@/api/ministero/Udm/ControlliUdmRepository";

export default {
  components: {
    BreadcrumbComponent,
    ListaRendicontiControlloFormaleComponent,
    AlertComponent,
    DialogAssegnaIncarichiUdm,
    RiquadroProfiloRuolo,
  },
  name: "ListaVerbaliFormaleUdm",
  data: () => ({
    loadingPage: false,
    loadingRendiconti: false,
    verbaliControlli: [],
    rendiconti: [],
    rendicontiSelezionati: [],
    assegnazioneEseguita: 0,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
  }),
  created() {
    this.initialize();
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/udm/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Controllo formale rendiconti",
            disabled: true,
            to: `/scrivania/controlli/nuovo-controllo`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno", "capofila", "user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  watch: {
    anno() {
      this.loadingPage = false;
      this.initialize();
    },
    assegnazioneEseguita() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getRendiconti(this.anno);
      }
    },
    async getRendiconti(anno) {
      this.loadingRendiconti = true;
      this.verbaliControlli =
        await ControlliUdmRepository.getVerbaliControlliFormaliRendicontiUdm(
          anno
        );
      this.loadingPage = true;
      this.loadingRendiconti = false;
      /*if (this.isRoleIncaricatoControllo) {
        let userId = this.user.id;
        let rendiconti = [
          ...this.verbaliControlli.verbaleControlloFormaleRendiconto.rendiconti,
        ];
        let rendicontiAssegnati = rendiconti.filter(function (rendiconto) {
          return rendiconto.assegnazioneControlloUdm !== null;
        });
        this.rendiconti = rendicontiAssegnati.filter(function (rendiconto) {
          if (rendiconto.assegnazioneControlloUdm.incaricato) {
            return rendiconto.assegnazioneControlloUdm.incaricato.id === userId;
          }
        });
      }*/
    },
    getTextDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      } else {
        return "";
      }
    },
    getRendicontiVerbale(listaRendicontiVerbale) {
      let rendiconti = listaRendicontiVerbale.map((item) => {
        if (item.rendiconto.verificaFormaleRendiconto) {
          if (item.rendiconto.verificaFormaleRendiconto.dataFineControllo) {
            switch (item.rendiconto.verificaFormaleRendiconto.esitoControllo) {
              case "Positivo":
                item.rendiconto.statoTesto = "Ammesso";
                item.rendiconto.statoColore = "success";
                break;
              case "Negativo":
                item.rendiconto.statoTesto = "Non Ammesso";
                item.rendiconto.statoColore = "red";
                break;
              case "Parzialmente positivo":
                item.rendiconto.statoTesto = "Parzialmente Ammesso";
                item.rendiconto.statoColore = "yellow";
                break;
              default:
                item.rendiconto.statoTesto = "In verifica";
                item.rendiconto.statoColore = "blue";
                break;
            }
          } else {
            item.rendiconto.statoTesto = "In verifica";
            item.rendiconto.statoColore = "blue";
          }
        } else {
          if (item.rendiconto.assegnazioneControlloUdm) {
            if (item.rendiconto.assegnazioneControlloUdm.statoAssegnazione) {
              item.rendiconto.statoTesto = "Da verificare";
              item.rendiconto.statoColore = "blue";
            } else {
              item.rendiconto.statoTesto = "Assegnato";
              item.rendiconto.statoColore = "blue";
            }
          } else {
            item.rendiconto.statoTesto = "Da assegnare";
            item.rendiconto.statoColore = "purple";
          }
        }
        return item.rendiconto;
      });
      if (this.isRoleIncaricatoControllo) {
        let userId = this.user.id;

        let rendicontiAssegnati = rendiconti.filter(function (rendiconto) {
          return (
            rendiconto.assegnazioneControlloUdm !== null &&
            rendiconto.assegnazioneControlloUdm.statoAssegnazione
          );
        });
        rendiconti = rendicontiAssegnati.filter(function (rendiconto) {
          if (rendiconto.assegnazioneControlloUdm.incaricato) {
            return rendiconto.assegnazioneControlloUdm.incaricato.id === userId;
          }
        });
      }
      return rendiconti;
    },
    setRendicontiSelezionati(value) {
      this.rendicontiSelezionati = value;
    },
    async esportaSuExcel(idVerbale, rendicontiCampionati) {
      const formBody = new FormData();
      formBody.append(
        "rendicontiVerbaleCampionamento",
        JSON.stringify({
          rendiconti: this.getRendicontiVerbale(rendicontiCampionati),
        })
      );
      await ControlliUdmRepository.esportaSuExcelRendicontiFormali(
        this.anno,
        idVerbale,
        formBody
      );
    },
    changeStatoRegis(esitoPositivo) {
      if (esitoPositivo) {
        this.snackbarColor = "success";
        this.snackbarText = "Dati salvati";
        this.snackbar = true;
        setTimeout(() => {
          this.initialize();
        }, 2000);
      } else {
        this.snackbarText =
          "ATTENZIONE: Errore durante il salvataggio del dato";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
  },
};
</script>
